export default {
  modelName: "project",
  label: "專案",
  fields: {
    id: {
      type: "text",
      label: "ID",
      width: '50px',
      readonly: true
    },
    url_id: {
      type: "text",
      label: "網址ID",
      rules: "required",
    },
    name: {
      type: "text",
      label: "名稱",
      autofocus: true,
      rules: "required",
      maxlength: 30,
    },
    slack_webhook_url: {
      type: 'text',
      label: 'Slack Webhook URL'
    },
    status: {
      type: 'select',
      label: '狀態',
      items: [
        { value: 0, text: '🛠 進行中' },
        { value: 1, text: '🌱 預備中' },
        { value: 2, text: '🚫 暫停' },
        { value: 3, text: '⭐ 結案' },
      ],
      defaultValue: 0
    },
    slack_channel: {
      type: 'text',
      label: 'Slack Channel',
    },
    zeplin: {
      type: 'text',
      label: 'Zeplin 帳號',
    },
    gitlab_wasa: {
      type: 'link',
      label: 'Gitlab 連結 (山葵)',
    },
    gitlab_client: {
      type: 'link',
      label: 'Gitlab 連結 (客戶)',
    },
    google_drive_wasa: {
      type: 'link',
      label: 'Googl Drive 連結 (山葵)',
    },
    google_drive_client: {
      type: 'link',
      label: 'Googl Drive 連結 (客戶)',
    },
    remark: {
      type: 'textarea',
      label: '備註'
    },
    noun: {
      type: 'list',
      label: '專案名詞對照表',
      fields: {
        text: {
          type: 'text',
          label: '中文',
        },
        name: {
          type: 'text',
          label: '英文',
        },
      },
      showFields: ['text', 'name'],
    },
    relate_links: {
      type: 'list',
      label: '其他相關連結',
      fields: {
        name: {
          type: 'text',
          label: '名稱',
          rules: ['required'],
        },
        link: {
          type: 'link',
          label: '連結',
          rules: ['required'],
        },
        remark: {
          type: 'textarea',
          label: '備註',
        },
      },
      showFields: ['name', 'link', 'remark'],
    },
    relate_infos: {
      type: 'list',
      label: '相關資訊',
      sm: 12,
      fields: {
        name: {
          type: 'text',
          label: '資訊名稱',
          rules: ['required'],
        },
        remark: {
          type: 'textarea',
          label: '資訊內容',
        },
      },
      showFields: ['name', 'remark'],
    },
    creator: {
      type: 'belongsTo',
      label: '建立者',
      textKey: "name",
      modelName: "wasa",
      readonly: true,
    },
    wasas: {
      type: 'belongsToMany',
      label: '山葵仔',
      textKey: "name",
      modelName: "wasa",
    },
    updated_admin: {
      type: "belongsTo",
      label: "最後更新人",
      readonly: true,
      textKey: "name",
      modelName: "wasa"
    },
  },
}
export default [
  {
    path: '/analytics/time',
    name: 'analytics_time',
    component: () => import('../views/Analytics/Time.vue'),
    meta: {
      middleware: 'auth',
      requiredScopes: ['boss', 'pm']
    }
  },
  {
    path: '/analytics/me',
    name: 'analytics_me',
    component: () => import('../views/Analytics/Me.vue'),
    meta: {
      middleware: 'auth',
    }
  },
  {
    path: '/analytics/company',
    name: 'analytics_company',
    component: () => import('../views/Analytics/Company.vue'),
    meta: {
      middleware: 'auth',
      requiredScopes: ['boss']
    }
  },
  {
    path: '/analytics/wasa',
    name: 'analytics_wasa',
    component: () => import('../views/Analytics/Wasa.vue'),
    meta: {
      middleware: 'auth',
      requiredScopes: ['boss']
    }
  },
  {
    path: '/analytics/overtime_tasks',
    name: 'analytics_overtime_tasks',
    component: () => import('../views/Analytics/OvertimeTasks.vue'),
    meta: {
      middleware: 'auth',
      requiredScopes: ['boss']
    }
  },
  {
    path: '/analytics/overtime_evaluation_materials',
    name: 'analytics_overtime_evaluation_materials',
    component: () => import('../views/Analytics/OvertimeEvaluationMaterials.vue'),
    meta: {
      middleware: 'auth',
      requiredScopes: ['boss']
    }
  },
  {
    path: '/overtime/calc',
    name: 'overtime_calc',
    component: () => import('../views/OvertimeCalc/Index.vue'),
    meta: {
      middleware: 'auth',
      requiredScopes: ['boss', 'accounting']
    }
  },
  {
    path: '/analytics/overtime_tasks',
    name: 'analytics_overtime_tasks',
    component: () => import('../views/Analytics/OvertimeTasks.vue'),
    meta: {
      middleware: 'auth',
      requiredScopes: ['boss']
    }
  },
]
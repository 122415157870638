import axios from 'axios'
export default {
  async create(data) {
    return new Promise((resolve, reject) => {
      axios
        .post('pocket_image', data)
        .then(res => {
          resolve(res.data.data)
        })
        .catch((err) => {
          reject(err)
        })
    })
  },
  async upload(file, fileName, signed = false) {
    return new Promise((resolve, reject) => {
      axios
        .get('pocket_image/upload_url', {
          params: {
            name: fileName
          }
        })
        .then(res => {
          const uploadUrl = res.data
          axios
            .put(uploadUrl, file)
            .finally((f) => {
              console.log(f)
              const createData = {
                name: fileName,
                signed: signed,
              }
              const _url = uploadUrl.split("?")[0]
              if (signed) {
                createData.signed_url = _url
              } else {
                createData.url = _url
              }
              this
                .create(createData)
                .then(res => {
                  resolve(res)
                })
                .catch((err) => {
                  reject(err)
                })
            })
        })
        .catch((err) => {
          reject(err)
        })
    })
  }
}
import moment from 'moment'
export default {
  modelName: "wasa",
  label: "山葵仔",
  fields: {
    id: {
      type: "text",
      label: "ID",
      readonly: true
    },
    name: {
      type: "text",
      label: "名稱",
      autofocus: true,
      rules: "required"
    },
    working_status: {
      type: 'select',
      label: '任用狀態',
      rules: 'required',
      defaultValue: 1,
      items: [
        {
          value: 0,
          text: '已離職'
        },
        {
          value: 1,
          text: '任職中'
        },
        {
          value: 2,
          text: '留職停薪'
        },
      ]
    },
    identity_id: {
      type: 'text',
      label: '身分證字號'
    },
    mobile: {
      type: 'text',
      label: '手機'
    },
    wasa_mail: {
      type: 'email',
      label: '山葵信箱'
    },
    personal_mail: {
      type: 'email',
      label: '個人信箱'
    },
    identity_card_front: {
      type: 'file',
      label: '附件上傳-身分證正面',
      uploadUrl: 'wasa/file/'
    },
    identity_card_back: {
      type: 'file',
      label: '附件上傳-身分證背面',
      uploadUrl: 'wasa/file/'
    },
    health_card_front: {
      type: 'file',
      label: '附件上傳-健保卡正面',
      uploadUrl: 'wasa/file/'
    },
    diploma: {
      type: 'file',
      label: '附件上傳-畢業證書',
      uploadUrl: 'wasa/file/'
    },
    medical_report: {
      type: 'file',
      label: '附件上傳-體檢報告',
      uploadUrl: 'wasa/file/'
    },
    public_key: {
      type: 'textarea',
      label: 'Public Key'
    },
    start_date: {
      type: 'date',
      label: '到職日期',
      defaultValue: moment().format('YYYY-MM-DD')
    },
    end_date: {
      type: 'date',
      label: '離職日期'
    },
    is_active: {
      type: 'switch',
      label: '帳號狀態',
      activeText: '使用中',
      inactiveText: '停用',
      defaultValue: 0,
    },
    password: {
      type: 'text',
      label: '密碼',
      rules: 'required'
    },
    email: {
      type: "email",
      label: "Email",
      rules: 'required|email',
      maxlength: 50,
      note: '新增後不可修改'
    },
    gitlab_account: {
      type: "text",
      label: "Gitlab帳號",
    },
    github_account: {
      type: "text",
      label: "Github帳號",
    },
    // password: {
    //   type: 'text',
    //   label: '密碼',
    //   rules: 'required|min:6',
    //   maxlength: 20,
    // },
    avatar: {
      type: "image",
      label: "頭像",
      uploadUrl: 'wasa/avatar/'
    },
    status: {
      type: 'select',
      label: '狀態',
      rules: 'required',
      defaultValue: 1,
      items: [
        {
          value: 1,
          text: 'Active'
        },
        {
          value: 0,
          text: 'Inactive'
        },
      ]
    },
    relate_links: {
      type: "list",
      label: "相關連結",
      fields: {
        name: {
          type: "text",
          label: "名稱",
          rules: "required"
        },
        link: {
          type: "link",
          label: "連結",
          rules: "required"
        },
        remark: {
          type: "textarea",
          label: "備註"
        }
      },
      showFields: ["name", 'link', 'remark']
    },
    scopes: {
      type: 'multi-select',
      label: '權限角色',
      defaultValue: ['wasa'],
      items: [
        {
          value: 'boss',
          text: '山葵大老'
        },
        {
          value: 'wasa',
          text: '山葵仔'
        },
        {
          value: 'pm',
          text: 'PM'
        },
        {
          value: 'ui',
          text: '設計師'
        },
        {
          value: 'engineer',
          text: '工程師'
        },
      ]
    },
    speed: {
      type: 'number',
      label: '執行速度',
      defaultValue: 50
    },
    score: {
      type: 'number',
      label: '山葵仔等級',
      defaultValue: 100
    },
    color: {
      type: 'color',
      label: '顏色',
    },
    // firestore_id: {
    //   type: 'text',
    //   label: 'Firestore ID',
    //   readonly:true
    // },
    updated_admin: {
      type: "belongsTo",
      label: "最後更新人",
      readonly: true,
      textKey: "name",
      modelName: "wasa"
    },
  },
}
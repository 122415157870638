export default {
  modelName: "excute_type",
  label: "執行類型",
  fields: {
    id: {
      type: "text",
      label: "ID",
      readonly: true
    },
    name: {
      type: "text",
      label: "名稱",
      autofocus: true,
      rules: "required"
    },
    sr: {
      type: 'switch',
      label: 'SR',
      activeText: '要',
      inactiveText: '不用',
      defaultValue: false,
    },
    dr: {
      type: 'switch',
      label: 'DR',
      activeText: '要',
      inactiveText: '不用',
      defaultValue: false,
    },
    cr: {
      type: 'switch',
      label: 'CR',
      activeText: '要',
      inactiveText: '不用',
      defaultValue: false,
    },
    excute_index: {
      type: 'number',
      label: '執行順序',
      defaultValue: 0
    },
    excute_index_second: {
      type: 'number',
      label: '次要執行順序',
      defaultValue: 0
    },
    updated_at: {
      type: "datetime",
      label: "最後更新時間",
      readonly: true
    },
  },
}
<template>
  <WsMain>
    <WsBreadcrumbs :menu="_breadcrumbsItems"></WsBreadcrumbs>
    <WsFlex class="mt-20">
      <WsTitle class="flex-0">{{_projectName}}</WsTitle>
      <WsSpacer></WsSpacer>
      <WsBtn :to="`/project/${_id}/update`">編輯</WsBtn>
    </WsFlex>
    <WsTabsContent
      v-if="project"
      class="mt-20"
      :tabs="_tabs"
      :routePre="`/project/${_id}/`"
    >
      <WsFlex>
        <WsState
          type="date"
          label="分析起日"
          v-model="state.startDate"
        ></WsState>
        <WsState
          class="ml-20"
          type="date"
          label="分析起日"
          v-model="state.endDate"
        ></WsState>
      </WsFlex>
      <WsRow class="mt-20">
        <WsCol class="col-4">
          <WsAnalyticsCard title="全部Task預計時數加總">
            <template v-slot:content>
              <WsTitle
                size="1"
                class="py-66"
                center
              >{{_taskEstimatedHours}}</WsTitle>
            </template>
          </WsAnalyticsCard>
        </WsCol>
        <WsCol class="col-4">
          <WsAnalyticsCard title="已完成Task預計時數加總">
            <template v-slot:content>
              <WsTitle
                size="1"
                class="py-66"
                center
              >{{_doneTaskEstimatedHours}}</WsTitle>
            </template>
          </WsAnalyticsCard>
        </WsCol>
        <WsCol class="col-4">
          <WsAnalyticsCard title="已完成Task執行時數加總">
            <template v-slot:content>
              <WsTitle
                size="1"
                class="py-66"
                center
              >{{_doneTaskFinishedHours}}</WsTitle>
            </template>
          </WsAnalyticsCard>
        </WsCol>
      </WsRow>
    </WsTabsContent>
  </WsMain>
</template>

<script>
import model from "@/models/project";
export default {
  metaInfo() {
    const title = this.project ? this.project.name : "專案";
    return {
      title: title,
    };
  },
  data: () => ({
    fields: model.fields,
    project: null,
    tasks: null,
    state: {
      startDate: null,
      endDate: null,
    },
  }),
  methods: {
    async $_fetchTask() {
      console.log('$_fetchTask');
      if (!this.state.startDate || !this.state.endDate) {
        return;
      }
      const params = {
        time_field: "start_time",
        start_time: this.state.startDate,
        end_time: this.state.endDate,
        order_by: "start_time",
        order_way: "asc",
        project: this.project.id,
      };
      console.log("params", params);
      const res = await this.$axios.get("task/index/all", {
        params: params,
      });
      this.tasks = res.data.data;
      console.log("this.tasks", this.tasks);
    },
    $_stateInit() {
      this.state.endDate = this.$moment().add(-3, "days").format("YYYY-MM-DD");
      this.state.startDate = this.$moment()
        .add(-3, "days")
        .add(-1, "month")
        .format("YYYY-MM-DD");
    },
    $_fetchProject() {
      return new Promise((resolve, reject) => {
        this.$axios
          .get(`/project/${this._id}`)
          .then((res) => {
            this.project = res.data.data;
            resolve();
          })
          .catch((err) => {
            reject(err);
          });
      });
    },
    isScopeValid(validScopes, userScopes) {
      const valid = validScopes.filter((value) =>
        userScopes.includes(value)
      ).length;
      return valid;
    },
  },
  computed: {
    _taskEstimatedHours() {
      if (!this.tasks) {
        return "-";
      }
      let _taskEstimatedHours = 0;
      this.tasks.forEach((task) => {
        _taskEstimatedHours += task.hour;
      });
      return _taskEstimatedHours;
    },
    _doneTaskFinishedHours() {
      if (!this.tasks) {
        return "-";
      }
      let _doneTaskFinishedHours = 0;
      this.tasks.forEach((task) => {
        if (task.is_finish) {
          _doneTaskFinishedHours += task.finish_hour;
        }
      });
      return _doneTaskFinishedHours;
    },
    _doneTaskEstimatedHours() {
      if (!this.tasks) {
        return "-";
      }
      let _doneTaskEstimatedHours = 0;
      this.tasks.forEach((task) => {
        if (task.is_finish) {
          _doneTaskEstimatedHours += task.hour;
        }
      });
      return _doneTaskEstimatedHours;
    },
    _tabs() {
      const _tabs = [
        {
          text: "總覽",
          to: "overview",
        },
      ];
      const isValid = this.isScopeValid(
        ["admin", "boss", "pm"],
        this.$store.state.auth.scopes
      );
      if (isValid) {
        _tabs.push({
          text: "Task",
          to: "task",
        });
      }
      return _tabs;
    },
    _projectName() {
      return this.project ? this.project.name : null;
    },
    _id() {
      return this.$route.params.id;
    },
    _breadcrumbsItems() {
      const _breadcrumbsItems = [];
      _breadcrumbsItems.push(
        ...[
          {
            text: "專案",
            to: "/project",
          },
        ]
      );
      if (this.project) {
        _breadcrumbsItems.push({
          text: this.project.name,
          disabled: true,
        });
      }
      return _breadcrumbsItems;
    },
  },

  watch: {
    state: {
      handler() {
        this.$_fetchTask();
      },
      deep: true,
    },
  },
  async mounted() {
    this.$_stateInit();
    await this.$_fetchProject();
    this.$_fetchTask();
  },
};
</script>

<style>
</style>
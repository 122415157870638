export default {
  modelName: "out_source",
  label: "外部資源",
  fields: {
    id: {
      type: "text",
      label: "ID",
      readonly: true
    },
    name: {
      type: "text",
      label: "名稱",
      autofocus: true,
      rules: "required"
    },
    updated_at: {
      type: "datetime",
      label: "最後更新時間",
      readonly: true
    },
    sequence: {
      type: "text",
      label: "排序"
    },
    address: {
      type: "text",
      label: "地址"
    },
    agency: {
      type: "text",
      label: "單位"
    },
    email: {
      type: "text",
      label: "Email"
    },
    line: {
      type: "text",
      label: "LINE"
    },
    mobile: {
      type: "text",
      label: "手機"
    },
    tel: {
      type: "text",
      label: "電話"
    },
    relate_links: {
      type: "list",
      label: "相關連結",
      fields: {
        name: {
          type: 'text',
          label: '連結名稱',
        },
        link: {
          type: 'link',
          label: '連結'
        }
      }
    },
    remark: {
      type: "textarea",
      label: "備註"
    },
  },
}
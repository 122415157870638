export default {
  modelName: "client",
  label: "山葵客戶",
  fields: {
    id: {
      type: "text",
      label: "ID",
      readonly: true
    },
    name: {
      type: "text",
      label: "名稱",
      autofocus: true,
      rules: "required"
    },
    cow: {
      type: 'switch',
      label: '是否為乳牛',
      activeText: '是乳牛',
      inactiveText: '不是乳牛',
      defaultValue: false,
    },
    score: {
      type: 'text',
      label: '分數'
    },
    uniform_numbers: {
      type: 'text',
      label: '統編'
    },
    tel: {
      type: 'text',
      label: '電話'
    },
    address: {
      type: 'text',
      label: '地址'
    },
    official_link: {
      type: 'text',
      label: '官方連結'
    },
    remark: {
      type: 'textarea',
      label: '備註'
    },
    bargain_rate: {
      type: 'text',
      label: '議價增加比例'
    },
    windows: {
      type: 'list',
      label: "窗口",
      fields: {
        name: {
          type: "text",
          label: "名稱"
        },
        remark: {
          type: "textarea",
          label: "備註"
        }
      },
      showFields: ['name', 'remark']
    },
    updated_at: {
      type: "datetime",
      label: "最後更新時間",
      readonly: true
    },
    sequence: {
      type: "text",
      label: "排序"
    },
  },
}
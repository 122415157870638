export default {
  modelName: "hr_position",
  label: "職位",
  fields: {
    id: {
      type: "text",
      label: "ID",
      readonly: true
    },
    name: {
      type: "text",
      label: "名稱",
      autofocus: true,
      rules: "required",
      maxlength: 30,
    },
    updated_admin: {
      type: "belongsTo",
      label: "最後更新人",
      readonly: true,
      textKey: "name",
      modelName: "wasa"
    },
  },
}
// import axios from 'axios'
import store from '@/store'
import serviceAuth from './service/api/v1/auth'
import serviceLocale from './service/api/v1/locale'
import config from '@/__vue2stone_cms_config'
import auth from './service/api/v1/auth'
import * as changeCase from "change-case";

export default {

  async middlewareAuth(to, from, next) {

    // CurrentUser Set
    const accessToken = auth.getToken()
    let currentUser
    if (accessToken && !store.state.auth.currentUser) {
      try {
        currentUser = await serviceAuth.getUser()
      } catch (error) {
        currentUser = null
      }
    } else {
      currentUser = store.state.auth.currentUser
    }

    // Locale Set
    if (config.locale.api) {
      serviceLocale.setLocalesFromAPI()
    }

    // Auth Check
    const requiresAuth = to.matched.some((record) => record.meta.middleware && record.meta.middleware == 'auth');

    if (requiresAuth && !currentUser) {
      const query = (to.path && to.path != '/') ? { redirect: to.path } : {}
      next({ path: '/login', query: query });
      return;
    }

    // Login Check
    if (to.name == 'login') {
      if (!currentUser) {
        next();
        return
      } else {
        next('/');
        return;
      }
    }

    // Logout Check
    if (to.name == 'logout') {
      if (!currentUser) {
        next('/login');
        return
      } else {
        next();
        return;
      }
    }

    // Scope Check
    const requiredScopes = to.meta && to.meta.requiredScopes ? to.meta.requiredScopes : null
    if (requiredScopes) {
      const scopes = store.state.auth.scopes
      if (!scopes) {
        next('404');
        return
      }
      const hasScope = requiredScopes.some(scope => scopes.indexOf(scope) >= 0)
      if (!hasScope) {
        next('404');
        return;
      } else {
        next()
        return
      }
    }

    next()
    return
  },

  getCmsRoutes(routes, routeNames = []) {
    const _cmsRoutes = [
      ...routes,
      {
        path: '/login',
        name: 'login',
        component: () => import('./views/Login.vue'),
        meta: {
          template: 'auth'
        }
      },
      {
        path: '/logout',
        name: 'logout',
        component: () => import('./views/Logout.vue'),
        meta: {
          template: 'auth'
        }
      },
    ]
    if (routeNames.includes('tulpa')) {
      _cmsRoutes.push(...[
        ...this.getResourceRoutes({
          routes: ['index', 'create', 'update', 'read'],
          modelName: 'tulpa_section_template',
          filePrefix: "/Tulpa",
          viewsPath: '__vue2stone_cms/views'
        }),
        ...this.getResourceRoutes({
          routes: ['index', 'create', 'update', 'read'],
          modelName: 'tulpa_section',
          filePrefix: "/Tulpa",
          viewsPath: '__vue2stone_cms/views'
        }),
        ...this.getResourceRoutes({
          routes: ['index', 'create', 'update', 'read'],
          modelName: 'tulpa_page',
          filePrefix: "/Tulpa",
          viewsPath: '__vue2stone_cms/views'
        }),
      ])
    }
    _cmsRoutes.push(...[
      {
        path: '/*',
        name: 'notfound',
        component: () => import('./views/Notfound.vue')
      },
    ])
    return _cmsRoutes
  },

  getResourceRoutes({
    routes = ['index'],
    modelName,
    filePrefix = '',
    pathPrefix = '',
    viewsPath = 'views',
    meta = {
      middleware: 'auth'
    }
  }) {
    const _routes = []
    const _pascalCase = changeCase.pascalCase(modelName);
    if (routes.includes('index')) {
      _routes.push({
        path: `${pathPrefix}/${modelName}`,
        name: `${modelName}_index`,
        component: () => import(`@/${viewsPath}${filePrefix}/${_pascalCase}/Index.vue`),
        meta: meta
      })
    }
    if (routes.includes('create')) {
      _routes.push({
        path: `${pathPrefix}/${modelName}/create`,
        name: `${modelName}_create`,
        component: () => import(`@/${viewsPath}${filePrefix}/${_pascalCase}/Create.vue`),
        meta: meta
      })
    }
    if (routes.includes('update')) {
      _routes.push({
        path: `${pathPrefix}/${modelName}/:id/update`,
        name: `${modelName}_update`,
        component: () => import(`@/${viewsPath}${filePrefix}/${_pascalCase}/Update.vue`),
        meta: meta
      })
    }
    if (routes.includes('read')) {
      _routes.push({
        path: `${pathPrefix}/${modelName}/:id`,
        name: `${modelName}_read`,
        component: () => import(`@/${viewsPath}${filePrefix}/${_pascalCase}/Read.vue`),
        meta: meta
      })
    }
    return _routes;
  }
}
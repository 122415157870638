<template>
  <WsMain>
    <WsCrud
      :modelName="$store.state.model[modelName].modelName"
      :label="$store.state.model[modelName].label"
      :fields="$store.state.model[modelName].fields"
      :liveSearching="true"
      :showFields="showFields"
      :expandable="false"
      :showExpand="false"
      :creatable="false"
      :createHideFields="createHideFields"
      :updateHideFields="updateHideFields"
      :filterSelects="filterSelects"
    ></WsCrud>
  </WsMain>
</template>

<script>
export default {
  metaInfo() {
    return {
      title: "訂單收款階段",
    };
  },

  data: () => ({
    modelName: "order_stage",
    showFields: [
      "id",
      "status",
      "order_no",
      "order",
      "stage_name",
      "price",
      "total_price",
      "tax_price",
      "invoice_date",
      "invoice_number",
    ],
    createHideFields: ["order"],
    updateHideFields: ["order"],
    filterSelects: {
      status: {
        label: "狀態",
        items: [
          {
            text: "全部",
            value: "all",
            params: {},
          },
          {
            text: "未結",
            value: "notyet",
            params: {
              invoice_date: "null",
              transfer_date: "null",
            },
          },
          {
            text: "預收",
            value: "preget",
            params: {
              invoice_date: "null",
              transfer_date: "not_null",
            },
          },
          {
            text: "應收＋逾期",
            value: "overdate+waitget",
            params: {
              invoice_date: "not_null",
              transfer_date: "null",
            },
          },
          {
            text: "已收",
            value: "got",
            params: {
              invoice_date: "not_null",
              transfer_date: "not_null",
            },
          },
        ],
      },
    },
  }),
};
</script>
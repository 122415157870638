<template>
  <div>
    <WsDialog
      ref="WsDialog"
      v-model="dialog"
      :title="`新增${label}`"
      :type="type"
      :escMetaClose="true"
      @close="$_onDialogClose()"
    >
      <template v-slot:content>
        <WsErrorMessage
          v-if="pageErrorMessage"
          class="mb-20"
        >{{pageErrorMessage}}</WsErrorMessage>
        <ValidationObserver ref="form">
          <form
            ref="createForm"
            @submit.prevent
          >
            <WsStateLocales
              v-if="_localeField&&dialog"
              :fields="_localeField.fields"
              :value="createData[_localeFieldKey]"
              @input="$_onInput(_localeFieldKey,$event)"
            ></WsStateLocales>
            <div class="ws-row">
              <div
                class="ws-col"
                v-for="(field, fieldKey) in _generalFields"
                cols="12"
                :class="[`sm-col-${field.sm?field.sm:12}`]"
                :key="fieldKey"
              >
                <WsState
                  :type="field.type"
                  :label="field.label"
                  :fields="field.fields"
                  :showFields="field.showFields"
                  :rules="field.rules"
                  :items="field.items"
                  :modelName="field.modelName"
                  :textKey="field.textKey"
                  :uploadUrl="field.uploadUrl"
                  :mode="field.mode"
                  :staticUrl="field.staticUrl"
                  :postData="field.postData"
                  :requestMethod="field.requestMethod"
                  :conditions="field.conditions"
                  :activeText="field.activeText"
                  :inactiveText="field.inactiveText"
                  :note="field.note"
                  :maxWidth="field.maxWidth"
                  :autofocus="field.autofocus"
                  :parent="field.parentKey"
                  :parentState="$_getParentState(fieldKey,fields)"
                  :requiredField="field.requiredField"
                  :displayLabel="field.displayLabel"
                  :value="createData[fieldKey]"
                  :errorMessage="$_getErrorMessage(fieldKey)"
                  @input="$_onInput(fieldKey,$event)"
                />
              </div>
            </div>
          </form>
        </ValidationObserver>
      </template>
      <template v-slot:rightActions>
        <WsBtn
          @click="$_onCancel()"
          minWidth="100px"
          color="textSecondary"
          outlined
        >取消</WsBtn>
        <WsBtn
          @click="$_onSubmit()"
          minWidth="100px"
          :loading="submitLoading"
          color="primary"
        >送出</WsBtn>
      </template>
    </WsDialog>
  </div>
</template>

<script>
export default {
  name: "WsCreateDialog",
  data: () => ({
    pageErrorMessage: null,
    createData: {},
    submitLoading: false,
    dialog: false,
  }),
  methods: {
    $_onDialogClose() {
      this.close();
    },
    error(message) {
      this.pageErrorMessage = message;
    },
    $_getErrorMessage(fieldKey) {
      if (this.errorMessages) {
        if (this.errorMessages[fieldKey]) {
          return this.errorMessages[fieldKey];
        } else {
          return null;
        }
      } else {
        return null;
      }
    },
    $_keydownHandler(event) {
      if (event.metaKey && event.key == "Enter") {
        this.$_onSubmit();
      }
    },
    $_getParentState(fieldKey, modedFields) {
      const field = this.$_CMS_getFieldByFieldKey(fieldKey, modedFields);
      if (field.parentKey) {
        return this.createData[field.parentKey];
      } else if (field.requiredField) {
        return this.createData[field.requiredField];
      } else {
        return null;
      }
    },
    $_onInput(fieldKey, $event) {
      this.createData[fieldKey] = $event;
    },
    stopLoading() {
      this.submitLoading = false;
    },
    startLoading() {
      this.submitLoading = true;
    },
    async $_onSubmit() {
      const isValidate = await this.$refs.form.validate();
      if (!isValidate) {
        return;
      }
      this.$emit("submit", this.$_formatSubmitData(this.createData));
    },
    $_removeFieldFromArray(array, fieldName) {
      if (!array) {
        return [];
      }
      const _array = [];
      array.forEach((arrayItem) => {
        const _item = {};
        for (let key in arrayItem) {
          if (key == fieldName) {
            continue;
          } else {
            _item[key] = arrayItem[key];
          }
        }
        _array.push(_item);
      });
      return _array;
    },
    $_formatSubmitData(createData) {
      const _fornatSubmitData = {};
      for (let key in createData) {
        const field = this.fields[key];
        const createDataItem = createData[key];
        if (field.type == "hasManyList") {
          _fornatSubmitData[key] = this.$_removeFieldFromArray(
            createDataItem,
            "id"
          );
        } else if (field.type == "hasManyList") {
          _fornatSubmitData[key] = this.$_removeFieldFromArray(
            createDataItem,
            "id"
          );
        } else {
          _fornatSubmitData[key] = createDataItem;
        }
      }
      return _fornatSubmitData;
    },
    $_onCancel() {
      this.close();
    },
    close() {
      this.dialog = false;
      this.pageErrorMessage = null;
      this.reset();
      window.removeEventListener("keydown", this.$_keydownHandler);
    },
    $_createDataSet(template, createData, fields) {
      for (let fieldKey in fields) {
        const field = fields[fieldKey];
        if (field.defaultValue !== undefined) {
          template.$set(createData, fieldKey, field.defaultValue);
        } else {
          template.$set(createData, fieldKey, null);
        }
      }
    },
    reset() {
      this.$refs.form.reset();
      this.submitLoading = false;
    },
    open(item = null) {
      this.$_CMS_updateDataSetFromFields(
        this,
        this.createData,
        this.fields,
        item
      );
      this.dialog = true;
      window.addEventListener("keydown", this.$_keydownHandler);
    },
  },
  computed: {
    _generalFields() {
      const _generalFields = {};
      for (let fieldKey in this.fields) {
        const field = this.fields[fieldKey];
        if (field.type == "locales") {
          continue;
        }
        _generalFields[fieldKey] = field;
      }
      return _generalFields;
    },
    _localeField() {
      let _localeField = null;
      for (let fieldKey in this.fields) {
        const field = this.fields[fieldKey];
        if (field.type == "locales") {
          _localeField = field;
        }
      }
      return _localeField;
    },
    _localeFieldKey() {
      let _localeFieldKey = null;
      for (let fieldKey in this.fields) {
        const field = this.fields[fieldKey];
        if (field.type == "locales") {
          _localeFieldKey = fieldKey;
        }
      }
      return _localeFieldKey;
    },
  },
  props: {
    type: {
      type: String,
      default: "leftPanel",
    },
    errorMessages: {
      type: Object,
      default: null,
    },
    modelName: String,
    label: {
      type: String,
      default: null,
    },
    fields: Object,
  },
  watch: {
    dialog: {
      handler() {
        if (!this.dialog) {
          this.reset();
        }
      },
    },
  },
};
</script>
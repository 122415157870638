<template>
  <div class="ws-auth-layout">
    <div class="ws-auth-layout__header">
      <div class="ws-auth-layout__header__logo">
        <img
          :src="_logo"
          alt=""
        >
      </div>
    </div>
    <div class="ws-auth-layout__container">
      <router-view />
    </div>
  </div>
</template>
<script>
export default {
  computed: {
    _logo() {
      if (this.$config.theme.logo) {
        return this.$config.theme.logo;
      } else {
        return require("@/__vue2stone_cms/img/logo.svg");
      }
    },
  },
};
</script>
<style lang="scss">
.ws-auth-layout {
  min-height: 100vh;
  width: 100%;
  .ws-auth-layout__header {
    @include flex_center;
    position: fixed;
    top: 0;
    left: 0;
    width: 100%;
    height: 50px;
    background: var(--appBar-1);
    box-shadow: 0 2px 4px -1px rgba(0, 0, 0, 0.2),
      0 4px 5px 0 rgba(0, 0, 0, 0.14), 0 1px 10px 0 rgba(0, 0, 0, 0.12);
    .ws-auth-layout__header__logo {
      @include flex_center;
      width: 120px;
      height: 40px;
      img {
        max-width: 100%;
        max-height: 100%;
      }
    }
  }
  .ws-auth-layout__container {
    @include flex;
    @include flex_align;
    flex-direction: column;
    padding: 40px;
    padding-top: 50px;
    @media (max-width: 767px) {
      padding: 0;
      padding-top: 50px;
    }
  }
}
</style>
<template>
  <WsMain>
    <WsRow>
      <WsCol class="col-4">
        <WsAnalyticsModelRangeSum
          title="訂單總金額"
          unitPreText="NT"
          modelName="order"
          countField="price"
          range="thismonth"
          dateField="deal_date"
          currency
        ></WsAnalyticsModelRangeSum>
      </WsCol>
    </WsRow>
    <WsRow class="mt-8">
      <WsCol class="col-6">
        <WsCard>
          <WsTitle size="5">逾期未完成Task</WsTitle>
          <WsCrudTable
            class="mt-20"
            ref="WsCrudTable"
            :items="undoTasks"
            :fields="taskFields"
            :showFields="undoTaskShowFields"
            :inRowBtnRead="false"
            :inRowBtnUpdate="false"
            :inRowBtnDelete="false"
            :expandable="false"
            modelName="task"
            :paginate="false"
          ></WsCrudTable>
        </WsCard>
      </WsCol>
      <WsCol class="col-6">
        <WsCard>
          <WsTitle size="5">逾期未完成Milestone</WsTitle>
          <WsCrudTable
            class="mt-20"
            ref="WsCrudTable"
            :items="undoMilestones"
            :fields="milestoneFields"
            :showFields="undoMilestoneShowFields"
            :inRowBtnRead="false"
            :inRowBtnUpdate="false"
            :inRowBtnDelete="false"
            :expandable="false"
            modelName="task"
            :paginate="false"
          ></WsCrudTable>
        </WsCard>
      </WsCol>
    </WsRow>
  </WsMain>
</template>

<script>
import task from "@/models/task";
import milestone from "@/models/milestone";
export default {
  metaInfo() {
    return {
      title: "山葵概況",
    };
  },

  data: () => ({
    undoTasks: [],
    undoMilestones: [],
    taskFields: task.fields,
    milestoneFields: milestone.fields,
    undoTaskShowFields: ["id", "name", "start_time", "taker", "creator"],
    undoMilestoneShowFields: ["id", "name", "date", "taker", "creator"],
  }),

  methods: {
    async $_fetchUndoTasks() {
      const params = {
        is_finish: 0,
        time_field: "start_time",
        end_time: this.$moment().add(-3, "days").format("YYYY-MM-DD"),
        order_by: "start_time",
      };
      const res = await this.$axios.get("task/index/all", {
        params: params,
      });
      this.undoTasks = res.data.data;
    },
    async $_fetchUndoMilestones() {
      const params = {
        is_finish: 0,
        time_field: "date",
        end_time: this.$moment().add(-3, "days").format("YYYY-MM-DD"),
        order_by: "start_time",
      };
      const res = await this.$axios.get("milestone/index/all", {
        params: params,
      });
      this.undoMilestones = res.data.data;
    },
  },

  mounted() {
    this.$_fetchUndoTasks();
    this.$_fetchUndoMilestones();
  },
};
</script>

<style>
</style>
export default {
  modelName: "voucher_template",
  label: "傳票公版",
  fields: {
    id: {
      type: "text",
      label: "ID",
      readonly: true
    },
    name: {
      type: "text",
      label: "名稱",
      rules: 'required',
      autofocus: true
    },
    remark: {
      type: 'textarea',
      label: '備註'
    },
    voucher_events: {
      type: 'list',
      label: '傳票事件',
      fields: {
        name: {
          type: "text",
          label: "名稱",
          rules: 'required',
          autofocus: true
        },
        borrow_amount: {
          type: "number",
          label: "借方金額",
        },
        loan_amount: {
          type: "number",
          label: "貸方金額",
        },
        remark: {
          type: 'textarea',
          label: '備註'
        },
        voucher: {
          type: "belongsTo",
          label: '傳票',
          textKey: "name",
          modelName: "voucher",
        },
        borrow_accounting_subject_first: {
          type: "belongsTo",
          label: '一級科目',
          textKey: "name",
          modelName: "accounting_subject_first",
        },
        borrow_accounting_subject_second: {
          type: "belongsTo",
          label: '二級科目',
          textKey: "name",
          modelName: "accounting_subject_second",
        },
        loan_accounting_subject_first: {
          type: "belongsTo",
          label: '一級科目',
          textKey: "name",
          modelName: "accounting_subject_first",
        },
        loan_accounting_subject_second: {
          type: "belongsTo",
          label: '二級科目',
          textKey: "name",
          modelName: "accounting_subject_second",
        },
      },
      showFields: ['name', 'remark']
    }
  },
}
<template>
  <WsMain>
    Hi, Wasa
  </WsMain>
</template>

<script>
export default {
  components: {},
};
</script>

<template>
  <WsMain>
    <WsCrud
      :modelName="$store.state.model[modelName].modelName"
      :label="$store.state.model[modelName].label"
      :fields="$store.state.model[modelName].fields"
      :liveSearching="true"
      :showFields="showFields"
      :infiniteScroll="true"
      :order="order"
      :plusOrderItems="plusOrderItems"
    ></WsCrud>
  </WsMain>
</template>

<script>
export default {
  metaInfo() {
    return {
      title: "二級科目",
    };
  },
  data: () => ({
    modelName: "accounting_subject_second",
    showFields: ["id", "accounting_subject_first", "name"],
    order: "accounting_subject_first",
    plusOrderItems: [
      {
        value: "accounting_subject_first",
        text: "一級科目",
        order_by: "accounting_subject_first",
        order_way: "asc",
      },
    ],
  }),
};
</script>
import moment from 'moment'
export default {
  modelName: "quotation",
  label: '報價單',
  fields: {
    id: {
      type: "text",
      label: "ID",
      readonly: true,
      width: '70px',
      sortable: true,
    },
    client: {
      type: "belongsTo",
      label: "山葵客戶",
      textKey: "name",
      modelName: "client",
      rules: 'required',
      sortable: true,
    },
    name: {
      type: 'text',
      label: '報價單名稱',
      rules: 'required',
      sortable: true,
    },
    creator_name: {
      type: 'text',
      label: '山葵人員',
      rules: 'required',
      width: '100px',
      sortable: true
    },
    contact_window: {
      type: 'text',
      label: '聯絡代表'
    },
    contact_window_email: {
      type: 'email',
      label: '電子郵件'
    },
    has_return_sign: {
      type: 'switch',
      label: '回簽',
      activeText: '要回簽欄位',
      inactiveText: '不用回簽欄位',
    },
    date: {
      type: 'date',
      label: '報價日期',
      defaultValue: moment().format('YYYY-MM-DD'),
      rules: 'required',
      width: '160px',
      sortable: true
    },
    begin_date: {
      type: 'date',
      label: '預計執行日期',
      defaultValue: moment().format('YYYY-MM-DD'),
      width: '160px',
      sortable: true
    },
    need_total_amount: {
      type: 'switch',
      label: '報價項目總計',
      activeText: '要總計',
      inactiveText: '不用總計',
    },
    excutes: {
      type: 'list',
      label: "執行項目",
      fields: {
        name: {
          type: 'text',
          label: '名稱',
          autofocus: true,
        },
        content: {
          type: 'textarea',
          label: '內容'
        },
        excute_days: {
          type: 'number',
          label: '執行天數'
        },
        price: {
          type: 'number',
          label: '價格'
        },
      },
      showFields: [
        'name',
        'content',
        'excute_days',
        'price',
      ]
    },
    descriptions: {
      type: 'list',
      label: "說明項目",
      fields: {
        content: {
          type: 'textarea',
          label: '內容',
          autofocus: true,
        },
      },
      showFields: ['content']
    },
    updated_admin: {
      type: "belongsTo",
      label: "最後更新人",
      readonly: true,
      textKey: "name",
      modelName: "wasa"
    },
  },
}
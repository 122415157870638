<template>
  <div class="ws-state-image">
    <img
      v-lazy="value"
      alt=""
      class="mb-10"
    >
    <WsPocketImagePicker @submit="$_onPocketImageSubmit($event)"></WsPocketImagePicker>
  </div>
</template>

<script>
export default {
  data: () => ({}),

  computed: {},

  methods: {
    $_onPocketImageSubmit($event) {
      this.$emit("input", $event);
    },
  },

  props: {
    value: {
      type: String,
      default: null,
    },
  },
};
</script>

<style>
</style>
import moment from 'moment'
export default {
  modelName: "hr_recruit",
  label: "面試紀錄",
  fields: {
    id: {
      type: "text",
      label: "ID",
      readonly: true
    },
    name: {
      type: "text",
      label: "名稱",
      autofocus: true,
      rules: "required",
      maxlength: 30,
    },
    gender: {
      type: 'select',
      label: '性別',
      rules: "required",
      defaultValue: 0,
      items: [
        {
          value: 0,
          text: '女'
        },
        {
          value: 1,
          text: '男'
        },
        {
          value: 2,
          text: '其他'
        },
      ]
    },
    resume_link: {
      type: 'link',
      label: '履歷連結'
    },
    hr_position: {
      type: "belongsTo",
      label: "面試職位",
      textKey: "name",
      modelName: "hr_position"
    },
    hr_stage: {
      type: 'select',
      label: '徵試階段',
      rules: 'required',
      defaultValue: 1,
      items: [
        { text: '電訪', value: 0 },
        { text: '面試', value: 1 },
        { text: '視訊面試', value: 2 },
      ]
    },
    birth: {
      type: 'date',
      label: '生日'
    },
    apply_date: {
      type: 'date',
      label: '履歷投遞日期'
    },
    interview_date: {
      type: 'date',
      label: '面試日期',
      defaultValue: moment().format('YYYY-MM-DD')
    },
    interview_remark: {
      type: 'textarea',
      label: '面試筆記'
    },
    gossip: {
      type: 'textarea',
      label: '評價打聽'
    },
    nickname: {
      type: "text",
      label: "別名",
      maxlength: 30,
    },
    education: {
      type: 'textarea',
      label: '學歷',
    },
    experience: {
      type: 'textarea',
      label: '經歷',
    },
    mobile: {
      type: 'text',
      label: '手機'
    },
    line: {
      type: 'text',
      label: 'Line'
    },
    facebook: {
      type: 'text',
      label: 'FB'
    },
    github: {
      type: 'text',
      label: 'Github'
    },
    website: {
      type: 'text',
      label: '網站'
    },
    // relate_files: {
    //   type: "list",
    //   label: "相關檔案",
    //   fields: {
    //     name: {
    //       type: "text",
    //       label: "名稱",
    //       rules: "required"
    //     },
    //     file: {
    //       type: "files",
    //       label: "檔案",
    //       uploadUrl: "/hr_recruit/file/",
    //     },
    //     remark: {
    //       type: "textarea",
    //       label: "備註"
    //     }
    //   },
    //   showFields: ["name", "file", "remark"]
    // },
    relate_links: {
      type: "list",
      label: "相關連結",
      fields: {
        name: {
          type: "text",
          label: "名稱",
          rules: "required"
        },
        link: {
          type: "link",
          label: "連結",
          rules: "required"
        },
        remark: {
          type: "textarea",
          label: "備註"
        }
      },
      showFields: ["name", 'link', 'remark']
    },
    updated_admin: {
      type: "belongsTo",
      label: "最後更新人",
      readonly: true,
      textKey: "name",
      modelName: "wasa"
    },
  },
}
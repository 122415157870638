<template>
  <div
    class="ws-info-belongs-to"
    v-if="value"
  >
    <div v-if="relationPopup">
      <WsPopupModel
        :value="value"
        :fields="_fields"
        :textKey="textKey"
        :getText="getText"
        :modelName="modelName"
        :id="value.id"
      ></WsPopupModel>
    </div>
    <div v-else>{{_value}}</div>
  </div>
</template>

<script>
export default {
  computed: {
    _fields() {
      if (this.$store.state.model[this.modelName]) {
        return this.$store.state.model[this.modelName].fields;
      } else {
        return null;
      }
    },
    _value() {
      if (!this.value) {
        return null;
      }
      if (this.getText) {
        return this.getText(this.value);
      } else {
        return this.value[this.textKey];
      }
    },
  },
  props: {
    getText: {
      type: Function,
      default: null,
    },
    modelName: {
      type: String,
      default: null,
    },
    relationPopup: {
      type: Boolean,
      default: false,
    },
    fields: {
      type: Object,
      default: null,
    },
    value: {
      type: Object,
      default: null,
    },
    textKey: {
      type: String,
      default: null,
    },
  },
};
</script>
<template>
  <div class="ws-state-images-item">
    <WsLoading v-if="loading">{{percent}}</WsLoading>
    <img
      :max-width="maxWidth"
      v-else-if="url"
      :src="url"
    />
  </div>
</template>

<script>
export default {
  props: {
    maxWidth: {
      type: String,
      default: null,
    },
    updatable: {
      type: Boolean,
      default: true,
    },
    loading: {
      type: Boolean,
      default: false,
    },
    percent: {
      type: Number,
      default: 0,
    },
    url: {
      type: String,
      default: null,
    },
  },
  methods: {
    $_onRemoveClick() {
      this.$emit("remove");
    },
    $_handleUploadProgress(progressEvent) {
      this.uploadPercent = Math.round(
        (progressEvent.loaded / progressEvent.total) * 100
      );
    },
    $_getFileNameFromUrl(url) {
      if (!url) {
        return null;
      }
      return decodeURIComponent(url).split("?")[0].split("/").pop();
    },
  },
};
</script>
<template>
  <div class="ws-state-datetime">
    <DatePicker
      :value="_value"
      :format="format"
      type="datetime"
      @input="$emit('input',$moment($event).format(format))"
    ></DatePicker>
  </div>
</template>

<script>
export default {
  props: {
    clearable: {
      type: Boolean,
      default: true,
    },
    value: {},
    placeholder: {
      type: String,
      default: "選擇日期",
    },
    format: {
      type: String,
      default: "YYYY-MM-DD HH:mm:ss",
    },
  },
  computed: {
    _value() {
      if (this.value) {
        return new Date(this.value);
      } else {
        return null;
      }
    },
  },
  methods: {
    customFormatter(date) {
      return this.$moment(date).format(this.format);
    },
  },
  components: {},
};
</script>
<template>
  <div
    v-if="active"
    class="ws-progress-bar"
  >
    <div
      class="ws-progress-bar__progress"
      :style="{width:`${percent}%`}"
    ></div>
  </div>
</template>

<script>
export default {
  data: () => ({
    percent: 0,
    active: false,
  }),
  props: {
    loading: {
      type: Boolean,
      default: false,
    },
  },
  watch: {
    loading: {
      handler() {
        if (this.loading) {
          this.active = true;
          this.percent = 0;
          setTimeout(() => {
            this.percent = 30;
          }, 50);
          setTimeout(() => {
            this.percent = 70;
          }, 200);
        } else {
          this.active = false;
          this.percent = 0;
        }
      },
    },
  },
};
</script>
export default {
  modelName: "voucher",
  label: "傳票",
  fields: {
    id: {
      type: "text",
      label: "ID",
      readonly: true
    },
    name: {
      type: "text",
      label: "名稱",
      rules: 'required',
      autofocus: true
    },
    date: {
      type: 'date',
      label: '日期',
      rules: 'required',
    },
    remark: {
      type: 'textarea',
      label: '備註'
    },
    voucher_template: {
      type: "belongsTo",
      label: '傳票公版',
      textKey: "name",
      modelName: "voucher_template",
    }
  },
}
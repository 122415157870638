import cms from './cms'
import Router from './router'
import Message from './message'
import config from '@/__vue2stone_cms_config'
export const router = Router
export const message = Message[config.locale.lang]
export default {
  install: (app) => {
    cms.init(app);
  },
}
<template>
  <WsMain>
    <WsCrud
      :modelName="$store.state.model[modelName].modelName"
      label="我的請款單"
      :fields="$store.state.model[modelName].fields"
      :liveSearching="true"
      :showFields="showFields"
      :fetchQuery="_fetchQuery"
      :inRowBtnUpdate="false"
      :inRowBtnDelete="false"
      :updatable="false"
      :createHideFields="createHideFields"
      :createDefaultValues="_createDefaultValues"
      :order="order"
      :plusOrderItems="plusOrderItems"
      :filterSelects="filterSelects"
      :infiniteScroll="true"
    ></WsCrud>
  </WsMain>
</template>

<script>
export default {
  metaInfo() {
    return {
      title: "我的請款單",
    };
  },
  data: () => ({
    createHideFields: ["status", "wasa", "pay_date"],
    modelName: "billing_statement",
    showFields: ["id", "apply_date", "pay_date", "amount", "status"],
    order: "last_apply",
    plusOrderItems: [
      {
        value: "last_apply",
        text: "最近請款",
        order_by: "apply_date",
        order_way: "desc",
      },
      {
        value: "last_pay",
        text: "最近付款",
        order_by: "pay_date",
        order_way: "desc",
      },
    ],
    filterSelects: {
      status: {
        label: "狀態",
        items: [
          {
            text: "全部",
            value: "all",
            params: {},
          },
          {
            value: 0,
            text: "申請中",
            params: {
              status: 0,
            },
          },
          {
            value: 1,
            text: "OK",
            params: {
              status: 1,
            },
          },
          {
            value: 2,
            text: "打妹",
            params: {
              status: 2,
            },
          },
          {
            value: 3,
            text: "已付款",
            params: {
              status: 3,
            },
          },
        ],
      },
    },
  }),

  computed: {
    _createDefaultValues() {
      return {
        wasa: this._currentUser.id,
      };
    },
    _fetchQuery() {
      return {
        wasa: this._currentUser.id,
      };
    },
    _currentUser() {
      return this.$store.state.auth.currentUser;
    },
  },
};
</script>
export default {
  modelName: "tulpa_section",
  label: "頁面用區塊",
  fields: {
    id: {
      type: "text",
      label: "ID",
      readonly: true
    },
    name: {
      type: 'text',
      label: '名稱',
      rules: 'required'
    },
    content: {
      type: "form",
      label: "內容",
    },
    tags: {
      type: 'tags',
      label: 'Tags'
    },
    remark: {
      type: 'textarea',
      label: '備註'
    },
    tulpa_section_template: {
      type: 'belongsTo',
      label: 'Tulpa區塊公版',
      textKey: "name",
      modelName: "tulpa_section_template",
      width: '100px',
      rules: 'required'
    },
  },
}
<template>
  <table
    v-if="value"
    class="ws-dev-array-table"
  >
    <tr
      v-for="(trItme, trIndex) in value"
      :key="trIndex"
    >
      <td
        :style="[{'max-width':'200px'},{width:'200px'}]"
        v-for="(tdItem, tdKey) in trItme"
        :key="tdKey"
      >{{tdItem}}</td>
    </tr>
  </table>
</template>

<script>
export default {
  props: {
    value: {
      type: Array,
      default: null,
    },
  },
};
</script>

<style>
</style>
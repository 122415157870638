export default {
  modelName: "accounting_subject_first",
  label: "一級科目",
  fields: {
    id: {
      type: "text",
      label: "ID",
      readonly: true
    },
    name: {
      type: "text",
      label: "名稱",
      rules: 'required',
      autofocus: true
    },
  },
}
import moment from 'moment'
export default {
  modelName: "work_from_home",
  label: "Work From Home",
  fields: {
    id: {
      type: "text",
      label: "ID",
      readonly: true
    },
    date: {
      type: "date",
      label: "日期",
      defaultValue: moment().format('YYYY-MM-DD')
    },
    des: {
      type: "textarea",
      label: "描述",
      rules: "required",
      maxlength: 300,
    },
    status: {
      type: 'select',
      label: '覆核狀態',
      defaultValue: 0,
      items: [
        {
          value: 0,
          text: '申請中'
        },
        {
          value: 1,
          text: 'OK'
        },
        {
          value: 2,
          text: '打妹'
        },
      ]
    },
    wasa: {
      type: "belongsTo",
      label: "山葵仔",
      textKey: "name",
      modelName: "wasa"
    },
    updated_admin: {
      type: "belongsTo",
      label: "最後更新人",
      readonly: true,
      textKey: "name",
      modelName: "wasa"
    },
  },
}
<template>
  <WsMain>
    <WsCrud
      :modelName="$store.state.model[modelName].modelName"
      :label="$store.state.model[modelName].label"
      :fields="$store.state.model[modelName].fields"
      :liveSearching="true"
      :showFields="showFields"
      :copyable="true"
      :filterSelects="_filterSelects"
      :infiniteScroll="true"
    ></WsCrud>
  </WsMain>
</template>

<script>
export default {
  metaInfo() {
    return {
      title: "評估元件",
    };
  },

  data: () => ({
    modelName: "evaluation_material",
    showFields: [
      "id",
      "evaluation_type",
      "name",
      "price",
      "excute_days",
      "tags",
    ],
    evaluationTypes: [],
    filterSelects: {
      status: {
        label: "評估類型",
        items: [
          {
            text: "全部",
            value: "all",
            params: {},
          },
        ],
      },
    },
  }),

  computed: {
    _filterSelects() {
      const evaluationTypeItems = [];
      evaluationTypeItems.push({
        text: "全部",
        value: "all",
        params: {},
      });
      this.evaluationTypes.forEach((evaluationType) => {
        evaluationTypeItems.push({
          text: evaluationType.name,
          value: evaluationType.id,
          params: {
            evaluation_type: evaluationType.id,
          },
        });
      });

      return {
        evaluation_type: {
          label: "評估類型",
          items: evaluationTypeItems,
        },
      };
    },
  },

  methods: {
    async $_fetchEvaluationTypes() {
      const res = await this.$axios.get("evaluation_type/index/all");
      this.evaluationTypes = res.data.data
    },
  },

  mounted() {
    this.$_fetchEvaluationTypes();
  },
};
</script>
<template>
  <WsMain class="sm-pa-0">
    <WsCrud
      :modelName="$store.state.model[modelName].modelName"
      :label="$store.state.model[modelName].label"
      :fields="$store.state.model[modelName].fields"
      :liveSearching="true"
      :showFields="showFields"
      :infiniteScroll="true"
    ></WsCrud>
  </WsMain>
</template>

<script>
export default {
  data: () => ({
    modelName: "public_property",
    showFields: ["id", "public_property_no", "start_date", "remark", "last_user"],
  }),
};
</script>
<template>
  <div
    v-if="value"
    class="ws-info-color"
    :style="[{'background-color':value}]"
  ></div>
</template>

<script>
export default {
  props: {
    value: {
      type: String,
      default: null,
    },
  },
};
</script>
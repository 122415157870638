<template>
  <WsMain>
    <WsTitle center>管理後台</WsTitle>
    <WsContent class="mt-10">
      <WsErrorMessage
        v-if="pageErrorMessage"
        class="mt-10"
      >{{pageErrorMessage}}</WsErrorMessage>
      <ValidationObserver ref="form">
        <form
          ref="form"
          @submit.prevent="onSubmit()"
          class="mt-5"
          autocomplete="on"
        >
          <WsState
            ref="WsStateEmail"
            autofocus
            name="email"
            rules="required|email"
            label="帳號"
            type="email"
            v-model="email.value"
            autocomplete
            :errorMessage="email.errorMessage"
          ></WsState>
          <WsState
            class="mt-10"
            ref="WsStatePassword"
            rules="required|min:6"
            label="Password"
            type="password"
            name="password"
            v-model="password.value"
            :errorMessage="password.errorMessage"
          ></WsState>
          <WsBtn
            full
            type="submit"
            :loading="loading"
            class="mt-50"
            block
          >
            登入
          </WsBtn>
        </form>
      </ValidationObserver>
      <WsInfoSection
        class="mt-20"
        title="如有任何疑問請聯絡山葵組設計"
        link="hello@wasateam.com"
      ></WsInfoSection>
    </WsContent>
  </WsMain>
</template>
<script>
export default {
  data: () => ({
    loading: false,
    email: {
      value: "",
      errorMessage: null,
    },
    password: {
      value: "",
      errorMessage: null,
    },
    pageErrorMessage: null,
  }),

  methods: {
    async onSubmit() {
      const isValidate = await this.$refs.form.validate();
      if (!isValidate) {
        return;
      }
      this.loading = true;
      try {
        await this.$auth.login({
          email: this.email.value,
          password: this.password.value,
        });
      } catch (error) {
        if (
          error.response &&
          error.response.data &&
          error.response.data.message
        ) {
          const errorMessage = error.response.data.message;
          if (errorMessage == "find no email.") {
            this.email.errorMessage = this.$message[errorMessage];
            this.$refs.WsStateEmail.focus();
          } else if (errorMessage == "password not correct.") {
            this.password.errorMessage = this.$message[errorMessage];
            this.$refs.WsStatePassword.focus();
          } else {
            this.pageErrorMessage = errorMessage;
          }
        } else {
          this.pageErrorMessage = this.$message["internet error."];
        }
      } finally {
        this.loading = false;
      }
    },
  },
};
</script>
<template>
  <div class="ws-row">
    <slot></slot>
  </div>
</template>

<script>
export default {};
</script>

<style>
</style>
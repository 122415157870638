<template>
  <div class="ws-easy-table">
    <WsEasyTableItem
      v-for="(field,fieldKey) in fields"
      :key="fieldKey"
      :field="field"
      :modelData="modelData"
      :value="$_getValueByFieldKey(fieldKey)"
    />
  </div>
</template>

<script>
export default {
  props: {
    modelName: {
      type: String,
      default: null,
    },
    fields: Object,
    modelData: {
      type: Object,
      default: null,
    },
  },
  methods: {
    $_getValueByFieldKey(fieldKey) {
      if (!this.modelData) {
        return null;
      } else {
        return this.modelData[fieldKey];
      }
    },
  },
  computed: {},
};
</script>
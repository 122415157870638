<template>
  <div class="ws-tabs">
    <div
      v-for="(item, itemIndex) in items"
      :key="itemIndex"
      class="ws-tab"
      :class="{active:item.value==value}"
      @click="$_onItemClick(item)"
    >{{item.text}}</div>
  </div>
</template>

<script>
export default {
  methods: {
    $_onItemClick(item) {
      this.$emit("input", item.value);
    },
  },

  props: {
    value: {
      default: null,
    },
    items: {
      type: Array,
      required: true,
    },
  },
};
</script>

<style>
</style>
<template>
  <div class="ws-read-content-block">
    <WsCard v-if="type=='infoCard'">
      <WsContent
        v-for="(floor,floorKey) in floors"
        :key="floorKey"
      >
        <WsContentTitle v-if="floor.title">{{floor.title}}</WsContentTitle>
        <WsContentRemark v-if="floor.remark">{{floor.remark}}</WsContentRemark>
        <WsInfo
          v-for="(fieldKey,fieldIndex) in floor.fields"
          :key="fieldIndex"
          :locale="locale"
          :label="$_CMS_getLabelByHideLabelFields(fieldKey,modedFields,floor.hideLabelFields)"
          :type="$_CMS_getFieldByFieldKey(fieldKey,modedFields).type"
          :fields="$_CMS_getFieldByFieldKey(fieldKey,modedFields).fields"
          :items="$_CMS_getFieldByFieldKey(fieldKey,modedFields).items"
          :textKey="$_CMS_getFieldByFieldKey(fieldKey,modedFields).textKey"
          :displayLabel="$_CMS_getFieldByFieldKey(fieldKey,modedFields).displayLabel"
          :activeText="$_CMS_getFieldByFieldKey(fieldKey,modedFields).activeText"
          :inactiveText="$_CMS_getFieldByFieldKey(fieldKey,modedFields).inactiveText"
          :value="$_CMS_getValueByFieldKey(fieldKey,modelData)"
        ></WsInfo>
      </WsContent>
    </WsCard>
    <WsDataExpandList
      v-else-if="type=='expandList'"
      :modelName="modelName"
      :label="label"
      :parentModelName="parentModelName"
      :parentState="parentState"
      :locale="locale"
      :fields="fields"
      :titleKey="titleKey"
      :searchMode="searchMode"
      :batchMode="batchMode"
      :fieldsRead="fieldsRead"
      :modeVersions="modeVersions"
      :orderBy="orderBy"
      :orderWay="orderWay"
    ></WsDataExpandList>
    <WsCard v-else-if="type=='activeSwitch'">
      <WsContent>
        <WsContentTitle v-if="title">{{title}}</WsContentTitle>
        <WsContentRemark v-if="remark">{{remark}}</WsContentRemark>
        <WsLiveStateSwitch
          :value="$_CMS_getValueByFieldKey(field,modelData)"
          :field="field"
          :modelName="parentModelName"
          :modelId="modelId"
          :activeText="$_CMS_getFieldByFieldKey(field,modedFields).activeText"
          :inactiveText="$_CMS_getFieldByFieldKey(field,modedFields).inactiveText"
        ></WsLiveStateSwitch>
      </WsContent>
    </WsCard>
    <WsCard v-else-if="type=='tagsEditor'">
      <WsContent>
        <WsContentTitle v-if="title">{{title}}</WsContentTitle>
        <WsContentRemark v-if="remark">{{remark}}</WsContentRemark>
        <WsLiveStateTags
          :value="$_CMS_getValueByFieldKey(field,modelData)"
          :field="field"
          :modelName="parentModelName"
          :modelId="modelId"
          :activeText="activeText"
          :inactiveText="inactiveText"
        ></WsLiveStateTags>
      </WsContent>
    </WsCard>
  </div>
</template>

<script>
export default {
  props: {
    activeText: {
      type: String,
      default: null,
    },
    inactiveText: {
      type: String,
      default: null,
    },
    orderBy: {
      type: String,
      default: "sequence",
    },
    orderWay: {
      type: String,
      default: "asc",
    },
    titleKey: {
      type: String,
      default: null,
    },
    modeVersions: {
      type: Boolean,
      default: false,
    },
    batchMode: {
      type: Boolean,
      default: false,
    },
    searchMode: {
      type: Boolean,
      default: false,
    },
    parentState: {
      type: [Number, String],
      default: null,
    },
    label: {
      type: String,
      default: null,
    },
    title: {
      type: String,
      default: null,
    },
    remark: {
      type: String,
      default: null,
    },
    modelData: {
      type: Object,
      required: true,
    },
    locale: {
      type: String,
      default: null,
    },
    fieldsRead: {
      type: Array,
      default: null,
    },
    modedFields: {
      type: Object,
      required: true,
    },
    field: {
      type: String,
      default: null,
    },
    fields: {
      type: Object,
      default: null,
    },
    type: {
      type: String,
      default: "infoCard",
    },
    floors: {
      type: Array,
      default() {
        return [];
      },
    },
    parentModelName: {
      type: String,
      default: null,
    },
    modelId: {
      type: [Number, String],
      default: null,
    },
    modelName: String,
    modeParent: {
      type: Boolean,
      default: false,
    },
    customButtons: {
      type: Array,
      default: null,
    },
  },
  methods: {},
};
</script>

<style>
</style>
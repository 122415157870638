<template>
  <WsMain>
    <WsCrud
      :modelName="$store.state.model[modelName].modelName"
      :label="$store.state.model[modelName].label"
      :fields="$store.state.model[modelName].fields"
      :liveSearching="true"
      :pageMode="true"
      :showFields="showFields"
      :expandable="false"
      :showExpand="false"
      :infiniteScroll="true"
    ></WsCrud>
  </WsMain>
</template>

<script>
export default {
  metaInfo: {
    title: "執行評估",
  },
  data: () => ({
    modelName: "evaluation",
    showFields: ["id", "name", "start_date"],
  }),
};
</script>
export default {
  modelName: "tulpa_page",
  label: "頁面",
  fields: {
    id: {
      type: "text",
      label: "ID",
      readonly: true
    },
    name: {
      type: "text",
      label: "頁面名稱 (後台顯示)",
      rules: 'required',
      autofocus: true
    },
    route: {
      type: "text",
      label: "Route",
      rules: 'required',
    },
    title: {
      type: "text",
      label: "標題",
      rules: 'required',
    },
    description: {
      type: "textarea",
      label: "說明",
      rules: 'required',
    },
    og_image: {
      type: "text",
      label: "og Image",
    },
    is_active: {
      type: 'switch',
      label: '啟用',
      activeText: '是',
      inactiveText: '否',
      defaultValue: false,
    },
    tags: {
      type: 'tags',
      label: 'Tags'
    },
    remark: {
      type: 'textarea',
      label: '備註'
    },
    status: {
      type: 'select',
      label: '狀態',
      items: [
        {
          text: '待補充',
          value: 0
        }, {
          text: '完成',
          value: 1
        }
      ],
      defaultValue: 0
    }
  },
}
<template>
  <fade-transition>
    <div
      class="ws-popup"
      v-if="active"
    >
      <div class="ws-popup__container">
        <div
          v-if="title||$slots.title"
          class="ws-popup__title"
        >
          <slot
            v-if="$slots.title"
            name="title"
          ></slot>
          <div
            v-else-if="title"
            class="ws-popup__title__text"
          >{{title}}</div>
        </div>
        <div
          class="ws-popup__content"
          :class="[{'has-padding':contentHasPadding}]"
          ref="dialogContent"
        >
          <slot name="content"></slot>
        </div>
        <div
          v-if="$slots.leftActions||$slots.rightActions"
          class="ws-popup__actions"
        >
          <slot name="leftActions"></slot>
          <WsSpacer></WsSpacer>
          <slot name="rightActions"></slot>
        </div>
      </div>
      <div
        @click="$_onBgClick()"
        class="ws-popup__bg"
      ></div>
    </div>
  </fade-transition>
</template>

<script>
export default {
  data: () => ({
    active: false,
  }),

  methods: {
    $_onBgClick() {
      this.close();
    },
    close() {
      this.active = false;
      this.$emit("close");
      this.$emit("input", false);
      document.body.classList.remove("scroll-disabled");
      window.removeEventListener("keydown", this.$_keydownHandler);
    },
    open() {
      this.active = true;
      this.$emit("open");
      this.$emit("input", true);
      document.body.classList.add("scroll-disabled");
      window.addEventListener("keydown", this.$_keydownHandler);
    },
    $_keydownHandler($event) {
      if (this.escMetaClose) {
        if ($event.metaKey && $event.code == "Escape") {
          this.close();
        }
      } else if (this.escClose) {
        if ($event.code == "Escape") {
          this.close();
        }
      }
    },
  },

  props: {
    contentHasPadding: {
      type: Boolean,
      default: true,
    },
    escMetaClose: {
      type: Boolean,
      default: false,
    },
    escClose: {
      type: Boolean,
      default: true,
    },
    title: {
      type: String,
      default: null,
    },
  },
};
</script>

<style>
</style>
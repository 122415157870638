<template>
  <WsDialog
    ref="WsDialog"
    v-model="dialog"
    :title="title"
    :type="type"
    :transition="transition"
    :escMetaClose="true"
  >
    <template
      v-if="items"
      v-slot:content
    >
      <WsRow>
        <WsCol
          class="col-6"
          v-for="(item, itemIndex) in items"
          :key="itemIndex"
        >
          <WsBtn
            @click="$_onClick(item)"
            outlined
          >{{item.text}}</WsBtn>
        </WsCol>
      </WsRow>
    </template>
  </WsDialog>
</template>

<script>
export default {
  data: () => ({
    dialog: false,
  }),

  methods: {
    $_onClick(item) {
      this.$emit("submit", item);
      this.close();
    },
    close() {
      this.dialog = false;
    },
    open() {
      this.dialog = true;
    },
  },

  props: {
    transition: {
      type: String,
      default: "zoom-y-transition",
    },
    type: {
      type: String,
      default: "popup",
    },
    title: {
      type: String,
      default: "選擇",
    },
    items: {
      type: Array,
      default: null,
    },
  },
};
</script>

<style>
</style>
export default {
  modelName: "report",
  label: "問題回報",
  fields: {
    id: {
      type: "text",
      label: "ID",
      readonly: true
    },
    title: {
      type: "text",
      label: "標題",
      autofocus: true,
      rules: "required",
      maxlength: 30,
    },
    des: {
      type: "textarea",
      label: "問題描述",
      rules: "required",
      maxlength: 300,
    },
    handle_des: {
      type: "textarea",
      label: "處理描述",
      rules: "required",
      maxlength: 300,
    },
    status: {
      type: 'select',
      label: '狀態',
      items: [
        {
          value: 0,
          text: '未處理'
        },
        {
          value: 1,
          text: '已處理'
        },
      ]
    },
    wasa: {
      type: "belongsTo",
      label: "山葵仔",
      readonly: true,
      textKey: "name",
      modelName: "wasa"
    },
    updated_admin: {
      type: "belongsTo",
      label: "最後更新人",
      readonly: true,
      textKey: "name",
      modelName: "wasa"
    },
  },
}
<template>
  <div class="ws-crud__title-bar">
    <div class="ws-crud__title-bar__text">{{label}}</div>
    <WsSpacer></WsSpacer>
    <WsBtn
      class="mr-20"
      v-if="importable"
      outlined
      :to="`/${modelName}/import`"
    >
      <WsIcon>publish</WsIcon>
      <div class="ml-6">匯入</div>
    </WsBtn>
    <template v-if="creatable">
      <WsBtn
        v-if="pageMode&&!dialogCreate"
        :to="_createUrl"
      >
        <WsIcon>add</WsIcon>
        <div class="ml-6">新增</div>
      </WsBtn>
      <WsBtn
        v-else
        @click="$emit('create')"
      >
        <WsIcon>add</WsIcon>
        <div class="ml-6">新增</div>
      </WsBtn>
    </template>
  </div>

</template>

<script>
export default {
  computed: {
    _createUrl() {
      const _createUrl = this.createUrl
        ? this.createUrl
        : `/${this.modelName}/create`;
      return _createUrl;
    },
  },
  props: {
    dialogCreate: {
      type: Boolean,
      default: false,
    },
    creatable: {
      type: Boolean,
      default: true,
    },
    importable: Boolean,
    createUrl: String,
    modelName: String,
    label: String,
    pageMode: Boolean,
  },
};
</script>
<template>
  <div class="ws-easy-table__content-item">
    <div class="ws-easy-table__content-title">{{field.label}}</div>
    <div class="ws-easy-table__content-content">
      <WsInfo
        :type="field.type"
        :fields="field.fields"
        :showFields="field.showFields"
        :items="field.items"
        :textKey="field.textKey"
        :relationPopup="field.relationPopup"
        :value="value"
        :modelName="field.modelName"
        :activeText="field.activeText"
        :inactiveText="field.inactiveText"
        :getText="field.getText"
        :getValue="field.getValue"
        :modelData="modelData"
      ></WsInfo>
    </div>

  </div>
</template>

<script>
export default {
  computed: {
    _type() {
      if (this.field.type == "list") {
        return "list";
      } else if (this.field.type == "hasMany") {
        return "hasMany";
      } else if (this.field.type == "files") {
        return "files";
      } else {
        return "text";
      }
    },
  },
  props: {
    modelData: {
      type: Object,
      default: null,
    },
    modelName: {
      type: String,
      default: null,
    },
    field: Object,
    value: {},
  },
};
</script>
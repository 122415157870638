<template>
  <div class="ws-state-color">
    <ColorPicker
      :value="_value"
      @input="$_onInput($event)"
    ></ColorPicker>
  </div>
</template>

<script>
export default {
  methods: {
    $_onInput($event) {
      this.$emit("input", $event.hex);
    },
  },
  computed: {
    _value() {
      const color = this.value ? this.value : "#000";
      return {
        hex: color,
        source: "hex",
      };
    },
  },
  props: {
    value: {
      type: String,
      default: null,
    },
  },
};
</script>

<style>
</style>
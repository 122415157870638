export default [
  {
    path: '/user',
    name: 'user',
    component: () => import('../views/User/Update.vue'),
    meta: {
      middleware: 'auth',
    }
  },
  {
    path: '/mybillingstatement',
    name: 'mybillingstatement',
    component: () => import('../views/MyBillingStatement.vue'),
    meta: {
      middleware: 'auth',
    }
  },
  {
    path: '/myovertimeapply',
    name: 'myovertimeapply',
    component: () => import('../views/MyOvertimeApply.vue'),
    meta: {
      middleware: 'auth',
    }
  },
  {
    path: '/myworkfromhome',
    name: 'myworkfromhome',
    component: () => import('../views/MyWorkFromHome.vue'),
    meta: {
      middleware: 'auth',
    }
  },
  {
    path: '/myleaveday',
    name: 'myleaveday',
    component: () => import('../views/MyLeaveDay.vue'),
    meta: {
      middleware: 'auth',
    }
  },
  {
    path: '/myproject',
    name: 'myproject',
    component: () => import('../views/MyProject.vue'),
    meta: {
      middleware: 'auth',
    }
  },
  {
    path: '/mycalendar',
    name: 'mycalendar',
    component: () => import('../views/MyCalendar.vue'),
    meta: {
      middleware: 'auth',
    }
  },
]
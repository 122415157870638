<template>
  <div
    @click="$_onClick()"
    class="ws-state-switch"
    :class="{active:value}"
  >
    <div class="ws-state-switch__switch">
      <div class="ws-state-switch__switch-button"></div>
    </div>
    <div
      v-if="value&&activeText"
      class="ws-state-switch__text"
    >{{activeText}}</div>
    <div
      v-else-if="!value&&inactiveText"
      class="ws-state-switch__text"
    >{{inactiveText}}</div>
  </div>
</template>

<script>
export default {
  methods: {
    $_onClick() {
      this.$emit("input", !this.value);
    }
  },
  props: {
    value: {
      type: [Boolean, Number],
      default: false
    },
    activeText: String,
    inactiveText: String
  }
};
</script>
<template>
  <div class="ws-bottom-nav">
    <div class="left-actions">
      <slot name="leftActions"></slot>
    </div>
    <div class="right-actions">
      <slot name="rightActions"></slot>
    </div>
  </div>
</template>

<script>
export default {
  created() {
    this.$store.dispatch("app/hasBottomNavActive");
  },
  beforeDestroy() {
    this.$store.dispatch("app/hasBottomNavDeactive");
  },
};
</script>

<style>
</style>
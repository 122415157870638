import auth from './auth'
import header from './header'
import locale from './locale'
import menu from './menu'
import message from './message'
import scopes from './scopes'
import theme from './theme'
export default {
  auth: auth,
  header: header,
  locale: locale,
  menu: menu,
  message: message,
  scopes: scopes,
  theme: theme,
}
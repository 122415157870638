<template>
  <WsMain>
    <img
      v-if="state.url"
      v-lazy="state.url"
      alt=""
    >
    <WsState
      label="名稱"
      v-model="state.name"
    ></WsState>
    <WsState
      class="mt-20"
      label="連結"
      v-model="state.url"
    ></WsState>
  </WsMain>
</template>

<script>
import servicePocketImage from "@/__vue2stone_cms/service/api/v1/pocket_image";
export default {
  data: () => ({
    state: {
      name: "",
      url: "",
    },
  }),

  methods: {
    submit() {
      return new Promise((resolve, reject) => {
        servicePocketImage
          .create({
            name: this.state.name,
            url: this.state.url,
            signed: 0,
          })
          .then((res) => {
            resolve(res);
            this.$emit("submit", res.url);
          })
          .catch((err) => {
            reject(err);
          });
      });
    },
  },
};
</script>

<style>
</style>
<template>
  <div>
    <WsCreate
      ref="WsCreate"
      :modelName="model.tulpa_page.modelName"
      :label="model.tulpa_page.label"
      :fields="model.tulpa_page.fields"
      :primary="pageSetting.primary"
      :secondary="pageSetting.secondary"
      v-model="updateData"
      :emitSubmit="true"
      @submit="$_onSubmit()"
    >
    </WsCreate>
    <WsMain>
      <WsStateTulpaSections v-model="state.content"></WsStateTulpaSections>
    </WsMain>
  </div>
</template>

<script>
import tulpa_page from "@/__vue2stone_cms/models/tulpa_page";
import ServiceTulpaPage from "@/__vue2stone_cms/service/api/v1/tulpa_page";
export default {
  metaInfo() {
    return {
      title: "建立頁面",
    };
  },

  data: () => ({
    updateData: null,
    pageSetting: {
      primary: [
        {
          type: "stateCard",
          floors: [
            {
              fields: ["name", "route", "title", "description", "og_image"],
            },
          ],
        },
      ],
      secondary: [
        {
          type: "stateCard",
          floors: [
            {
              title: "設定",
              fields: ["is_active", "tags", "remark", "status"],
            },
          ],
        },
      ],
    },
    model: {
      tulpa_page: tulpa_page,
    },
    state: {
      content: [],
    },
  }),

  methods: {
    async $_onSubmit() {
      const postData = JSON.parse(JSON.stringify(this.updateData));
      const _content = [];
      const _tulpa_sections = [];
      this.state.content.forEach((contentItem) => {
        _content.push({
          id: contentItem.id,
          content: contentItem.content,
        });
        _tulpa_sections.push(contentItem.id);
      });
      postData.content = _content;
      postData.tulpa_sections = _tulpa_sections;
      try {
        this.$refs.WsCreate.loadingStart();
        await ServiceTulpaPage.create(postData);
        this.$router.push("/tulpa_page");
      } catch (error) {
        console.log(error);
        alert("儲存錯誤，確認Route是否重複");
      } finally {
        this.$refs.WsCreate.loadingStop();
      }
    },
  },
};
</script>

<style>
</style>
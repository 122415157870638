<template>
  <WsMain>
    <ValidationObserver ref="form">
      <form
        ref="updateForm"
        @submit.prevent
      >
        <div class="ws-row">
          <div class="ws-col col-6">
            <WsState
              label="名稱"
              v-model="updateData.name"
              rules="required"
            ></WsState>
          </div>
          <div class="ws-col col-6">
            <WsState
              type="password"
              label="新密碼"
              rules="min:6"
              v-model="updateData.password"
            ></WsState>
          </div>
          <div class="ws-col col-6">
            <WsState
              type="image"
              label="頭像"
              v-model="updateData.avatar"
              uploadUrl="wasa/avatar/"
            ></WsState>
          </div>
          <div class="ws-col col-6">
            <WsState
              type="color"
              label="主色"
              v-model="updateData.color"
            ></WsState>
          </div>
          <div class="ws-col col-6">
            <WsBtn
              @click="$_onSubmit()"
              :loading="loading"
            >儲存</WsBtn>
          </div>
        </div>
      </form>
    </ValidationObserver>
  </WsMain>
</template>

<script>
export default {
  metaInfo() {
    return {
      title: "我的帳號",
    };
  },
  data: () => ({
    updateData: {
      name: "",
      avatar: null,
      password: null,
      color: null,
    },
    loading: false,
  }),
  methods: {
    async $_onSubmit() {
      const isValidate = await this.$refs.form.validate();
      if (!isValidate) {
        return;
      }
      const _updateData = {
        name: this.updateData.name,
        color: this.updateData.color,
        avatar: this.updateData.avatar,
      };
      if (this.updateData.password) {
        _updateData.password = this.updateData.password;
      }
      try {
        this.loading = true;
        await this.$axios.patch("/auth/user", _updateData);
        await this.$auth.getUser();
        this.$router.push("/");
      } catch (error) {
        alert("儲存錯誤");
      } finally {
        this.loading = false;
      }
    },
    $_updateDataSet() {
      this.updateData.name = this._user.name;
      this.updateData.avatar = this._user.avatar;
      this.updateData.color = this._user.color;
    },
  },
  computed: {
    _user() {
      return this.$store.state.auth.currentUser;
    },
  },
  mounted() {
    this.$_updateDataSet();
  },
};
</script>

<style>
</style>
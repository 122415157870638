<template>
  <AppStateQuotation
    v-if="!_loading"
    :quotation="modelData"
    ref="AppStateQuotation"
    @save="$_onSave($event)"
  >
  </AppStateQuotation>
</template>

<script>
export default {
  data: () => ({
    modelName: "quotation",
    modelData: null,
  }),
  methods: {
    async $_onSave($event) {
      this.$refs.AppStateQuotation.submitLoadingStart();
      try {
        await this.$axios.post("quotation", $event);
        this.$router.push("/quotation");
      } catch (error) {
        alert("儲存錯誤");
      } finally {
        this.$refs.AppStateQuotation.submitLoadingStop();
      }
    },
    async $_fetchCopyFromModel() {
      console.log("$_fetchCopyFromModel");
      try {
        this.$store.dispatch("app/startPageLoading");
        const res = await this.$axios.get(
          `${this.modelName}/${this._copyFromid}`
        );
        this.modelData = res.data.data;
      } catch (error) {
        alert("取得評估錯誤");
      } finally {
        this.$store.dispatch("app/stopPageLoading");
      }
    },
  },

  mounted() {
    if (this._copyFromid) {
      this.$_fetchCopyFromModel();
    }
  },

  computed: {
    _loading() {
      return this.$store.state.app.pageLoading;
    },
    _copyFromid() {
      if (this.$route.query.copyfrom) {
        return this.$route.query.copyfrom;
      } else {
        return null;
      }
    },
  },
};
</script>

<style>
</style>
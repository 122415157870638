<template>
  <div
    class="ws-menu"
    :class="{active:active}"
  >
    <div
      @click="$_onTitleClick()"
      class="ws-menu__title"
      v-ripple.300
    >
      <WsIcon
        class="pre-icon"
        v-if="icon"
      >{{icon}}</WsIcon>
      <div class="ws-menu__title__text">{{title}}</div>
      <WsIcon class="arrow">expand_more</WsIcon>
    </div>
    <collapse-transition :duration="200">
      <div
        class="ws-menu__menu"
        v-if="active"
      >
        <WsMenuItem
          v-for="(menuItem,menuIndex) in menu"
          :key="menuIndex"
          :title="menuItem.title"
          :link="menuItem.link"
        ></WsMenuItem>
      </div>
    </collapse-transition>
  </div>
</template>

<script>
export default {
  data: () => ({
    active: false,
  }),
  methods: {
    $_onTitleClick() {
      this.active = !this.active;
    },
  },
  props: {
    menu: {
      type: Array,
      default: null,
    },
    title: {
      type: String,
      default: null,
    },
    icon: {
      type: String,
      default: null,
    },
  },
};
</script>
<template>
  <div class="ws-data-table-pagenate">
    <WsPagenateTypeB
      @pageto="$_onPageto($event)"
      :currentPage="currentPage"
      :dataTotalCount="dataTotalCount"
      :itemsPerPage="itemsPerPage"
      :lastPage="lastPage"
    ></WsPagenateTypeB>
  </div>
</template>

<script>
export default {
  props: {
    currentPage: [Number, String],
    lastPage: Number,
    dataTotalCount: Number,
    itemsPerPage: Number,
  },
  methods: {
    $_onPageto($event) {
      this.$emit("pageto", $event);
    },
  },
};
</script>
<template>
  <i
    @click="$emit('click',$event)"
    class="ws-icon"
    :class="[_iconClass,{button:_hasClickListener}]"
    :style="[{'font-size':`${size}px`}]"
  >{{_icon}}</i>
</template>

<script>
export default {
  computed: {
    _hasClickListener() {
      return this.$listeners && this.$listeners.click;
    },
    _iconClass() {
      return 'material-icons'
      // if (this.source == "mdi") {
      //   return `mdi mdi-${this._icon}`;
      // } else {
      //   return null;
      // }
    },
    _icon() {
      if (
        this.$slots &&
        this.$slots.default &&
        this.$slots.default[0] &&
        this.$slots.default[0].text
      ) {
        return this.$slots.default[0].text;
      } else {
        return null;
      }
    },
  },
  props: {
    source: {
      type: String,
      default: "mdi",
    },
    size: {
      type: [String, Number],
      default: null,
    },
  },
};
</script>
<template>
  <div class="ws-info-section">
    <WsIcon
      class="ws-info-section__icon"
      size="20"
    >info</WsIcon>
    <div class="ws-info-section__content">
      <div class="ws-info-section__title">{{title}}</div>
      <a class="ws-info-section__link">{{link}}</a>
    </div>
  </div>
</template>

<script>
export default {
  props: {
    title: {
      type: String,
      default: null,
    },
    link: {
      type: String,
      default: null,
    },
  },
};
</script>
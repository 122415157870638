<template>
  <div class="ws-stepper">
    <slot name="steps">
    </slot>
  </div>
</template>

<script>
export default {};
</script>

<style>
</style>
<template>
  <div class="ws-app-wasajon">
    <div class="ws-app-wasajon__body">
      <img
        src="@/__vue2stone_cms/img/wasajon.svg"
        alt=""
      >
    </div>
  </div>
</template>

<script>
export default {};
</script>

<style>
</style>
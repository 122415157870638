export default {
  boss: [
    {
      icon: 'dashboard',
      title: "山葵分析",
      menu: [
        {
          title: '山葵概況',
          link: '/analytics/company'
        },
        {
          icon: 'dashboard',
          title: '我的概況',
          link: '/analytics/me'
        },
        {
          title: '時間分析',
          link: '/analytics/time'
        },
        {
          title: '人員概況',
          link: '/analytics/wasa'
        },
        {
          title: "超時Task",
          link: "/analytics/overtime_tasks",
        },
        // {
        //   title: "超時評估元件",
        //   link: "/analytics/overtime_evaluation_materials",
        // },
      ]
    },
    {
      icon: 'done',
      title: '主管覆核',
      menu: [
        {
          title: "請款覆核",
          link: "/billing_statement/review",
        },
        {
          title: "加班申請單覆核",
          link: "/overtime_apply/review",
        },
        {
          title: "請假覆核",
          link: "/leave_day/review",
        },
      ]
    },
    {
      icon: 'event',
      title: '我的行事曆',
      link: '/mycalendar'
    },
    {
      icon: 'extension',
      title: "我的專案",
      link: "/myproject",
    },
    {
      icon: 'build',
      title: "公物管理",
      link: "/public_property",
    },
    {
      icon: 'local_atm',
      title: "我的請款單",
      link: "/mybillingstatement",
    },
    {
      icon: 'fast_forward',
      title: "我的加班申請",
      link: "/myovertimeapply",
    },
    {
      icon: 'home',
      title: "我的一日宅宅",
      link: "/myworkfromhome",
    },
    {
      icon: 'nature_people',
      title: "我的請假",
      link: "/myleaveday",
    },
    {
      icon: 'event',
      title: '山葵行事曆',
      link: '/calendar'
    },
    {
      icon: 'settings',
      title: "系統基礎設定",
      menu: [
        {
          icon: 'colorize',
          title: "額外工作日",
          link: "/additional_working_day",
        },
        {
          icon: 'colorize',
          title: "額外休息日",
          link: "/additional_off_day",
        },
        {
          icon: 'colorize',
          title: "評估類型",
          link: "/evaluation_type",
        },
        {
          icon: 'colorize',
          title: "執行類型",
          link: "/excute_type",
        },
      ]
    },
    {
      icon: 'phone',
      title: '通訊錄',
      menu: [
        {
          icon: 'colorize',
          title: "山葵好朋友",
          link: "/friend",
        },
        {
          icon: 'colorize',
          title: "山葵客戶",
          link: "/client",
        },
        {
          icon: 'colorize',
          title: "外部資源",
          link: "/out_source",
        },
      ]
    },
    {
      icon: 'child_care',
      title: "HR",
      menu: [
        {
          icon: 'colorize',
          title: "加班計算",
          link: "/overtime/calc",
        },
        {
          icon: 'colorize',
          title: "打卡管理",
          link: "/crud/punch",
        },
        {
          icon: 'fast_forward',
          title: "加班申請單覆核",
          link: "/overtime_apply/review",
        },
        {
          icon: 'home',
          title: "一日宅宅覆核",
          link: "/work_from_home/review",
        },
        {
          icon: 'nature_people',
          title: "請假覆核",
          link: "/leave_day/review",
        },
        {
          icon: 'colorize',
          title: "山葵仔",
          link: "/wasa",
        },
        {
          icon: 'list',
          title: "山葵仔職業技能",
          link: "/wasa_hr_skill",
        },
        {
          icon: 'colorize',
          title: "面試紀錄",
          link: "/hr_recruit",
        },
        {
          icon: 'list',
          title: "職位技能",
          link: "/hr_skill",
        },
        {
          icon: 'colorize',
          title: "職位",
          link: "/hr_position",
        },
      ]
    },
    {
      icon: 'extension',
      title: "專案執行",
      menu: [
        {
          title: "專案",
          link: "/project",
        },
        {
          title: "批次刪除",
          link: "/calendar/batch/delete",
        },
        // {
        //   title: "大風吹",
        //   link: "/calendar/adjust",
        // },
        {
          title: "Task分析",
          link: "/task/analytics",
        },
        {
          title: "Task",
          link: "/task",
        },
        {
          title: "Milestone",
          link: "/milestone",
        }
      ]
    },
    {
      icon: 'request_page',
      title: "財務",
      menu: [
        {
          title: "傳票事件",
          link: "/voucher_event",
        },
        {
          title: "傳票",
          link: "/voucher",
        },
        {
          title: "傳票公版",
          link: "/voucher_template",
        },
        {
          title: "一級科目",
          link: "/accounting_subject_first",
        },
        {
          title: "二級科目",
          link: "/accounting_subject_second",
        },
        {
          title: "訂單",
          link: "/order",
        },
        {
          title: "收款階段",
          link: "/order_stage",
        },
        {
          icon: 'local_atm',
          title: "請款覆核",
          link: "/billing_statement/review",
        },
      ]
    },
    {
      icon: 'calculate',
      title: "評估報價",
      menu: [
        {
          title: "執行評估",
          link: "/evaluation",
        },
        {
          title: '報價單',
          link: '/quotation',
        },
        {
          title: "評估元件",
          link: "/evaluation_material",
        },
        {
          title: '報價單執行項目',
          link: '/quotation_excute',
        },
        {
          title: '報價單說明項目',
          link: '/quotation_description',
        },
      ]
    },
    // {
    //   icon: 'build',
    //   title: "修正",
    //   menu: [
    //     {
    //       title: '訂單建立時間',
    //       link: '/fix/order/created_at'
    //     }
    //   ]
    // },
    // {
    //   icon: 'cloud_upload',
    //   title: "匯入",
    //   menu: [
    //     {
    //       title: "報價單",
    //       link: "/import/quotation",
    //     },
    //     {
    //       title: "報價單說明項目",
    //       link: "/import/quotation_description",
    //     },
    //     {
    //       title: "報價單執行項目",
    //       link: "/import/quotation_excute",
    //     },
    //     {
    //       title: "山葵仔",
    //       link: "/import/wasa",
    //     },
    //     {
    //       title: "打卡",
    //       link: "/import/punch",
    //     },
    //     {
    //       title: "山葵客戶",
    //       link: "/import/client",
    //     },
    //     {
    //       title: "外部資源",
    //       link: "/import/out_source",
    //     },
    //     {
    //       title: "業務開發",
    //       link: "/import/business_extension",
    //     },
    //     {
    //       title: "匯入_評估元件",
    //       link: "/import/evaluation_material",
    //     },
    //     {
    //       title: "山葵好朋友",
    //       link: "/import/friend",
    //     },
    //     {
    //       title: "請假",
    //       link: "/import/leave_day",
    //     },
    //     {
    //       title: "訂單",
    //       link: "/import/order",
    //     },
    //     {
    //       title: "訂單收款階段",
    //       link: "/import/order_stage",
    //     },
    //     {
    //       title: "專案",
    //       link: "/import/project",
    //     },
    //     {
    //       title: "Task",
    //       link: "/import/task",
    //     },
    //     {
    //       title: "Milestone",
    //       link: "/import/milestone",
    //     },
    //     {
    //       title: "職位",
    //       link: "/import/hr_position",
    //     },
    //     {
    //       title: "面試紀錄",
    //       link: "/import/hr_recruit",
    //     },
    //   ],
    // },
    {
      icon: 'colorize',
      title: "業務開發",
      link: "/business_extension",
    },
    {
      icon: 'link',
      title: '山葵相關網站連結',
      link: '/wasa_relate_links'
    },
    {
      icon: 'colorize',
      title: "登出",
      link: "/logout",
    },
  ],
  pm: [
    {
      icon: 'alarm_on',
      title: "打卡",
      link: "/punch",
    },
    {
      icon: 'event',
      title: '我的行事曆',
      link: '/mycalendar'
    },
    {
      icon: 'extension',
      title: "我的專案",
      link: "/myproject",
    },
    {
      icon: 'local_atm',
      title: "我的請款單",
      link: "/mybillingstatement",
    },
    {
      icon: 'fast_forward',
      title: "我的加班申請",
      link: "/myovertimeapply",
    },
    {
      icon: 'home',
      title: "我的一日宅宅",
      link: "/myworkfromhome",
    },
    {
      icon: 'nature_people',
      title: "我的請假",
      link: "/myleaveday",
    },
    {
      icon: 'event',
      title: '山葵行事曆',
      link: '/calendar'
    },
    {
      title: "山葵Task",
      link: "/task",
    },
    {
      title: "山葵Milestone",
      link: "/milestone",
    },
    {
      icon: 'build',
      title: "公物管理",
      link: "/public_property",
    },
    {
      icon: 'settings',
      title: "系統基礎設定",
      menu: [
        {
          icon: 'colorize',
          title: "額外工作日",
          link: "/additional_working_day",
        },
        {
          icon: 'colorize',
          title: "額外休息日",
          link: "/additional_off_day",
        },
        {
          icon: 'colorize',
          title: "評估類型",
          link: "/evaluation_type",
        },
        {
          icon: 'colorize',
          title: "執行類型",
          link: "/excute_type",
        },
      ]
    },
    {
      icon: 'request_page',
      title: "財務",
      menu: [
        {
          title: "訂單",
          link: "/order",
        },
        {
          title: "收款階段",
          link: "/order_stage",
        },
      ]
    },
    {
      icon: 'calculate',
      title: "評估報價",
      menu: [
        {
          title: "執行評估",
          link: "/evaluation",
        },
        {
          title: '報價單',
          link: '/quotation',
        },
        {
          title: "評估元件",
          link: "/evaluation_material",
        },
        {
          title: '報價單執行項目',
          link: '/quotation_excute',
        },
        {
          title: '報價單說明項目',
          link: '/quotation_description',
        },
      ]
    },
    {
      icon: 'link',
      title: '山葵相關網站連結',
      link: '/wasa_relate_links'
    },
    {
      icon: 'colorize',
      title: "登出",
      link: "/logout",
    },
  ],
  admin: [
    {
      icon: 'alarm_on',
      title: "打卡",
      link: "/punch",
    },
    {
      icon: 'event',
      title: '我的行事曆',
      link: '/mycalendar'
    },
    {
      icon: 'extension',
      title: "我的專案",
      link: "/myproject",
    },
    {
      icon: 'local_atm',
      title: "我的請款單",
      link: "/mybillingstatement",
    },
    {
      icon: 'fast_forward',
      title: "我的加班申請",
      link: "/myovertimeapply",
    },
    {
      icon: 'home',
      title: "我的一日宅宅",
      link: "/myworkfromhome",
    },
    {
      icon: 'nature_people',
      title: "我的請假",
      link: "/myleaveday",
    },
    {
      icon: 'build',
      title: "公物管理",
      link: "/public_property",
    },
    {
      icon: 'event',
      title: '山葵行事曆',
      link: '/calendar'
    },
    {
      icon: 'extension',
      title: "專案執行",
      menu: [
        {
          title: "專案",
          link: "/project",
        },
        {
          title: "Task",
          link: "/task",
        },
        {
          title: "Milestone",
          link: "/milestone",
        }
      ]
    },
    {
      icon: 'colorize',
      title: "評估類型",
      link: "/evaluation_type",
    },
    {
      icon: 'colorize',
      title: "執行類型",
      link: "/excute_type",
    },
    {
      icon: 'colorize',
      title: "執行評估",
      link: "/evaluation",
    },
    {
      icon: 'colorize',
      title: "評估元件",
      link: "/evaluation_material",
    },
    {
      icon: 'link',
      title: '山葵相關網站連結',
      link: '/wasa_relate_links'
    },
    {
      icon: 'colorize',
      title: "登出",
      link: "/logout",
    },
  ],
  wasa: [
    {
      icon: 'alarm_on',
      title: "打卡",
      link: "/punch",
    },
    {
      icon: 'dashboard',
      title: '我的概況',
      link: '/analytics/me'
    },
    {
      icon: 'event',
      title: '我的行事曆',
      link: '/mycalendar'
    },
    {
      icon: 'extension',
      title: "我的專案",
      link: "/myproject",
    },
    {
      icon: 'local_atm',
      title: "我的請款單",
      link: "/mybillingstatement",
    },
    {
      icon: 'fast_forward',
      title: "我的加班申請",
      link: "/myovertimeapply",
    },
    {
      icon: 'home',
      title: "我的一日宅宅",
      link: "/myworkfromhome",
    },
    {
      icon: 'nature_people',
      title: "我的請假",
      link: "/myleaveday",
    },
    {
      icon: 'build',
      title: "公物管理",
      link: "/public_property",
    },
    {
      icon: 'link',
      title: '山葵相關網站連結',
      link: '/wasa_relate_links'
    },
    {
      icon: 'colorize',
      title: "登出",
      link: "/logout",
    },
  ],
}
<template>
  <WsCard>
    <WsCard
      v-for="(contentItem, contentItemIndex) in value"
      :key="contentItemIndex"
      color="#333"
      class="mt-10"
    >
      <WsTitle>{{contentItem.name}}</WsTitle>
      <WsRow class="mt-10">
        <WsCol class="col-6">
          <WsTitle size="5">區塊預設內容</WsTitle>
          <WsCard class="mt-10">
            <WsEasyTable
              :fields="contentItem.fields"
              :modelData="contentItem.defaultContent"
            />
          </WsCard>
        </WsCol>
        <WsCol class="col-6">
          <WsTitle size="5">頁面調整內容</WsTitle>
          <WsCard class="mt-10">
            <WsEasyTable
              :fields="contentItem.fields"
              :modelData="contentItem.content"
            />
          </WsCard>
        </WsCol>
      </WsRow>
    </WsCard>
  </WsCard>
</template>

<script>
import tulpa_section from "@/__vue2stone_cms/models/tulpa_section";
export default {
  data: () => ({
    model: {
      tulpa_section: tulpa_section,
    },
    showFields: {
      tulpa_section: ["id", "name", "tags", "remark"],
    },
  }),

  methods: {
    $_onSortOpen() {
      this.$refs.WsSortDialog.open();
    },
    $_onCreateOpen() {
      this.$refs.WsModelPicker.open();
    },
    $_onModelPickerSubmit($event) {
      const _tulpaSections = $event;
      const _value = JSON.parse(JSON.stringify(this.value));
      _tulpaSections.forEach((_tulpaSection) => {
        _value.push({
          id: _tulpaSection.id,
          content: {},
          name: _tulpaSection.name,
          fields: _tulpaSection.tulpa_section_template.fields,
          defaultContent: _tulpaSection.content,
        });
      });
      this.$emit("input", _value);
    },
  },

  props: {
    value: {
      type: Array,
      default: null,
    },
  },
};
</script>
import store from '@/store'
import axios from 'axios'
export default {
  async setLocalesFromAPI() {
    try {
      const res = await axios.get('locale')
      store.dispatch('locale/setLocales', res.data.data);
    } catch (err) {
      alert(this.message['index locale error.']);
    }
  }
}
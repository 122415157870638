<template>
  <div class="ws-pocket-image-picker">
    <WsDialog
      ref="WsDialog"
      v-model="dialog"
      title="選擇圖片"
      :escMetaClose="true"
      :contentHasPadding="false"
      @close="$_onDialogClose()"
    >
      <template v-slot:content>
        <WsTabs
          v-model="focusTab"
          :items="tabItems"
        ></WsTabs>
        <WsPocketImagePickerPocket
          v-if="focusTab=='pocket'"
          ref="WsPocketImagePickerPocket"
          @submit="$_onInputSubmit($event)"
        />
        <WsPocketImagePickerUpload
          v-else-if="focusTab=='upload'"
          ref="WsPocketImagePickerUpload"
          @submit="$_onInputSubmit($event)"
          :signed="signed"
        />
        <WsPocketImagePickerLink
          v-else-if="focusTab=='link'"
          ref="WsPocketImagePickerLink"
          @submit="$_onInputSubmit($event)"
        />
      </template>
      <template v-slot:rightActions>
        <WsBtn
          @click="$_onCancel()"
          minWidth="100px"
          color="textSecondary"
          outlined
        >取消</WsBtn>
        <WsBtn
          @click="$_onSubmit()"
          minWidth="100px"
          :loading="loading.submit"
          color="primary"
        >送出</WsBtn>
      </template>
    </WsDialog>
    <WsBtn @click="$_onPickDialogOpen()">選擇圖片</WsBtn>
  </div>
</template>

<script>
export default {
  data: () => ({
    loading: {
      submit: false,
    },
    dialog: false,
    focusTab: "pocket",
    tabItems: [
      {
        text: "圖片庫",
        value: "pocket",
      },
      {
        text: "上傳圖片",
        value: "upload",
      },
      {
        text: "來源連結",
        value: "link",
      },
    ],
  }),

  methods: {
    $_onInputSubmit($event) {
      this.$emit("submit", $event);
      this.close();
    },
    $_onPickDialogOpen() {
      this.dialog = true;
    },
    close() {
      this.dialog = false;
    },
    $_onDialogClose() {
      this.close();
    },
    $_onCancel() {},
    async $_onSubmit() {
      this.loading.submit = true;
      if (this.focusTab == "pocket") {
        this.$refs.WsPocketImagePickerPocket.submit();
      } else if (this.focusTab == "upload") {
        await this.$refs.WsPocketImagePickerUpload.submit();
      } else if (this.focusTab == "link") {
        await this.$refs.WsPocketImagePickerLink.submit();
      }
      this.loading.submit = false;
    },
  },

  props: {
    signed: {
      type: Boolean,
      default: false,
    },
  },
};
</script>

<style>
</style>
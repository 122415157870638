<template>
  <WsMain>
    <WsState
      label="開始時間"
      type="date"
      v-model="state.startDate"
    ></WsState>
    <WsState
      class="mt-20"
      label="結束時間"
      type="date"
      v-model="state.endDate"
    ></WsState>
    <WsState
      class="mt-20"
      type="belongsTo"
      label="山葵仔"
      textKey="name"
      modelName="wasa"
      v-model="state.wasa"
    ></WsState>
    <WsState
      class="mt-20"
      type="number"
      label="月薪"
      v-model="state.monthSalary"
    ></WsState>

    <table class="calc-table mt-40">
      <tr>
        <th>加班別 (類型)</th>
        <th>加班別 (時區間)</th>
        <th>倍率</th>
        <th>分鐘數</th>
        <th>加班費</th>
      </tr>
      <tr>
        <td>平日加班</td>
        <td>前兩小時以內：</td>
        <td class="center">1 + 1/3</td>
        <td class="center">{{_overTimeWorkdaypre2Minutes}}</td>
        <td>{{$_getOvertimeSalary(4/3,_overTimeWorkdaypre2Minutes)}}</td>
      </tr>
      <tr>
        <td>平日加班</td>
        <td>後兩小時以內：</td>
        <td class="center">1 + 2/3</td>
        <td class="center">{{_overTimeWorkdayafter2Minutes}}</td>
        <td>{{$_getOvertimeSalary(5/3,_overTimeWorkdayafter2Minutes)}}</td>
      </tr>
      <tr>
        <td>休假日出勤</td>
        <td>8小時以內：</td>
        <td class="center">1</td>
        <td class="center">{{_overTimeBreakdaypre8Minutes}}</td>
        <td>{{$_getOvertimeSalary(1,_overTimeBreakdaypre8Minutes)}}</td>
      </tr>
      <tr>
        <td>休假日出勤</td>
        <td>逾八小時後前兩小時：</td>
        <td class="center">1 + 1/3</td>
        <td class="center">{{_overTimeBreakdayover8pre2Minutes}}</td>
        <td>{{$_getOvertimeSalary(4/3,_overTimeBreakdayover8pre2Minutes)}}</td>
      </tr>
      <tr>
        <td>休假日出勤</td>
        <td>逾八小時後後兩小時：</td>
        <td class="center">1 + 2/3</td>
        <td class="center">{{_overTimeBreakdayover8after2Minutes}}</td>
        <td>{{$_getOvertimeSalary(5/3,_overTimeBreakdayover8after2Minutes)}}</td>
      </tr>
      <tr>
        <td>休息日加班</td>
        <td>前兩小時以內：</td>
        <td class="center">1 + 1/3</td>
        <td class="center">{{_overTimeWeekdaypre2Minutes}}</td>
        <td>{{$_getOvertimeSalary(4/3,_overTimeWeekdaypre2Minutes)}}</td>
      </tr>
      <tr>
        <td>休息日加班</td>
        <td>2~8小時內：</td>
        <td class="center">1 + 2/3</td>
        <td class="center">{{_overTimeWeekdaypre8Minutes}}</td>
        <td>{{$_getOvertimeSalary(5/3,_overTimeWeekdaypre8Minutes)}}</td>
      </tr>
      <tr>
        <td>休息日加班</td>
        <td>逾八小時：</td>
        <td class="center">2 + 2/3</td>
        <td class="center">{{_overTimeWeekdayover8Minutes}}</td>
        <td>{{$_getOvertimeSalary(8/3,_overTimeWeekdayover8Minutes)}}</td>
      </tr>
    </table>
    <WsRow class="mt-40">
      <WsCol>打卡一覽</WsCol>
      <WsCol
        v-for="(punch, punchIndex) in punches"
        :key="punchIndex"
      >{{$_getHightlightSymbol(punch)}}{{$moment(punch.punch_at).format('YYYY-MM-DD HH:mm:ss ddd')}} {{$_getPunchTypeText(punch.type)}} <span v-if="punch.type==0">加班 {{$_getOvertimeMinutes($_getFirstDayWorkMinutes(punch.punch_at))}}</span></WsCol>
    </WsRow>
  </WsMain>
</template>

<script>
export default {
  data: () => ({
    state: {
      startDate: null,
      endDate: null,
      wasa: null,
      monthSalary: null,
    },
    punches: [],
    additionalWorkingDays: [],
    additionalOffDays: [],
  }),

  methods: {
    $_getOvertimeSalary(rate, minutes) {
      if (!this.state.monthSalary) {
        return "-";
      } else {
        return Math.round((this.state.monthSalary / 240) * rate * minutes / 60);
      }
    },
    $_getOvertimeHour(workHour) {
      if (workHour >= 8) {
        return workHour - 8;
      } else {
        return 0;
      }
    },
    $_getOvertimeMinutes(workMinutes) {
      if (workMinutes >= 480) {
        return workMinutes - 480;
      } else {
        return 0;
      }
    },
    $_getFirstDayWorkHour(inTime) {
      const inTimeMoment = this.$moment(inTime);
      const beforePunch = this.punches.find((punch) => {
        return (
          this.$moment(punch.punch_at).isBefore(inTimeMoment) &&
          inTimeMoment.format("YYYY-MM-DD") ==
            this.$moment(punch.punch_at).format("YYYY-MM-DD")
        );
      });
      if (beforePunch) {
        return 0;
      } else {
        return this.$_getDayWorkHour(inTime);
      }
    },
    $_getDayWorkHour(inTime) {
      const inTimeMoment = this.$moment(inTime);
      const inPunches = this.punches.filter((e) => {
        return (
          this.$moment(e.punch_at).format("YYYY-MM-DD") ==
            inTimeMoment.format("YYYY-MM-DD") && e.type == 0
        );
      });
      const outPunches = this.punches.filter((e) => {
        return (
          this.$moment(e.punch_at).format("YYYY-MM-DD") ==
            inTimeMoment.format("YYYY-MM-DD") && e.type == 1
        );
      });

      if (inPunches.length != outPunches.length) {
        return 0;
      }

      let outSum = this.$moment();
      let inSum = this.$moment();
      outPunches.forEach((outPunch) => {
        outSum = outSum.add(this.$moment(outPunch.punch_at));
      });
      inPunches.forEach((inPunch) => {
        inSum = inSum.add(this.$moment(inPunch.punch_at));
      });

      const startInSumHour = this.$moment(inPunches[0].punch_at).get("hour");
      const endOutSumHour = this.$moment(
        outPunches[outPunches.length - 1].punch_at
      ).get("hour");

      let totalHour = Math.floor(outSum.diff(inSum, "minutes") / 30) / 2;

      if (startInSumHour <= 12 && endOutSumHour >= 13) {
        totalHour--;
      }
      if (startInSumHour <= 18 && endOutSumHour >= 19) {
        totalHour--;
      }
      return totalHour;
    },
    $_getFirstDayWorkMinutes(inTime) {
      const inTimeMoment = this.$moment(inTime);
      const beforePunch = this.punches.find((punch) => {
        return (
          this.$moment(punch.punch_at).isBefore(inTimeMoment) &&
          inTimeMoment.format("YYYY-MM-DD") ==
            this.$moment(punch.punch_at).format("YYYY-MM-DD")
        );
      });
      if (beforePunch) {
        return 0;
      } else {
        return this.$_getDayWorkMinutes(inTime);
      }
    },
    $_getDayWorkMinutes(inTime) {
      const inTimeMoment = this.$moment(inTime);
      const inPunches = this.punches.filter((e) => {
        return (
          this.$moment(e.punch_at).format("YYYY-MM-DD") ==
            inTimeMoment.format("YYYY-MM-DD") && e.type == 0
        );
      });
      const outPunches = this.punches.filter((e) => {
        return (
          this.$moment(e.punch_at).format("YYYY-MM-DD") ==
            inTimeMoment.format("YYYY-MM-DD") && e.type == 1
        );
      });

      if (inPunches.length != outPunches.length) {
        return 0;
      }

      let outSum = this.$moment();
      let inSum = this.$moment();
      outPunches.forEach((outPunch) => {
        outSum = outSum.add(this.$moment(outPunch.punch_at));
      });
      inPunches.forEach((inPunch) => {
        inSum = inSum.add(this.$moment(inPunch.punch_at));
      });

      const startInSumHour = this.$moment(inPunches[0].punch_at).get("hour");
      const endOutSumHour = this.$moment(
        outPunches[outPunches.length - 1].punch_at
      ).get("hour");

      let totalMinutes = Math.floor(outSum.diff(inSum, "minutes"));

      if (startInSumHour <= 12 && endOutSumHour >= 13) {
        totalMinutes -= 60;
      }
      if (startInSumHour <= 18 && endOutSumHour >= 19) {
        totalMinutes -= 60;
      }
      if (startInSumHour <= 18 && endOutSumHour === 18) {
        totalMinutes -= this.$moment(
          outPunches[outPunches.length - 1].punch_at
        ).get("minute");
      }
      return totalMinutes;
    },
    $_getInPunch(outTime) {
      const outTimeDate = this.$moment(outTime).format("YYYY-MM-DD");
      const inPunch = this.punches.find((e) => {
        return (
          e.type == 0 &&
          this.$moment(e.punch_at).format("YYYY-MM-DD") == outTimeDate
        );
      });
      return inPunch;
    },
    $_getOutPunch(inTime) {
      const inTimeDate = this.$moment(inTime).format("YYYY-MM-DD");
      const outPunch = this.punches.find((e) => {
        return (
          e.type == 1 &&
          this.$moment(e.punch_at).format("YYYY-MM-DD") == inTimeDate
        );
      });
      return outPunch;
    },
    $_getHightlightSymbol(punch) {
      if (punch.type == 0) {
        const outPunch = this.$_getOutPunch(punch.punch_at);
        if (!outPunch) {
          return "[ * ] ";
        } else {
          return null;
        }
      } else {
        const inPunch = this.$_getInPunch(punch.punch_at);
        if (!inPunch) {
          return "[ * ] ";
        } else {
          return null;
        }
      }
    },
    $_getPunchTypeText(type) {
      if (type == 0) {
        return "上班";
      } else if (type == 1) {
        return "下班";
      } else {
        return null;
      }
    },
    $_stateInit() {
      this.state.startDate = this.$moment()
        .add(-1, "months")
        .format("YYYY-MM-DD");
      this.state.endDate = this.$moment().format("YYYY-MM-DD");
    },
    async $_fetchPunches() {
      if (!this.state.startDate || !this.state.endDate || !this.state.wasa) {
        return;
      }
      const params = {
        time_field: "punch_at",
        start_time: this.state.startDate,
        end_time: this.state.endDate,
        wasa: this.state.wasa,
        order_by: "punch_at",
        order_way: "asc",
      };
      const res = await this.$axios.get("punch/index/all", {
        params: params,
      });
      this.punches = res.data.data;
    },
    async $_additionalWorkingDaysFetch() {
      if (!this.state.startDate || !this.state.endDate) {
        return;
      }
      const params = {
        time_field: "date",
        start_time: this.$moment(this.state.startDate).format("YYYY-MM-DD"),
        end_time: this.$moment(this.state.endDate).format("YYYY-MM-DD"),
      };
      const res = await this.$axios.get("additional_working_day/index/all", {
        params: params,
      });
      this.additionalWorkingDays = res.data.data;
    },
    async $_additionalOffDaysFetch() {
      if (!this.state.startDate || !this.state.endDate) {
        return;
      }
      const params = {
        time_field: "date",
        start_time: this.$moment(this.state.startDate).format("YYYY-MM-DD"),
        end_time: this.$moment(this.state.endDate).format("YYYY-MM-DD"),
      };
      const res = await this.$axios.get("additional_off_day/index/all", {
        params: params,
      });
      this.additionalOffDays = res.data.data;
    },
  },

  computed: {
    _overTimeWorkdaypre2Minutes() {
      let _overTimeWorkdaypre2Minutes = 0;
      this.punches
        .slice()
        .reverse()
        .forEach((punch) => {
          if (punch.type == 1) {
            return;
          }
          const punchMoment = this.$moment(punch.punch_at);
          const punchDay = punchMoment.day();
          const punchDate = punchMoment.format("YYYY-MM-DD");
          if (this._additionalOffDays.includes(punchDate)) {
            return;
          }
          if (
            (punchDay == 6 || punchDay == 0) &&
            !this._additionalWorkingDays.includes(punchDate)
          ) {
            return;
          }
          const workMinutes = this.$_getFirstDayWorkMinutes(punchMoment);
          console.log(workMinutes);
          if (workMinutes > 480) {
            if (workMinutes > 600) {
              _overTimeWorkdaypre2Minutes += 120;
            } else {
              _overTimeWorkdaypre2Minutes += workMinutes - 480;
            }
          }
        });
      return _overTimeWorkdaypre2Minutes;
    },
    _overTimeWorkdayafter2Minutes() {
      let _overTimeWorkdayafter2Minutes = 0;
      this.punches
        .slice()
        .reverse()
        .forEach((punch) => {
          if (punch.type == 1) {
            return;
          }
          const punchMoment = this.$moment(punch.punch_at);
          const punchDay = punchMoment.day();
          const punchDate = punchMoment.format("YYYY-MM-DD");
          if (this._additionalOffDays.includes(punchDate)) {
            return;
          }
          if (
            (punchDay == 6 || punchDay == 0) &&
            !this._additionalWorkingDays.includes(punchDate)
          ) {
            return;
          }
          const workMinutes = this.$_getFirstDayWorkMinutes(punchMoment);
          if (workMinutes > 600) {
            _overTimeWorkdayafter2Minutes += workMinutes - 600;
          }
        });
      return _overTimeWorkdayafter2Minutes;
    },
    _overTimeBreakdaypre8Minutes() {
      let _overTimeBreakdaypre8Minutes = 0;
      this.punches
        .slice()
        .reverse()
        .forEach((punch) => {
          if (punch.type == 1) {
            return;
          }
          const punchMoment = this.$moment(punch.punch_at);
          const punchDay = punchMoment.day();
          const punchDate = punchMoment.format("YYYY-MM-DD");
          if (
            (punchDay == 0 &&
              !this._additionalWorkingDays.includes(punchDate)) ||
            this._additionalOffDays.includes(punchDate)
          ) {
            const workMinutes = this.$_getFirstDayWorkMinutes(punchMoment);
            if (workMinutes >= 480) {
              _overTimeBreakdaypre8Minutes += 480;
            } else {
              _overTimeBreakdaypre8Minutes += workMinutes;
            }
          }
        });
      return _overTimeBreakdaypre8Minutes;
    },
    _overTimeBreakdayover8pre2Minutes() {
      let _overTimeBreakdayover8pre2Minutes = 0;
      this.punches
        .slice()
        .reverse()
        .forEach((punch) => {
          if (punch.type == 1) {
            return;
          }
          const punchMoment = this.$moment(punch.punch_at);
          const punchDay = punchMoment.day();
          const punchDate = punchMoment.format("YYYY-MM-DD");
          if (
            (punchDay == 0 &&
              !this._additionalWorkingDays.includes(punchDate)) ||
            this._additionalOffDays.includes(punchDate)
          ) {
            const workMinutes = this.$_getFirstDayWorkMinutes(punchMoment);
            if (workMinutes > 480) {
              if (workMinutes > 600) {
                _overTimeBreakdayover8pre2Minutes += 120;
              } else {
                _overTimeBreakdayover8pre2Minutes += workMinutes - 480;
              }
            }
          }
        });
      return _overTimeBreakdayover8pre2Minutes;
    },
    _overTimeBreakdayover8after2Minutes() {
      let _overTimeBreakdayover8after2Minutes = 0;
      this.punches
        .slice()
        .reverse()
        .forEach((punch) => {
          if (punch.type == 1) {
            return;
          }
          const punchMoment = this.$moment(punch.punch_at);
          const punchDay = punchMoment.day();
          const punchDate = punchMoment.format("YYYY-MM-DD");
          if (
            (punchDay == 0 &&
              !this._additionalWorkingDays.includes(punchDate)) ||
            this._additionalOffDays.includes(punchDate)
          ) {
            const workMinutes = this.$_getFirstDayWorkMinutes(punchMoment);
            if (workMinutes > 600) {
              _overTimeBreakdayover8after2Minutes += workMinutes - 600;
            }
          }
        });
      return _overTimeBreakdayover8after2Minutes;
    },
    _overTimeWeekdaypre2Minutes() {
      let _overTimeWeekdaypre2Minutes = 0;
      this.punches
        .slice()
        .reverse()
        .forEach((punch) => {
          if (punch.type == 1) {
            return;
          }
          const punchMoment = this.$moment(punch.punch_at);
          const punchDay = punchMoment.day();
          const punchDate = punchMoment.format("YYYY-MM-DD");
          if (this._additionalOffDays.includes(punchDate)) {
            return;
          }
          if (
            punchDay == 6 &&
            !this._additionalWorkingDays.includes(punchDate)
          ) {
            const workMinutes = this.$_getFirstDayWorkMinutes(punchMoment);
            if (workMinutes > 120) {
              _overTimeWeekdaypre2Minutes += 120;
            } else {
              _overTimeWeekdaypre2Minutes += workMinutes;
            }
          }
        });
      return _overTimeWeekdaypre2Minutes;
    },
    _overTimeWeekdaypre8Minutes() {
      let _overTimeWeekdaypre8Minutes = 0;
      this.punches
        .slice()
        .reverse()
        .forEach((punch) => {
          if (punch.type == 1) {
            return;
          }
          const punchMoment = this.$moment(punch.punch_at);
          const punchDay = punchMoment.day();
          const punchDate = punchMoment.format("YYYY-MM-DD");
          if (this._additionalOffDays.includes(punchDate)) {
            return;
          }
          if (
            punchDay == 6 &&
            !this._additionalWorkingDays.includes(punchDate)
          ) {
            const workMinutes = this.$_getFirstDayWorkMinutes(punchMoment);
            if (workMinutes > 120 && workMinutes <= 480) {
              _overTimeWeekdaypre8Minutes += workMinutes - 120;
            } else if (workMinutes > 480) {
              _overTimeWeekdaypre8Minutes += 360;
            }
          }
        });
      return _overTimeWeekdaypre8Minutes;
    },
    _overTimeWeekdayover8Minutes() {
      let _overTimeWeekdayover8Minutes = 0;
      this.punches
        .slice()
        .reverse()
        .forEach((punch) => {
          if (punch.type == 1) {
            return;
          }
          const punchMoment = this.$moment(punch.punch_at);
          const punchDay = punchMoment.day();
          const punchDate = punchMoment.format("YYYY-MM-DD");
          if (this._additionalOffDays.includes(punchDate)) {
            return;
          }
          if (
            punchDay == 6 &&
            !this._additionalWorkingDays.includes(punchDate)
          ) {
            const workMinutes = this.$_getFirstDayWorkMinutes(punchMoment);
            if (workMinutes > 480) {
              _overTimeWeekdayover8Minutes += workMinutes - 480;
            }
          }
        });
      return _overTimeWeekdayover8Minutes;
    },
    _overTimeWorkdaypre2Hour() {
      let _overTimeWorkdaypre2Hour = 0;
      this.punches
        .slice()
        .reverse()
        .forEach((punch) => {
          if (punch.type == 1) {
            return;
          }
          const punchMoment = this.$moment(punch.punch_at);
          const punchDay = punchMoment.day();
          const punchDate = punchMoment.format("YYYY-MM-DD");
          if (this._additionalOffDays.includes(punchDate)) {
            return;
          }
          if (
            (punchDay == 6 || punchDay == 0) &&
            !this._additionalWorkingDays.includes(punchDate)
          ) {
            return;
          }
          const workHour = this.$_getFirstDayWorkHour(punchMoment);
          if (workHour > 8) {
            if (workHour > 10) {
              _overTimeWorkdaypre2Hour += 2;
            } else {
              _overTimeWorkdaypre2Hour += workHour - 8;
            }
          }
        });
      return _overTimeWorkdaypre2Hour;
    },
    _overTimeWorkdayafter2Hour() {
      let _overTimeWorkdayafter2Hour = 0;
      this.punches
        .slice()
        .reverse()
        .forEach((punch) => {
          if (punch.type == 1) {
            return;
          }
          const punchMoment = this.$moment(punch.punch_at);
          const punchDay = punchMoment.day();
          const punchDate = punchMoment.format("YYYY-MM-DD");
          if (this._additionalOffDays.includes(punchDate)) {
            return;
          }
          if (
            (punchDay == 6 || punchDay == 0) &&
            !this._additionalWorkingDays.includes(punchDate)
          ) {
            return;
          }
          const workHour = this.$_getFirstDayWorkHour(punchMoment);
          if (workHour > 10) {
            _overTimeWorkdayafter2Hour += workHour - 10;
          }
        });
      return _overTimeWorkdayafter2Hour;
    },
    _overTimeBreakdaypre8Hour() {
      let _overTimeBreakdaypre8Hour = 0;
      this.punches
        .slice()
        .reverse()
        .forEach((punch) => {
          if (punch.type == 1) {
            return;
          }
          const punchMoment = this.$moment(punch.punch_at);
          const punchDay = punchMoment.day();
          const punchDate = punchMoment.format("YYYY-MM-DD");
          if (
            (punchDay == 0 &&
              !this._additionalWorkingDays.includes(punchDate)) ||
            this._additionalOffDays.includes(punchDate)
          ) {
            const workHour = this.$_getFirstDayWorkHour(punchMoment);
            if (workHour >= 8) {
              _overTimeBreakdaypre8Hour += 8;
            } else {
              _overTimeBreakdaypre8Hour += workHour;
            }
          }
        });
      return _overTimeBreakdaypre8Hour;
    },
    _overTimeBreakdayover8pre2Hour() {
      let _overTimeBreakdayover8pre2Hour = 0;
      this.punches
        .slice()
        .reverse()
        .forEach((punch) => {
          if (punch.type == 1) {
            return;
          }
          const punchMoment = this.$moment(punch.punch_at);
          const punchDay = punchMoment.day();
          const punchDate = punchMoment.format("YYYY-MM-DD");
          if (
            (punchDay == 0 &&
              !this._additionalWorkingDays.includes(punchDate)) ||
            this._additionalOffDays.includes(punchDate)
          ) {
            const workHour = this.$_getFirstDayWorkHour(punchMoment);
            if (workHour > 8) {
              if (workHour > 10) {
                _overTimeBreakdayover8pre2Hour += 2;
              } else {
                _overTimeBreakdayover8pre2Hour += workHour - 8;
              }
            }
          }
        });
      return _overTimeBreakdayover8pre2Hour;
    },
    _overTimeBreakdayover8after2Hour() {
      let _overTimeBreakdayover8after2Hour = 0;
      this.punches
        .slice()
        .reverse()
        .forEach((punch) => {
          if (punch.type == 1) {
            return;
          }
          const punchMoment = this.$moment(punch.punch_at);
          const punchDay = punchMoment.day();
          const punchDate = punchMoment.format("YYYY-MM-DD");
          if (
            (punchDay == 0 &&
              !this._additionalWorkingDays.includes(punchDate)) ||
            this._additionalOffDays.includes(punchDate)
          ) {
            const workHour = this.$_getFirstDayWorkHour(punchMoment);
            if (workHour > 10) {
              _overTimeBreakdayover8after2Hour += workHour - 10;
            }
          }
        });
      return _overTimeBreakdayover8after2Hour;
    },
    _overTimeWeekdaypre2Hour() {
      let _overTimeWeekdaypre2Hour = 0;
      this.punches
        .slice()
        .reverse()
        .forEach((punch) => {
          if (punch.type == 1) {
            return;
          }
          const punchMoment = this.$moment(punch.punch_at);
          const punchDay = punchMoment.day();
          const punchDate = punchMoment.format("YYYY-MM-DD");
          if (this._additionalOffDays.includes(punchDate)) {
            return;
          }
          if (
            punchDay == 6 &&
            !this._additionalWorkingDays.includes(punchDate)
          ) {
            const workHour = this.$_getFirstDayWorkHour(punchMoment);
            if (workHour > 2) {
              _overTimeWeekdaypre2Hour += 2;
            } else {
              _overTimeWeekdaypre2Hour += workHour;
            }
          }
        });
      return _overTimeWeekdaypre2Hour;
    },
    _overTimeWeekdaypre8Hour() {
      let _overTimeWeekdaypre8Hour = 0;
      this.punches
        .slice()
        .reverse()
        .forEach((punch) => {
          if (punch.type == 1) {
            return;
          }
          const punchMoment = this.$moment(punch.punch_at);
          const punchDay = punchMoment.day();
          const punchDate = punchMoment.format("YYYY-MM-DD");
          if (this._additionalOffDays.includes(punchDate)) {
            return;
          }
          if (
            punchDay == 6 &&
            !this._additionalWorkingDays.includes(punchDate)
          ) {
            const workHour = this.$_getFirstDayWorkHour(punchMoment);
            if (workHour > 2 && workHour <= 8) {
              _overTimeWeekdaypre8Hour += workHour - 2;
            } else if (workHour > 8) {
              _overTimeWeekdaypre8Hour += 6;
            }
          }
        });
      return _overTimeWeekdaypre8Hour;
    },
    _overTimeWeekdayover8Hour() {
      let _overTimeWeekdayover8Hour = 0;
      this.punches
        .slice()
        .reverse()
        .forEach((punch) => {
          if (punch.type == 1) {
            return;
          }
          const punchMoment = this.$moment(punch.punch_at);
          const punchDay = punchMoment.day();
          const punchDate = punchMoment.format("YYYY-MM-DD");
          if (this._additionalOffDays.includes(punchDate)) {
            return;
          }
          if (
            punchDay == 6 &&
            !this._additionalWorkingDays.includes(punchDate)
          ) {
            const workHour = this.$_getFirstDayWorkHour(punchMoment);
            if (workHour > 8) {
              _overTimeWeekdayover8Hour += workHour - 8;
            }
          }
        });
      return _overTimeWeekdayover8Hour;
    },
    _additionalWorkingDays() {
      if (!this.additionalWorkingDays) {
        return [];
      }
      const _additionalWorkingDays = [];
      this.additionalWorkingDays.forEach((additionalWorkingDay) => {
        _additionalWorkingDays.push(additionalWorkingDay.date);
      });
      return _additionalWorkingDays;
    },
    _additionalOffDays() {
      if (!this.additionalOffDays) {
        return [];
      }
      const _additionalOffDays = [];
      this.additionalOffDays.forEach((additionalOffDay) => {
        _additionalOffDays.push(additionalOffDay.date);
      });
      return _additionalOffDays;
    },
  },

  watch: {
    state: {
      handler() {
        this.$_fetchPunches();
        this.$_additionalWorkingDaysFetch();
        this.$_additionalOffDaysFetch();
      },
      deep: true,
    },
  },

  mounted() {
    this.$_stateInit();
  },
};
</script>

<style lang="scss" >
.calc-table {
  tr {
    th {
      padding: 10px;
    }
    td {
      padding: 10px;
      &.center {
        text-align: center;
        vertical-align: middle;
      }
    }
  }
}
</style>
export default {
  modelName: "excute_material",
  label: "執行元件",
  fields: {
    id: {
      type: "text",
      label: "ID",
      readonly: true
    },
    name: {
      type: "text",
      label: "名稱",
      autofocus: true,
      rules: "required"
    },
    hour: {
      type: 'text',
      label: '執行時數',
    },
    remark: {
      type: 'textarea',
      label: '備註'
    },
    excute_type: {
      type: 'belongsTo',
      label: '執行類型',
      textKey: "name",
      modelName: "excute_type",
      relationPopup: true,
      width: '100px',
    },
    tags: {
      type: 'hasMany',
      label: '標籤',
      textKey: "name",
      modelName: "tag",
      relationPopup: true,
    },
    updated_at: {
      type: "datetime",
      label: "最後更新時間",
      readonly: true
    },
    sequence: {
      type: "text",
      label: "排序"
    },
  },
}
import evaluation_material_option from './evaluation_material_option'
import evaluation_material_excute from './evaluation_material_excute'
export default {
  modelName: "evaluation_material",
  label: "評估元件",
  fields: {
    id: {
      type: "text",
      label: "ID",
      readonly: true,
      width: '70px',
      sortable: true
    },
    name: {
      type: "text",
      label: "名稱",
      autofocus: true,
      rules: "required"
    },
    evaluation_type: {
      type: 'belongsTo',
      label: '評估類型',
      textKey: "name",
      modelName: "evaluation_type",
      width: '100px',
    },
    price: {
      type: 'number',
      label: '價格',
      width: '70px',
      sortable: true
    },
    excute_days: {
      type: 'number',
      label: '執行天數',
      rules: 'required'
    },
    remark: {
      type: 'textarea',
      label: '備註'
    },
    tags: {
      type: 'tags',
      label: 'Tag',
    },
    images: {
      type: "images",
      label: "範例圖片",
      uploadUrl: 'evaluation_material/image/'
    },
    evaluation_material_excutes: {
      type: 'hasManyList',
      label: '執行項目',
      textKey: "hour",
      modelName: "evaluation_material_excute",
      fields: evaluation_material_excute.fields,
      showFields: [
        'excute_type',
        'hour',
      ]
    },
    evaluation_material_options: {
      type: 'hasMany',
      label: '選項',
      textKey: "name",
      modelName: "evaluation_material_option",
      relationPopup: true,
      fields: evaluation_material_option.fields,
      showFields: [
        'name',
        'price',
        'excute_days',
      ]
    },
    updated_at: {
      type: "datetime",
      label: "最後更新時間",
      readonly: true
    },
    // sequence: {
    //   type: "text",
    //   label: "排序"
    // },
  },
}

<template>
  <WsMain>
    <WsTitle>新增{{label}}</WsTitle>
    <div class="ws-content-section">
      <ValidationObserver
        ref="form"
        class="ws-content-section-observer"
      >
        <form
          ref="createForm"
          @submit.prevent
        >
          <div class="ws-content-section">
            <div class="ws-content-section__primary">
              <WsStateContentBlock
                v-for="(item,itemKey) in primary"
                :key="itemKey"
                :type="item.type"
                :label="item.label"
                :title="item.title"
                :remark="item.remark"
                :floors="item.floors"
                :modedFields="_updateFields"
                :fields="item.fields"
                :field="item.field"
                :modelName="item.modelName"
                :modeVersions="item.modeVersions"
                :modeParent="item.modeParent"
                :customButtons="item.customButtons"
                :fieldsRead="item.fieldsRead"
                :updateData="updateData"
                :parentModelName="$_getParentModelName(item)"
              ></WsStateContentBlock>
            </div>
            <div class="ws-content-section__secondary">
              <WsStateContentBlock
                v-for="(item,itemKey) in secondary"
                :key="itemKey"
                :type="item.type"
                :label="item.label"
                :title="item.title"
                :remark="item.remark"
                :floors="item.floors"
                :modedFields="_updateFields"
                :fields="item.fields"
                :field="item.field"
                :modelName="item.modelName"
                :modeVersions="item.modeVersions"
                :modeParent="item.modeParent"
                :customButtons="item.customButtons"
                :fieldsRead="item.fieldsRead"
                :updateData="updateData"
                :parentModelName="$_getParentModelName(item)"
              ></WsStateContentBlock>
            </div>
          </div>
        </form>
      </ValidationObserver>
    </div>
    <WsBottomNav>
      <template v-slot:rightActions>
        <WsBtn
          @click="$router.go(-1)"
          outlined
        >取消</WsBtn>
        <WsBtn
          class="ml-8"
          :loading="loading.submit"
          @click="$_onSubmit()"
        >送出</WsBtn>
      </template>
    </WsBottomNav>
  </WsMain>
</template>

<script>
export default {
  data: () => ({
    isMounted: false,
    parentModelData: null,
    updateData: {},
    loading: {
      submit: false,
    },
  }),
  methods: {
    loadingStart() {
      this.loading.submit = true;
    },
    loadingStop() {
      this.loading.submit = false;
    },
    async $_onSubmit() {
      const isValidate = await this.$refs.form.validate();
      if (!isValidate) {
        return;
      }
      if (this.emitSubmit) {
        this.$emit("submit", this.updateData);
        return;
      }
      this.loadingStart();
      try {
        let postUrl;
        if (this.parentModelName && this.parentId) {
          postUrl = `/${this.parentModelName}/${this.parentId}/${this.modelName}`;
        } else {
          postUrl = `/${this.modelName}`;
        }
        const postData = JSON.parse(JSON.stringify(this.updateData));
        const res = await this.$axios.post(postUrl, postData);
        if (this.afterSubmitAction) {
          this.afterSubmitAction(res.data.data);
        }
        if (this._updateFields.last_version) {
          try {
            await this.$axios.post(
              `/${this.modelName}/${res.data.data.id}/${this._versionModelName}`,
              this.updateData.last_version
            );
            this.loading.submit = false;
            this.$store.dispatch("app/stopNeedSave");
            if (this.$route.query && this.$route.query.redirect) {
              this.$router.push(this.$route.query.redirect);
            } else {
              this.$router.push(`/${this.modelName}`);
            }
          } catch (error) {
            alert("版本儲存發生錯誤");
            this.loadingStop();
          }
        } else {
          this.$store.dispatch("app/stopNeedSave");
          if (this.$route.query && this.$route.query.redirect) {
            this.$router.push(this.$route.query.redirect);
          } else {
            this.$router.push(`/${this.modelName}`);
          }
        }
      } catch (error) {
        alert("儲存錯誤");
        this.loadingStop();
      }
    },
    $_getParentModelName(primaryItem) {
      if (primaryItem.modeVersions) {
        return `${this.modelName}_version`;
      } else {
        return this.modelName;
      }
    },
    async $_parentDetchData() {
      this.$store.dispatch("app/startPageLoading");
      let getUrl = `/${this.parentModelName}/${this.parentId}`;
      try {
        const res = await this.$axios.get(getUrl);
        this.parentModelData = res.data.data;
      } catch (error) {
        alert(error);
      } finally {
        this.$store.dispatch("app/stopPageLoading");
      }
    },
  },
  computed: {
    _parentTitle() {
      if (!this.parentModelName) {
        return null;
      } else if (!this.parentModelData) {
        return this.parentLabel;
      } else {
        return this.$_CMS_getValueByFieldKey(
          this.parentTitleKey,
          this.parentModelData
        );
      }
    },
    _breadcrumbsItems() {
      const _breadcrumbsItems = [];
      if (
        this.parentModelName &&
        this.parentId &&
        this.parentLabel &&
        this.parentTitleKey
      ) {
        _breadcrumbsItems.push({
          text: this.parentLabel,
          to: `/${this.parentModelName}`,
          disabled: false,
        });
        _breadcrumbsItems.push({
          text: this._parentTitle,
          to: `/${this.parentModelName}/${this.parentId}`,
          disabled: false,
        });
      }
      _breadcrumbsItems.push({
        text: `新增${this.label}`,
        disabled: true,
      });
      return _breadcrumbsItems;
    },
    _versionModelName() {
      return `${this.modelName}_version`;
    },
    _updateFields() {
      return this.$_CMS_geUpdateFields(this.fields);
    },
  },
  props: {
    emitSubmit: {
      type: Boolean,
      default: false,
    },
    afterSubmitAction: {
      type: Function,
      default: null,
    },
    value: {
      type: Object,
      default: null,
    },
    parentTitleKey: {
      type: String,
      default: null,
    },
    parentLabel: {
      type: String,
      default: null,
    },
    parentId: {
      type: [String, Number],
      default: null,
    },
    parentModelName: {
      type: String,
      default: null,
    },
    primary: {
      type: Array,
      default() {
        return [];
      },
    },
    secondary: {
      type: Array,
      default() {
        return [];
      },
    },
    modelName: String,
    label: String,
    fields: Object,
  },
  mounted() {
    if (this.parentModelName && this.parentId) {
      this.$_parentDetchData();
    }
    this.$_CMS_updateDataSetFromFields(
      this,
      this.updateData,
      this._updateFields
    );
    if (this.value) {
      this.updateData = this.value;
    }
    setTimeout(() => {
      this.isMounted = true;
    }, 0);
  },
  watch: {
    value: {
      handler() {
        this.updateData = this.value;
      },
    },
    updateData: {
      handler() {
        this.$emit("input", this.updateData);
        if (this.isMounted) {
          this.$store.dispatch("app/startNeedSave");
        }
      },
      deep: true,
    },
  },
};
</script>
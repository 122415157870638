<template>
  <div class="app-my-calendar">
    <WsFullCalendar
      ref="WsFullCalendar"
      :events="C_calendarEvents"
      @click="$_onClick($event)"
      @date-rander="$_onDateRender($event)"
      :calendarWeekends="_calendarWeekends"
    ></WsFullCalendar>
    <WsReadDialog
      ref="taskReadDialog"
      :fields="_taskFields"
      :deletable="false"
      :updatable="false"
    >
      <template v-slot:rightActions>
        <WsBtn
          v-if="!taskFocus.is_finish"
          @click="$_onTaskDone()"
        >Done</WsBtn>
      </template>
    </WsReadDialog>
    <WsReadDialog
      ref="additionalOffDayReadDialog"
      :fields="_additionalOffDayFields"
      :deletable="false"
      :updatable="false"
    ></WsReadDialog>
    <WsReadDialog
      ref="additionalWorkingDayReadDialog"
      :fields="_additionalWorkingDayFields"
      :deletable="false"
      :updatable="false"
    ></WsReadDialog>
    <AppTaskDoneDialog
      ref="taskDoneDialog"
      @submit="$_onTaskDoneSubmit($event)"
    ></AppTaskDoneDialog>
    <WsReadDialog
      ref="milestoneReadDialog"
      :fields="_milestoneFields"
      :deletable="false"
      :updatable="false"
    >
      <template v-slot:rightActions>
        <WsBtn
          v-if="!milestoneFocus.is_finish"
          @click="$_onMilestoneDone()"
          :loading="milestoneDoneLoading"
        >Done</WsBtn>
      </template>
    </WsReadDialog>
  </div>
</template>

<script>
export default {
  data: () => ({
    dateFocus: null,
    milestoneDoneLoading: false,
    C_calendarEvents: null,
    taskFocus: null,
    additionalOffDayFocus: null,
    additionalWorkingDayFocus: null,
    milestoneFocus: null,
    updateErrorMessages: null,
    createErrorMessages: null,
    allDayDragging: null,
    additionalWorkingDayFields: ["name", "date"],
    additionalOffDayFields: ["name", "date"],
    taskFields: [
      "name",
      "project",
      "hour",
      "finish_hour",
      "excute_type",
      "start_time",
      "is_close",
      "is_finish",
      "remark",
    ],
    milestoneFields: [
      "name",
      "project",
      "date",
      "excute_type",
      "is_close",
      "is_finish",
      "remark",
    ],
  }),

  methods: {
    $_onDateRender($event) {
      this.$emit("date-rander", $event);
      const calendarApi = this.$refs.WsFullCalendar.$refs.FullCalendar.getApi();
      this.dateFocus = calendarApi.getDate();
    },
    async $_onTaskDoneSubmit($event) {
      try {
        this.$refs.taskDoneDialog.startLoading();
        const res = await this.$axios.post(`task/${this.taskFocus.id}/done`, {
          finish_hour: $event.finishHour,
        });
        this.$emit("task-done", res.data.data);
      } catch (error) {
        alert("錯誤");
      } finally {
        this.$refs.taskDoneDialog.stopLoading();
        this.$refs.taskDoneDialog.close();
        this.$refs.taskReadDialog.close();
      }
    },
    async $_onMilestoneDone() {
      try {
        this.milestoneDoneLoading = true;
        const res = await this.$axios.post(
          `milestone/${this.milestoneFocus.id}/done`
        );
        this.$emit("milestone-done", res.data.data);
      } catch (error) {
        alert("錯誤");
      } finally {
        this.milestoneDoneLoading = false;
        this.$refs.milestoneReadDialog.close();
      }
    },
    $_onTaskDone() {
      this.$refs.taskDoneDialog.open();
    },
    $_onClick($event) {
      const type = $event.event.extendedProps.type;
      if (type == "task") {
        this.taskFocus = $event.event.extendedProps.data;
        this.$refs.taskReadDialog.open(this.taskFocus);
      } else if (type == "milestone") {
        this.milestoneFocus = $event.event.extendedProps.data;
        this.$refs.milestoneReadDialog.open(this.milestoneFocus);
      } else if (type == "additional_off_day") {
        this.additionalOffDayFocus = $event.event.extendedProps.data;
        this.$refs.additionalOffDayReadDialog.open(this.additionalOffDayFocus);
      } else if (type == "additional_working_day") {
        this.additionalWorkingDayFocus = $event.event.extendedProps.data;
        this.$refs.additionalWorkingDayReadDialog.open(
          this.additionalWorkingDayFocus
        );
      }
    },
    $_calendarEventsReset() {
      this.C_calendarEvents = [];
      setTimeout(() => {
        this.calendarEvents.forEach((event) => {
          this.C_calendarEvents.push(event);
        });
      }, 0);
    },
  },

  computed: {
    _additionalOffDayFields() {
      const _additionalOffDayFields = {};
      const modelAdditionalOffDayFields = this.$store.state.model
        .additional_off_day.fields;
      this.additionalOffDayFields.forEach((additionalOffDayField) => {
        if (modelAdditionalOffDayFields[additionalOffDayField] != undefined) {
          _additionalOffDayFields[additionalOffDayField] =
            modelAdditionalOffDayFields[additionalOffDayField];
        }
      });
      return _additionalOffDayFields;
    },
    _additionalWorkingDayFields() {
      const _additionalWorkingDayFields = {};
      const modelAdditionalWorkingDayFields = this.$store.state.model
        .additional_working_day.fields;
      this.additionalWorkingDayFields.forEach((additionalWorkingDayField) => {
        if (
          modelAdditionalWorkingDayFields[additionalWorkingDayField] !=
          undefined
        ) {
          _additionalWorkingDayFields[additionalWorkingDayField] =
            modelAdditionalWorkingDayFields[additionalWorkingDayField];
        }
      });
      return _additionalWorkingDayFields;
    },
    _calendarWeekends() {
      if (!this.dateFocus) {
        return false;
      }
      const dateFocusMoment = this.$moment(this.dateFocus);
      const startDate = dateFocusMoment.startOf("week").format("YYYY-MM-DD");
      const endDate = dateFocusMoment.endOf("week").format("YYYY-MM-DD");
      if (
        this.additionalWorkingDays.includes(startDate) ||
        this.additionalWorkingDays.includes(endDate)
      ) {
        return true;
      } else {
        return false;
      }
    },
    _taskDoneFields() {
      if (!this.taskFocus) {
        return null;
      } else {
        const _taskDoneFields = {};
        const modelTaskFields = this.$store.state.model.task.fields;
        _taskDoneFields.finish_hour = modelTaskFields.finish_hour;
        return _taskDoneFields;
      }
    },
    _taskFields() {
      if (!this.taskFocus) {
        return null;
      } else {
        const _taskFields = {};
        const modelTaskFields = this.$store.state.model.task.fields;
        this.taskFields.forEach((taskField) => {
          if (modelTaskFields[taskField] != undefined) {
            _taskFields[taskField] = modelTaskFields[taskField];
          }
        });
        return _taskFields;
      }
    },
    _milestoneFields() {
      if (!this.milestoneFocus) {
        return null;
      } else {
        const _milestoneFields = {};
        const modelMilestoneFields = this.$store.state.model.milestone.fields;
        this.milestoneFields.forEach((milestoneField) => {
          if (modelMilestoneFields[milestoneField] != undefined) {
            _milestoneFields[milestoneField] =
              modelMilestoneFields[milestoneField];
          }
        });
        return _milestoneFields;
      }
    },
  },

  props: {
    additionalWorkingDays: {
      type: Array,
      default: null,
    },
    additionalOffDays: {
      type: Array,
      default: null,
    },
    calendarEvents: {
      type: Array,
      default: null,
    },
  },

  watch: {
    calendarEvents: {
      handler() {
        this.$_calendarEventsReset();
      },
    },
  },

  mounted() {
    this.$_calendarEventsReset();
  },
};
</script>

<style>
</style>
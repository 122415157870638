<template>
  <div class="ws-info-select">{{_content}}</div>
</template>

<script>
export default {
  methods: {
    $_getItemByValue(value) {
      const item = this.items.find((e) => {
        return e.value == value;
      });
      return item;
    },
  },
  computed: {
    _content() {
      if (this.value == undefined || !this.items.length) {
        return null;
      } else {
        if (this.multiple) {
          let _value = "";
          this.value.forEach((valueItem, valueIndex) => {
            if (valueIndex > 0) {
              _value += ", ";
            }
            if (this.$_getItemByValue(valueItem)) {
              _value += this.$_getItemByValue(valueItem).text;
            }
          });
          return _value;
        } else {
          if (this.$_getItemByValue(this.value)) {
            return this.$_getItemByValue(this.value).text;
          } else {
            return null;
          }
        }
      }
    },
  },
  props: {
    multiple: {
      type: Boolean,
      default: false,
    },
    value: {
      type: [Number, String, Array],
      default: null,
    },
    items: {
      type: Array,
      required: true,
    },
  },
};
</script>
<template>
  <WsMain>
    <WsTitle>個人指標</WsTitle>
    <WsCrud
      class="mt-40"
      :modelName="$store.state.model.wasa_hr_skill.modelName"
      :label="$store.state.model.wasa_hr_skill.label"
      :fields="$store.state.model.wasa_hr_skill.fields"
      :liveSearching="true"
      :showFields="wasaHrSkillShowFields"
      :expandable="false"
      :showExpand="false"
      :fetchQuery="_wasaHrSkillFetchQuery"
      :creatable="false"
      :updatable="false"
      :deletable="false"
      :inRowBtnUpdate="false"
      :inRowBtnRead="false"
      :inRowBtnDelete="false"
    ></WsCrud>
    <WsRow class="mt-10">
      <WsCol class="col-4">
        <WsAnalyticsCard title="Speed">
          <template v-slot:content>
            <WsTitle
              size="1"
              class="py-66"
              center
            >{{_wasaAnalytics.speed}}</WsTitle>
          </template>
        </WsAnalyticsCard>
      </WsCol>
      <WsCol class="col-4">
        <WsAnalyticsCard title="Score">
          <template v-slot:content>
            <WsTitle
              size="1"
              class="py-66"
              center
            >{{_wasaAnalytics.score}}</WsTitle>
          </template>
        </WsAnalyticsCard>
      </WsCol>
    </WsRow>
    <WsTitle class="mt-20">時間內指標</WsTitle>
    <WsRow class="mt-10">
      <WsCol class="col-4">
        <WsState
          label="開始時間"
          type="date"
          v-model="state.startDate"
        ></WsState>
      </WsCol>
      <WsCol class="col-4">
        <WsState
          label="結束時間"
          type="date"
          v-model="state.endDate"
        ></WsState>
      </WsCol>
    </WsRow>
    <WsRow class="mt-10">
      <WsCol class="col-4">
        <WsAnalyticsCard title="應排時數">
          <template v-slot:content>
            <WsTitle
              size="1"
              class="py-66"
              center
            >{{_wasaAnalytics.totalWorkHours}}</WsTitle>
          </template>
        </WsAnalyticsCard>
      </WsCol>
      <WsCol class="col-4">
        <WsAnalyticsCard title="執行時數">
          <template v-slot:content>
            <WsTitle
              size="1"
              class="py-66"
              center
            >{{_wasaAnalytics.finishHours}}</WsTitle>
          </template>
        </WsAnalyticsCard>
      </WsCol>
      <WsCol class="col-4">
        <WsAnalyticsCard
          title="未執行時數"
          :alert="_wasaAnalytics.unfinishHours"
        >
          <template v-slot:content>
            <WsTitle
              size="1"
              class="py-66"
              center
            >{{_wasaAnalytics.unfinishHours}}</WsTitle>
          </template>
        </WsAnalyticsCard>
      </WsCol>
      <WsCol class="col-4">
        <WsAnalyticsCard title="安排時數">
          <template v-slot:content>
            <WsTitle
              size="1"
              class="py-66"
              center
            >{{_wasaAnalytics.planHours}}</WsTitle>
          </template>
        </WsAnalyticsCard>
      </WsCol>
      <WsCol class="col-4">
        <WsAnalyticsCard
          title="安排率"
          :alert="_wasaAnalytics.planRate<80"
        >
          <template v-slot:content>
            <WsTitle
              size="1"
              class="py-66"
              center
            >{{_wasaAnalytics.planRate}}%</WsTitle>
          </template>
          <template v-slot:titleright>
            <WsTooptip
              text="安排時數 / 應排時數"
              alignVertical="right"
            ></WsTooptip>
          </template>
        </WsAnalyticsCard>
      </WsCol>
      <WsCol class="col-4">
        <WsAnalyticsCard
          title="執行效率"
          :alert="_wasaAnalytics.efficiency<90"
        >
          <template v-slot:content>
            <WsTitle
              size="1"
              class="py-66"
              center
            >{{_wasaAnalytics.efficiency}}%</WsTitle>
          </template>
          <template v-slot:titleright>
            <WsTooptip
              text="已完成時數 / 已完成安排時數"
              alignVertical="right"
            ></WsTooptip>
          </template>
        </WsAnalyticsCard>
      </WsCol>
      <WsCol class="col-4">
        <WsAnalyticsCard
          title="效益值"
          :alert="_wasaAnalytics.benefitNumber<90"
        >
          <template v-slot:content>
            <WsTitle
              size="1"
              class="py-66"
              center
            >{{_wasaAnalytics.benefitNumber}}</WsTitle>
          </template>
          <template v-slot:titleright>
            <WsTooptip
              text="執行效率 / 安排率"
              alignVertical="right"
            ></WsTooptip>
          </template>
        </WsAnalyticsCard>
      </WsCol>
      <WsCol class="col-4">
        <WsAnalyticsCard
          title="加班時數"
          :alert="_wasaAnalytics.overTimeHours>15"
        >
          <template v-slot:content>
            <WsTitle
              size="1"
              class="py-66"
              center
            >{{_wasaAnalytics.overTimeHours}}</WsTitle>
          </template>
        </WsAnalyticsCard>
      </WsCol>
      <WsCol class="col-4">
        <WsAnalyticsCard
          title="真實執行效率"
          :alert="_wasaAnalytics.efficiencyReal<90"
        >
          <template v-slot:content>
            <WsTitle
              size="1"
              class="py-66"
              center
            >{{_wasaAnalytics.efficiencyReal}}%</WsTitle>
          </template>
          <template v-slot:titleright>
            <WsTooptip
              text="去除異常加權之執行效率"
              alignVertical="right"
            ></WsTooptip>
          </template>
        </WsAnalyticsCard>
      </WsCol>
      <WsCol class="col-4">
        <WsAnalyticsCard
          title="真實效益值"
          :alert="_wasaAnalytics.benefitNumberReal<90"
        >
          <template v-slot:content>
            <WsTitle
              size="1"
              class="py-66"
              center
            >{{_wasaAnalytics.benefitNumberReal}}</WsTitle>
          </template>
          <template v-slot:titleright>
            <WsTooptip
              text="去除異常加權之效益值"
              alignVertical="right"
            ></WsTooptip>
          </template>
        </WsAnalyticsCard>
      </WsCol>
      <WsCol class="col-4">
        <WsAnalyticsCard
          title="異常時數"
          :alert="_wasaAnalytics.abnormalHours>0"
        >
          <template v-slot:content>
            <WsTitle
              size="1"
              class="py-66"
              center
            >{{_wasaAnalytics.abnormalHours}}</WsTitle>
          </template>
          <template v-slot:titleright>
            <WsTooptip
              text="加班時數、應排時數加總之異常時數"
              alignVertical="right"
            ></WsTooptip>
          </template>
        </WsAnalyticsCard>
      </WsCol>
      <WsCol class="col-4">
        <WsAnalyticsCard
          title="異常比例"
          :alert="_wasaAnalytics.abnormalRate>0"
        >
          <template v-slot:content>
            <WsTitle
              size="1"
              class="py-66"
              center
            >{{_wasaAnalytics.abnormalRate}}%</WsTitle>
          </template>
          <template v-slot:titleright>
            <WsTooptip
              text="異常時數佔總安排之比例"
              alignVertical="right"
            ></WsTooptip>
          </template>
        </WsAnalyticsCard>
      </WsCol>
    </WsRow>
  </WsMain>
</template>

<script>
import serviceWasa from "@/services/api/v1/wasa";
import serviceLeaveDay from "@/services/api/v1/leave_day";
import serviceAdditionalOffDay from "@/services/api/v1/additional_off_day";
import serviceAdditionalWorkingDay from "@/services/api/v1/additional_working_day";
import serviceTask from "@/services/api/v1/task";
import servicePunch from "@/services/api/v1/punch";
export default {
  metaInfo() {
    return {
      title: "我的概況",
    };
  },

  data: () => ({
    state: {
      startDate: null,
      endDate: null,
    },
    tasks: null,
    leaveDays: null,
    additionalOffDays: null,
    additionalWorkingDays: null,
    punches: null,
    wasaHrSkillShowFields: ["hr_skill", "reach_at"],
  }),

  methods: {
    async $_fetchLeaveDays() {
      const params = {
        start_time: this.state.startDate,
        end_time: this.state.endDate,
        time_field: "start_date",
        wasa: this._currentUser.id,
      };
      this.leaveDays = await serviceLeaveDay.getListAll(params);
    },
    async $_fetchAdditionalOffDay() {
      const params = {
        start_time: this.state.startDate,
        end_time: this.state.endDate,
        time_field: "date",
      };
      this.additionalOffDays = await serviceAdditionalOffDay.getListAll(params);
    },
    async $_fetchAdditionalWorkingDay() {
      const params = {
        start_time: this.state.startDate,
        end_time: this.state.endDate,
        time_field: "date",
      };
      this.additionalWorkingDays = await serviceAdditionalWorkingDay.getListAll(
        params
      );
    },
    async $_fetchTask() {
      if (!this.state.startDate || !this.state.endDate) {
        return;
      }
      const params = {
        time_field: "start_time",
        start_time: this.state.startDate,
        end_time: this.state.endDate,
        order_by: "start_time",
        order_way: "asc",
        taker: this._currentUser.id,
      };
      this.tasks = await serviceTask.getListAll(params);
    },
    async $_fetchPunches() {
      if (!this.state.startDate || !this.state.endDate) {
        return;
      }
      const params = {
        time_field: "punch_at",
        start_time: this.state.startDate,
        end_time: this.state.endDate,
        wasa: this._currentUser.id,
        order_by: "punch_at",
        order_way: "asc",
      };
      this.punches = await servicePunch.getListAll(params);
    },
    $_dateSet() {
      this.state.startDate = this.$moment()
        .add(-1, "month")
        .add(-7, "day")
        .format("YYYY-MM-DD");
      this.state.endDate = this.$moment().add(-7, "day").format("YYYY-MM-DD");
    },
  },

  computed: {
    _wasaHrSkillFetchQuery() {
      return {
        wasa: this._currentUser.id,
      };
    },
    _wasaAnalytics() {
      return serviceWasa.getAnalytics({
        wasa: this._currentUser,
        startDate: this.state.startDate,
        endDate: this.state.endDate,
        additionalOffDays: this.additionalOffDays,
        additionalWorkingDays: this.additionalWorkingDays,
        leaveDays: this.leaveDays,
        tasks: this.tasks,
        punches: this.punches,
      });
    },
    _currentUser() {
      if (!this.$store.state.auth.currentUser) {
        return null;
      } else {
        return this.$store.state.auth.currentUser;
      }
    },
  },

  mounted() {
    this.$_dateSet();
  },

  watch: {
    state: {
      handler() {
        this.$_fetchLeaveDays();
        this.$_fetchTask();
        this.$_fetchAdditionalOffDay();
        this.$_fetchAdditionalWorkingDay();
        this.$_fetchPunches();
      },
      deep: true,
    },
  },
};
</script>

<style>
</style>
<template>
  <div class="app-evaluation-wasas">
    <div class="ws-row">
      <div
        v-for="(evaluationWasa,evaluationWasaIndex) in value"
        :key="evaluationWasaIndex"
        class="ws-col"
      >
        <WsState
          type="select"
          :label="$_getExcuteTypeLabel(evaluationWasa)"
          :items="wasaItems"
          v-model="evaluationWasa.wasa"
        ></WsState>
      </div>
    </div>
  </div>
</template>

<script>
export default {
  methods: {
    $_getExcuteTypeLabel(evaluationWasa) {
      const tarExcuteType = this.excuteTypes.find((e) => {
        return e.id == evaluationWasa.excute_type;
      });
      return tarExcuteType.name;
    },
  },
  props: {
    value: {
      type: Array,
      default: null,
    },
    excuteTypes: {
      type: Array,
      default: null,
    },
    wasaItems: {
      type: Array,
      default: null,
    },
  },
};
</script>

<style>
</style>
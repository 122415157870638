<template>
  <div
    class="app-evaluation-stage"
    v-if="value"
  >
    <div class="app-evaluation-stage__name">{{value.name}}</div>
    <div class="app-evaluation-stage__materials__title mt-20">菜單列表</div>
    <div class="app-evaluation-stage__materials mt-20">
      <div
        v-for="(evaluationMaterial,evaluationMaterialIndex) in value.evaluation_materials"
        :key="evaluationMaterialIndex"
        class="app-evaluation-stage__material"
      >
        <div class="name">
          {{evaluationMaterial.name}}
        </div>
        <div
          v-if="evaluationMaterial.evaluation_material_options&&evaluationMaterial.evaluation_material_options.length"
          class="options"
        >
          <div class="options-title">加點項目</div>
          <div
            v-for="(option,optionIndex) in evaluationMaterial.evaluation_material_options"
            :key="optionIndex"
            class="option"
            @click="$_onStageOptionClick(option)"
          >
            <WsIcon
              class="pick-icon"
              :class="{active:$_pickedCheck(value,option)}"
            >check_box</WsIcon>
            <WsIcon
              class="pick-icon"
              :class="{active:!$_pickedCheck(value,option)}"
            >check_box_outline_blank</WsIcon>
            <div class="option-name">{{option.name}}</div>
          </div>
        </div>
        <WsIcon
          class="icon-remove"
          @click="$_onStageMaterialRemove(evaluationMaterialIndex)"
        >delete</WsIcon>
      </div>
    </div>
    <WsBtn
      class="mt-10"
      @click="$_onOpenPicker()"
    >編輯菜單</WsBtn>
    <WsModelPicker
      ref="WsModelPicker"
      :value="value.evaluation_materials"
      @input="$_onPickerInput($event)"
      modelName="evaluation_material"
      :fields="$store.state.model.evaluation_material.fields"
      :showFields="pickerShowFields"
      :valueSync="true"
    ></WsModelPicker>
  </div>
</template>

<script>
export default {
  data: () => ({
    pickerShowFields: ["name", "remark", "tags", "evaluation_type"],
  }),
  methods: {
    $_onOpenPicker() {
      this.$refs.WsModelPicker.open();
    },
    $_pickedCheck(stage, option) {
      const tarOptionIndex = stage.evaluation_material_options.findIndex(
        (e) => {
          return e.id == option.id;
        }
      );
      if (tarOptionIndex >= 0) {
        return true;
      } else {
        return false;
      }
    },
    $_onStageOptionClick(option) {
      let _value = this.value ? JSON.parse(JSON.stringify(this.value)) : [];
      const tarOptionIndex = _value.evaluation_material_options.findIndex(
        (e) => {
          return e.id == option.id;
        }
      );
      if (tarOptionIndex >= 0) {
        _value.evaluation_material_options.splice(tarOptionIndex, 1);
      } else {
        _value.evaluation_material_options.push(option);
      }
      _value = this.$_excuteTypesSet(_value);
      this.$emit("input", _value);
    },
    $_onPickerInput($event) {
      let _value = JSON.parse(JSON.stringify(this.value));
      _value.evaluation_materials = $event;
      _value = this.$_excuteTypesSet(_value);
      this.$emit("input", _value);
    },
    $_onStageMaterialRemove(evaluationMaterialIndex) {
      let _value = this.value ? JSON.parse(JSON.stringify(this.value)) : [];
      _value.evaluation_materials.splice(evaluationMaterialIndex, 1);
      _value = this.$_excuteTypesSet(_value);
      this.$emit("input", _value);
    },
    $_excuteTypesSet(value) {
      const _excute_types = [];
      value.evaluation_materials.forEach(
        (evaluation_material) => {
          evaluation_material.evaluation_material_excutes.forEach(
            (evaluation_material_excute) => {
              if (evaluation_material_excute.excute_type) {
                const exist = _excute_types.find((e) => {
                  return e.id == evaluation_material_excute.excute_type.id;
                });
                if (!exist) {
                  _excute_types.push(evaluation_material_excute.excute_type);
                }
              }
            }
          );
        }
      );
      value.evaluation_material_options.forEach(
        (evaluation_material_option) => {
          evaluation_material_option.evaluation_material_option_excutes.forEach(
            (evaluation_material_option_excute) => {
              if (evaluation_material_option_excute.excute_type) {
                const exist = _excute_types.find((e) => {
                  return e.id == evaluation_material_option_excute.excute_type.id;
                });
                if (!exist) {
                  _excute_types.push(evaluation_material_option_excute.excute_type);
                }
              }
            }
          );
        }
      );
      value.excute_types = _excute_types;
      return value;
    },
  },
  props: {
    value: {
      type: Object,
    },
  },
};
</script>

<style>
</style>
export default {
  modelName: "quotation_excute",
  label: '報價單執行項目',
  fields: {
    id: {
      type: "text",
      label: "ID",
      readonly: true,
      width: '70px'
    },
    name: {
      type: 'text',
      label: '名稱',
      width: '150px'
    },
    content: {
      type: 'textarea',
      label: '內容'
    },
    excute_days: {
      type: 'number',
      label: '執行天數',
      width: '50px'
    },
    price: {
      type: 'number',
      label: '價格',
      width: '100px'
    },
    updated_admin: {
      type: "belongsTo",
      label: "最後更新人",
      readonly: true,
      textKey: "name",
      modelName: "wasa"
    },
  },
}
<template>
  <div class="ws-state-textarea">
    <textarea-autosize
      :placeholder="placeholder"
      :min-height="100"
      :max-height="500"
      :value="value"
      @input="$emit('input',$event)"
      @keyup="$_onKeyup"
    />
    <div class="ws-state-textarea__bg"></div>
  </div>
</template>

<script>
export default {
  props: {
    placeholder: {
      type: String,
      default: '輸入...',
    },
    name: {
      type: String,
      default: null,
    },
    value: {},
    autoGrow: {
      type: Boolean,
      default: false,
    },
    rules: {
      type: String,
      default: null,
    },
  },
  methods: {
    $_onKeyup($event) {
      this.$emit("keyup", $event);
    },
  },
};
</script>
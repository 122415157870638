<template>
  <div class="ws-tooptip">
    
  </div>
</template>

<script>
export default {};
</script>

<style>
</style>
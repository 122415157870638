<template>
  <div
    class="ws-app"
    :class="[{'has-drawer':$store.state.app.hasDrawer},{'has-header':$store.state.app.hasHeader},{'bottom-nav':$store.state.app.hasBottomNav}]"
  >
    <WsNavigationDrawer :menu="_menu"></WsNavigationDrawer>
    <WsAppHeader></WsAppHeader>
    <WsAppWasajon></WsAppWasajon>
    <div class="ws-app__container">
      <router-view />
    </div>
  </div>
</template>

<script>
export default {
  data: () => ({
    hasDrawer: true,
    hasHeader: true,
  }),
  computed: {
    // _templateBottomNav() {
    //   return this.$route.meta && this.$route.meta.templateBottomNav
    //     ? this.$route.meta.templateBottomNav
    //     : false;
    // },
    _mainScope() {
      if (
        !this.$store.state.auth.currentUser ||
        !this.$store.state.auth.scopes
      ) {
        return null;
      }
      const _mainScope =
        this.$config.scopes && this.$config.scopes.length
          ? this.$_CMS_getPrimaryMatch(
              this.$store.state.auth.scopes,
              this.$config.scopes
            )
          : null;
      return _mainScope;
    },
    _menu() {
      console.log(this._mainScope);
      if (this._mainScope) {
        return this.$config.menu[this._mainScope];
      } else {
        return this.$config.menu;
      }
    },
  },
};
</script>

<style lang="scss" >
.ws-app {
  width: 100%;
  min-height: 100vh;
  &.has-drawer {
    .ws-app__container {
      padding-left: 240px;
      @media (max-width: 767px) {
        padding-left: 0;
      }
    }
    .ws-bottom-nav {
      width: calc(100% - 240px);
    }
  }
  &.has-header {
    .ws-app__container {
      padding-top: 50px;
    }
  }
  &.bottom-nav {
    .ws-app__container {
      padding-bottom: 60px;
    }
  }
  .ws-app__container {
    width: 100%;
    height: 100%;
    min-height: 100vh;
  }
}
</style>
import moment from 'moment'
import numeral from 'numeral'
const orderstage = {
  modelName: "order_stage",
  label: "訂單收款階段",
  fields: {
    id: {
      type: "text",
      label: "ID",
      readonly: true
    },
    status: {
      type: "custom",
      label: "狀態",
      getValue: item => {
        return orderstage.getOrderStageStatus(item).text;
      },
      getTextColor: item => {
        return orderstage.getOrderStageStatus(item).color;
      }
    },
    stage_name: {
      type: 'text',
      label: '請款階段'
    },
    order_no: {
      type: 'custom',
      label: '訂單編號',
      getValue: item => {
        if (!item.order) {
          return
        } else {
          return item.order.order_no
        }
      },
    },
    order: {
      type: 'belongsTo',
      label: '訂單',
      textKey: "name",
      modelName: "order",
      width: '100px',
    },
    price: {
      type: 'currency',
      label: '銷項金額'
    },
    tax_price: {
      type: 'custom',
      label: "稅金",
      getValue: item => {
        if (!item.price) {
          return 0;
        }
        const tax_type = item.order ? item.order.tax_type : null
        if (tax_type == 5) {
          return numeral(Math.round(item.price * 0.05)).format("$0,0");
        } else {
          return numeral(item.price).format("$0,0");
        }
      },
    },
    total_price: {
      type: 'custom',
      label: "應收金額(稅後)",
      getValue: item => {
        if (!item.price) {
          return 0;
        }
        const tax_type = item.order ? item.order.tax_type : null
        if (tax_type == 5) {
          return numeral(Math.round(item.price * 1.05)).format("$0,0");
        } else {
          return numeral(item.price).format("$0,0");
        }
      },
    },
    invoice_date: {
      type: "date",
      label: "發票日期",
      defaultValue: null
    },
    receiver: {
      type: 'text',
      label: '收件人'
    },
    address: {
      type: 'text',
      label: '發票地址'
    },
    uniform_numbers: {
      type: 'text',
      label: '統編'
    },
    invoice_number: {
      type: 'text',
      label: '發票號碼'
    },
    invoice_photos: {
      type: 'images',
      label: '發票相關照片',
      uploadUrl: "order_stage/invoice_photo/"
    },
    invoice_type: {
      type: "select",
      label: "發票類型",
      defaultValue: 3,
      items: [
        {
          text: '三聯式',
          value: 3
        },
        {
          text: '二聯式',
          value: 2
        },
        {
          text: 'Invoice',
          value: 1
        },
      ]
    },
    expect_transfer_date: {
      type: 'date',
      label: "預計收款日期",
      defaultValue: moment().add(1, 'months').format('YYYY-MM-DD')
    },
    transfer_date: {
      type: "date",
      label: "到款日期",
      defaultValue: null
    },
    remark: {
      type: "textarea",
      label: "備註"
    },
  },
  isValidDate(date) {
    if (!date) {
      return false
    } else if (moment(date).isValid()) {
      return true
    } else {
      return false
    }
  },
  statusList: {
    notyet: {
      text: "未結",
      color: "#FBC02D"
    },
    preget: {
      text: "預收",
      color: null
    },
    overdate: {
      text: "逾期",
      color: "#E040FB"
    },
    waitget: {
      text: "應收",
      color: "#E040FB"
    },
    got: {
      text: "已收",
      color: "#9695a6"
    },
    na: {
      text: "N/A",
      color: null
    }
  },
  getOrderStageStatus(item) {
    let key;
    let todayDate = moment();
    let expectTransderTimeMoment = moment(item.expect_transfer_date);
    const invoiceDateValid = orderstage.isValidDate(item.invoice_date);
    const transferTimeValid = orderstage.isValidDate(item.transfer_date);
    if (!invoiceDateValid && !transferTimeValid) {
      key = "notyet";
    } else if (!invoiceDateValid && transferTimeValid) {
      key = "preget";
    } else if (invoiceDateValid && !transferTimeValid) {
      if (
        transferTimeValid &&
        moment(expectTransderTimeMoment).isBefore(todayDate)
      ) {
        key = "overdate";
      } else {
        key = "waitget";
      }
    } else if (invoiceDateValid && transferTimeValid) {
      key = "got";
    } else {
      key = "na";
    }
    return orderstage.statusList[key];
  }
}


export default orderstage
export default {
  modelName: "hr_skill",
  label: "職位技能",
  fields: {
    id: {
      type: "text",
      label: "ID",
      readonly: true
    },
    name: {
      type: "text",
      label: "名稱",
      autofocus: true,
      rules: "required",
      maxlength: 30,
    },
    score: {
      type: "number",
      label: "分數",
      rules: "required",
    },
    remark: {
      type: "textarea",
      label: "說明",
    },
    hr_position: {
      type: "belongsTo",
      label: "職位",
      textKey: "name",
      modelName: "hr_position",
      relationPopup: true
    },
    updated_admin: {
      type: "belongsTo",
      label: "最後更新人",
      readonly: true,
      textKey: "name",
      modelName: "wasa"
    },
  },
}
<template>
  <div class="ws-info-currency">{{$numeral(value).format('$0,0')}}</div>
</template>

<script>
export default {
  props: {
    value: {
      type: [String, Number],
      default: null,
    },
  },
};
</script>
<template>
  <WsMain>
    <WsCrud
      :modelName="$store.state.model[modelName].modelName"
      :label="$store.state.model[modelName].label"
      :fields="$store.state.model[modelName].fields"
      :liveSearching="true"
      :showFields="showFields"
    ></WsCrud>
  </WsMain>
</template>

<script>
export default {
  data: () => ({
    modelName: "quotation_excute",
    showFields: ["id", "name", "content", "excute_days", "price"],
  }),
};
</script>
import moment from 'moment'
export default {
  methods: {
    $_GRD_getEndtimeByStartTimeAndHour({
      start_time,
      hour,
      hourStart = 9,
      hourEnd = 18,
      additionalOffDays,
      additionalWorkingDays,
      preventWeekdays = [0, 6] }) {
      if (!start_time || !hour) {
        return null;
      }
      let time_count;
      time_count = moment(start_time);
      let hour_count = hour;
      let max_count = 0;
      while (hour_count > 0 && max_count < 500) {
        if (time_count.hour() <= 12) {
          let hour_change;
          if (hour_count > 12 - time_count.hour()) {
            hour_change = 12 - time_count.hour();
            time_count.add(hour_change + 1, "hour");
          } else {
            hour_change = hour_count;
            time_count.add(hour_change, "hour");
          }
          hour_count -= hour_change;
        } else if (time_count.hour() <= hourEnd) {
          let hour_change;
          if (hour_count > hourEnd - time_count.hour()) {
            hour_change = 18 - time_count.hour();
            time_count.add(hour_change + 24 - hourEnd + hourStart, "hour");
            for (let i = 0; i < 7; i++) {
              const time_count_date = time_count.format("YYYY-MM-DD");
              if (
                additionalOffDays &&
                additionalOffDays.includes(time_count_date)
              ) {
                time_count.add(24, "hour");
                continue;
              }
              if (
                preventWeekdays.includes(time_count.weekday()) &&
                additionalWorkingDays &&
                !additionalWorkingDays.includes(time_count_date)
              ) {
                time_count.add(24, "hour");
                continue;
              }
            }
          } else {
            hour_change = hour_count;
            time_count.add(hour_change, "hour");
          }
          hour_count -= hour_change;
        } else {
          hour_count = 0;
        }
        max_count++;
      }
      return time_count.format();
    },
  },
}
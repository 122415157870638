import axios from 'axios'
import moment from 'moment'
export default {
  getListDeep() {
    return new Promise((resolve, reject) => {
      axios
        .get("wasa/index/all/deep")
        .then((res) => {
          resolve(res.data.data)
        })
        .catch(() => {
          reject('取得使用者列表錯誤')
        });
    })
  },
  getAnalytics({
    wasa = null,
    startDate,
    endDate,
    additionalOffDays,
    additionalWorkingDays,
    leaveDays,
    tasks,
    punches,
  }) {
    const _wasa = {}
    const _totalWorkHours = this.getTotalWorkHours({
      startDate: startDate,
      endDate: endDate,
      additionalOffDays: additionalOffDays,
      additionalWorkingDays: additionalWorkingDays,
      leaveDays: leaveDays,
    })
    const _finishHours = this.getFinishHours({
      tasks: tasks,
    })
    const _unfinishHours = this.getUnfinishHours({
      tasks: tasks,
    })
    const _planHours = this.getPlanHours({
      tasks: tasks,
    })
    const _planRate = Math.round((_planHours / _totalWorkHours) * 100);
    const _efficiency = Math.round((_finishHours / _planHours) * 100);
    const _benefitNumber = Math.round((_efficiency / _planRate) * 100);
    const _overTimeWorkdaypre2Minutes = this.getOverTimeWorkdaypre2Minutes({
      punches: punches,
      additionalOffDays: additionalOffDays,
      additionalWorkingDays: additionalWorkingDays
    })
    const _overTimeWorkdayafter2Minutes = this.getOverTimeWorkdayafter2Minutes({
      punches: punches,
      additionalOffDays: additionalOffDays,
      additionalWorkingDays: additionalWorkingDays
    })
    const _overTimeBreakdaypre8Minutes = this.getOverTimeBreakdaypre8Minutes({
      punches: punches,
      additionalOffDays: additionalOffDays,
      additionalWorkingDays: additionalWorkingDays
    })
    const _overTimeBreakdayover8pre2Minutes = this.getOverTimeBreakdayover8pre2Minutes({
      punches: punches,
      additionalOffDays: additionalOffDays,
      additionalWorkingDays: additionalWorkingDays
    })
    const _overTimeBreakdayover8after2Minutes = this.getOverTimeBreakdayover8after2Minutes({
      punches: punches,
      additionalOffDays: additionalOffDays,
      additionalWorkingDays: additionalWorkingDays
    })
    const _overTimeWeekdaypre2Minutes = this.getOverTimeWeekdaypre2Minutes({
      punches: punches,
      additionalOffDays: additionalOffDays,
      additionalWorkingDays: additionalWorkingDays
    })
    const _overTimeWeekdaypre8Minutes = this.getOverTimeWeekdaypre8Minutes({
      punches: punches,
      additionalOffDays: additionalOffDays,
      additionalWorkingDays: additionalWorkingDays
    })
    const _overTimeWeekdayover8Minutes = this.getOverTimeWeekdayover8Minutes({
      punches: punches,
      additionalOffDays: additionalOffDays,
      additionalWorkingDays: additionalWorkingDays
    })
    const _overTimeHours = Math.round((_overTimeWorkdaypre2Minutes +
      _overTimeWorkdayafter2Minutes +
      _overTimeBreakdaypre8Minutes +
      _overTimeBreakdayover8pre2Minutes +
      _overTimeBreakdayover8after2Minutes +
      _overTimeWeekdaypre2Minutes +
      _overTimeWeekdaypre8Minutes +
      _overTimeWeekdayover8Minutes) / 60)
    const _emptyHours = (_totalWorkHours - _finishHours) > 0 ? _totalWorkHours - _finishHours : 0
    const _abnormalHours = this.getAbnormalHours(_totalWorkHours, _overTimeHours, _finishHours)
    const _abnormalRate = Math.round((_abnormalHours / _finishHours) * 100);
    const _efficiencyReal = _efficiency - _abnormalRate
    const _benefitNumberReal = Math.round((_efficiencyReal / _planRate) * 100);

    _wasa.speed = wasa ? wasa.speed : null
    _wasa.score = wasa ? wasa.score : null
    _wasa.totalWorkHours = _totalWorkHours
    _wasa.finishHours = _finishHours
    _wasa.unfinishHours = _unfinishHours
    _wasa.planHours = _planHours
    _wasa.planRate = _planRate
    _wasa.efficiency = _efficiency
    _wasa.benefitNumber = _benefitNumber
    _wasa.overTimeWorkdaypre2Minutes = _overTimeWorkdaypre2Minutes
    _wasa.overTimeWorkdayafter2Minutes = _overTimeWorkdayafter2Minutes
    _wasa.overTimeBreakdaypre8Minutes = _overTimeBreakdaypre8Minutes
    _wasa.overTimeBreakdayover8pre2Minutes = _overTimeBreakdayover8pre2Minutes
    _wasa.overTimeBreakdayover8after2Minutes = _overTimeBreakdayover8after2Minutes
    _wasa.overTimeWeekdaypre2Minutes = _overTimeWeekdaypre2Minutes
    _wasa.overTimeWeekdaypre8Minutes = _overTimeWeekdaypre8Minutes
    _wasa.overTimeWeekdayover8Minutes = _overTimeWeekdayover8Minutes
    _wasa.overTimeHours = _overTimeHours
    _wasa.emptyHours = _emptyHours
    _wasa.abnormalHours = _abnormalHours
    _wasa.abnormalRate = _abnormalRate
    _wasa.efficiencyReal = _efficiencyReal
    _wasa.benefitNumberReal = _benefitNumberReal
    return _wasa
  },
  getTotalWorkHours({
    startDate,
    endDate,
    additionalOffDays,
    additionalWorkingDays,
    leaveDays,
  }) {
    if (
      !startDate ||
      !endDate ||
      !additionalOffDays ||
      !additionalWorkingDays ||
      !leaveDays
    ) {
      return null;
    } else {
      let _totalWorkHours = 0;
      const diffDays = moment(endDate).diff(
        startDate,
        "days"
      );
      for (let index = 0; index < diffDays + 1; index++) {
        const tarDate = moment(startDate).add(index, "days");
        const weekDay = tarDate.get("day");
        if (weekDay == 0 || weekDay == 6) {
          const dateText = tarDate.format("YYYY-MM-DD");
          const matchWorking = additionalWorkingDays.find((e) => {
            return e.date == dateText;
          });
          if (matchWorking) {
            _totalWorkHours += 8;
            continue;
          }
        } else {
          const dateText = tarDate.format("YYYY-MM-DD");
          const matchOff = additionalOffDays.find((e) => {
            return e.date == dateText;
          });
          if (matchOff) {
            continue;
          }
          const matchLeave = leaveDays.find((e) => {
            return e.start_date == dateText;
          });
          if (matchLeave) {
            if (matchLeave.hour) {
              _totalWorkHours += 8 - parseFloat(matchLeave.hour);
            }
          } else {
            _totalWorkHours += 8;
          }
        }
      }
      return Math.round(_totalWorkHours);
    }

  },
  getFinishHours({
    tasks
  }) {
    if (!tasks) {
      return null;
    }
    let _finishHours = 0;
    tasks.forEach((task) => {
      if (!task.finish_hour) {
        return;
      } else {
        _finishHours += parseFloat(task.finish_hour);
      }
    });
    return Math.round(_finishHours);
  },
  getUnfinishHours({
    tasks
  }) {
    if (!tasks) {
      return null;
    }
    let _unfinishHours = 0;
    tasks.forEach((task) => {
      if (task.finish_hour) {
        return;
      } else {
        _unfinishHours += parseFloat(task.hour);
      }
    });
    return Math.round(_unfinishHours);
  },
  getPlanHours({
    tasks
  }) {
    if (!tasks) {
      return null;
    }
    let _planHours = 0;
    tasks.forEach((task) => {
      if (!task.hour) {
        return;
      } else {
        _planHours += parseFloat(task.hour);
      }
    });
    return Math.round(_planHours);
  },
  getFirstDayWorkMinutes(inTime, punches) {
    const inTimeMoment = moment(inTime);
    const beforePunch = punches.find((punch) => {
      return (
        moment(punch.punch_at).isBefore(inTimeMoment) &&
        inTimeMoment.format("YYYY-MM-DD") ==
        moment(punch.punch_at).format("YYYY-MM-DD")
      );
    });
    if (beforePunch) {
      return 0;
    } else {
      return this.getDayWorkMinutes(inTime, punches);
    }
  },
  getDayWorkMinutes(inTime, punches) {
    const inTimeMoment = moment(inTime);
    const inPunches = punches.filter((e) => {
      return (
        moment(e.punch_at).format("YYYY-MM-DD") ==
        inTimeMoment.format("YYYY-MM-DD") && e.type == 0
      );
    });
    const outPunches = punches.filter((e) => {
      return (
        moment(e.punch_at).format("YYYY-MM-DD") ==
        inTimeMoment.format("YYYY-MM-DD") && e.type == 1
      );
    });

    if (inPunches.length != outPunches.length) {
      return 0;
    }

    let outSum = moment();
    let inSum = moment();
    outPunches.forEach((outPunch) => {
      outSum = outSum.add(moment(outPunch.punch_at));
    });
    inPunches.forEach((inPunch) => {
      inSum = inSum.add(moment(inPunch.punch_at));
    });

    const startInSumHour = moment(inPunches[0].punch_at).get("hour");
    const endOutSumHour = moment(
      outPunches[outPunches.length - 1].punch_at
    ).get("hour");

    let totalMinutes = Math.floor(outSum.diff(inSum, "minutes"));

    if (startInSumHour <= 12 && endOutSumHour >= 13) {
      totalMinutes -= 60;
    }
    if (startInSumHour <= 18 && endOutSumHour >= 19) {
      totalMinutes -= 60;
    }
    if (startInSumHour <= 18 && endOutSumHour === 18) {
      totalMinutes -= moment(
        outPunches[outPunches.length - 1].punch_at
      ).get("minute");
    }
    return totalMinutes;
  },
  getFormatedAdditionalWorkingDays(additionalWorkingDays) {
    if (!additionalWorkingDays) {
      return []
    }
    const _additionalWorkingDays = [];
    additionalWorkingDays.forEach((additionalWorkingDay) => {
      _additionalWorkingDays.push(additionalWorkingDay.date);
    });
    return _additionalWorkingDays;
  },
  getFormatedAdditionalOffDays(additionalOffDays) {
    if (!additionalOffDays) {
      return []
    }
    const _additionalOffDays = [];
    additionalOffDays.forEach((additionalOffDay) => {
      _additionalOffDays.push(additionalOffDay.date);
    });
    return _additionalOffDays;
  },
  getOverTimeWorkdaypre2Minutes({
    punches,
    additionalOffDays,
    additionalWorkingDays
  }) {
    if (!punches) {
      return null
    }
    console.log(punches);
    let _overTimeWorkdaypre2Minutes = 0;
    const _additionalOffDays = this.getFormatedAdditionalOffDays(additionalOffDays)
    const _additionalWorkingDays = this.getFormatedAdditionalWorkingDays(additionalWorkingDays)
    punches
      .slice()
      .reverse()
      .forEach((punch) => {
        if (punch.type == 1) {
          return;
        }
        const punchMoment = moment(punch.punch_at);
        const punchDay = punchMoment.day();
        const punchDate = punchMoment.format("YYYY-MM-DD");
        if (_additionalOffDays.includes(punchDate)) {
          return;
        }
        if (
          (punchDay == 6 || punchDay == 0) &&
          !_additionalWorkingDays.includes(punchDate)
        ) {
          return;
        }
        const workMinutes = this.getFirstDayWorkMinutes(punchMoment, punches);
        console.log(workMinutes);
        if (workMinutes > 480) {
          if (workMinutes > 600) {
            _overTimeWorkdaypre2Minutes += 120;
          } else {
            _overTimeWorkdaypre2Minutes += workMinutes - 480;
          }
        }
      });
    return _overTimeWorkdaypre2Minutes;
  },
  getOverTimeWorkdayafter2Minutes({
    punches,
    additionalOffDays,
    additionalWorkingDays
  }) {
    if (!punches) {
      return null
    }
    let _overTimeWorkdayafter2Minutes = 0;
    const _additionalOffDays = this.getFormatedAdditionalOffDays(additionalOffDays)
    const _additionalWorkingDays = this.getFormatedAdditionalWorkingDays(additionalWorkingDays)
    punches
      .slice()
      .reverse()
      .forEach((punch) => {
        if (punch.type == 1) {
          return;
        }
        const punchMoment = moment(punch.punch_at);
        const punchDay = punchMoment.day();
        const punchDate = punchMoment.format("YYYY-MM-DD");
        if (_additionalOffDays.includes(punchDate)) {
          return;
        }
        if (
          (punchDay == 6 || punchDay == 0) &&
          !_additionalWorkingDays.includes(punchDate)
        ) {
          return;
        }
        const workMinutes = this.getFirstDayWorkMinutes(punchMoment, punches);
        if (workMinutes > 600) {
          _overTimeWorkdayafter2Minutes += workMinutes - 600;
        }
      });
    return _overTimeWorkdayafter2Minutes;
  },
  getOverTimeBreakdaypre8Minutes({
    punches,
    additionalOffDays,
    additionalWorkingDays
  }) {
    if (!punches) {
      return null
    }
    let _overTimeBreakdaypre8Minutes = 0;
    const _additionalOffDays = this.getFormatedAdditionalOffDays(additionalOffDays)
    const _additionalWorkingDays = this.getFormatedAdditionalWorkingDays(additionalWorkingDays)
    punches
      .slice()
      .reverse()
      .forEach((punch) => {
        if (punch.type == 1) {
          return;
        }
        const punchMoment = moment(punch.punch_at);
        const punchDay = punchMoment.day();
        const punchDate = punchMoment.format("YYYY-MM-DD");
        if (
          (punchDay == 0 &&
            !_additionalWorkingDays.includes(punchDate)) ||
          _additionalOffDays.includes(punchDate)
        ) {
          const workMinutes = this.getFirstDayWorkMinutes(punchMoment, punches);
          if (workMinutes >= 480) {
            _overTimeBreakdaypre8Minutes += 480;
          } else {
            _overTimeBreakdaypre8Minutes += workMinutes;
          }
        }
      });
    return _overTimeBreakdaypre8Minutes;
  },
  getOverTimeBreakdayover8pre2Minutes({
    punches,
    additionalOffDays,
    additionalWorkingDays
  }) {
    if (!punches) {
      return null
    }
    let _overTimeBreakdayover8pre2Minutes = 0;
    const _additionalOffDays = this.getFormatedAdditionalOffDays(additionalOffDays)
    const _additionalWorkingDays = this.getFormatedAdditionalWorkingDays(additionalWorkingDays)
    punches
      .slice()
      .reverse()
      .forEach((punch) => {
        if (punch.type == 1) {
          return;
        }
        const punchMoment = moment(punch.punch_at);
        const punchDay = punchMoment.day();
        const punchDate = punchMoment.format("YYYY-MM-DD");
        if (
          (punchDay == 0 &&
            !_additionalWorkingDays.includes(punchDate)) ||
          _additionalOffDays.includes(punchDate)
        ) {
          const workMinutes = this.getFirstDayWorkMinutes(punchMoment, punches);
          if (workMinutes > 480) {
            if (workMinutes > 600) {
              _overTimeBreakdayover8pre2Minutes += 120;
            } else {
              _overTimeBreakdayover8pre2Minutes += workMinutes - 480;
            }
          }
        }
      });
    return _overTimeBreakdayover8pre2Minutes;
  },
  getOverTimeBreakdayover8after2Minutes({
    punches,
    additionalOffDays,
    additionalWorkingDays
  }) {
    if (!punches) {
      return null
    }
    let _overTimeBreakdayover8after2Minutes = 0;
    const _additionalOffDays = this.getFormatedAdditionalOffDays(additionalOffDays)
    const _additionalWorkingDays = this.getFormatedAdditionalWorkingDays(additionalWorkingDays)
    punches
      .slice()
      .reverse()
      .forEach((punch) => {
        if (punch.type == 1) {
          return;
        }
        const punchMoment = moment(punch.punch_at);
        const punchDay = punchMoment.day();
        const punchDate = punchMoment.format("YYYY-MM-DD");
        if (
          (punchDay == 0 &&
            !_additionalWorkingDays.includes(punchDate)) ||
          _additionalOffDays.includes(punchDate)
        ) {
          const workMinutes = this.getFirstDayWorkMinutes(punchMoment, punches);
          if (workMinutes > 600) {
            _overTimeBreakdayover8after2Minutes += workMinutes - 600;
          }
        }
      });
    return _overTimeBreakdayover8after2Minutes;
  },
  getOverTimeWeekdaypre2Minutes({
    punches,
    additionalOffDays,
    additionalWorkingDays
  }) {
    if (!punches) {
      return null
    }
    let _overTimeWeekdaypre2Minutes = 0;
    const _additionalOffDays = this.getFormatedAdditionalOffDays(additionalOffDays)
    const _additionalWorkingDays = this.getFormatedAdditionalWorkingDays(additionalWorkingDays)
    punches
      .slice()
      .reverse()
      .forEach((punch) => {
        if (punch.type == 1) {
          return;
        }
        const punchMoment = moment(punch.punch_at);
        const punchDay = punchMoment.day();
        const punchDate = punchMoment.format("YYYY-MM-DD");
        if (_additionalOffDays.includes(punchDate)) {
          return;
        }
        if (
          punchDay == 6 &&
          !_additionalWorkingDays.includes(punchDate)
        ) {
          const workMinutes = this.getFirstDayWorkMinutes(punchMoment, punches);
          if (workMinutes > 120) {
            _overTimeWeekdaypre2Minutes += 120;
          } else {
            _overTimeWeekdaypre2Minutes += workMinutes;
          }
        }
      });
    return _overTimeWeekdaypre2Minutes;
  },
  getOverTimeWeekdaypre8Minutes({
    punches,
    additionalOffDays,
    additionalWorkingDays
  }) {
    if (!punches) {
      return null
    }
    let _overTimeWeekdaypre8Minutes = 0;
    const _additionalOffDays = this.getFormatedAdditionalOffDays(additionalOffDays)
    const _additionalWorkingDays = this.getFormatedAdditionalWorkingDays(additionalWorkingDays)
    punches
      .slice()
      .reverse()
      .forEach((punch) => {
        if (punch.type == 1) {
          return;
        }
        const punchMoment = moment(punch.punch_at);
        const punchDay = punchMoment.day();
        const punchDate = punchMoment.format("YYYY-MM-DD");
        if (_additionalOffDays.includes(punchDate)) {
          return;
        }
        if (
          punchDay == 6 &&
          !_additionalWorkingDays.includes(punchDate)
        ) {
          const workMinutes = this.getFirstDayWorkMinutes(punchMoment, punches);
          if (workMinutes > 120 && workMinutes <= 480) {
            _overTimeWeekdaypre8Minutes += workMinutes - 120;
          } else if (workMinutes > 480) {
            _overTimeWeekdaypre8Minutes += 360;
          }
        }
      });
    return _overTimeWeekdaypre8Minutes;
  },
  getOverTimeWeekdayover8Minutes({
    punches,
    additionalOffDays,
    additionalWorkingDays
  }) {
    if (!punches) {
      return null
    }
    let _overTimeWeekdayover8Minutes = 0;
    const _additionalOffDays = this.getFormatedAdditionalOffDays(additionalOffDays)
    const _additionalWorkingDays = this.getFormatedAdditionalWorkingDays(additionalWorkingDays)
    punches
      .slice()
      .reverse()
      .forEach((punch) => {
        if (punch.type == 1) {
          return;
        }
        const punchMoment = moment(punch.punch_at);
        const punchDay = punchMoment.day();
        const punchDate = punchMoment.format("YYYY-MM-DD");
        if (_additionalOffDays.includes(punchDate)) {
          return;
        }
        if (
          punchDay == 6 &&
          !_additionalWorkingDays.includes(punchDate)
        ) {
          const workMinutes = this.getFirstDayWorkMinutes(punchMoment, punches);
          if (workMinutes > 480) {
            _overTimeWeekdayover8Minutes += workMinutes - 480;
          }
        }
      });
    return _overTimeWeekdayover8Minutes;
  },
  getAbnormalHours(totalWorkHours, overTimeHours, finishHours) {
    if (!overTimeHours) {
      return 0
    } else if (overTimeHours / totalWorkHours * 20 < 1) {
      return 0
    } else {
      const _getAbnormalHours = Math.round(totalWorkHours + overTimeHours - finishHours)
      if (_getAbnormalHours > 0) {
        return _getAbnormalHours
      } else {
        return 0
      }
    }
  }
}
<template>
  <WsMain>
    <img
      v-if="url"
      v-lazy="url"
      alt=""
      @click="$_onUploadClick()"
    >
    <WsBtn @click="$_onUploadClick()">上傳圖片</WsBtn>
    <input
      v-show="false"
      @change="$_onChange($event)"
      type="file"
      ref="fileInput"
      accept="image/*"
    >
  </WsMain>
</template>

<script>
import serviceGraphicImage from "@/__vue2stone_cms/service/graphic/image";
import servicePocketImage from "@/__vue2stone_cms/service/api/v1/pocket_image";
export default {
  data: () => ({
    file: null,
    url: null,
  }),

  methods: {
    async $_onChange($event) {
      this.file = $event.target.files[0];
      this.url = await serviceGraphicImage.getBase64(this.file);
    },
    $_onUploadClick() {
      this.$refs.fileInput.click();
    },
    submit() {
      return new Promise((resolve, reject) => {
        const fileName = this.file.name;
        servicePocketImage
          .upload(this.file, fileName)
          .then((res) => {
            if (res.signed) {
              this.$emit("submit", res.signed_url);
            } else {
              this.$emit("submit", res.url);
            }
            resolve(res)
          })
          .catch((err) => {
            reject(err);
          });
      });
    },
  },

  props: {
    signed: {
      type: Boolean,
      default: false,
    },
  },
};
</script>

<style>
</style>
<template>
  <div class="ws-info-tags">
    <div
      v-for="(tag,tagKey) in _tags"
      :key="tagKey"
      class="ws-info-tag"
    >{{tag}}</div>
  </div>
</template>

<script>
export default {
  computed: {
    _tags() {
      if (!this.value) {
        return [];
      } else {
        return this.value;
      }
    },
  },
  props: {
    value: {
      type: Array,
      default: null,
    },
  },
};
</script>

<style>
</style>
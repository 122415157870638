<template>
  <div class="ws-table">
    <WsNoDataMessage v-if="(!items||!items.length)&&!loading">沒資料 :(</WsNoDataMessage>
    <div
      v-else
      class="ws-table__table-container"
    >
      <table
        cellspacing="0"
        cellpadding="0"
      >
        <tr>
          <th
            v-for="(headersItem,headersIndex) in headers"
            :key="headersIndex"
          >
            <span>{{headersItem.text}}</span>
          </th>
        </tr>
        <tr
          :key="itemIndex"
          v-for="(item,itemIndex) in items"
        >
          <td
            v-for="(headersItem,headersIndex) in headers"
            :key="headersIndex"
          >
            {{item[headersItem.value]}}
          </td>
          <slot
            name="extendfield"
            :itemIndex="itemIndex"
          ></slot>
        </tr>
      </table>
    </div>
  </div>
</template>

<script>
export default {
  props: {
    loading: {
      type: Boolean,
      default: false,
    },
    items: {
      type: Array,
      default: null,
    },
    headers: {
      type: Array,
      default: null,
    },
  },
};
</script>

<style>
</style>
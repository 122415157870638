<template>
  <WsDialog
    class="ws-model-picker"
    ref="WsDialog"
    :bottomEmitDis="50"
    :contentHasPadding="false"
    @reach-bottom="$_onReachBottom()"
    @close="$_onDialogClose()"
  >
    <template v-slot:title>
      <div class="ws-model-picker__search-container">
        <WsState
          type="search"
          placeholder="搜尋..."
          v-model="seaching"
          autofocus
        ></WsState>
      </div>
    </template>
    <template v-slot:content>
      <WsLoading v-if="searchingLoading"></WsLoading>
      <div
        v-else
        class="ws-model-picker__models"
      >
        <div class="ws-model-picker__model">
          <table
            cellspacing="0"
            cellpadding="0"
          >
            <tr>
              <th></th>
              <th
                v-for="(headersItem,headersKey) in _tableHeaders"
                :key="headersKey"
              >{{headersItem.text}}</th>
            </tr>
            <tr
              v-for="(item,itemIndex) in _items"
              :key="itemIndex"
              @click="$_onRowClick(item,itemIndex)"
              :class="[{'picked':$_pickedCheck(item)}]"
            >
              <td>
                <WsIcon
                  class="pick-icon"
                  :class="{active:$_pickedCheck(item)}"
                >check_box</WsIcon>
                <WsIcon
                  class="pick-icon"
                  :class="{active:!$_pickedCheck(item)}"
                >check_box_outline_blank</WsIcon>
              </td>
              <td
                v-for="(headersItem,headersKey) in _tableHeaders"
                :key="headersKey"
              >{{item[headersItem.value]}}</td>
            </tr>
          </table>
          <WsLoading v-if="loading"></WsLoading>
        </div>
      </div>
    </template>
    <template v-slot:rightActions>
      <WsBtn
        @click="$_onClose()"
        minWidth="100px"
        color="textSecondary"
        outlined
      >關閉</WsBtn>
      <WsBtn
        v-if="!value"
        @click="$_onSubmit()"
        minWidth="100px"
        color="textSecondary"
      >送出</WsBtn>
    </template>
  </WsDialog>
</template>

<script>
export default {
  data: () => ({
    C_value: [],
    seaching: null,
    modelDatas: [],
    searchingLoading: false,
    searchingTimeout: null,
    loading: false,
    submitLoading: false,
    currentPage: null,
    dataTotalCount: 0,
    lastPage: 1,
  }),
  methods: {
    $_onDialogClose() {
      this.clear();
    },
    $_onSubmit() {
      this.$emit("submit", this.C_value);
      // setTimeout(() => {
      this.close();
      // }, 0);
    },
    $_pickedCheck(item) {
      if (!this.C_value) {
        return false;
      } else {
        if (this._valueIds.includes(item.id)) {
          return true;
        } else {
          return false;
        }
      }
    },
    open() {
      this.$refs.WsDialog.open();
    },
    close() {
      this.$refs.WsDialog.close();
      this.clear();
    },
    clear() {
      if (!this.value) {
        this.C_value = [];
      }
    },
    $_onRowClick(item, itemIndex) {
      if (this._valueIds.includes(item.id)) {
        const tarIndex = this.C_value.findIndex((e) => {
          return e.id == item.id;
        });
        this.C_value.splice(tarIndex, 1);
      } else {
        this.C_value.push(this.modelDatas[itemIndex]);
      }
      if (this.valueSync) {
        this.$emit("input", this.C_value);
      }
    },
    $_onReachBottom() {
      if (this.loading) {
        return;
      } else {
        this.$_fetchData();
      }
    },
    $_valueSet() {
      if (this.value) {
        this.C_value = this.value;
      }
    },
    $_onClose() {
      this.close();
    },
    async $_fetchData() {
      if (this.currentPage == this.lastPage) {
        return;
      }
      this.loading = true;
      if (!this.currentPage) {
        this.currentPage = 1;
      } else {
        this.currentPage++;
      }
      try {
        const getUrl = `/${this.modelName}`;
        const params = {
          search: this.seaching,
          page: this.currentPage,
          order_by: this.orderBy,
          order_way: this.orderWay,
        };
        const res = await this.$axios.get(getUrl, {
          params: params,
        });
        this.modelDatas = [...this.modelDatas, ...res.data.data];
        this.lastPage = res.data.meta.last_page;
        this.dataTotalCount = res.data.meta.total;
      } catch (error) {
        console.log(error);
      } finally {
        this.loading = false;
        this.searchingLoading = false;
      }
    },
  },
  computed: {
    _valueIds() {
      if (!this.C_value) {
        return [];
      } else {
        const _valueIds = [];
        this.C_value.forEach((valueItem) => {
          _valueIds.push(valueItem.id);
        });
        return _valueIds;
      }
    },
    _items() {
      return this.$_CMS_getFormatedTableItems(this.modelDatas, this.fields);
    },
    _displayFields() {
      return this.$_CMS_getDisplayFields(this.fields);
    },
    _tableHeaders() {
      let _tableHeaders = [];
      this._showFields.forEach((showFieldKey) => {
        if (showFieldKey in this._displayFields) {
          const field = this._displayFields[showFieldKey];
          if (field.type == "list" || field.type == "evaluationStage") {
            return;
          }

          if (
            field.type == "image" ||
            field.type == "tags" ||
            field.type == "password" ||
            field.type == "link" ||
            field.type == "editor"
          ) {
            _tableHeaders.push({
              value: showFieldKey,
              text: field.label,
              width: field.width,
              sortable: false,
            });
            return;
          }

          _tableHeaders.push({
            value: showFieldKey,
            text: field.label,
            width: field.width,
          });
        }
      });
      return _tableHeaders;
    },
    _showFields() {
      if (this.showFields) {
        return this.showFields;
      } else {
        const _showFields = [];
        for (let fieldKey in this.fields) {
          _showFields.push(fieldKey);
        }
        return _showFields;
      }
    },
  },
  mounted() {
    this.$_valueSet();
    this.$_fetchData();
  },
  watch: {
    // C_value: {
    //   handler() {
    //     this.$emit("input", this.C_value);
    //   },
    // },
    value: {
      handler() {
        this.$_valueSet();
      },
    },
    seaching: {
      handler() {
        this.searchingLoading = true;
        clearTimeout(this.searchingTimeout);
        this.searchingTimeout = setTimeout(() => {
          this.modelDatas = [];
          this.currentPage = null;
          this.dataTotalCount = 0;
          this.lastPage = 1;

          this.$_fetchData();
        }, 500);
      },
    },
  },
  props: {
    orderBy: {
      type: String,
      default: "created_at",
    },
    orderWay: {
      type: String,
      default: "desc",
    },
    value: {
      type: Array,
      default: null,
    },
    modelName: {
      type: String,
      required: true,
    },
    showFields: {
      type: Array,
      default: null,
    },
    fields: {
      type: Object,
      required: true,
    },
    valueSync: {
      type: Boolean,
      default: false,
    },
  },
};
</script>

<style>
</style>
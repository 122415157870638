<template>
  <WsMain>
    <WsCrud
      :modelName="$store.state.model[modelName].modelName"
      :label="$store.state.model[modelName].label"
      :fields="$store.state.model[modelName].fields"
      :creatable="true"
      :liveSearching="true"
      :showFields="showFields"
      :order="order"
      :plusOrderItems="plusOrderItems"
      :filterSelects="filterSelects"
      :infiniteScroll="true"
    ></WsCrud>
  </WsMain>
</template>

<script>
export default {
  data: () => ({
    modelName: "billing_statement",
    showFields: ["id", "wasa", "apply_date", "pay_date", "amount", "status"],
    order: "last_apply",
    plusOrderItems: [
      {
        value: "last_apply",
        text: "最近請款",
        order_by: "apply_date",
        order_way: "desc",
      },
      {
        value: "last_pay",
        text: "最近付款",
        order_by: "pay_date",
        order_way: "desc",
      },
    ],
    filterSelects: {
      status: {
        label: "狀態",
        items: [
          {
            text: "全部",
            value: "all",
            params: {},
          },
          {
            value: 0,
            text: "申請中",
            params: {
              status: 0,
            },
          },
          {
            value: 1,
            text: "OK",
            params: {
              status: 1,
            },
          },
          {
            value: 2,
            text: "打妹",
            params: {
              status: 2,
            },
          },
          {
            value: 3,
            text: "已付款",
            params: {
              status: 3,
            },
          },
        ],
      },
    },
  }),
};
</script>
<template>
  <WsMain>
    <WsCrud
      :modelName="$store.state.model[modelName].modelName"
      :label="$store.state.model[modelName].label"
      :fields="$store.state.model[modelName].fields"
      :liveSearching="true"
      :showFields="showFields"
      :order="order"
      :plusOrderItems="plusOrderItems"
      :filterSelects="filterSelects"
      :infiniteScroll="true"
    ></WsCrud>
  </WsMain>
</template>

<script>
export default {
  metaInfo() {
    return {
      title: "請假管理",
    };
  },
  
  data: () => ({
    order: "last_leave",
    modelName: "leave_day",
    showFields: ["id", "start_date", "days", "wasa", "status"],
    plusOrderItems: [
      {
        value: "last_leave",
        text: "最近請假",
        order_by: "start_date",
        order_way: "desc",
      },
    ],
    filterSelects: {
      status: {
        label: "狀態",
        items: [
          {
            text: "全部",
            value: "all",
            params: {},
          },
          {
            value: 0,
            text: "申請中",
            params: {
              status: 0,
            },
          },
          {
            value: 1,
            text: "OK",
            params: {
              status: 1,
            },
          },
        ],
      },
    },
  }),
};
</script>
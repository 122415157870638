<template>
  <WsMain>
    <WsCrud
      :modelName="$store.state.model[modelName].modelName"
      :label="$store.state.model[modelName].label"
      :fields="$store.state.model[modelName].fields"
      :liveSearching="true"
      :showFields="showFields"
      :updateHideFields="updateHideFields"
    ></WsCrud>
  </WsMain>
</template>

<script>
export default {
  metaInfo() {
    return {
      title: "山葵仔",
    };
  },
  data: () => ({
    modelName: "wasa",
    showFields: ["id", "name", "email", "color"],
    updateHideFields: ["password"],
  }),
};
</script>
export default {
  modelName: "evaluation_type",
  label: "評估類型",
  fields: {
    id: {
      type: "text",
      label: "ID",
      readonly: true
    },
    name: {
      type: "text",
      label: "名稱",
      autofocus: true,
      rules: "required"
    },
    updated_at: {
      type: "datetime",
      label: "最後更新時間",
      readonly: true
    },
    sequence: {
      type: "text",
      label: "排序"
    },
  },
}
<template>
  <WsMain>
    <WsState
      label="開始時間"
      type="date"
      v-model="state.startDate"
    ></WsState>
    <WsState
      class="mt-20"
      label="結束時間"
      type="date"
      v-model="state.endDate"
    ></WsState>
    <WsTitle class="mt-40">超時Task</WsTitle>
    <table class="calc-table mt-10">
      <tr>
        <th>Task名稱</th>
        <th>專案</th>
        <th>執行人</th>
        <th>執行時間</th>
        <th>執行類型</th>
        <th>執行元件ID</th>
        <th>執行元件選項ID</th>
        <th>安排時數</th>
        <th>執行時數</th>
      </tr>
      <tr
        v-for="(task, taskIndex) in _overtimeTasks"
        :key="taskIndex"
      >
        <td>{{task.name}}</td>
        <td>{{task.project?task.project.name:null}}</td>
        <td>{{task.taker?task.taker.name:null}}</td>
        <td>{{$moment(task.start_time).format('YYYY.MM.DD')}}</td>
        <td>{{task.excute_type?task.excute_type.name:null}}</td>
        <td>{{task.evaluation_material?task.evaluation_material.id:null}}</td>
        <td>{{task.evaluation_material_option?task.evaluation_material_option.id:null}}</td>
        <td>{{task.hour}}</td>
        <td>{{task.finish_hour}}</td>
      </tr>
    </table>
  </WsMain>
</template>

<script>
export default {
  metaInfo() {
    return {
      title: "超時Task",
    };
  },

  data: () => ({
    tasks: [],
    state: {
      startDate: null,
      endDate: null,
    },
  }),

  computed: {
    _overtimeTasks() {
      if (!this.tasks || !this.tasks.length) {
        return [];
      }
      const _overtimeTasks = [];
      this.tasks.forEach((task) => {
        if (task.hour && task.finish_hour && task.finish_hour > task.hour) {
          _overtimeTasks.push(task);
        }
      });
      console.log(_overtimeTasks);
      return _overtimeTasks;
    },
    // _excuteMaterials(){
    //   if(!this.tasks||!this.tasks.length){
    //     return []
    //   }else{
    //     this.tasks.forEach(task => {
    //       // console.log(task);
    //       if(task.evaluation_material){
    //         console.log(task.evaluation_material);
    //       }
    //     });
    //     return []
    //   }
    // }
  },

  methods: {
    async $_fetchTask() {
      if (!this.state.startDate || !this.state.endDate) {
        return;
      }
      const params = {
        time_field: "start_time",
        start_time: this.state.startDate,
        end_time: this.state.endDate,
        order_by: "start_time",
        order_way: "asc",
      };
      const res = await this.$axios.get("task/index/all", {
        params: params,
      });
      this.tasks = res.data.data;
    },
    $_dateSet() {
      this.state.startDate = this.$moment()
        .add(-1, "month")
        .format("YYYY-MM-DD");
      this.state.endDate = this.$moment().format("YYYY-MM-DD");
    },
  },

  watch: {
    state: {
      handler() {
        this.$_fetchTask();
      },
      deep: true,
    },
  },

  mounted() {
    this.$_dateSet();
    // this.$_fetchTask();
  },
};
</script>
<style lang="scss" >
.calc-table {
  tr {
    th {
      padding: 10px;
      text-align: left;
    }
    td {
      padding: 10px;
      &.center {
        text-align: center;
        vertical-align: middle;
      }
    }
  }
}
</style>
<template>
  <div class="ws-info-images">
    <div v-if="value">
      <img
        v-for="(image,imageIndex) in value"
        :key="imageIndex"
        :src="image"
      />
    </div>
  </div>
</template>

<script>
export default {
  props: {
    value: {
      type: Array,
      default: null,
    },
  },
};
</script>
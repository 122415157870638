<template>
  <WsMain>
    <WsCrud
      :modelName="$store.state.model[modelName].modelName"
      label="我的加班申請單"
      :fields="_fields"
      :liveSearching="true"
      :showFields="showFields"
      :fetchQuery="_fetchQuery"
      :inRowBtnUpdate="false"
      :inRowBtnDelete="false"
      :updatable="false"
      :createHideFields="createHideFields"
      :createDefaultValues="_createDefaultValues"
      :infiniteScroll="true"
    ></WsCrud>
  </WsMain>
</template>

<script>
export default {
  metaInfo() {
    return {
      title: "我的加班申請單",
    };
  },
  data: () => ({
    createHideFields: ["status", "wasa"],
    modelName: "overtime_apply",
    showFields: ["id", "apply_date", "apply_hours", "task", "status"],
  }),

  computed: {
    _fields() {
      const fields = this.$store.state.model[this.modelName].fields;
      fields.task.conditions = [
        {
          key: "taker",
          value: this._currentUser.id,
        },
      ];
      return fields;
    },
    _createDefaultValues() {
      return {
        wasa: this._currentUser.id,
      };
    },
    _fetchQuery() {
      return {
        wasa: this._currentUser.id,
      };
    },
    _currentUser() {
      return this.$store.state.auth.currentUser;
    },
  },
};
</script>
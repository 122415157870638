export default {
  namespaced: true,
  state: {
    selectingLocale: null,
    locales: null,
  },
  mutations: {
    SET_LOCALES(state, data) {
      state.locales = data
      state.selectingLocale = data[0].code
    },
    SET_SELECTING_LOCALE(state, data) {
      state.selectingLocale = data
    }
  },
  actions: {
    setLocales({ commit }, data) {
      commit('SET_LOCALES', data)
    },
    setSelectingLocale({ commit }, data) {
      commit('SET_SELECTING_LOCALE', data)
    }
  }
}
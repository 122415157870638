import axios from 'axios';
import moment from 'moment';
// import { Promise } from 'core-js'
export default {
  methods: {
    // Waiting Format
    $_CMS_startTimeValidCheck({ startTime, preventWeekdays = [0, 6], additionalOffDays, additionalWorkingDays }) {
      const _startTime = this.$moment(startTime);
      if (additionalWorkingDays.includes(_startTime.format("YYYY-MM-DD"))) {
        return true;
      } else if (
        additionalOffDays.includes(_startTime.format("YYYY-MM-DD"))
      ) {
        return false;
      } else if (preventWeekdays.includes(_startTime.weekday())) {
        return false;
      } else {
        return true;
      }
    },
    $_CMS_getExcuteTypeNextKeepWeekById({ excuteType, excuteTypeSetting }) {
      if (!excuteType) {
        return false;
      } else {
        const setting = excuteTypeSetting.find((e) => {
          return e.excute_type == excuteType.id;
        });
        return setting ? setting.next_keep_week : false;
      }
    },
    $_CMS_getStartTimeByPrevTask({ prevTask, additionalOffDays, additionalWorkingDays, excuteTypeSetting }) {
      const endTimePrevTask = this.$_GRD_getEndtimeByStartTimeAndHour({
        start_time: prevTask.start_time,
        hour: prevTask.hour,
        additionalOffDays: additionalOffDays,
        additionalWorkingDays: additionalWorkingDays,
      });
      const settingNextKeepWeek = this.$_CMS_getExcuteTypeNextKeepWeekById({
        excuteType: prevTask.excute_type,
        excuteTypeSetting: excuteTypeSetting,
      });
      const startTime = this.$_CMS_getStartTimeByEndTime({
        end_time: endTimePrevTask,
        next_keep_week: settingNextKeepWeek,
        additionalOffDays: additionalOffDays,
        additionalWorkingDays: additionalWorkingDays,
      });
      return startTime;
    },
    $_CMS_getPrevTaskByType({ task, prevTasks, type, additionalOffDays, additionalWorkingDays }) {
      const _prevTasks = prevTasks.filter(e => {
        if (type == "takermatch") {
          if (!e.taker || !task.taker) {
            return false;
          } else {
            return e.taker.id == task.taker.id;
          }
        } else if (type == "prevexcuteindex") {
          if (!e.excute_type || !task.excute_type) {
            return false;
          } else if (e.excute_type.excute_index == task.excute_type.excute_index) {
            return e.excute_type.excute_index_second < task.excute_type.excute_index_second;
          } else {
            return e.excute_type.excute_index < task.excute_type.excute_index;
          }
        }
      })

      if (!_prevTasks.length) {
        return null
      }

      let comparePrevTask = _prevTasks[0]
      _prevTasks.forEach(_prevTask => {
        const endTime = this.$_GRD_getEndtimeByStartTimeAndHour({
          start_time: _prevTask.start_time,
          hour: _prevTask.hour,
          additionalOffDays: additionalOffDays,
          additionalWorkingDays: additionalWorkingDays,
        });

        const compareEndTime = this.$_GRD_getEndtimeByStartTimeAndHour({
          start_time: comparePrevTask.start_time,
          hour: comparePrevTask.hour,
          additionalOffDays: additionalOffDays,
          additionalWorkingDays: additionalWorkingDays,
        });
        if (moment(endTime).isAfter(compareEndTime)) {
          comparePrevTask = _prevTask
        }
      });

      return comparePrevTask
    },
    $_CMS_getPrevTask({
      task,
      prevTasks,
      additionalOffDays,
      additionalWorkingDays,
      excuteTypeSetting
    }) {
      if (task.qc) {
        let tarIndex = 0;
        prevTasks
          .forEach((prevTask, prevTaskIndex) => {
            if (prevTask.qc) {
              return;
            } else {
              const tarTaskEndTime = this.$_GRD_getEndtimeByStartTimeAndHour({
                start_time: this.$moment(prevTasks[tarIndex].start_time),
                hour: prevTasks[tarIndex].hour,
                additionalOffDays: additionalOffDays,
                additionalWorkingDays: additionalWorkingDays,
              });
              const prevTaskEndTime = this.$_GRD_getEndtimeByStartTimeAndHour({
                start_time: this.$moment(prevTask.start_time),
                hour: prevTask.hour,
                additionalOffDays: additionalOffDays,
                additionalWorkingDays: additionalWorkingDays,
              });
              if (this.$moment(tarTaskEndTime).isBefore(prevTaskEndTime)) {
                tarIndex = prevTaskIndex;
              }
            }
          });
        return prevTasks[tarIndex];
      }

      const prevTaskTakerMatch = this.$_CMS_getPrevTaskByType({
        task: task,
        prevTasks: prevTasks,
        type: "takermatch",
        additionalOffDays: additionalOffDays,
        additionalWorkingDays: additionalWorkingDays,
      });
      const prevTaskPrevIndex = this.$_CMS_getPrevTaskByType({
        task: task,
        prevTasks: prevTasks,
        type: "prevexcuteindex",
        additionalOffDays: additionalOffDays,
        additionalWorkingDays: additionalWorkingDays,
      });
      if (prevTaskTakerMatch && !prevTaskPrevIndex) {
        return prevTaskTakerMatch;
      } else if (!prevTaskTakerMatch && prevTaskPrevIndex) {
        return prevTaskPrevIndex;
      } else if (!prevTaskTakerMatch && !prevTaskPrevIndex) {
        return null;
      } else {
        const startTimePrevIndex = this.$_CMS_getStartTimeByPrevTask({
          prevTask: prevTaskPrevIndex,
          additionalOffDays: additionalOffDays,
          additionalWorkingDays: additionalWorkingDays,
          excuteTypeSetting: excuteTypeSetting
        });
        const startTimeTakerMatch = this.$_CMS_getStartTimeByPrevTask({
          prevTask: prevTaskTakerMatch,
          additionalOffDays: additionalOffDays,
          additionalWorkingDays: additionalWorkingDays,
          excuteTypeSetting: excuteTypeSetting
        });
        if (this.$moment(startTimeTakerMatch).isAfter(startTimePrevIndex)) {
          return prevTaskTakerMatch;
        } else {
          return prevTaskPrevIndex;
        }
      }
    },
    $_CMS_getNextValidDateTime({ time, dayDiff = 1, additionalOffDays, additionalWorkingDays }) {
      const _time = this.$moment(time);
      let maxCount = 0;
      for (let i = 0; i < dayDiff; i++) {
        _time.add(1, "day");
        while (!this.$_CMS_startTimeValidCheck({
          startTime: _time,
          additionalOffDays: additionalOffDays,
          additionalWorkingDays: additionalWorkingDays
        }) && maxCount < 100) {
          _time.add(1, "day");
          maxCount++;
        }
      }
      return _time;
    },
    $_CMS_tasksTimeSet({ tasks, startDate, additionalOffDays, additionalWorkingDays, excuteTypeSetting, leaveDays = [], afterTasks }) {
      tasks.forEach((task, taskIndex) => {
        const prevTasks = tasks.slice(0, taskIndex);
        const prevTask = this.$_CMS_getPrevTask({
          task: task,
          prevTasks: prevTasks,
          additionalOffDays: additionalOffDays,
          additionalWorkingDays: additionalWorkingDays,
          excuteTypeSetting: excuteTypeSetting
        });
        let start_time;
        if (!prevTask) {
          start_time = this.$moment(startDate)
            .set({
              hour: this.hourStart,
              minute: 0,
              second: 0,
            })
            .format();
        } else {
          if (task.qc) {
            const _start_time = this.$moment(
              this.$_CMS_getStartTimeByPrevTask({
                prevTask: prevTask,
                additionalOffDays: additionalOffDays,
                additionalWorkingDays: additionalWorkingDays,
                excuteTypeSetting: excuteTypeSetting,
              })
            ).format();
            start_time = this.$_CMS_getNextValidDateTime({
              time: _start_time,
              dayDiff: 2,
              additionalOffDays: additionalOffDays,
              additionalWorkingDays: additionalWorkingDays
            })
              .set({
                hour: this.hourStart,
              })
              .format();
          } else {
            start_time = this.$moment(
              this.$_CMS_getStartTimeByPrevTask({
                prevTask: prevTask,
                additionalOffDays: additionalOffDays,
                additionalWorkingDays: additionalWorkingDays,
                excuteTypeSetting: excuteTypeSetting,
              })
            ).format();
          }
        }
        start_time = this.$_CMS_getTaskValidStartTime({
          start_time: this.$moment(start_time),
          task: task,
          afterTasks: afterTasks,
          leaveDays: leaveDays,
          additionalOffDays: additionalOffDays,
          additionalWorkingDays: additionalWorkingDays,
        });
        task.start_time = start_time;
      });
      return tasks;
    },
    $_CMS_getTaskValidStartTime({ start_time, task, afterTasks, leaveDays, additionalOffDays, additionalWorkingDays }) {
      if (!task.taker || !this.afterTasks) {
        return start_time;
      }
      let _start_time = start_time;
      const takerAfterTasks = afterTasks.filter((e) => {
        if (!e.taker) {
          return false;
        } else {
          return e.taker.id == task.taker.id;
        }
      });
      const takerLeaveDays = leaveDays.filter((e) => {
        if (!e.wasa) {
          return false;
        } else {
          return e.wasa.id == task.taker.id;
        }
      });
      let count = 0;
      let checkIndexAfterTask = 0;
      let checkIndexLeaveDay = 0;
      let complete = false;
      while (
        count < 500 &&
        (checkIndexAfterTask < takerAfterTasks.length ||
          checkIndexLeaveDay < takerLeaveDays.length) &&
        !complete
      ) {
        const _takerAfterTasks = takerAfterTasks.slice(
          checkIndexAfterTask,
          takerAfterTasks.length
        );
        const _takerLeaveDays = takerLeaveDays.slice(
          checkIndexLeaveDay,
          takerLeaveDays.length
        );
        const _end_time = this.$_GRD_getEndtimeByStartTimeAndHour({
          start_time: _start_time,
          hour: task.hour,
          additionalOffDays: additionalOffDays,
          additionalWorkingDays: additionalWorkingDays,
        });
        let valid = true;

        // after task
        for (
          let _takerAfterTaskIndex = 0;
          _takerAfterTaskIndex < _takerAfterTasks.length;
          _takerAfterTaskIndex++
        ) {
          const _takerAfterTask = _takerAfterTasks[_takerAfterTaskIndex];

          const _takerAfterTaskEndTime = this.$_GRD_getEndtimeByStartTimeAndHour(
            {
              start_time: _takerAfterTask.start_time,
              hour: _takerAfterTask.hour,
              additionalOffDays: additionalOffDays,
              additionalWorkingDays: additionalWorkingDays,
            }
          );
          if (
            (this.$moment(_start_time).isSameOrBefore(
              _takerAfterTask.start_time
            ) &&
              this.$moment(_end_time).isAfter(_takerAfterTask.start_time)) ||
            (this.$moment(_takerAfterTask.start_time).isSameOrBefore(
              _start_time
            ) &&
              this.$moment(_takerAfterTaskEndTime).isAfter(_start_time))
          ) {
            valid = false;
            _start_time = this.$_CMS_getStartTimeByEndTime({
              end_time: _takerAfterTaskEndTime,
              additionalOffDays: additionalOffDays,
              additionalWorkingDays: additionalWorkingDays,
            });
            checkIndexAfterTask =
              _takerAfterTaskIndex +
              takerAfterTasks.length -
              _takerAfterTasks.length;
            break;
          }
        }

        // leave day
        for (
          let _takerLeaveDayIndex = 0;
          _takerLeaveDayIndex < _takerLeaveDays.length;
          _takerLeaveDayIndex++
        ) {
          // const _takerAfterTask = _takerAfterTasks[_takerAfterTaskIndex];
          const takerLeaveDay = takerLeaveDays[_takerLeaveDayIndex];

          let start =
            takerLeaveDay.start_time && takerLeaveDay.hour
              ? this.$moment(takerLeaveDay.start_time).format(
                "YYYY-MM-DD HH:mm:ss"
              )
              : this.$moment(takerLeaveDay.start_date)
                .set({
                  hour: 9,
                  minute: 0,
                  second: 0,
                })
                .format("YYYY-MM-DD HH:mm:ss");
          let hour = 0;
          if (takerLeaveDay.hour) {
            hour = takerLeaveDay.hour;
          } else if (takerLeaveDay.days) {
            hour = takerLeaveDay.days * 8;
          }

          const _takerLeaveDayEndTime = this.$_GRD_getEndtimeByStartTimeAndHour(
            {
              start_time: start,
              hour: hour,
              additionalOffDays: this.additionalOffDays,
              additionalWorkingDays: this.additionalWorkingDays,
            }
          );
          if (
            (this.$moment(_start_time).isSameOrBefore(start) &&
              this.$moment(_end_time).isAfter(start)) ||
            (this.$moment(start).isSameOrBefore(_start_time) &&
              this.$moment(_takerLeaveDayEndTime).isAfter(_start_time))
          ) {
            valid = false;
            _start_time = this.$_CMS_getStartTimeByEndTime({
              end_time: _takerLeaveDayEndTime,
              additionalOffDays: additionalOffDays,
              additionalWorkingDays: additionalWorkingDays,
            });
            checkIndexLeaveDay =
              _takerLeaveDayIndex +
              takerLeaveDays.length -
              _takerLeaveDays.length;
            break;
          }
        }

        if (valid) {
          complete = true;
        }
        count++;
      }

      return this.$moment(_start_time).format();
    },
    $_CMS_getStartTimeByEndTime({ end_time, next_keep_week = false, hourStart = 9, additionalOffDays, additionalWorkingDays }) {
      const _startTime = this.$moment(end_time);
      if (next_keep_week) {
        _startTime.add(1, "weeks").set({
          weekday: 1,
          hour: hourStart,
          minute: 0,
          second: 0,
        });
        let maxCount = 0;
        while (!this.$_CMS_startTimeValidCheck({
          startTime: _startTime,
          additionalOffDays: additionalOffDays,
          additionalWorkingDays: additionalWorkingDays
        }) && maxCount < 100) {
          _startTime.add(1, "day");
          maxCount++;
        }
        return _startTime;
      } else if (_startTime.hour() >= this.hourEnd) {
        _startTime.add(1, "day").set({
          hour: this.hourStart,
          minute: 0,
          second: 0,
        });
        let maxCount = 0;
        while (!this.$_CMS_startTimeValidCheck({
          startTime: _startTime,
          additionalOffDays: additionalOffDays,
          additionalWorkingDays: additionalWorkingDays
        }) && maxCount < 100) {
          _startTime.add(1, "day");
          maxCount++;
        }
        return _startTime;
      } else {
        return end_time;
      }
    },
    $_CMS_getEvaluationCalendarEvents({
      tasks = [],
      milestones = [],
      afterTasks = [],
      leaveDays = [],
      additionalOffDays = [],
      additionalWorkingDays = [],
    }) {
      const _additionalOffDays = [];
      if (additionalOffDays) {
        additionalOffDays.forEach((additionalOffDay) => {
          _additionalOffDays.push(additionalOffDay.date);
        });
      }
      const _additionalWorkingDays = [];
      if (additionalWorkingDays) {
        additionalWorkingDays.forEach((additionalWorkingDay) => {
          _additionalWorkingDays.push(additionalWorkingDay.date);
        });
      }
      const _calendarEvents = [];

      // Task
      tasks.forEach((task) => {
        if (task.start_time && task.hour) {
          const title = task.name;
          const start = this.$moment(task.start_time).format();
          const end = this.$_GRD_getEndtimeByStartTimeAndHour({
            start_time: task.start_time,
            hour: task.hour,
            additionalOffDays: _additionalOffDays,
            additionalWorkingDays: _additionalWorkingDays,
          });
          const color = task.taker
            ? this.$_CMS_hexToRgb(task.taker.color)
            : null;
          _calendarEvents.push({
            title: `${title}`,
            start: start,
            end: end,
            color: color,
            allDay: false,
            data: task,
            type: "task",
          });
        }
      });

      // Milestone
      milestones.forEach((milestone) => {
        if (milestone.date) {
          const color = milestone.taker
            ? this.$_CMS_hexToRgb(milestone.taker.color)
            : null;
          _calendarEvents.push({
            title: milestone.name,
            start: milestone.date,
            data: milestone,
            color: color,
            type: "milestone",
            allDay: true,
          });
        }
      });

      // Additional Off Days
      if (additionalOffDays) {
        additionalOffDays.forEach(additionalOffDay => {
          _calendarEvents.push({
            title: `😄😄 ${additionalOffDay.name}`,
            start: additionalOffDay.date,
            allDay: true,
            color: '#ff1774',
            data: additionalOffDay,
            type: "additional_off_day",
          });
        });
      }

      // Additional Working Days
      if (additionalWorkingDays) {
        additionalWorkingDays.forEach(additionalWorkingDay => {
          _calendarEvents.push({
            title: `😭😭 ${additionalWorkingDay.name}`,
            start: additionalWorkingDay.date,
            allDay: true,
            color: '#000',
            data: additionalWorkingDay,
            type: "additional_working_day",
          });
        });
      }

      // After Tasks
      if (afterTasks) {
        afterTasks.forEach((afterTask) => {
          if (afterTask.start_time && afterTask.hour) {
            const title = afterTask.name;
            const start = this.$moment(afterTask.start_time).format();
            const end = this.$_GRD_getEndtimeByStartTimeAndHour({
              start_time: afterTask.start_time,
              hour: afterTask.hour,
              additionalOffDays: _additionalOffDays,
              additionalWorkingDays: _additionalWorkingDays,
            });
            const color = afterTask.taker
              ? this.$_CMS_hexToRgb(afterTask.taker.color, 0.3)
              : null;
            _calendarEvents.push({
              title: title,
              start: start,
              end: end,
              color: color,
              allDay: false,
              data: afterTask,
              type: "afterTask",
            });
          }
        });
      }

      // Leave Day
      if (leaveDays) {
        leaveDays.forEach((leaveDay) => {
          const color = leaveDay.wasa
            ? this.$_CMS_hexToRgb(leaveDay.wasa.color)
            : null;
          let start =
            leaveDay.start_time && leaveDay.hour
              ? this.$moment(leaveDay.start_time).format("YYYY-MM-DD HH:mm:ss")
              : this.$moment(leaveDay.start_date)
                .set({
                  hour: 9,
                  minute: 0,
                  second: 0,
                })
                .format("YYYY-MM-DD HH:mm:ss");
          let hour = 0;
          if (leaveDay.hour) {
            hour = leaveDay.hour;
          } else if (leaveDay.days) {
            hour = leaveDay.days * 8;
          }
          const end = this.$_GRD_getEndtimeByStartTimeAndHour({
            start_time: start,
            hour: hour,
            additionalOffDays: _additionalOffDays,
            additionalWorkingDays: _additionalWorkingDays,
          });
          _calendarEvents.push({
            title: `[休] ${leaveDay.wasa.name}請假`,
            start: start,
            end: end,
            allDay: false,
            color: color,
            data: leaveDay,
            type: "leave_day",
          });
          _calendarEvents.push({
            title: `[休] ${leaveDay.wasa.name}請假`,
            start: start,
            allDay: true,
            color: color,
            data: leaveDay,
            type: "leave_day",
          });
        });
      }

      return _calendarEvents;
    },
    $_CMS_getCalendarEvents({ tasks = [], milestones = [], additionalOffDays = []
      , additionalWorkingDays = [], leaveDays = [], projectInTitle = true }) {

      const _calendarEvents = [];

      const _additionalOffDays = [];
      if (additionalOffDays) {
        additionalOffDays.forEach((additionalOffDay) => {
          _additionalOffDays.push(additionalOffDay.date);
        });
      }
      const _additionalWorkingDays = [];
      if (additionalWorkingDays) {
        additionalWorkingDays.forEach((additionalWorkingDay) => {
          _additionalWorkingDays.push(additionalWorkingDay.date);
        });
      }

      // Task
      tasks.forEach((task) => {
        if (!task.start_time || !task.hour) {
          return;
        }
        let title = "";
        if (projectInTitle && task.project) {
          title += `${task.project.name}@`;
        }
        if (task.evaluation_stage) {
          title += `${task.evaluation_stage.name}__`;
        }
        title += task.name;
        const start = moment(task.start_time).format();

        const end = this.$_GRD_getEndtimeByStartTimeAndHour({
          start_time: task.start_time,
          hour: task.hour,
          additionalOffDays: _additionalOffDays,
          additionalWorkingDays: _additionalWorkingDays,
        });
        let opa = 1;
        if (task.is_close) {
          opa = 0.03;
        } else if (task.is_finish) {
          opa = 0.3;
        }
        const color = task.taker
          ? this.$_CMS_hexToRgb(task.taker.color, opa)
          : null;
        _calendarEvents.push({
          title: title,
          start: start,
          end: end,
          color: color,
          allDay: false,
          data: task,
          type: "task",
        });
      });

      // Milestone
      milestones.forEach((milestone) => {
        let title = "";
        if (projectInTitle && milestone.project) {
          title += `${milestone.project.name}@`;
        }
        title += milestone.name;
        const start = milestone.date;
        let opa = 1;
        if (milestone.is_close) {
          opa = 0.03;
        } else if (milestone.is_finish) {
          opa = 0.3;
        }
        const color = milestone.taker
          ? this.$_CMS_hexToRgb(milestone.taker.color, opa)
          : null;
        _calendarEvents.push({
          title: title,
          start: start,
          allDay: true,
          color: color,
          data: milestone,
          type: "milestone",
        });
      });

      // Additional Off Days
      if (additionalOffDays) {
        additionalOffDays.forEach(additionalOffDay => {
          _calendarEvents.push({
            title: `😄😄 ${additionalOffDay.name}`,
            start: additionalOffDay.date,
            allDay: true,
            color: '#ff1774',
            data: additionalOffDay,
            type: "additional_off_day",
          });
        });
      }

      // Additional Working Days
      if (additionalWorkingDays) {
        additionalWorkingDays.forEach(additionalWorkingDay => {
          _calendarEvents.push({
            title: `😭😭 ${additionalWorkingDay.name}`,
            start: additionalWorkingDay.date,
            allDay: true,
            color: '#000',
            data: additionalWorkingDay,
            type: "additional_working_day",
          });
        });
      }

      // Leave Days
      if (leaveDays) {
        leaveDays.forEach(leaveDay => {
          if (!leaveDay.wasa) {
            return
          }
          const color = leaveDay.wasa
            ? this.$_CMS_hexToRgb(leaveDay.wasa.color)
            : null;
          let start = (leaveDay.start_time && leaveDay.hour) ? this.$moment(leaveDay.start_time).format('YYYY-MM-DD HH:mm:ss') : this.$moment(leaveDay.start_date).set({
            hour: 9,
            minute: 0,
            second: 0,
          }).format('YYYY-MM-DD HH:mm:ss')
          let hour = 0
          if (leaveDay.hour) {
            hour = leaveDay.hour
          } else if (leaveDay.days) {
            hour = leaveDay.days * 8
          }
          const end = this.$_GRD_getEndtimeByStartTimeAndHour({
            start_time: start,
            hour: hour,
            additionalOffDays: additionalOffDays,
            additionalWorkingDays: additionalWorkingDays,
          });
          _calendarEvents.push({
            title: `[休] ${leaveDay.wasa.name}請假`,
            start: start,
            end: end,
            allDay: false,
            color: color,
            data: leaveDay,
            type: "leave_day",
          });
          _calendarEvents.push({
            title: `[休] ${leaveDay.wasa.name}請假`,
            start: start,
            allDay: true,
            color: color,
            data: leaveDay,
            type: "leave_day",
          });
        });
      }
      return _calendarEvents;
    },
    $_CMS_slackNotificate({ url, config }) {
      return new Promise((resolve, reject) => {
        const http = axios.create();
        delete http.defaults.headers.common["Authorization"];
        http.post(url, JSON.stringify(config)).then(res => {
          resolve(res)
        }).catch(error => {
          console.log(error);
          console.log(error.message);
          reject(error)
        });
      })
    },
    $_CMS_getDateOrNull(date, format = 'YYYY-MM-DD') {
      if (!moment(date).isValid()) {
        return null
      } else {
        return moment(date).format(format)
      }
    },
    $_CMS_getPrimaryMatch(array1, array2) {
      const match = array1.find(item => {
        return array2.includes(item)
      })
      return match
    },
    $_CMS_getLabelByHideLabelFields(fieldKey, fields, hideLabelFields) {
      if (!hideLabelFields) {
        return this.$_CMS_getFieldByFieldKey(fieldKey, fields).label;
      } else {
        if (hideLabelFields.indexOf(fieldKey) >= 0) {
          return null;
        } else {
          return this.$_CMS_getFieldByFieldKey(fieldKey, fields).label;
        }
      }
    },
    $_CMS_getFieldByFieldKey(fieldKey, fields) {
      const keyArr = fieldKey.split(".");
      let target = fields;
      for (let i = 0; i < keyArr.length; i++) {
        const keyItem = keyArr[i];
        if (i == 0) {
          if (target[keyItem] !== undefined) {
            target = target[keyItem];
          } else {
            return null;
          }
        } else {
          if (target.fields[keyItem] !== undefined) {
            target = target.fields[keyItem];
          } else {
            return null;
          }
        }
      }
      return target;
    },
    $_CMS_getVersionsFields(versionsFields, keyPre = "", labelPre = "") {
      let versions_fields = {};
      for (let versionsFieldKey in versionsFields) {
        const versionsField = versionsFields[versionsFieldKey];
        if (versionsField.type == "locales") {
          versions_fields = {
            ...this.$_CMS_getLocalesFields(
              versionsField.fields,
              `${keyPre}last_version__`,
              "最新版本-"
            ),
            ...versions_fields
          };
        } else {
          versions_fields[`${keyPre}last_version__${versionsFieldKey}`] = {
            ...versionsField,
            label: `${labelPre}最新版本-${versionsField.label}`,
            showOnly: true,
          };
        }
      }
      return versions_fields;
    },
    $_CMS_getLocalesFields(localesFields, keyPre = "", labelPre = "") {
      let locales_fields = {};
      for (let localesFieldKey in localesFields) {
        const localesField = localesFields[localesFieldKey];
        locales_fields[`${keyPre}locales_main__${localesFieldKey}`] = {
          ...localesField,
          label: `主語言-${labelPre}${localesField.label}`,
          showOnly: true
        };
        const tarLocale = this.$store.state.locale.locales.find(e => {
          return e.code == this.$store.state.locale.selectingLocale
        })
        locales_fields[
          `${keyPre}locales_${this.$store.state.locale.selectingLocale}__${localesFieldKey}`
        ] = {
          ...localesField,
          label: `${labelPre}${tarLocale.name}-${localesField.label}`,
          showOnly: true
        };

        // locales.forEach(locale => {
        //   locales_fields[
        //     `${keyPre}locales_${locale.code}__${localesFieldKey}`
        //   ] = {
        //     ...localesField,
        //     label: `${labelPre}${locale.name}-${localesField.label}`,
        //     showOnly: true
        //   };
        // });
      }
      return locales_fields;
    },
    $_CMS_geUpdateFields(fields, updateHideFields) {
      let _fields = {};
      for (let fieldKey in fields) {
        if (updateHideFields && updateHideFields.includes(fieldKey)) {
          continue
        }
        const field = fields[fieldKey];
        if (field.readonly) {
          continue
        } else if (field.type == 'custom') {
          continue
        } else {
          _fields[fieldKey] = field;
        }
      }
      return _fields;
    },
    $_CMS_getDisplayFields(fields) {
      let _fields = {};
      for (let fieldKey in fields) {
        const field = fields[fieldKey];
        if (field.type == "last_version") {
          _fields = {
            ...this.$_CMS_getVersionsFields(field.fields),
            ..._fields
          };
        } else if (field.type == "locales") {
          _fields = {
            ...this.$_CMS_getLocalesFields(field.fields),
            ..._fields
          };
        } else {
          _fields[fieldKey] = field;
        }
      }
      return _fields;
    },
    // Get value from key in sequence string format
    $_CMS_getValueByFieldKey(fieldKey, valueItem) {
      const keyArr = fieldKey.split(".");
      let target = valueItem;
      for (let i = 0; i < keyArr.length; i++) {
        const keyItem = keyArr[i];
        let targetKey = keyItem;
        if (targetKey == 'locales' && keyArr.length > 1) {
          continue
        }
        if (target[targetKey] !== undefined) {
          target = target[targetKey];
        } else {
          return null;
        }
      }
      return target;
    },
    $_CMS_getUpdateData(originData, field) {
      if (originData !== undefined) {
        if (field.type == "last_version") {
          return originData
        } else if (field.type == "belongsTo") {
          if (!originData) {
            return null
          } else {
            return originData.id
          }
        } else if (field.type == "hasMany") {
          const _value = []
          originData.forEach(hasManyItem => {
            const _hasManyItem = {}
            for (let fieldKey in field.fields) {
              const subField = field.fields[fieldKey]
              _hasManyItem[fieldKey] = this.$_CMS_getUpdateData(hasManyItem[fieldKey], subField)
            }
            _value.push(_hasManyItem)
          });
          return _value
        } else if (field.type == "belongsToMany") {
          const _value = []
          originData.forEach(valueItem => {
            _value.push(valueItem.id)
          });
          return _value
        } else {
          return originData
        }
      } else if (field.defaultValue !== undefined) {
        return field.defaultValue
      } else {
        return null
      }
    },
    // Model update data set for v-model
    $_CMS_updateDataSetFromFields(template, updateData, fields, item) {
      for (let fieldKey in fields) {
        const field = fields[fieldKey];
        if (field.readonly) {
          continue;
        }
        let valueKey = fieldKey;
        if (item && item[valueKey] != undefined) {
          if (field.type == "last_version" && item) {
            template.$set(updateData, fieldKey, item[valueKey]);
          } else if (field.type == "date" && item) {
            template.$set(updateData, fieldKey, moment(item[valueKey]).format('YYYY-MM-DD'));
          } else if (field.type == "datetime" && item) {
            template.$set(updateData, fieldKey, moment(item[valueKey]).format('YYYY-MM-DD HH:mm:ss'));
          } else if (field.type == "belongsTo" && item) {
            template.$set(updateData, fieldKey, item[valueKey].id);
          } else if (field.type == "belongsToMany" && item) {
            const _value = []
            item[valueKey].forEach(valueItem => {
              _value.push(valueItem.id)
            });
            template.$set(updateData, fieldKey, _value);
          } else {
            template.$set(updateData, fieldKey, item[valueKey]);
          }
        } else if (field.defaultValue !== undefined) {
          template.$set(updateData, fieldKey, field.defaultValue);
          continue;
        } else {
          template.$set(updateData, fieldKey, null);
        }
      }
    },
    $_CMS_formatItemVersions(item, itemPre, versionsFields, keyPre = "") {
      for (let versionsFieldKey in versionsFields) {
        const versionsField = versionsFields[versionsFieldKey];
        this.$_CMS_formatItem(
          item,
          itemPre.last_version,
          versionsField,
          versionsFieldKey,
          `${keyPre}last_version__`
        );
      }
    },
    $_CMS_formatItemLocales(item, itemPre, localesFields, keyPre = "") {
      for (let localesFieldKey in localesFields) {
        item[`${keyPre}locales_main__${localesFieldKey}`] = itemPre
          ? itemPre[localesFieldKey]
          : null;
        const locales = this.$store.state.locale.locales;
        locales.forEach((locale) => {
          item[`${keyPre}locales_${locale.code}__${localesFieldKey}`] =
            itemPre && itemPre.locales && itemPre.locales[locale.code]
              ? itemPre.locales[locale.code][localesFieldKey]
              : null;
        });
      }
    },
    $_CMS_formatItemBelongsTo(
      item,
      itemPre,
      fieldKey,
      keyPre,
      textKey = "text",
      selectMode = false
    ) {
      if (!itemPre || !itemPre[fieldKey]) {
        return;
      }
      if (selectMode) {
        return;
      }
      item[`${keyPre}${fieldKey}`] = itemPre[fieldKey][textKey];
    },
    $_CMS_formatItemHasMany(
      item,
      itemPre,
      fieldKey,
      keyPre = "",
      textKey = "text",
      selectMode = false
    ) {
      if (!itemPre || !itemPre[fieldKey]) {
        return;
      }
      if (selectMode) {
        return;
      }
      let hasManyValue = "";
      itemPre[fieldKey].forEach((hasmanyItem) => {
        if (hasManyValue != "") {
          hasManyValue += ", ";
        }
        hasManyValue += hasmanyItem[textKey];
      });
      item[`${keyPre}${fieldKey}`] = hasManyValue;
    },
    $_CMS_formatItemTags(
      item,
      itemPre,
      fieldKey,
      keyPre = "",
    ) {
      if (!itemPre || !itemPre[fieldKey]) {
        return;
      }
      let _value = "";
      itemPre[fieldKey].forEach((tag) => {
        if (_value != "") {
          _value += ", ";
        }
        _value += tag;
      });
      item[`${keyPre}${fieldKey}`] = _value;
    },
    $_CMS_formatItemDate(item, itemPre, fieldKey, keyPre = "") {
      if (!itemPre || !itemPre[fieldKey]) {
        return;
      }
      item[`${keyPre}${fieldKey}`] = this.$moment(itemPre[fieldKey]).format(
        "YYYY.MM.DD"
      );
    },
    $_CMS_formatItemLink(item, itemPre, fieldKey, keyPre = "") {
      if (!itemPre || !itemPre[fieldKey]) {
        return;
      }
      item[`${keyPre}${fieldKey}`] = `
        <WsIcon>link</WsIcon>
      `
    },
    $_CMS_formatItemDatetime(item, itemPre, fieldKey, keyPre = "") {
      if (!itemPre || !itemPre[fieldKey]) {
        return;
      }
      item[`${keyPre}${fieldKey}`] = this.$moment(itemPre[fieldKey]).format(
        "YYYY.MM.DD HH:mm:ss"
      );
    },
    $_CMS_formatItemCustom(item, itemPre, fieldKey, keyPre = "", getValue) {
      // if (!itemPre || !itemPre[fieldKey]) {
      //   return;
      // }
      item[`${keyPre}${fieldKey}`] = getValue(item);
    },
    $_CMS_formatItemSwitch(
      item,
      itemPre,
      fieldKey,
      keyPre = "",
      activeText = "有",
      inactiveText = "無"
    ) {
      if (!itemPre) {
        return;
      }
      item[`${keyPre}${fieldKey}`] = itemPre[fieldKey]
        ? activeText
        : inactiveText;
    },
    $_CMS_formatItemSelect(
      item,
      itemPre,
      fieldKey,
      keyPre = "",
      textKey = "text",
      items = []
    ) {
      if (!itemPre || itemPre[fieldKey] == undefined) {
        return;
      }
      const tarItem = items.find((e) => {
        return e.value == item[`${keyPre}${fieldKey}`];
      });
      if (!tarItem) {
        return;
      }
      item[`${keyPre}${fieldKey}`] = tarItem[textKey];
    },
    $_CMS_formatItemOther(item, itemPre, fieldKey, keyPre) {
      if (!itemPre || !itemPre[fieldKey]) {
        return;
      }
      item[`${keyPre}${fieldKey}`] = itemPre ? itemPre[fieldKey] : null;
    },
    $_CMS_formatItem(item, itemPre, field, fieldKey, keyPre = "") {
      if (field.type == "last_version") {
        this.$_CMS_formatItemVersions(item, itemPre, field.fields, keyPre);
      } else if (field.type == "locales") {
        this.$_CMS_formatItemLocales(item, itemPre, field.fields, keyPre);
      } else if (field.type == "select") {
        this.$_CMS_formatItemSelect(
          item,
          itemPre,
          fieldKey,
          keyPre,
          field.textKey,
          field.items
        );
      } else if (field.type == "switch") {
        this.$_CMS_formatItemSwitch(
          item,
          itemPre,
          fieldKey,
          keyPre,
          field.activeText,
          field.inactiveText
        );
      } else if (field.type == "belongsTo") {
        this.$_CMS_formatItemBelongsTo(
          item,
          itemPre,
          fieldKey,
          keyPre,
          field.textKey,
          field.selectMode
        );
      } else if (field.type == "hasMany") {
        this.$_CMS_formatItemHasMany(
          item,
          itemPre,
          fieldKey,
          keyPre,
          field.textKey,
          field.selectMode
        );
      } else if (field.type == "tags") {
        this.$_CMS_formatItemTags(
          item,
          itemPre,
          fieldKey,
          keyPre,
        );
      } else if (field.type == "date") {
        this.$_CMS_formatItemDate(item, itemPre, fieldKey, keyPre);
      } else if (field.type == "link") {
        this.$_CMS_formatItemLink(item, itemPre, fieldKey, keyPre);
      } else if (field.type == "datetime") {
        this.$_CMS_formatItemDatetime(item, itemPre, fieldKey, keyPre);
      } else if (field.type == "custom") {
        this.$_CMS_formatItemCustom(
          item,
          itemPre,
          fieldKey,
          keyPre,
          field.getValue
        );
      } else {
        this.$_CMS_formatItemOther(item, itemPre, fieldKey, keyPre);
      }
    },
    $_CMS_getCollectionFromFirestoreSnap(snapshot) {
      const collection = [];
      snapshot.docs.forEach((ref) => {
        const refData = ref.data();
        collection.push({
          id: ref.id,
          ...refData,
        });
      });
      return collection;
    },
    $_CMS_getFormatedTableItems(items, fields) {
      if (!items) {
        return [];
      }
      const _items = JSON.parse(JSON.stringify(items));
      _items.forEach((item) => {
        for (let fieldKey in fields) {
          const field = fields[fieldKey];
          this.$_CMS_formatItem(item, item, field, fieldKey);
        }
      });
      return _items;
    },
    $_CMS_hexToRgb(hex, alpha) {
      var result = /^#?([a-f\d]{2})([a-f\d]{2})([a-f\d]{2})$/i.exec(hex);
      var toString = function () {
        if (this.alpha == undefined) {
          return "rgb(" + this.r + ", " + this.g + ", " + this.b + ")";
        }
        if (this.alpha > 1) {
          this.alpha = 1;
        } else if (this.alpha < 0) {
          this.alpha = 0;
        }
        return (
          "rgba(" +
          this.r +
          ", " +
          this.g +
          ", " +
          this.b +
          ", " +
          this.alpha +
          ")"
        );
      };
      if (alpha == undefined) {
        return result
          ? {
            r: parseInt(result[1], 16),
            g: parseInt(result[2], 16),
            b: parseInt(result[3], 16),
            toString: toString
          }
          : null;
      }
      if (alpha > 1) {
        alpha = 1;
      } else if (alpha < 0) {
        alpha = 0;
      }
      return result
        ? {
          r: parseInt(result[1], 16),
          g: parseInt(result[2], 16),
          b: parseInt(result[3], 16),
          alpha: alpha,
          toString: toString
        }
        : null;
    },
  },
  computed: {
    _CMS_theme() {
      // return vuetify.userPreset.theme.dark ? "dark" : "light";
    },
    _CMS_menu() {
      let _CMS_menu = [
        ...this.SET_menu,
        {
          title: '登出',
          link: '/signout'
        }
      ]
      return _CMS_menu
    },
  },
}
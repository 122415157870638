export default [
  {
    path: '/voucher_template',
    name: 'voucher_template',
    component: () => import('../views/VoucherTemplate/Index.vue'),
    meta: {
      middleware: 'auth',
      requiredScopes: ['boss']
    }
  },
  {
    path: '/accounting_subject_first',
    name: 'accounting_subject_first',
    component: () => import('../views/AccountingSubjectFirst/Index.vue'),
    meta: {
      middleware: 'auth',
      requiredScopes: ['boss']
    }
  },
  {
    path: '/accounting_subject_second',
    name: 'accounting_subject_second',
    component: () => import('../views/AccountingSubjectSecond/Index.vue'),
    meta: {
      middleware: 'auth',
      requiredScopes: ['boss']
    }
  },
  {
    path: '/billing_statement/review',
    name: 'billing_statement_review',
    component: () => import('../views/BillingStatementReview.vue'),
    meta: {
      middleware: 'auth',
      requiredScopes: ['boss']
    }
  },
]
export default [
  {
    path: '/order_stage',
    name: 'order_stage',
    component: () => import('../views/OrderStage/Index.vue'),
    meta: {
      middleware: 'auth',
      requiredScopes: ['boss', 'admin', 'pm']
    }
  },
  {
    path: '/order',
    name: 'order',
    component: () => import('../views/Order/Index.vue'),
    meta: {
      middleware: 'auth',
      requiredScopes: ['boss', 'admin', 'pm']
    }
  },
  {
    path: '/order/create',
    name: 'order_create',
    component: () => import('../views/Order/Create.vue'),
    meta: {
      middleware: 'auth',
      requiredScopes: ['boss', 'admin', 'pm']
    }
  },
  {
    path: '/order/:id/update',
    name: 'order_update',
    component: () => import('../views/Order/Update.vue'),
    meta: {
      middleware: 'auth',
      requiredScopes: ['boss', 'admin', 'pm']
    }
  },
  {
    path: '/order/:id',
    name: 'order_read',
    component: () => import('../views/Order/Read.vue'),
    requiredScopes: ['boss', 'admin', 'pm']
  },
]
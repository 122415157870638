<template>
  <div
    class="ws-app-header-button"
    @click="$emit('click')"
  >
    <WsIcon>language</WsIcon>
    <div class="ws-app-header-button__text">
      <slot></slot>
    </div>
  </div>
</template>

<script>
export default {};
</script>
export default {
  modelName: "task",
  label: "Task",
  fields: {
    id: {
      type: "text",
      label: "ID",
      readonly: true,
      width: '70px',
      sortable: true
    },
    name: {
      type: "text",
      label: "名稱",
      autofocus: true,
      rules: "required",
      width: '160px',
      sortable: true
    },
    start_time: {
      type: "datetime",
      label: '開始時間',
      rules: "required",
      width: '160px',
      sortable: true
    },
    // is_close: {
    //   type: 'switch',
    //   label: '關閉',
    //   activeText: 'CLOSE',
    //   inactiveText: 'OPEN',
    //   defaultValue: false,
    // },
    is_not_complete: {
      type: 'switch',
      label: '是否全部完成',
      activeText: '有項目未完成',
      inactiveText: '項目皆已完成',
      defaultValue: 0,
      width: '70px'
    },
    is_finish: {
      type: 'switch',
      label: '狀態',
      activeText: '完成',
      inactiveText: '未完成',
      defaultValue: false,
      width: '70px'
    },
    hour: {
      type: 'number',
      label: '安排時數',
      defaultValue: 1,
      rules: "required",
    },
    finish_hour: {
      type: 'number',
      label: '完成時數',
    },
    remark: {
      type: 'textarea',
      label: '備註',
    },
    project: {
      type: 'belongsTo',
      label: '專案',
      textKey: "name",
      modelName: "project",
    },
    creator: {
      type: 'belongsTo',
      label: '建立人',
      textKey: "name",
      modelName: "wasa",
      width: '100px',
      sortable: true
    },
    taker: {
      type: 'belongsTo',
      label: '執行者',
      textKey: "name",
      modelName: "wasa",
      rules: "required",
      width: '100px',
      sortable: true
    },
    excute_type: {
      type: 'belongsTo',
      label: '執行類型',
      textKey: "name",
      modelName: "excute_type",
    },
    evaluation_material: {
      type: 'belongsTo',
      label: '評估元件',
      textKey: "name",
      modelName: "evaluation_material",
    },
    evaluation_material_option: {
      type: 'belongsTo',
      label: '評估元件選項',
      textKey: "name",
      modelName: "evaluation_material_option",
    },
    adjust: {
      type: 'switch',
      label: '是否為調整Task',
      activeText: '是',
      inactiveText: '否',
      defaultValue: false,
    },
    qc: {
      type: 'switch',
      label: '是否為QcTask',
      activeText: '是',
      inactiveText: '否',
      defaultValue: false,
    },
    is_rd_task: {
      type: 'switch',
      label: '是否為開發Task (教戰、尺、文件)',
      activeText: '是',
      inactiveText: '否',
      defaultValue: false,
    },
    updated_at: {
      type: "datetime",
      label: "最後更新時間",
      readonly: true
    },
    sequence: {
      type: "text",
      label: "排序"
    },
  },
}
<template>
  <div
    ref="wsTabsSection"
    class="ws-tabs-section"
  >
    <div class="ws-tabs-section__tabs">
      <RouterLink
        v-for="(tab, tabIndex) in tabs"
        :key="tabIndex"
        :to="$_getTo(tab)"
      >{{tab.text}}</RouterLink>
    </div>
    <div class="ws-tabs-section__content">
      <slot></slot>
    </div>
  </div>
</template>

<script>
export default {
  methods: {
    $_getTo(tab) {
      if (this.routePre) {
        return `${this.routePre}${tab.to}`;
      } else {
        return tab.to;
      }
    },
  },
  props: {
    tabs: {
      type: Array,
      default: null,
    },
    routePre: {
      type: String,
      default: null,
    },
  },
};
</script>

<style>
</style>
<template>
  <div class="ws-state-has-many">
    
  </div>
</template>

<script>
export default {
  props: {
    modelName: {
      type: String,
      default: null,
    },
    textKey: {
      type: String,
      default: null,
    },
    value: {
      type: Array,
      default: null,
    },
  },
};
</script>

<style>
</style>
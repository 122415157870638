<template>
  <WsDialog
    ref="WsDialog"
    title="覆核"
    :escMetaClose="true"
  >
    <template v-slot:content>
      afasdf
    </template>
  </WsDialog>
</template>

<script>
export default {
  data: () => ({
    dialog: false,
    submitLoading: false,
  }),

  methods: {
    stopLoading() {
      this.submitLoading = false;
    },
    startLoading() {
      this.submitLoading = true;
    },
    $_onCancel() {
      this.close();
    },
    close() {
      this.dialog = false;
      this.$refs.WsDialog.close();
      window.removeEventListener("keydown", this.$_keydownHandler);
    },
    open() {
      this.dialog = true;
      this.$refs.WsDialog.open();
      window.addEventListener("keydown", this.$_keydownHandler);
    },
  },
};
</script>

<style>
</style>
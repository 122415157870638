<template>
  <div class="ws-info-fields">
    <WsCrudTable
      class="mt-10"
      :showFields="showFields"
      :fields="_fields"
      :items="_items"
      :paginate="false"
      :showExpand="false"
      :expandable="false"
      :inRowBtnRead="false"
      :inRowBtnUpdate="false"
      :inRowBtnDelete="false"
    ></WsCrudTable>
  </div>
</template>

<script>
export default {
  data: () => ({
    showFields: ["name", "label", "type"],
    fields: {
      name: {
        type: "text",
        label: "名稱(程式)",
        autofocus: true,
        rules: "required",
      },
      label: {
        type: "text",
        label: "名稱(瀏覽)",
        rules: "required",
      },
      type: {
        type: "select",
        label: "類型",
        items: [],
        rules: "required",
      },
    },
    typeItems: [
      {
        text: "文字",
        value: "text",
      },
      {
        text: "文字區塊",
        value: "textarea",
      },
      {
        text: "圖片",
        value: "imagePicker",
      },
    ],
    tableHeaders: [
      {
        text: "名稱(程式)",
        value: "name",
      },
      {
        text: "名稱(瀏覽)",
        value: "label",
      },
      {
        text: "類型",
        value: "type",
      },
      {
        text: "",
        value: "actions",
      },
    ],
    stateFieldsList: {
      text: ["placeholder", "rules"],
      textarea: ["placeholder", "rules"],
      image: ["rules", "uploadPath"],
    },
    focusIndex: -1,
  }),

  computed: {
    _arrayFields() {
      const _arrayFields = [];
      for (let fieldKey in this.value) {
        const _field = this.value[fieldKey];
        _arrayFields.push({
          name: fieldKey,
          label: _field.label,
          type: _field.type,
          placeholder: _field.placeholder,
          items: _field.items,
          textKey: _field.textKey,
          rules: _field.rules,
          activeText: _field.activeText,
          inactiveText: _field.inactiveText,
          uploadPath: _field.uploadPath,
          maxWidth: _field.maxWidth,
          maxHeight: _field.maxHeight,
        });
      }
      return _arrayFields;
    },

    _fields() {
      const _fields = JSON.parse(JSON.stringify(this.fields));
      _fields.type.items = this.typeItems;
      _fields.type.defaultValue = this.typeItems[0].value;
      return _fields;
    },

    _items() {
      if (!this._arrayFields) {
        return null;
      } else {
        const _items = [];
        this._arrayFields.forEach((valueItem) => {
          _items.push({
            name: valueItem.name,
            label: valueItem.label,
            type: valueItem.type,
            placeholder: valueItem.placeholder,
            items: valueItem.items,
            textKey: valueItem.textKey,
            rules: valueItem.rules,
            activeText: valueItem.activeText,
            inactiveText: valueItem.inactiveText,
            uploadPath: valueItem.uploadPath,
            maxWidth: valueItem.maxWidth,
            maxHeight: valueItem.maxHeight,
          });
        });
        return _items;
      }
    },
  },

  props: {
    label: {
      type: String,
      default: null,
    },
    value: {
      type: Object,
      default: null,
    },
  },
};
</script>
import serviceAuth from './service/api/v1/auth'
import axios from 'axios';
import moment from 'moment';
import numeral from 'numeral';
import config from '@/__vue2stone_cms_config'
import message from './message'
import theme from './theme'
import TextareaAutosize from 'vue-textarea-autosize'
import linkify from 'vue-linkify';
import { ValidationProvider, ValidationObserver } from 'vee-validate';
import { SlickList, SlickItem, HandleDirective } from "vue-slicksort";
import Vue2Transitions from 'vue2-transitions'
import DatePicker from 'vue2-datepicker';
import 'vue2-datepicker/index.css';
import 'vue2-datepicker/locale/zh-tw';
import { Chrome } from 'vue-color'
import vClickOutside from 'v-click-outside'
import VueMeta from 'vue-meta'
import Croppa from 'vue-croppa'
import 'vue-croppa/dist/vue-croppa.css'
import Ripple from 'vue-ripple-directive'
import VueLazyload from 'vue-lazyload'
import * as Case from "change-case";
import mixin from './mixin'

export default {
  init(app) {
    this.configInit(config)
    this.propertiesInit(app)
    this.themeInit()
    this.componentsInit(app)
    this.packagesInit(app)
    this.mixinInit(app)
  },

  configInit(config) {

    // Message
    for (let messageKey in message) {
      if (config.message[messageKey]) {
        message[messageKey] = { ...message[messageKey], ...config.message[messageKey] }
      }
    }
  },

  propertiesInit(app) {
    app.prototype.$auth = serviceAuth;
    axios.defaults.baseURL = process.env.VUE_APP_API_BASE_URL;
    app.prototype.$axios = axios;
    app.prototype.$moment = moment;
    app.prototype.$numeral = numeral;
    app.prototype.$config = config;
    app.prototype.$message = message[config.locale.lang];
    app.prototype.$case = Case;
  },

  themeInit() {
    const mode = config.theme.mode
    const color = theme.color
    const colorDefault = color[mode]
    const colorConfig = config.theme.color
    const root = document.documentElement;
    for (let colorKey in colorDefault) {
      root.style.setProperty(`--${colorKey}-1`, colorDefault[colorKey]);
    }
    for (let colorKey in colorConfig) {
      root.style.setProperty(`--${colorKey}-1`, colorConfig[colorKey]);
    }
  },

  componentsInit(app) {
    const layouts = require.context('./layouts/', true, /\.vue$/i);
    layouts.keys().map(key => app.component(key.split('/').pop().split('.')[0], layouts(key).default));
    const components = require.context('./components/', true, /\.vue$/i);
    components.keys().map(key => app.component(key.split('/').pop().split('.')[0], components(key).default));
  },

  packagesInit(app) {
    app.use(TextareaAutosize)
    app.directive('linkified', linkify);
    app.component('ValidationProvider', ValidationProvider);
    app.component('ValidationObserver', ValidationObserver);
    app.component('SlickList', SlickList);
    app.component('SlickItem', SlickItem);
    app.directive('handle', HandleDirective);
    app.use(Vue2Transitions)
    app.component('DatePicker', DatePicker)
    app.component('ColorPicker', Chrome)
    app.use(vClickOutside)
    app.use(VueMeta)
    app.use(Croppa)
    Ripple.color = 'rgba(255, 255, 255, 0.2)';
    app.directive('ripple', Ripple);
    app.use(VueLazyload, {
      loading: require('@/__vue2stone_cms/img/loading.gif')
    })
  },

  mixinInit(app) {
    app.mixin(mixin)
  }
}
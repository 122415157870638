<template>
  <div class="app-qc-stages">
    <div
      v-for="(stage,stageIndex) in value"
      :key="stageIndex"
      class="app-qc-stage"
      @click="$_onStageClick(stageIndex)"
    >
      <WsIcon
        class="pick-icon"
        :class="{active:stage.qc}"
      >check_box</WsIcon>
      <WsIcon
        class="pick-icon"
        :class="{active:!stage.qc}"
      >check_box_outline_blank</WsIcon>
      <div class="app-qc-stage__name">{{stage.name}}</div>
    </div>
  </div>
</template>

<script>
export default {
  methods: {
    $_onStageClick(stageIndex) {
      const _value = this.value ? JSON.parse(JSON.stringify(this.value)) : [];
      _value[stageIndex].qc = !_value[stageIndex].qc;
      this.$emit("input", _value);
    },
    $_pickedCheck(stage) {
      if (!this.value) {
        return false;
      } else {
        if (this.value.includes(stage.id)) {
          return true;
        } else {
          return false;
        }
      }
    },
  },
  props: {
    value: {
      type: Array,
      default: null,
    },
  },
};
</script>

<style>
</style>
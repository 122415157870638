<template>
  <div>{{_value}}</div>
</template>

<script>
export default {
  computed: {
    _value() {
      if (!this.value) {
        return null;
      } else {
        return this.$moment(this.value).format("HH:mm:ss");
      }
    },
  },
  props: {
    value: {
      type: String,
      default: null,
    },
  },
};
</script>

<style>
</style>
<template>
  <div
    id="app"
    v-cloak
  >
    <LayoutAuth v-if="_template=='auth'" />
    <LayoutApp v-else-if="_template=='app'&&$store.state.auth.currentUser" />
  </div>
</template>

<script>
const appName = process.env.VUE_APP_APP_NAME;
export default {
  metaInfo() {
    return {
      title: appName,
      titleTemplate: `%s | ${appName}`,
    };
  },

  computed: {
    _template() {
      if (this.$route.meta && this.$route.meta.template) {
        return this.$route.meta.template;
      } else {
        return "app";
      }
    },
  },

  watch: {
    "$route.name": {
      handler() {
        this.$store.dispatch("app/menuClose");
      },
    },
  },
};
</script>

<style lang="scss">
@import "@/__vue2stone_cms/scss/app";
@import "@/scss/app";
</style>
<template>
  <WsMain
    v-if="modelData"
  >
    <WsBreadcrumbs :menu="_breadcrumbsItems"></WsBreadcrumbs>
    <div class="ws-content-section">
      <ValidationObserver
        ref="form"
        class="ws-content-section-observer"
      >
        <form
          ref="updateForm"
          @submit.prevent
        >
          <div class="ws-content-section">
            <div class="ws-content-section__primary">
              <WsStateContentBlock
                v-for="(item,itemKey) in primary"
                :key="itemKey"
                :modelData="modelData"
                :type="item.type"
                :label="item.label"
                :title="item.title"
                :remark="item.remark"
                :floors="item.floors"
                :modedFields="fields"
                :fields="item.fields"
                :field="item.field"
                :modelName="item.modelName"
                :modeVersions="item.modeVersions"
                :modeParent="item.modeParent"
                :customButtons="item.customButtons"
                :fieldsRead="item.fieldsRead"
                :updateData="updateData"
                :parentModelName="$_getParentModelName(item)"
              ></WsStateContentBlock>
            </div>
            <div class="ws-content-section__secondary">
              <WsStateContentBlock
                v-for="(item,itemKey) in secondary"
                :key="itemKey"
                :modelData="modelData"
                :type="item.type"
                :label="item.label"
                :title="item.title"
                :remark="item.remark"
                :floors="item.floors"
                :modedFields="fields"
                :fields="item.fields"
                :field="item.field"
                :modelName="item.modelName"
                :modeVersions="item.modeVersions"
                :modeParent="item.modeParent"
                :customButtons="item.customButtons"
                :fieldsRead="item.fieldsRead"
                :updateData="updateData"
                :parentModelName="$_getParentModelName(item)"
              ></WsStateContentBlock>
            </div>
          </div>
        </form>
      </ValidationObserver>
    </div>
    <WsBottomNav>
      <template v-slot:rightActions>
        <WsBtn
          @click="$router.go(-1)"
          outlined
        >取消</WsBtn>
        <WsBtn
          class="ml-8"
          :loading="loading.submit"
          @click="$_onSubmit()"
        >送出</WsBtn>
      </template>
    </WsBottomNav>
  </WsMain>
</template>

<script>
export default {
  data: () => ({
    isMounted: false,
    modelData: null,
    updateData: {},
    loading: {
      submit: false,
    },
  }),
  methods: {
    loadingStart() {
      this.loading.submit = true;
    },
    loadingStop() {
      this.loading.submit = false;
    },
    async $_onSubmit() {
      const isValidate = await this.$refs.form.validate();
      if (!isValidate) {
        return;
      }
      if (this.emitSubmit) {
        this.$emit("submit", this.updateData);
        return;
      }
      this.loading.submit = true;
      try {
        const postData = JSON.parse(JSON.stringify(this.updateData));
        const res = await this.$axios.patch(
          `/${this.modelName}/${this.id}`,
          postData
        );
        if (this.fields.last_version) {
          if (this.modelData.last_version) {
            try {
              await this.$axios.patch(
                `/${this._versionModelName}/${this.modelData.last_version.id}`,
                this.updateData.last_version
              );
              this.loading.submit = false;
              this.$store.dispatch("app/stopNeedSave");
              if (this.$route.query && this.$route.query.redirect) {
                this.$router.push(this.$route.query.redirect);
              } else {
                this.$router.push(`/${this.modelName}/${this.id}`);
              }
            } catch (error) {
              alert("更新發生錯誤");
              this.loading.submit = false;
            }
          } else {
            try {
              await this.$axios.post(
                `/${this.modelName}/${res.data.data.id}/${this._versionModelName}`,
                this.updateData.last_version
              );
              this.loading.submit = false;
              this.$store.dispatch("app/stopNeedSave");
              if (this.$route.query && this.$route.query.redirect) {
                this.$router.push(this.$route.query.redirect);
              } else {
                this.$router.push(`/${this.modelName}/${this.id}`);
              }
            } catch (error) {
              alert("更新發生錯誤");
              this.loading.submit = false;
            }
          }
        } else {
          this.$store.dispatch("app/stopNeedSave");
          if (this.$route.query && this.$route.query.redirect) {
            this.$router.push(this.$route.query.redirect);
          } else {
            this.$router.push(`/${this.modelName}/${this.id}`);
          }
        }
      } catch (error) {
        alert(error);
        this.loading.submit = false;
      }
    },
    $_getParentModelName(primaryItem) {
      if (primaryItem.modeVersions) {
        return `${this.modelName}_version`;
      } else {
        return this.modelName;
      }
    },
    async $_fetchData() {
      this.$store.dispatch("app/startPageLoading");
      let getUrl = `/${this.modelName}/${this.id}`;
      try {
        const res = await this.$axios.get(getUrl);
        this.modelData = res.data.data;
        this.$emit("modelDataInit", this.modelData);
        this.$_CMS_updateDataSetFromFields(
          this,
          this.updateData,
          this.fields,
          this.modelData
        );
        setTimeout(() => {
          this.isMounted = true;
        }, 0);
      } catch (error) {
        alert(error);
      } finally {
        this.$store.dispatch("app/stopPageLoading");
      }
    },
  },
  computed: {
    _title() {
      if (!this.modelData) {
        return this.label;
      } else {
        return this.$_CMS_getValueByFieldKey(this.titleKey, this.modelData);
      }
    },
    _breadcrumbsItems() {
      const _breadcrumbsItems = [];
      _breadcrumbsItems.push({
        text: this.label,
        to: `/${this.modelName}`,
        disabled: false,
      });
      _breadcrumbsItems.push({
        text: this._title,
        to: `/${this.modelName}/${this.id}`,
        disabled: false,
      });
      _breadcrumbsItems.push({
        text: "編輯",
        disabled: true,
      });
      return _breadcrumbsItems;
    },
    _backRouteText() {
      if (this.backRouteText) {
        return "返回";
      } else {
        return `${this.label}列表`;
      }
    },
    _backRoute() {
      if (this.backRoute) {
        return this.backRoute;
      } else {
        return `/${this.modelName}`;
      }
    },
    _versionModelName() {
      return `${this.modelName}_version`;
    },
  },
  props: {
    emitSubmit: {
      type: Boolean,
      default: false,
    },
    titleKey: {
      type: String,
      default: "text",
    },
    backRouteText: {
      type: String,
      default: "返回",
    },
    backRoute: {
      type: String,
      default: null,
    },
    primary: {
      type: Array,
      default() {
        return [];
      },
    },
    secondary: {
      type: Array,
      default() {
        return [];
      },
    },
    modelName: String,
    label: String,
    fields: Object,
    id: {
      type: [String, Number],
      default: null,
    },
  },
  mounted() {
    this.$_fetchData();
  },
  watch: {
    updateData: {
      handler() {
        this.$emit("input", this.updateData);
        if (this.isMounted) {
          this.$store.dispatch("app/startNeedSave");
        }
      },
      deep: true,
    },
  },
};
</script>
<template>
  <div
    class="app-quotation"
    ref="quotation"
  >
    <div class="quotation-title">山葵組設計股份有限公司</div>
    <div class="quotation-title">報價單</div>
    <img
      src="@/assets/image/member_bottom_line.png"
      alt=""
      class="quotation-line"
    >
    <h1>一、聯絡資訊</h1>
    <div class="info-grid">
      <div class="quotation-info">
        <div class="info-title">專案名稱</div>
        <div class="info-content">{{name}}</div>
      </div>
      <div
        class="quotation-info"
        v-if="company_name"
      >
        <div class="info-title">客戶名稱</div>
        <div class="info-content">{{company_name}}</div>
      </div>
      <div class="quotation-info">
        <div class="info-title">報價日期</div>
        <div class="info-content">{{getDateText(date)}}</div>
      </div>
      <div
        class="quotation-info"
        v-if="begin_date"
      >
        <div class="info-title">執行日期</div>
        <div class="info-content">{{getDateText(begin_date)}}</div>
      </div>
      <div
        class="quotation-info"
        v-if="contact_window"
      >
        <div class="info-title">聯絡代表</div>
        <div class="info-content">{{contact_window}}</div>
      </div>
      <div
        class="quotation-info"
        v-if="creator_name"
      >
        <div class="info-title">負責人員</div>
        <div class="info-content">{{creator_name}}</div>
      </div>
      <div
        class="quotation-info"
        v-if="contact_window_email"
      >
        <div class="info-title">電子郵件</div>
        <div class="info-content">{{contact_window_email}}</div>
      </div>
    </div>
    <template v-if="excutes.length||people_list.length">
      <h1>二、報價（新台幣）</h1>
      <div
        class="quotation-items-section"
        v-if="excutes.length"
      >
        <div class="quotation-items-title-section">
          <div>項目</div>
          <div>工作內容</div>
          <div>工作天數</div>
          <div class="align-right">單價</div>
        </div>
        <div class="quotation-items">
          <div
            class="quotation-item"
            v-for="(excutes_e,excutes_k) in excutes"
            :key="excutes_k"
          >
            <div>{{excutes_e.name}}</div>
            <div>{{excutes_e.content}}</div>
            <div>{{excutes_e.excute_days}}</div>
            <div class="right">{{getDollarFormat(excutes_e.price)}}</div>
          </div>
        </div>
        <div
          v-if="need_total_amount"
          class="quotation-calcs"
        >
          <div class="quotation-calc">
            <div>小計</div>
            <div>{{getDollarFormat(getItemTotalPrice(excutes))}}</div>
          </div>
          <div class="quotation-calc">
            <div>營業稅</div>
            <div>{{getDollarFormat(getItemTotalPrice(excutes)*0.05)}}</div>
          </div>
          <div class="quotation-calc">
            <div>總計</div>
            <div>{{getDollarFormat(getItemTotalPrice(excutes)*1.05)}}</div>
          </div>
        </div>
      </div>
      <div
        class="quotation-items-section"
        v-if="people_list.length"
      >
        <div class="quotation-items-title-section people">
          <div>項目</div>
          <div>工作內容</div>
          <div>數量</div>
          <div class="align-right">單價</div>
        </div>
        <div class="quotation-items">
          <div
            class="quotation-item people"
            v-for="(people_list_e,people_list_k) in people_list"
            :key="people_list_k"
          >
            <div>{{people_list_e.name}}</div>
            <div>{{people_list_e.content}}</div>
            <div class="right">{{people_list_e.count}}</div>
            <div class="right">{{getDollarFormat(people_list_e.price)}} / 人日</div>
          </div>
        </div>
        <div
          v-if="need_total_amount"
          class="quotation-calcs"
        >
          <div class="quotation-calc">
            <div>小計</div>
            <div>{{getDollarFormat(getPeopleTotalPrice(people_list))}}</div>
          </div>
          <div class="quotation-calc">
            <div>營業稅</div>
            <div>{{getDollarFormat(getPeopleTotalPrice(people_list)*0.05)}}</div>
          </div>
          <div class="quotation-calc">
            <div>總計</div>
            <div>{{getDollarFormat(getPeopleTotalPrice(people_list)*1.05)}}</div>
          </div>
        </div>
      </div>
    </template>
    <template v-if="descriptions.length">
      <h1>三、其他說明</h1>
      <ul class="remarks-section">
        <li
          v-for="(descriptions_e,descriptions_index) in descriptions"
          :key="descriptions_index"
        >{{descriptions_e.content}}</li>
      </ul>
    </template>
    <template v-if="has_return_sign">
      <h1>四、客戶回簽欄</h1>
      <div class="signature">
        <div class="line"></div>
        <p>（簽章）</p>
      </div>
    </template>
    <div class="footer">
      <div class="logo">
        <img
          :src="bottom_logo"
          alt=""
        >
      </div>
      <div class="info-sec">
        <div class="quotation-info">
          <div class="info-title">統&nbsp;&nbsp;編</div>
          <div class="info-content">52243963</div>
        </div>
        <div class="quotation-info">
          <div class="info-title">電&nbsp;&nbsp;話</div>
          <div class="info-content">02-2928-0133</div>
        </div>
      </div>
      <div class="info-sec">
        <div class="quotation-info">
          <div class="info-title">Email</div>
          <div class="info-content">hello@wasateam.com</div>
        </div>
        <div class="quotation-info">
          <div class="info-title">地&nbsp;&nbsp;址</div>
          <div class="info-content">104070 台北市中山區松江路 315 號 2 樓 D3 室</div>
        </div>
      </div>
    </div>
    <img
      :src="bottom_wasajon"
      alt=""
      class="wasajon"
    >
  </div>
</template>

<script>
export default {
  methods: {
    getPeopleTotalPrice(list) {
      let total = 0;
      list.forEach((list_e) => {
        total += Math.round(list_e.count) * Math.round(list_e.price);
      });
      return total;
    },
    getItemTotalPrice(list) {
      let total = 0;
      list.forEach((list_e) => {
        total += Math.round(list_e.price);
      });
      return total;
    },
    getDollarFormat(price) {
      return this.$numeral(price).format("$ 0,0");
    },
    getDateText(date, extend = 0) {
      if (!date) {
        return null;
      }
      let date_tar = new Date(date);
      date_tar.setDate(date_tar.getDate() + extend);
      return (
        date_tar.getFullYear() +
        "年" +
        (date_tar.getMonth() + 1) +
        "月" +
        date_tar.getDate() +
        "日"
      );
    },
  },
  props: {
    bottom_wasajon: {
      type: String,
      default: require("@/assets/image/wasajon-2.png"),
    },
    bottom_logo: {
      type: String,
      default: require("@/assets/image/logo_green.png"),
    },
    name: {
      type: String,
      default: null,
    },
    company_name: {
      type: String,
      default: null,
    },
    creator_name: {
      type: String,
      default: null,
    },
    contact_window: {
      type: String,
      default: null,
    },
    contact_window_email: {
      type: String,
      default: null,
    },
    date: {
      type: String,
      default: null,
    },
    begin_date: {
      type: String,
      default: null,
    },
    excutes: {
      type: Array,
      default() {
        return [];
      },
    },
    people_list: {
      type: Array,
      default() {
        return [];
      },
    },
    descriptions: {
      type: Array,
      default() {
        return [];
      },
    },
    has_return_sign: {
      type: [Boolean, Number],
      default: false,
    },
    need_total_amount: {
      type: [Boolean, Number],
      default: false,
    },
  },
};
</script>
<template>
  <div
    class="ws-state-select"
    :class="[{active:menuActive},{'is-error':errors&&errors.length},{'is-disabled':_disabled},_type]"
    v-click-outside="$_onClickOutside"
  >
    <a
      href="#"
      @click.prevent="$_onOpenClick()"
      class="ws-state-select__button"
    >
      <div
        v-if="value||value==0"
        class="ws-state-select__button-text"
      >{{_valueText}}</div>
      <div
        v-else
        class="ws-state-select__placeholder"
      >{{placeholder}}</div>
      <WsIcon>expand_more</WsIcon>
    </a>
    <div
      v-if="menuActive"
      class="ws-state-select__menu"
    >
      <div class="ws-state-select__options">
        <a
          v-for="(item,itemIndex) in items"
          :key="itemIndex"
          href="#"
          @click.prevent="$_onOptionClick(item)"
          :class="{active:$_activeCheck(item)}"
        >
          <div
            v-show="multiple"
            class="checkbox"
          >
            <WsIcon :class="{active:$_activeCheck(item)}">check_box</WsIcon>
            <WsIcon :class="{active:!$_activeCheck(item)}">check_box_outline_blank</WsIcon>
          </div>
          <p>{{item.text}}</p>
        </a>
      </div>
    </div>
  </div>
</template>

<script>
export default {
  data: () => ({
    menuActive: false,
  }),
  methods: {
    $_onOpenClick() {
      this.menuActive = !this.menuActive;
    },
    $_activeCheck(item) {
      if (!this.value) {
        return;
      }
      if (this.multiple) {
        return this.value.includes(item.value);
      } else {
        return this.value == item.value;
      }
    },
    $_onOptionClick(item) {
      if (this.multiple) {
        const _value = this.value ? JSON.parse(JSON.stringify(this.value)) : [];
        const itemIndex = _value.findIndex((e) => {
          return e == item.value;
        });
        if (itemIndex >= 0) {
          _value.splice(itemIndex, 1);
        } else {
          _value.push(item.value);
        }
        this.$emit("input", _value);
      } else {
        this.$emit("input", item.value);
        this.menuActive = false;
      }
    },
    $_onClickOutside() {
      this.menuActive = false;
    },
  },
  computed: {
    _type() {
      if (this.light) {
        return "light";
      } else {
        return "round";
      }
    },
    _disabled() {
      if (this.parent && !this.parentState) {
        return true;
      } else if (
        this.requiredField &&
        (!this.requiredFieldState || this.requiredFieldState.length == 0)
      ) {
        return true;
      } else {
        return false;
      }
    },
    _valueText() {
      if (this.value === undefined) {
        return null;
      } else {
        if (this.multiple) {
          let _valueText = "";
          this.value.forEach((valueItem) => {
            const tarItem = this.items.find((e) => {
              return e.value == valueItem;
            });
            if (tarItem) {
              if (_valueText) {
                _valueText += `, ${tarItem.text}`;
              } else {
                _valueText += tarItem.text;
              }
            }
          });
          return _valueText;
        } else {
          const tarItem = this.items.find((e) => {
            return e.value == this.value;
          });
          if (!tarItem) {
            return null;
          } else {
            return tarItem.text;
          }
        }
      }
    },
  },
  props: {
    light: {
      type: Boolean,
      default: false,
    },
    errors: {
      type: Array,
      default: null,
    },
    multiple: {
      type: Boolean,
      default: false,
    },
    round: {
      type: Boolean,
      default: true,
    },
    placeholder: {
      type: String,
      default: "select",
    },
    value: {
      type: [Number, String, Array, RegExp],
      default: null,
    },
    items: {
      type: Array,
      required: true,
    },
    requiredField: {
      default: null,
    },
    requiredFieldState: {
      default: null,
    },
  },
};
</script>
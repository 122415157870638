<template>
  <div>
    <WsUpdate
      :modelName="modelName"
      :id="_id"
      :label="label"
      :fields="fields"
      :primary="pageSetting.primary"
      :secondary="pageSetting.secondary"
      titleKey="name"
      v-model="updateData"
    >
    </WsUpdate>
  </div>
</template>

<script>
import model from "@/__vue2stone_cms/models/tulpa_section_template";
export default {
  metaInfo() {
    return {
      title: "編輯區塊公版",
    };
  },
  data: () => ({
    updateData: null,
    modelName: model.modelName,
    label: model.label,
    fields: model.fields,
    pageSetting: {
      primary: [
        {
          type: "stateCard",
          floors: [
            {
              fields: ["name", "component_name", "fields"],
            },
          ],
        },
      ],
      secondary: [
        {
          type: "stateCard",
          floors: [
            {
              title: "設定",
              fields: ["remark"],
            },
          ],
        },
      ],
    },
  }),
  computed: {
    _id() {
      return this.$route.params.id;
    },
  },
};
</script>

<style>
</style>
<template>
  <div>
    <WsCreate
      :modelName="modelName"
      :label="label"
      :fields="fields"
      :primary="pageSetting.primary"
      :secondary="pageSetting.secondary"
      :afterSubmitAction="$_afterSubmitAction"
      v-model="updateData"
    >
    </WsCreate>
  </div>
</template>

<script>
import model from "@/models/project";
export default {
  metaInfo() {
    return {
      title: "建立專案",
    };
  },
  data: () => ({
    updateData: null,
    modelName: model.modelName,
    label: model.label,
    fields: model.fields,
    pageSetting: {
      primary: [
        {
          type: "stateCard",
          floors: [
            {
              fields: [
                "name",
                "remark",
                "noun",
                "relate_links",
                "relate_infos",
              ],
            },
          ],
        },
      ],
      secondary: [
        {
          type: "stateCard",
          floors: [
            {
              title: "設定",
              fields: ["url_id", "slack_webhook_url", "wasas"],
            },
          ],
        },
        {
          type: "stateCard",
          floors: [
            {
              title: "連結",
              fields: [
                "slack_channel",
                "zeplin",
                "gitlab_wasa",
                "gitlab_client",
                "google_drive_wasa",
                "google_drive_client",
              ],
            },
          ],
        },
        {
          type: "stateCard",
          floors: [
            {
              title: "狀態",
              fields: ["status"],
              hideLabelFields: ["status"],
            },
          ],
        },
      ],
    },
    evaluation: null,
  }),
  methods: {
    async $_afterSubmitAction(model) {
      try {
        await this.$axios.patch(`evaluation/${this._evaluationId}`, {
          project: model.id,
        });
      } catch (error) {
        alert("綁定評估錯誤");
      }
    },
    $_setUpdateDataFromEvaluation() {
      this.updateData.name = this.evaluation.name;
    },
    async $_evaluationFetch() {
      const res = await this.$axios.get(`evaluation/${this._evaluationId}`);
      this.evaluation = res.data.data;
      this.$_setUpdateDataFromEvaluation();
    },
  },
  computed: {
    _evaluationId() {
      if (!this.$route.query && !this.$route.query.evaluation) {
        return null;
      } else {
        return this.$route.query.evaluation;
      }
    },
  },
  mounted() {
    if (this._evaluationId) {
      this.$_evaluationFetch();
    }
  },
};
</script>

<style>
</style>
<template>
  <div
    class="ws-app-header"
    :class="[{'has-drawer':hasDrawer}]"
  >
    <div
      @click="$_onMenuBtnClick()"
      class="menu-btn"
    >
      <WsIcon size="24">menu</WsIcon>
    </div>
    <WsSpacer></WsSpacer>
    <WsAppHeaderButton v-if="$config.locale.active">Language</WsAppHeaderButton>
    <WsAvatar
      v-if="_avatarShow"
      :src="_avatar"
      :to="userLink"
    ></WsAvatar>
  </div>
</template>

<script>
export default {
  data: () => ({
    hasDrawer: true,
  }),

  methods: {
    $_onMenuBtnClick() {
      this.$store.dispatch("app/menuToggle");
    },
  },

  computed: {
    _avatarShow() {
      if (this.$config.header === undefined) {
        return true;
      }
      if (this.$config.header && this.$config.header.avatar === undefined) {
        return true;
      }
      if (this.$config.header && !this.$config.header.avatar) {
        return false;
      }
      return false;
    },
    _avatar() {
      if (!this._user) {
        return null;
      } else {
        return this._user.avatar;
      }
    },
    _user() {
      return this.$store.state.auth.currentUser;
    },
  },

  props: {
    userLink: {
      type: String,
      default: "/user",
    },
  },
};
</script>
<template>
  <div class="ws-full-calendar">
    <div
      v-if="$slots.searchSection"
      class="ws-full-calendar__search-section mr-20 sm-mr-0"
    >
      <slot name="searchSection"></slot>
    </div>
    <FullCalendar
      ref="FullCalendar"
      @datesRender="$emit('date-rander',$event)"
      @eventDragStart="$emit('dragstart',$event)"
      @eventResize="$emit('resize',$event)"
      @eventDrop="$emit('drop',$event)"
      @eventClick="$emit('click',$event)"
      @dateClick="$emit('date-click',$event)"
      :defaultView="defaultView"
      :locale="locale"
      :height="height"
      :selectable="false"
      :editable="updatable"
      :droppable="true"
      dropAccept=".ws-task-list-task-item"
      :eventDurationEditable="eventDurationEditable"
      :buttonText="buttonText"
      :allDayText="allDayText"
      :minTime="minTime"
      :maxTime="maxTime"
      :header="header"
      :weekends="calendarWeekends"
      :events="events"
      :plugins="fullCalendar.calendarPlugins"
    />
  </div>
</template>

<script>
import FullCalendar from "@fullcalendar/vue";
import dayGridPlugin from "@fullcalendar/daygrid";
import timeGridPlugin from "@fullcalendar/timegrid";
import interactionPlugin from "@fullcalendar/interaction";
export default {
  data: () => ({
    fullCalendar: {
      calendarPlugins: [dayGridPlugin, timeGridPlugin, interactionPlugin],
    },
  }),
  methods: {
    $_keyupHandler($event) {
      if ($event.key == "/") {
        let calendarApi = this.$refs.FullCalendar.getApi();
        calendarApi.next();
      }
      if ($event.key == ".") {
        let calendarApi = this.$refs.FullCalendar.getApi();
        calendarApi.prev();
      }
    },
  },
  props: {
    events: {
      type: Array,
      default: null,
    },
    minTime: {
      type: String,
      default: "09:00:00",
    },
    maxTime: {
      type: String,
      default: "18:00:00",
    },
    header: {
      type: Object,
      default() {
        return {
          left: "prev,next today",
          center: "title",
          right: "dayGridMonth,timeGridWeek,timeGridDay",
        };
      },
    },
    calendarWeekends: {
      type: Boolean,
      default: true,
    },
    allDayText: {
      type: String,
      default: "Miles",
    },
    defaultView: {
      type: String,
      default: "timeGridWeek",
    },
    locale: {
      type: String,
      default: "zh-tw",
    },
    height: {
      type: [String, Number],
      default: "auto",
    },
    updatable: {
      type: Boolean,
      default: false,
    },
    eventDurationEditable: {
      type: Boolean,
      default: false,
    },
    buttonText: {
      type: Object,
      default() {
        return {
          today: "今天",
          month: "月",
          week: "週",
          day: "日",
          list: "列表",
        };
      },
    },
  },
  components: {
    FullCalendar,
  },
  created() {
    window.addEventListener("keyup", this.$_keyupHandler);
  },
  beforeDestroy() {
    window.removeEventListener("keyup", this.$_keyupHandler);
  },
};
</script>
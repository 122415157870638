<template>
  <div class="ws-state-fields">
    <div class="ws-row full-width">
      <div class="ws-col col-6">
        <WsBtn @click="$_onCreate()">新增欄位</WsBtn>
      </div>
      <slot name="extendCols"></slot>
    </div>
    <WsCrudTable
      class="mt-10"
      :showFields="showFields"
      :fields="_fields"
      :items="_items"
      :paginate="false"
      :showExpand="false"
      :expandable="false"
      :inRowBtnRead="true"
      :inRowBtnUpdate="true"
      :inRowBtnDelete="true"
      :dialogUpdate="true"
      @read="$_onRead($event)"
      @update="$_onUpdate($event)"
      @delete="$_onDelete($event)"
    ></WsCrudTable>
    <WsCreateDialog
      ref="createDialog"
      label="新增欄位"
      :fields="_fields"
      @submit="$_onCreateSubmit($event)"
    />
    <WsUpdateDialog
      ref="updateDialog"
      label="編輯欄位"
      :fields="_fields"
      @submit="$_onUpdateSubmit($event)"
    />
    <WsReadDialog
      ref="readDialog"
      :fields="_fields"
      titleKey="label"
      @delete="$_onDeleteA($event)"
    ></WsReadDialog>
    <WsAlert
      ref="deleteAlert"
      title="確定要刪除嗎？"
      description="刪除後資料將無法回覆"
      @submit="$_onDeleteSubmit($event)"
    ></WsAlert>
  </div>
</template>

<script>
export default {
  data: () => ({
    showFields: ["name", "label", "type"],
    fields: {
      name: {
        type: "text",
        label: "名稱(程式)",
        autofocus: true,
        rules: "required",
      },
      label: {
        type: "text",
        label: "名稱(瀏覽)",
        rules: "required",
      },
      type: {
        type: "select",
        label: "類型",
        items: [],
        rules: "required",
      },
    },
    typeItems: [
      {
        text: "文字",
        value: "text",
      },
      {
        text: "文字區塊",
        value: "textarea",
      },
      {
        text: "圖片",
        value: "image",
      },
    ],
    tableHeaders: [
      {
        text: "名稱(程式)",
        value: "name",
      },
      {
        text: "名稱(瀏覽)",
        value: "label",
      },
      {
        text: "類型",
        value: "type",
      },
      {
        text: "",
        value: "actions",
      },
    ],
    stateFieldsList: {
      text: ["placeholder", "rules"],
      textarea: ["placeholder", "rules"],
      image: ["rules", "uploadPath"],
    },
    focusIndex: -1,
  }),

  methods: {
    arrayFieldsToObjectFields(arrayFields) {
      const _objectFields = {};
      arrayFields.forEach((arrayField) => {
        _objectFields[arrayField.name] = {
          label: arrayField.label,
          type: arrayField.type,
        };
      });
      return _objectFields;
    },
    $_onRead($event) {
      const _index = $event.itemIndex;
      const _item = this._arrayFields[_index];
      this.focusIndex = _index;
      this.$refs.readDialog.open(_item);
    },
    $_onUpdate($event) {
      console.log($event);
      const _index = $event.itemIndex;
      const _item = this._arrayFields[_index];
      this.focusIndex = _index;
      this.$refs.updateDialog.open(_item);
    },
    $_onDeleteA($event){
      console.log($event);
    },
    $_onDelete($event) {
      this.$refs.deleteAlert.open($event);
    },
    $_validate(state) {
      let status = 1;
      let message = "";
      const _name = state.name;
      for (let fieldKey in this.value) {
        if (fieldKey == _name) {
          status = 0;
          message = this.$message["name has been used."];
        }
      }
      return {
        status: status,
        message: message,
      };
    },
    $_onCreateSubmit($event) {
      const _validate = this.$_validate($event);
      if (!_validate.status) {
        this.$refs.createDialog.error(_validate.message);
        return;
      }
      const _value = this._arrayFields
        ? JSON.parse(JSON.stringify(this._arrayFields))
        : [];
      _value.push($event);
      this.$emit("input", this.arrayFieldsToObjectFields(_value));
      this.$refs.createDialog.close();
    },
    $_onUpdateSubmit($event) {
      const _validate = this.$_validate($event);
      if (!_validate.status) {
        this.$refs.updateDialog.error(_validate.message);
        return;
      }
      const _value = this._arrayFields
        ? JSON.parse(JSON.stringify(this._arrayFields))
        : [];
      _value[this.focusIndex] = $event;
      this.$emit("input", this.arrayFieldsToObjectFields(_value));
      this.focusIndex = -1;
      this.$refs.updateDialog.close();
    },
    $_onDeleteSubmit($event) {
      const _index = $event.itemIndex;
      const _value = this._arrayFields
        ? JSON.parse(JSON.stringify(this._arrayFields))
        : [];
      _value.splice(_index, 1);
      this.$emit("input", this.arrayFieldsToObjectFields(_value));
      this.$refs.deleteAlert.close();
    },
    $_onCreate() {
      this.$refs.createDialog.open();
    },
  },

  computed: {
    _arrayFields() {
      const _arrayFields = [];
      for (let fieldKey in this.value) {
        const _field = this.value[fieldKey];
        _arrayFields.push({
          name: fieldKey,
          label: _field.label,
          type: _field.type,
          placeholder: _field.placeholder,
          items: _field.items,
          textKey: _field.textKey,
          rules: _field.rules,
          activeText: _field.activeText,
          inactiveText: _field.inactiveText,
          uploadPath: _field.uploadPath,
          maxWidth: _field.maxWidth,
          maxHeight: _field.maxHeight,
        });
      }
      return _arrayFields;
    },

    _fields() {
      const _fields = JSON.parse(JSON.stringify(this.fields));
      _fields.type.items = this.typeItems;
      _fields.type.defaultValue = this.typeItems[0].value;
      return _fields;
    },

    _items() {
      if (!this._arrayFields) {
        return null;
      } else {
        const _items = [];
        this._arrayFields.forEach((valueItem) => {
          _items.push({
            name: valueItem.name,
            label: valueItem.label,
            type: valueItem.type,
            placeholder: valueItem.placeholder,
            items: valueItem.items,
            textKey: valueItem.textKey,
            rules: valueItem.rules,
            activeText: valueItem.activeText,
            inactiveText: valueItem.inactiveText,
            uploadPath: valueItem.uploadPath,
            maxWidth: valueItem.maxWidth,
            maxHeight: valueItem.maxHeight,
          });
        });
        return _items;
      }
    },
  },

  props: {
    label: {
      type: String,
      default: null,
    },
    value: {
      type: Object,
      default: null,
    },
  },
};
</script>
export default {
  namespaced: true,
  state: {
    checkExcuteTypes: [
      {
        id: 13,
        name: "SR",
      },
      {
        id: 14,
        name: "DR",
      },
      {
        id: 15,
        name: "CR",
      },
      {
        id: 16,
        name: "QC",
      },
      {
        id: 17,
        name: "PM",
      },
    ],
  },
  mutations: {
  },
  actions: {
  }
}

<template>
  <WsRow>
    <WsCol
      v-for="(setting,settingIndex) in value"
      :key="settingIndex"
    >
      <WsState
        type="number"
        :label="$_getExcuteTypeLabel(setting)"
        :value="setting.adjust_times"
        @input="$_onInput($event,settingIndex)"
      ></WsState>
    </WsCol>
  </WsRow>
</template>

<script>
export default {
  methods: {
    $_onInput($event, index) {
      let _value = this.value ? JSON.parse(JSON.stringify(this.value)) : [];
      _value[index].adjust_times = parseInt($event);
      this.$emit("input", _value);
    },
    $_getExcuteTypeLabel(setting) {
      if (!setting.excute_type) {
        return null;
      } else {
        const excuteType = this.excuteTypes.find((e) => {
          return e.id == setting.excute_type;
        });
        return excuteType.name;
      }
    },
  },

  computed: {
    _value() {
      const _value = [];
      this.value.forEach((valueItem) => {
        _value.push({
          excute_type: valueItem.excute_type,
          next_keep_week: valueItem.next_keep_week,
          adjust_times: valueItem.adjust_times ? valueItem.adjust_times : 0,
        });
      });
      return _value;
    },
  },

  props: {
    value: {
      type: Array,
      default: null,
    },
    excuteTypes: {
      type: Array,
      default: null,
    },
  },
};
</script>

<style>
</style>
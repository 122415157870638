<template>
  <WsMain>
    <WsCrud
      :modelName="modelName"
      :label="label"
      :fields="fields"
      :liveSearching="true"
      :showFields="showFields"
      :infiniteScroll="true"
      :expandable="false"
      :showExpand="false"
      :filterSelects="filterSelects"
      :pageMode="true"
    ></WsCrud>
  </WsMain>
</template>

<script>
import tulpa_page from "@/__vue2stone_cms/models/tulpa_page";
export default {
  metaInfo() {
    return {
      title: "頁面",
    };
  },

  data: () => ({
    modelName: "tulpa_page",
    label: tulpa_page.label,
    fields: tulpa_page.fields,
    showFields: ["id", "route", "title", "tags"],
    filterSelects: {
      status: {
        label: "狀態",
        items: [
          {
            text: "全部",
            value: "all",
            params: {},
          },
          {
            text: "待補充",
            value: 0,
            params: {
              status: 0,
            },
          },
          {
            text: "完成",
            value: 1,
            params: {
              status: 1,
            },
          },
        ],
      },
    },
  }),
};
</script>
export default {
  modelName: "accounting_subject_second",
  label: "二級科目",
  fields: {
    id: {
      type: "text",
      label: "ID",
      readonly: true
    },
    name: {
      type: "text",
      label: "名稱",
      rules: 'required',
      autofocus: true
    },
    accounting_subject_first: {
      type: "belongsTo",
      label: '一級科目',
      textKey: "name",
      modelName: "accounting_subject_first",
    }
  },
}
<template>
  <WsMain>
    <WsBtn
      @click="$_onImport()"
      :loading="importLoading"
    >匯入</WsBtn>
    <div
      v-for="(firestoreData,firestoreDataKey) in firestoreDatas"
      :key="firestoreDataKey"
    >
      {{firestoreData.id}}</div>
  </WsMain>
</template>

<script>
import { db } from "@/__vue2stone_cms/service/firebase";
export default {
  data: () => ({
    importLoading: false,
    firestoreDatas: null,
  }),
  methods: {
    async $_onImport() {
      this.importLoading = true;
      const tasks = [];
      this.firestoreDatas
        .slice()
        .reverse()
        .forEach((firestoreData, firestoreDataIndex) => {
          console.log(firestoreDataIndex);

          if (this.returnContition && this.returnContition(firestoreData)) {
            return;
          }

          const postData = this.getPostData(firestoreData);

          if (firestoreData.created_at) {
            postData.created_at = this.$moment(
              firestoreData.created_at.toDate()
            ).format("YYYY-MM-DD HH:mm:ss");
          }
          if (firestoreData.updated_at) {
            postData.updated_at = this.$moment(
              firestoreData.updated_at.toDate()
            ).format("YYYY-MM-DD HH:mm:ss");
          }
          tasks.push(this.$axios.post(this.modelName, postData));
        });

      try {
        await Promise.all(tasks);
        console.log("匯入完成");
      } catch (error) {
        console.log("error");
      } finally {
        this.importLoading = false;
      }
    },
    async $_fetchFirestoreDatas() {
      let dataRef = db.collection(this.collection);

      if (this.orderRefs) {
        dataRef = dataRef.orderBy(this.orderBy, this.orderAsc);
      }
      if (this.gg) {
        dataRef = dataRef.where("gg", "==", false);
      }

      await this.$bind("firestoreDatas", dataRef, {
        maxRefDepth: this.maxRefDepth,
      });
      console.log(this.firestoreDatas);
    },
  },
  props: {
    orderRefs: {
      type: Boolean,
      default: true,
    },
    collection: {
      type: String,
      required: true,
    },
    modelName: {
      type: String,
      required: true,
    },
    orderBy: {
      type: String,
      default: "created_at",
    },
    orderAsc: {
      type: String,
      default: "desc",
    },
    maxRefDepth: {
      type: Number,
      default: 2,
    },
    gg: {
      type: Boolean,
      default: true,
    },
    getPostData: {
      type: Function,
      required: true,
    },
    returnContition: {
      type: Function,
      default: null,
    },
  },
  mounted() {
    this.$_fetchFirestoreDatas();
  },
};
</script>

<style>
</style>
import axios from 'axios'
export default {
  getListAll(params) {
    return new Promise((resolve, reject) => {
      axios
        .get("additional_working_day/index/all", {
          params: params
        })
        .then((res) => {
          resolve(res.data.data)
        })
        .catch(() => {
          reject('取得額外工作日錯誤')
        });
    })
  },
}
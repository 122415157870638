<template>
  <div
    @click="$emit('click',$event)"
    class="ws-icon-btn"
    :style="[{width:`${size}px`},{height:`${size}px`}]"
  >
    <RouterLink
      v-if="to"
      class="ws-icon-btn__link"
      :to="to"
    ></RouterLink>
    <p v-if="text">{{_icon}}</p>
    <WsIcon
      v-else
      :size="_iconSize"
    >{{_icon}}</WsIcon>
  </div>
</template>

<script>
export default {
  computed: {
    _iconSize() {
      return this.size / 2;
    },
    _icon() {
      if (
        this.$slots &&
        this.$slots.default &&
        this.$slots.default[0] &&
        this.$slots.default[0].text
      ) {
        return this.$slots.default[0].text;
      } else {
        return null;
      }
    },
  },
  props: {
    disabled: {
      type: Boolean,
      default: false,
    },
    text: {
      type: Boolean,
      default: false,
    },
    to: {
      type: String,
      default: null,
    },
    tooltipPosition: {
      type: String,
      default: "bottom",
    },
    iconColor: {
      type: String,
      default: "textSecondary",
    },
    color: {
      type: String,
      default: "border",
    },
    size: {
      type: Number,
      default: 36,
    },
    tooltip: {
      type: String,
      default: null,
    },
  },
};
</script>
<template>
  <AppInfoEvaluation
    v-if="!_loading&&evaluation"
    :evaluation="evaluation"
    @change="$_onChange()"
  ></AppInfoEvaluation>
</template>

<script>
export default {
  metaInfo() {
    const title = this.evaluation ? this.evaluation.name : "執行評估";
    return {
      title: title,
    };
  },
  data: () => ({
    evaluation: null,
  }),
  methods: {
    $_onChange() {
      this.$_fetchEvaluation();
    },
    async $_fetchEvaluation() {
      try {
        this.evaluation = null;
        this.$store.dispatch("app/startPageLoading");
        const res = await this.$axios.get(`evaluation/${this._id}`);
        this.evaluation = res.data.data;
      } catch (error) {
        alert("取得評估錯誤");
      } finally {
        this.$store.dispatch("app/stopPageLoading");
      }
    },
  },
  mounted() {
    this.$_fetchEvaluation();
  },
  computed: {
    _loading() {
      return this.$store.state.app.pageLoading;
    },
    _id() {
      return this.$route.params.id;
    },
  },
};
</script>

<style>
</style>
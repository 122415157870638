export default {
  dark: {
    primary: '#6f9630',
    primaryLight: '#a3b87e',
    loadingBar: '#b8cc93',
    tag: '#666',
    appBar: '#000',
    bottomNav: '#000',
    body: '#303030',
    card: '#424242',
    cardReverse: '#222',
    textGray: '#808080',
    textBlack: '#4a4a4a',
    text: '#ddd',
    textReverse: '#303030',
    textSecondary: '#848fa0',
    table: '#424242',
    tableHover: '#222',
    tableExpand: '#333',
    tablePicked: '#111',
    alert: '#f04526',
    good: '#41cf5f',
    border: '#888',
    disabled: '#000',
    info: '#272727',
    link: '#14ffb5',
    primaryReverse: '#eee',
    btnCover: "#fff",
    navigation: '#1d1d1d',
    navigationHover: '#3d3d3d',
    navigationActive: '#444',
    inputBackground: '#000',
    selectMenu: '#000',
    selectMenuHover: '#333',
    selectMenuFocus: '#333',
    dialog: '#00131d',
    white: '#fff',
    added: '#7bc9f3',
    removed: '#fa9a88',
    tab: '#1d1d1d',
    tabActive: '#444',
    tabHover: '#3d3d3d',
  },
  light: {
    theme: '#b8cc93',
    loadingBar: '#b8cc93',
    appBar: '#000',
  }
}
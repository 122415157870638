<template>
  <WsMain>
    <WsBreadcrumbs :menu="_breadcrumbsItems"></WsBreadcrumbs>
    <WsFlex class="mt-20">
      <WsTitle class="flex-0">{{_projectName}}</WsTitle>
      <WsSpacer></WsSpacer>
      <WsBtn :to="`/project/${_id}/update`">編輯</WsBtn>
    </WsFlex>
    <WsTabsContent
      v-if="project"
      class="mt-20"
      :tabs="_tabs"
      :routePre="`/project/${_id}/`"
    >
      <WsCard>
        <WsInfo
          :label="fields.remark.label"
          :type="fields.remark.type"
          :items="fields.remark.items"
          :value="this.project.remark"
        ></WsInfo>
        <WsInfo
          :label="fields.noun.label"
          :type="fields.noun.type"
          :items="fields.noun.items"
          :showFields="fields.noun.showFields"
          :fields="fields.noun.fields"
          :value="this.project.noun"
        ></WsInfo>
        <WsInfo
          :label="fields.relate_links.label"
          :type="fields.relate_links.type"
          :items="fields.relate_links.items"
          :showFields="fields.relate_links.showFields"
          :fields="fields.relate_links.fields"
          :value="this.project.relate_links"
        ></WsInfo>
        <WsInfo
          :label="fields.relate_infos.label"
          :type="fields.relate_infos.type"
          :items="fields.relate_infos.items"
          :showFields="fields.relate_infos.showFields"
          :fields="fields.relate_infos.fields"
          :value="this.project.relate_infos"
        ></WsInfo>
        <WsInfo
          :label="fields.creator.label"
          :type="fields.creator.type"
          :items="fields.creator.items"
          :value="this.project.creator"
        ></WsInfo>
        <WsInfo
          :label="fields.wasas.label"
          :type="fields.wasas.type"
          :items="fields.wasas.items"
          :value="this.project.wasas"
        ></WsInfo>
        <WsInfo
          :label="fields.status.label"
          :type="fields.status.type"
          :items="fields.status.items"
          :value="this.project.status"
        ></WsInfo>
        <WsInfo
          :label="fields.slack_channel.label"
          :type="fields.slack_channel.type"
          :value="this.project.slack_channel"
        ></WsInfo>
        <WsInfo
          :label="fields.zeplin.label"
          :type="fields.zeplin.type"
          :value="this.project.zeplin"
        ></WsInfo>
        <WsInfo
          :label="fields.gitlab_wasa.label"
          :type="fields.gitlab_wasa.type"
          :value="this.project.gitlab_wasa"
        ></WsInfo>
        <WsInfo
          :label="fields.gitlab_client.label"
          :type="fields.gitlab_client.type"
          :value="this.project.gitlab_client"
        ></WsInfo>
        <WsInfo
          :label="fields.google_drive_wasa.label"
          :type="fields.google_drive_wasa.type"
          :value="this.project.google_drive_wasa"
        ></WsInfo>
        <WsInfo
          :label="fields.google_drive_client.label"
          :type="fields.google_drive_client.type"
          :value="this.project.google_drive_client"
        ></WsInfo>
      </WsCard>
    </WsTabsContent>
  </WsMain>
</template>

<script>
import model from "@/models/project";
export default {
  metaInfo() {
    const title = this.project ? this.project.name : "專案";
    return {
      title: title,
    };
  },
  data: () => ({
    fields: model.fields,
    project: null,
  }),
  methods: {
    async $_fetchProject() {
      const res = await this.$axios.get(`/project/${this._id}`);
      this.project = res.data.data;
    },
    isScopeValid(validScopes, userScopes) {
      const valid = validScopes.filter((value) => userScopes.includes(value))
        .length;
      return valid;
    },
  },
  computed: {
    _tabs() {
      const _tabs = [
        {
          text: "總覽",
          to: "overview",
        },
      ];
      const isValid = this.isScopeValid(
        ["admin", "boss", "pm"],
        this.$store.state.auth.scopes
      );
      if (isValid) {
        _tabs.push({
          text: "Task",
          to: "task",
        });
        _tabs.push({
          text: "分析",
          to: "analytics",
        });
        // _tabs.push({
        //   text: "Task調整",
        //   to: "adjust",
        // });
      }
      return _tabs;
    },
    _projectName() {
      return this.project ? this.project.name : null;
    },
    _id() {
      return this.$route.params.id;
    },
    _breadcrumbsItems() {
      const _breadcrumbsItems = [];
      _breadcrumbsItems.push(
        ...[
          {
            text: "專案",
            to: "/project",
          },
        ]
      );
      if (this.project) {
        _breadcrumbsItems.push({
          text: this.project.name,
          disabled: true,
        });
      }
      return _breadcrumbsItems;
    },
  },
  mounted() {
    this.$_fetchProject();
  },
};
</script>

<style>
</style>
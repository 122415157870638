<template>
  <WsPopup
    :title="title"
    max-width="720"
    ref="alertPopup"
  >
    <template v-slot:content>
      {{description}}
    </template>
    <template v-slot:rightActions>
      <WsBtn
        @click="$_onCancel()"
        minWidth="100px"
        color="textSecondary"
        outlined
      >取消</WsBtn>
      <WsBtn
        @click="$_onSubmit()"
        minWidth="100px"
        alert
        :loading="loading"
      >確認刪除</WsBtn>
    </template>
  </WsPopup>
</template>

<script>
export default {
  data: () => ({
    loading: false,
    target: null,
  }),

  methods: {
    $_clear(){
      this.target=null
    },
    close() {
      this.$refs.alertPopup.close();
      this.loadingStop();
      this.$_clear();
    },
    open($event) {
      this.target = $event;
      this.$refs.alertPopup.open();
    },
    $_onCancel() {
      this.close();
    },
    async $_onSubmit() {
      this.$emit("submit", this.target);
    },
    loadingStart() {
      this.loading = true;
    },
    loadingStop() {
      this.loading = false;
    },
  },

  props: {
    title: {
      type: String,
      default: "警告",
    },
    description: {
      type: String,
      default: "是否確定刪除？",
    },
  },
};
</script>

<style>
</style>
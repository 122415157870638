<template>
  <div class="ws-error-message">
    <WsIcon>info</WsIcon>
    <div class="ws-error-message__content">
      <slot></slot>
    </div>
  </div>
</template>

<script>
export default {};
</script>
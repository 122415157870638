import moment from 'moment'
export default {
  modelName: "public_property",
  label: "公物管理",
  fields: {
    id: {
      type: "text",
      label: "ID",
      readonly: true
    },
    public_property_no: {
      type: 'text',
      label: '公物編號',
      maxlength: 20,
      width: '150px'
    },
    start_date: {
      type: 'date',
      label: '公物起始日期',
      defaultValue: moment().format('YYYY-MM-DD'),
      width: '150px'
    },
    remark: {
      type: 'textarea',
      label: '備註',
      maxlength: 200,
      width: '150px'
    },
    last_user: {
      type: "belongsTo",
      label: "目前使用者",
      textKey: "name",
      modelName: "wasa",
      width: '150px'
    },
    updated_admin: {
      type: "belongsTo",
      label: "最後更新人",
      readonly: true,
      textKey: "name",
      modelName: "wasa"
    },
  },
}
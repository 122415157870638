<template>
  <fade-transition :duration="200">
    <div
      class="ws-dialog"
      v-if="active"
      :class="[type]"
    >
      <component
        :is="transition"
        :duration="200"
      >
        <div
          v-if="activeContainer"
          class="ws-dialog__container"
        >
          <div class="ws-dialog__title">
            <slot
              v-if="$slots.title"
              name="title"
            ></slot>
            <div
              v-else
              class="ws-dialog__title__text"
            >{{title}}</div>
            <WsIcon
              @click="$_onCloseClick()"
              class="ws-dialog__title__icon"
            >close</WsIcon>
          </div>
          <div
            class="ws-dialog__content"
            :class="[{'has-padding':contentHasPadding},{'fill-height':contentFillHeight}]"
            ref="dialogContent"
            @wheel="$_onWheel($event)"
          >
            <slot name="content"></slot>
          </div>
          <div
            v-if="$slots.leftActions||$slots.rightActions"
            class="ws-dialog__actions"
          >
            <slot name="leftActions"></slot>
            <WsSpacer></WsSpacer>
            <slot name="rightActions"></slot>
          </div>
        </div>
      </component>
      <div
        @click="$_onBgClick()"
        class="ws-dialog__bg"
      ></div>
    </div>
  </fade-transition>
</template>

<script>
export default {
  data: () => ({
    active: false,
    activeContainer: false,
  }),

  methods: {
    $_onCloseClick() {
      this.close();
    },
    $_onWheel() {
      const scrollTop = this.$refs.dialogContent.scrollTop;
      const offsetHeight = this.$refs.dialogContent.offsetHeight;
      const scrollHeight = this.$refs.dialogContent.scrollHeight;
      if (scrollTop + offsetHeight + this.bottomEmitDis >= scrollHeight) {
        this.$emit("reach-bottom");
      }
    },
    $_onBgClick() {
      this.close();
    },
    close() {
      this.activeContainer = false;
      this.$emit("close");
      this.$emit("input", false);
      document.body.classList.remove("scroll-disabled");
      window.removeEventListener("keydown", this.$_keydownHandler);
      setTimeout(() => {
        this.active = false;
      }, 100);
    },
    open() {
      this.active = true;
      this.$emit("open");
      this.$emit("input", true);
      document.body.classList.add("scroll-disabled");
      window.addEventListener("keydown", this.$_keydownHandler);
      setTimeout(() => {
        this.activeContainer = true;
      }, 100);
    },
    $_keydownHandler($event) {
      if (this.escMetaClose) {
        if ($event.metaKey && $event.code == "Escape") {
          this.close();
        }
      } else if (this.escClose) {
        if ($event.code == "Escape") {
          this.close();
        }
      }
    },
  },

  mounted() {
    if (this.value) {
      this.active = this.value;
    }
  },
  props: {
    transition: {
      type: String,
      default: "slide-x-left-transition",
    },
    contentFillHeight: {
      type: Boolean,
      default: true,
    },
    contentHasPadding: {
      type: Boolean,
      default: true,
    },
    bottomEmitDis: {
      type: Number,
      default: 0,
    },
    type: {
      type: String,
      default: "leftPanel",
    },
    escMetaClose: {
      type: Boolean,
      default: false,
    },
    escClose: {
      type: Boolean,
      default: true,
    },
    value: {
      type: Boolean,
      default: false,
    },
    title: {
      type: String,
      default: null,
    },
  },

  watch: {
    value: {
      handler() {
        if (this.value) {
          this.open();
        } else {
          this.close();
        }
      },
    },
    active: {
      handler() {
        this.$emit("input", this.active);
        if (!this.active) {
          this.$emit("close");
        }
      },
    },
  },

  beforeDestroy() {
    document.body.classList.remove("scroll-disabled");
  },
};
</script>
export default {
  modelName: "milestone",
  label: "Milestone",
  fields: {
    id: {
      type: "text",
      label: "ID",
      readonly: true,
      width: '70px',
      sortable: true
    },
    name: {
      type: "text",
      label: "名稱",
      autofocus: true,
      rules: "required",
      width: '160px',
      sortable: true
    },
    date: {
      type: "date",
      label: '日期',
      rules: "required",
      width: '120px',
      sortable: true
    },
    is_close: {
      type: 'switch',
      label: '關閉',
      activeText: 'CLOSE',
      inactiveText: 'OPEN',
      defaultValue: false,
    },
    is_finish: {
      type: 'switch',
      label: '狀態',
      activeText: '完成',
      inactiveText: '未完成',
      defaultValue: false,
      width: '70px'
    },
    remark: {
      type: 'textarea',
      label: '備註',
    },
    project: {
      type: 'belongsTo',
      label: '專案',
      textKey: "name",
      modelName: "project",
    },
    creator: {
      type: 'belongsTo',
      label: '建立人',
      textKey: "name",
      modelName: "wasa",
      width: '100px',
      sortable: true
    },
    taker: {
      type: 'belongsTo',
      label: '執行者',
      textKey: "name",
      modelName: "wasa",
      width: '100px',
      sortable: true
    },
    excute_type: {
      type: 'belongsTo',
      label: '執行類型',
      textKey: "name",
      modelName: "excute_type",
    },
    updated_at: {
      type: "datetime",
      label: "最後更新時間",
      readonly: true
    },
    sequence: {
      type: "text",
      label: "排序"
    },
  },
}
export default [
  {
    path: '/overtime_apply/review',
    name: 'overtime_apply_review',
    component: () => import('../views/OvertimeApplyReview.vue'),
    meta: {
      middleware: 'auth',
      requiredScopes: ['boss']
    }
  },
  {
    path: '/work_from_home/review',
    name: 'work_from_home_review',
    component: () => import('../views/WorkFromHomeReview.vue'),
    meta: {
      middleware: 'auth',
      requiredScopes: ['boss']
    }
  },
  {
    path: '/leave_day/review',
    name: 'leave_day_review',
    component: () => import('../views/LeaveDayReview.vue'),
    meta: {
      middleware: 'auth',
      requiredScopes: ['boss']
    }
  },
  {
    path: '/punch',
    name: 'punch',
    component: () => import('../views/Punch.vue'),
    meta: {
      middleware: 'auth',
    }
  },
  {
    path: '/crud/punch',
    name: 'crud_punch',
    component: () => import('../views/Punch/Index.vue'),
    meta: {
      middleware: 'auth',
      requiredScopes: ['boss']
    }
  },
  {
    path: '/hr_position',
    name: 'hr_position',
    component: () => import('../views/HrPosition/Index.vue'),
    meta: {
      middleware: 'auth',
      requiredScopes: ['boss']
    }
  },
  {
    path: '/hr_skill',
    name: 'hr_skill',
    component: () => import('../views/HrSkill/Index.vue'),
    meta: {
      middleware: 'auth',
      requiredScopes: ['boss']
    }
  },
  {
    path: '/hr_recruit',
    name: 'hr_recruit',
    component: () => import('../views/HrRecruit/Index.vue'),
    meta: {
      middleware: 'auth',
      requiredScopes: ['boss']
    }
  },
  {
    path: '/wasa',
    name: 'wasa',
    component: () => import('../views/Wasa/Index.vue'),
    meta: {
      middleware: 'auth',
      requiredScopes: ['boss']
    }
  },
  {
    path: '/wasa_hr_skill',
    name: 'wasa_hr_skill',
    component: () => import('../views/WasaHrSkill/Index.vue'),
    meta: {
      middleware: 'auth',
      requiredScopes: ['boss']
    }
  },
]
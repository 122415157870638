<template>
  <WsMain>
    <WsBreadcrumbs :menu="_breadcrumbsItems"></WsBreadcrumbs>
    <WsFlex class="mt-20">
      <WsTitle class="flex-0">{{_projectName}}</WsTitle>
      <WsSpacer></WsSpacer>
      <WsBtn :to="`/project/${_id}/update`">編輯</WsBtn>
    </WsFlex>
    <WsTabsContent
      v-if="project"
      class="mt-20"
      :tabs="_tabs"
      :routePre="`/project/${_id}/`"
    >
      <WsRow>
        <WsCol>
          <WsBtn to="adjust/task_reset">批次重排Task時間</WsBtn>
        </WsCol>
      </WsRow>
      <WsRow>
        <WsCol>
          <WsBtn to="adjust/task_delete">批次刪除Task</WsBtn>
        </WsCol>
      </WsRow>
    </WsTabsContent>
  </WsMain>
</template>

<script>
import model from "@/models/project";
export default {
  metaInfo() {
    const title = this.project ? this.project.name : "專案";
    return {
      title: title,
    };
  },
  data: () => ({
    fields: model.fields,
    project: null,
  }),
  methods: {
    async $_fetchProject() {
      const res = await this.$axios.get(`/project/${this._id}`);
      this.project = res.data.data;
    },
    isScopeValid(validScopes, userScopes) {
      const valid = validScopes.filter((value) => userScopes.includes(value))
        .length;
      return valid;
    },
  },
  computed: {
    _params() {
      return {
        project: this.project ? this.project.id : null,
      };
    },
    _tabs() {
      const _tabs = [
        {
          text: "總覽",
          to: "overview",
        },
      ];
      const isValid = this.isScopeValid(
        ["admin", "boss", "pm"],
        this.$store.state.auth.scopes
      );
      if (isValid) {
        _tabs.push({
          text: "Task",
          to: "task",
        });
        _tabs.push({
          text: "Task調整",
          to: "adjust",
        });
      }
      return _tabs;
    },
    _projectName() {
      return this.project ? this.project.name : null;
    },
    _id() {
      return this.$route.params.id;
    },
    _breadcrumbsItems() {
      const _breadcrumbsItems = [];
      _breadcrumbsItems.push(
        ...[
          {
            text: "專案",
            to: "/project",
          },
        ]
      );
      if (this.project) {
        _breadcrumbsItems.push({
          text: this.project.name,
          disabled: true,
        });
      }
      return _breadcrumbsItems;
    },
  },
  mounted() {
    this.$_fetchProject();
  },
};
</script>

<style>
</style>
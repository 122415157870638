import firebase from 'firebase/app';
import firebaseConfig from './config';
import 'firebase/firestore';
import 'firebase/storage';

if (!firebase.apps.length) {
  firebase.initializeApp(firebaseConfig);
}
const { $Timestamp, $GeoPoint } = firebase.firestore;

export const db = firebase.firestore()
export const storage = firebase.storage()
export const Timestamp = $Timestamp
export const GeoPoint = $GeoPoint
export const config = firebaseConfig
export default {
  db: firebase.firestore(),
  storage: firebase.storage(),
  Timestamp: $Timestamp,
  GeoPoint: $GeoPoint,
  config: firebaseConfig,
};

import Vue from 'vue'
import App from './App.vue'
import './registerServiceWorker'
import router from './router'
import store from './store'
import mixin from './mixin'
import html2canvas from 'html2canvas';
import { firestorePlugin } from 'vuefire';

Vue.use(firestorePlugin);

// CMS Stone
import cms from './__vue2stone_cms'
Vue.use(cms)

// Prototype
Vue.prototype.$html2canvas = html2canvas;

// Mixin
Vue.mixin(mixin)

// Components
const components = require.context('@/components/', true, /\.vue$/i);
components.keys().map(key => Vue.component(key.split('/').pop().split('.')[0], components(key).default));

new Vue({
  router,
  store,
  render: h => h(App)
}).$mount('#app')

Vue.config.productionTip = false

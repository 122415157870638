<template>
  <WsMain>
    <WsCrud
      :modelName="$store.state.model[modelName].modelName"
      :label="$store.state.model[modelName].label"
      :fields="$store.state.model[modelName].fields"
      :liveSearching="true"
      :pageMode="true"
      :showFields="showFields"
      :expandable="false"
      :showExpand="false"
      :getUpdateUrl="getUpdateUrl"
      :getReadUrl="getReadUrl"
    ></WsCrud>
  </WsMain>
</template>

<script>
export default {
  metaInfo() {
    return {
      title: "專案",
    };
  },
  data: () => ({
    modelName: "project",
    showFields: ["id", "name", "status"],
  }),
  methods: {
    getUpdateUrl(model) {
      return `/project/${model.url_id}/update`;
    },
    getReadUrl(model) {
      return `/project/${model.url_id}`;
    },
  },
};
</script>
import evaluation_material_option_excute from './evaluation_material_option_excute'
export default {
  modelName: "evaluation_material_option",
  label: "評估元件選項",
  fields: {
    // id: {
    //   type: "text",
    //   label: "ID",
    //   readonly: true
    // },
    name: {
      type: "text",
      label: "名稱",
      autofocus: true,
      rules: "required"
    },
    price: {
      type: 'number',
      label: '價格',
    },
    excute_days: {
      type: 'number',
      label: '執行天數',
      rules: 'required'
    },
    remark: {
      type: 'textarea',
      label: '備註'
    },
    evaluation_material_option_excutes: {
      type: 'hasManyList',
      label: '執行項目',
      textKey: "hour",
      modelName: "evaluation_material_option_excute",
      fields: evaluation_material_option_excute.fields,
      showFields: [
        'excute_type',
        'hour',
      ]
    },
    updated_at: {
      type: "datetime",
      label: "最後更新時間",
      readonly: true
    },
  },
}
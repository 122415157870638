<template>
  <WsMain>
    <div class="ws-row">
      <div class="ws-col col-4">
        <WsBtn
          :loading="loading"
          @click="$_onPunchIn()"
          :disabled="_lastPunchType==0"
        >我上班囉</WsBtn>
        <WsBtn
          :loading="loading"
          @click="$_onPunchOut()"
          class="mt-20"
          :disabled="_lastPunchType==1"
        >我下班囉</WsBtn>
      </div>
      <div class="ws-col col-8">
        <WsCrudTable
          :headers="tableHeaders"
          :items="_punches"
          :fields="fields"
          :paginate="false"
          :showExpand="false"
          :expandable="false"
          :inRowBtnRead="false"
          :inRowBtnUpdate="false"
          :inRowBtnDelete="false"
        ></WsCrudTable>
      </div>
    </div>
  </WsMain>
</template>

<script>
// import punch from "@/models/punch";
export default {
  metaInfo() {
    return {
      title: "打卡",
    };
  },
  data: () => ({
    loading: false,
    punches: [],
    tableHeaders: [
      {
        value: "date",
        text: "日期",
      },
      {
        value: "time",
        text: "時間",
      },
      {
        value: "type",
        text: "",
      },
    ],
    fields: {
      date: {
        type: "date",
      },
      time: {
        type: "time",
      },
      type: {
        type: "select",
        label: "類型",
        items: [
          {
            value: 0,
            text: "上班",
          },
          {
            value: 1,
            text: "下班",
          },
        ],
      },
    },
  }),
  methods: {
    async $_onPunchIn() {
      try {
        this.loading = true;
        await this.$axios.post("punchin");
        this.$_fetchPunchRecord();
        await this.$_CMS_slackNotificate({
          url: process.env.VUE_APP_FIREBASE_SYSTEM_SLACK_WEBHOOK_URL,
          config: {
            pretext: `${this.$store.state.auth.currentUser.name} Punch In!!!!!! @Q@/ @Q@/ @Q@/ @Q@/`,
            color: "#b0c092",
          },
        });
      } catch (error) {
        alert("發生錯誤");
      } finally {
        this.loading = false;
      }
    },
    async $_onPunchOut() {
      try {
        this.loading = true;
        const taskParams = {
          start_time: this.$moment()
            .set({
              hour: 0,
              minute: 0,
              second: 0,
            })
            .format("YYYY-MM-DD HH:mm:ss"),
          end_time: this.$moment()
            .set({
              hour: 23,
              minute: 59,
              second: 59,
            })
            .format("YYYY-MM-DD HH:mm:ss"),
          time_field: "start_time",
          taker: this.$store.state.auth.currentUser.id,
        };
        const taskRes = await this.$axios.get("task/index/all", {
          params: taskParams,
        });
        const tasks = taskRes.data.data;
        let finish = true;
        tasks.forEach((task) => {
          const _endTime = this.$_GRD_getEndtimeByStartTimeAndHour({
            start_time: task.start_time,
            hour: task.hour,
          });
          if (this.$moment(_endTime).format("L")!=this.$moment().format('L')){
            return
          }
          if (!task.is_finish) {
            finish = false;
          }
        });
        if (!finish) {
          alert("今日有Task還沒完成，確認後再打卡");
          return;
        }
        await this.$axios.post("punchout");
        this.$_fetchPunchRecord();
        await this.$_CMS_slackNotificate({
          url: process.env.VUE_APP_FIREBASE_SYSTEM_SLACK_WEBHOOK_URL,
          config: {
            pretext: `${this.$store.state.auth.currentUser.name} Punch Out.`,
            color: "#b0c092",
          },
        });
      } catch (error) {
        alert("發生錯誤");
      } finally {
        this.loading = false;
      }
    },
    async $_fetchPunchRecord() {
      this.punches = [];
      const params = {
        wasa: this.$store.state.auth.currentUser.id,
        order_by: "punch_at",
        order_way: "desc",
      };
      const res = await this.$axios.get("punch", {
        params,
      });
      this.punches = res.data.data;
    },
  },
  computed: {
    _lastPunchType() {
      if (!this.punches || this.punches.length == 0) {
        return 1;
      } else {
        return this.punches[0].type;
      }
    },
    _punches() {
      if (!this.punches) {
        return [];
      }
      const _punches = [];
      this.punches.forEach((punch) => {
        _punches.push({
          date: punch.punch_at,
          time: punch.punch_at,
          type: punch.type,
        });
      });
      return _punches;
    },
    _displayFields() {
      return this.$_CMS_getDisplayFields(this.fields);
    },
  },
  mounted() {
    this.$_fetchPunchRecord();
  },
};
</script>

<style>
</style>
<template>
  <WsDialog
    ref="WsDialog"
    v-model="dialog"
    :title="`編輯${label}`"
    :escMetaClose="true"
    @close="$_onDialogClose()"
  >
    <template v-slot:content>
      <WsErrorMessage
        v-if="pageErrorMessage"
        class="mb-20"
      >{{pageErrorMessage}}</WsErrorMessage>
      <ValidationObserver ref="form">
        <form
          ref="createForm"
          @submit.prevent
        >
          <WsStateLocales
            v-if="_localeField&&dialog"
            :fields="_localeField.fields"
            :value="updateData[_localeFieldKey]"
            @input="$_onInput(_localeFieldKey,$event)"
          ></WsStateLocales>
          <div class="ws-row">
            <div
              class="ws-col"
              v-for="(field, fieldKey) in _generalFields"
              cols="12"
              :class="[`sm-col-${field.sm?field.sm:12}`]"
              :key="fieldKey"
            >
              <WsState
                v-if="dialog"
                :type="field.type"
                :label="field.label"
                :fields="field.fields"
                :showFields="field.showFields"
                :rules="field.rules"
                :items="field.items"
                :modelName="field.modelName"
                :textKey="field.textKey"
                :uploadUrl="field.uploadUrl"
                :mode="field.mode"
                :staticUrl="field.staticUrl"
                :postData="field.postData"
                :requestMethod="field.requestMethod"
                :conditions="field.conditions"
                :note="field.note"
                :activeText="field.activeText"
                :inactiveText="field.inactiveText"
                :maxWidth="field.maxWidth"
                :autofocus="field.autofocus"
                :parent="field.parentKey"
                :parentState="$_getParentState(fieldKey,fields)"
                :requiredField="field.requiredField"
                :displayLabel="field.displayLabel"
                :value="updateData[fieldKey]"
                :originValue="$_getOriginValue(modelData,fieldKey)"
                :errorMessage="$_getErrorMessage(fieldKey)"
                @input="$_onInput(fieldKey,$event)"
              />
            </div>
          </div>
        </form>
      </ValidationObserver>
    </template>
    <template v-slot:rightActions>
      <WsBtn
        @click="$_onCancel()"
        minWidth="100px"
        color="textSecondary"
        outlined
      >取消</WsBtn>
      <WsBtn
        @click="$_onSubmit()"
        minWidth="100px"
        :loading="submitLoading"
        color="primary"
      >送出</WsBtn>
    </template>
  </WsDialog>
</template>

<script>
export default {
  data: () => ({
    pageErrorMessage: null,
    modelData: null,
    updateData: {},
    submitLoading: false,
    dialog: false,
    id: null,
  }),
  methods: {
    $_onDialogClose() {
      this.close();
    },
    error(message) {
      this.pageErrorMessage = message;
    },
    $_getErrorMessage(fieldKey) {
      if (this.errorMessages) {
        if (this.errorMessages[fieldKey]) {
          return this.errorMessages[fieldKey];
        } else {
          return null;
        }
      } else {
        return null;
      }
    },
    $_getParentState(fieldKey, modedFields) {
      const field = this.$_CMS_getFieldByFieldKey(fieldKey, modedFields);
      if (field.parentKey) {
        return this.updateData[field.parentKey];
      } else if (field.requiredField) {
        return this.updateData[field.requiredField];
      } else {
        return null;
      }
    },
    $_getOriginValue(modelData, fieldKey) {
      if (!modelData) {
        return null;
      } else {
        return modelData[fieldKey];
      }
    },
    $_onInput(fieldKey, $event) {
      this.updateData[fieldKey] = $event;
    },
    stopLoading() {
      this.submitLoading = false;
    },
    startLoading() {
      this.submitLoading = true;
    },
    async $_onSubmit() {
      const isValidate = await this.$refs.form.validate();
      if (!isValidate) {
        return;
      }
      this.$emit("submit", {
        ...this.updateData,
        id: this.id,
      });
    },
    $_onCancel() {
      this.close();
    },
    close() {
      this.dialog = false;
      this.pageErrorMessage = null;
      this.reset();
      window.removeEventListener("keydown", this.$_keydownHandler);
    },
    reset() {
      this.$refs.form.reset();
      this.submitLoading = false;
    },
    open(item) {
      this.modelData = item;
      this.id = item.id ? item.id : null;
      this.$_CMS_updateDataSetFromFields(
        this,
        this.updateData,
        this.fields,
        item
      );
      this.dialog = true;
      this.$refs.WsDialog.open();
      window.addEventListener("keydown", this.$_keydownHandler);
    },
    $_keydownHandler($event) {
      if ($event.metaKey && $event.key == "Enter") {
        this.$_onSubmit();
      }
    },
  },
  computed: {
    _generalFields() {
      const _generalFields = {};
      for (let fieldKey in this.fields) {
        const field = this.fields[fieldKey];
        if (field.type == "locales") {
          continue;
        }
        _generalFields[fieldKey] = field;
      }
      return _generalFields;
    },
    _localeField() {
      let _localeField = null;
      for (let fieldKey in this.fields) {
        const field = this.fields[fieldKey];
        if (field.type == "locales") {
          _localeField = field;
        }
      }
      return _localeField;
    },
    _localeFieldKey() {
      let _localeFieldKey = null;
      for (let fieldKey in this.fields) {
        const field = this.fields[fieldKey];
        if (field.type == "locales") {
          _localeFieldKey = fieldKey;
        }
      }
      return _localeFieldKey;
    },
  },
  props: {
    errorMessages: {
      type: Object,
      default: null,
    },
    modelName: String,
    label: {
      type: String,
      default: null,
    },
    fields: Object,
  },
  watch: {
    dialog: {
      handler() {
        if (!this.dialog) {
          this.reset();
        }
      },
    },
  },
};
</script>
<template>
  <div class="ws-state-form">
    <ValidationObserver ref="form">
      <form
        ref="stateForm"
        @submit.prevent
      >
        <div class="ws-row">
          <div
            class="ws-col"
            v-for="(field, fieldKey) in fields"
            cols="12"
            :class="[`sm-col-${field.sm?field.sm:12}`]"
            :key="fieldKey"
          >
            <WsState
              :type="field.type"
              :label="field.label"
              :fields="field.fields"
              :showFields="field.showFields"
              :rules="field.rules"
              :items="field.items"
              :modelName="field.modelName"
              :textKey="field.textKey"
              :uploadUrl="field.uploadUrl"
              :mode="field.mode"
              :staticUrl="field.staticUrl"
              :postData="field.postData"
              :requestMethod="field.requestMethod"
              :conditions="field.conditions"
              :activeText="field.activeText"
              :inactiveText="field.inactiveText"
              :note="field.note"
              :maxWidth="field.maxWidth"
              :autofocus="field.autofocus"
              :parent="field.parentKey"
              :parentState="$_getParentState(fieldKey,fields)"
              :requiredField="field.requiredField"
              :displayLabel="field.displayLabel"
              :value="state[fieldKey]"
              :originValue="$_getOriginValue(resourceData,fieldKey)"
              :errorMessage="$_getErrorMessage(fieldKey)"
              @input="$_onInput(fieldKey,$event)"
            />
          </div>
        </div>
      </form>
    </ValidationObserver>
  </div>
</template>

<script>
export default {
  data: () => ({
    state: {},
  }),

  methods: {
    $_onInput(fieldKey, $event) {
      this.state[fieldKey] = $event;
    },
    $_getParentState(fieldKey, modedFields) {
      const field = this.$_CMS_getFieldByFieldKey(fieldKey, modedFields);
      if (field.parentKey) {
        return this.state[field.parentKey];
      } else if (field.requiredField) {
        return this.state[field.requiredField];
      } else {
        return null;
      }
    },
    $_getOriginValue(resourceData, fieldKey) {
      if (!resourceData) {
        return null;
      } else {
        return resourceData[fieldKey];
      }
    },
    $_getErrorMessage(fieldKey) {
      if (this.errorMessages) {
        if (this.errorMessages[fieldKey]) {
          return this.errorMessages[fieldKey];
        } else {
          return null;
        }
      } else {
        return null;
      }
    },
    $_valueSet() {
      this.$_CMS_updateDataSetFromFields(
        this,
        this.state,
        this.fields,
        this.value
      );
    },
    reset() {
      this.$refs.form.reset();
    },
    validate() {
      return this.$refs.form.validate();
    },
    async $_input() {
      this.$emit("input", this.state);
    },
  },

  computed: {},

  mounted() {
    this.$_valueSet();
  },

  watch: {
    state: {
      handler() {
        this.$_input();
      },
      deep: true,
    },
    value: {
      handler() {
        this.$_valueSet();
      },
      deep: true,
    },
  },

  props: {
    fields: {
      type: Object,
      default: null,
    },
    value: {},
    errorMessages: {
      type: Object,
      default: null,
    },
    resourceData: {
      type: Object,
      default: null,
    },
  },
};
</script>

<style>
</style>
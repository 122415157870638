<template>
  <RouterLink
    class="ws-menu-item"
    :to="link"
      v-ripple.300
  >
    <WsIcon v-if="icon">{{icon}}</WsIcon>
    <div class="ws-menu-item__text">{{title}}</div>
  </RouterLink>
</template>

<script>
export default {
  props: {
    link: {
      type: String,
      required: true,
    },
    title: {
      type: String,
      default: null,
    },
    icon: {
      type: String,
      default: null,
    },
  },
};
</script>
<template>
  <WsMain>
    <WsCrud
      ref="WsCrud"
      :modelName="modelName"
      label="一日宅宅覆核"
      :fields="$store.state.model[modelName].fields"
      :liveSearching="true"
      :showFields="showFields"
      :order="order"
      :plusOrderItems="plusOrderItems"
      :filterSelects="filterSelects"
      :infiniteScroll="true"
      :creatable="true"
      :deletable="false"
      :inRowBtnDelete="false"
      :inRowBtnUpdate="false"
      :updatable="false"
      :customTableActions="customTableActions"
      @check="$_onCheck($event)"
      @uncheck="$_onUncheck($event)"
    ></WsCrud>
    <WsReviewDialog ref="WsReviewDialog"></WsReviewDialog>
  </WsMain>
</template>

<script>
export default {
  metaInfo() {
    return {
      title: "一日宅宅覆核",
    };
  },

  data: () => ({
    customTableActions: [
      {
        emit: "check",
        icon: "done",
      },
      {
        emit: "uncheck",
        icon: "close",
      },
    ],
    order: "last_date",
    modelName: "work_from_home",
    showFields: ["id", "date", "wasa", "status"],
    plusOrderItems: [
      {
        value: "last_date",
        text: "最近宅宅",
        order_by: "date",
        order_way: "desc",
      },
    ],
    filterSelects: {
      status: {
        label: "狀態",
        items: [
          {
            text: "全部",
            value: "all",
            params: {},
          },
          {
            value: 0,
            text: "申請中",
            params: {
              status: 0,
            },
          },
          {
            value: 1,
            text: "OK",
            params: {
              status: 1,
            },
          },
          {
            value: 2,
            text: "打妹",
            params: {
              status: 2,
            },
          },
        ],
      },
    },
    statusItems: [
      {
        value: 1,
        text: "OK",
      },
      {
        value: 2,
        text: "打妹",
      },
    ],
  }),

  methods: {
    async $_onCheck($event) {
      const tarId = $event.item.id;
      await this.$axios.patch(`${this.modelName}/${tarId}`, {
        status: 1,
      });
      this.$refs.WsCrud.reset();
      this.$refs.WsCrud.fetchData();
    },
    async $_onUncheck($event) {
      const tarId = $event.item.id;
      await this.$axios.patch(`${this.modelName}/${tarId}`, {
        status: 2,
      });
      this.$refs.WsCrud.reset();
      this.$refs.WsCrud.fetchData();
    },
  },
};
</script>
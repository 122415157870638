<template>
  <WsCard v-if="value">
    <WsTitle>{{value.name}}</WsTitle>
    <WsStateSelect
      class="mt-20"
      :items="_excuteTypeItems"
      :value="_value"
      @input="$_onInput($event)"
      multiple
    ></WsStateSelect>
  </WsCard>
</template>

<script>
export default {
  methods: {
    $_onInput($event) {
      const _excuteTypes = [];
      $event.forEach((excuteTypeId) => {
        const excuteType = this._excuteTypes.find((e) => {
          return e.id == excuteTypeId;
        });
        if (excuteType) {
          _excuteTypes.push(excuteType);
        }
      });
      const _value = JSON.parse(JSON.stringify(this.value));
      _value.excute_types = _excuteTypes;
      this.$emit("input", _value);
    },
  },

  computed: {
    _value() {
      if (!this.value.excute_types) {
        return [];
      }
      const _value = [];
      this.value.excute_types.forEach((valueItem) => {
        _value.push(valueItem.id);
      });
      return _value;
    },
    _excuteTypes() {
      if (!this.value) {
        return [];
      }
      const _excuteTypes = [];
      this.value.evaluation_materials.forEach((evaluation_material) => {
        evaluation_material.evaluation_material_excutes.forEach(
          (evaluation_material_excute) => {
            if (evaluation_material_excute.excute_type) {
              const exist = _excuteTypes.find((e) => {
                return e.id == evaluation_material_excute.excute_type.id;
              });
              if (!exist) {
                _excuteTypes.push(evaluation_material_excute.excute_type);
              }
            }
          }
        );
      });
      this.value.evaluation_material_options.forEach(
        (evaluation_material_option) => {
          evaluation_material_option.evaluation_material_option_excutes.forEach(
            (evaluation_material_option_excute) => {
              if (evaluation_material_option_excute.excute_type) {
                const exist = _excuteTypes.find((e) => {
                  return (
                    e.id == evaluation_material_option_excute.excute_type.id
                  );
                });
                if (!exist) {
                  _excuteTypes.push(
                    evaluation_material_option_excute.excute_type
                  );
                }
              }
            }
          );
        }
      );
      return _excuteTypes;
    },
    _excuteTypeItems() {
      const _excuteTypeItems = [];
      this._excuteTypes.forEach((_excuteType) => {
        _excuteTypeItems.push({
          value: _excuteType.id,
          text: _excuteType.name,
        });
      });
      return _excuteTypeItems;
    },
  },

  props: {
    value: {
      type: Object,
      default: null,
    },
  },
};
</script>

<style>
</style>
<template>
  <div class="ws-state-file-item">
    <WsLoading v-if="loading">{{percent}}</WsLoading>
    <template v-else>
      <a
        class="ws-state-file-item__link"
        :href="url"
        target="_blank"
      >{{$_getFileNameFromUrl(url)}}</a>
      <WsIcon
        v-if="updatable"
        icon
        @click.stop="$_onRemoveClick()"
        class="ws-state-file-item__icon mr-3 textPrimary--text"
      >close</WsIcon>
    </template>
  </div>
</template>

<script>
export default {
  props: {
    updatable: {
      type: Boolean,
      default: true,
    },
    loading: {
      type: Boolean,
      default: false,
    },
    percent: {
      type: Number,
      default: 0,
    },
    url: {
      type: String,
      default: null,
    },
  },
  methods: {
    $_onRemoveClick() {
      this.$emit("remove");
    },
    $_handleUploadProgress(progressEvent) {
      this.uploadPercent = Math.round(
        (progressEvent.loaded / progressEvent.total) * 100
      );
    },
    $_getFileNameFromUrl(url) {
      if (!url) {
        return null;
      }
      return decodeURIComponent(url).split("?")[0].split("/").pop();
    },
  },
};
</script>
import moment from 'moment'
export default {
  modelName: "punch",
  label: "打卡記錄",
  fields: {
    punch_at: {
      type: 'datetime',
      label: '打卡時間',
      defaultValue: moment().format('YYYY-MM-DD HH:mm:ss')
    },
    wasa: {
      type: 'belongsTo',
      label: "打卡人",
      textKey: "name",
      modelName: "wasa"
    },
    type: {
      type: 'select',
      label: '類型',
      items: [
        {
          value: 0,
          text: '上班'
        },
        {
          value: 1,
          text: '下班'
        },
      ]
    },
    updated_admin: {
      type: "belongsTo",
      label: "最後更新人",
      readonly: true,
      textKey: "name",
      modelName: "wasa"
    },
  },
}
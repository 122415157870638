<template>
  <fade-transition :duration="200">
    <div
      v-if="active"
      class="ws-alert"
    >
      <div class="ws-alert__container">
        <div class="ws-alert__content">
          <div
            v-if="title"
            class="ws-alert__title"
          >{{title}}</div>
          <div
            v-if="description"
            class="ws-alert__description"
          >{{description}}</div>
        </div>
        <div class="ws-alert__actions">
          <WsSpacer></WsSpacer>
          <WsBtn
            @click="$emit('submit',item)"
            :loading="loading"
            alert
          >確認</WsBtn>
        </div>
      </div>
      <div
        @click="$_onBgClick()"
        class="ws-alert__bg"
      ></div>
    </div>
  </fade-transition>
</template>

<script>
export default {
  data: () => ({
    active: false,
    item: null,
    loading: false,
  }),
  methods: {
    $_onCancel() {
      this.close();
    },
    $_onBgClick() {
      this.close();
    },
    startLoading() {
      this.loading = true;
    },
    stopLoading() {
      this.loading = false;
    },
    close() {
      this.active = false;
      this.$emit("close");
      document.body.classList.remove("scroll-disabled");
      window.removeEventListener("keydown", this.$_keydownHandler);
    },
    open(item) {
      this.item = item;
      this.active = true;
      this.$emit("open");
      document.body.classList.add("scroll-disabled");
      window.addEventListener("keydown", this.$_keydownHandler);
    },
    $_keydownHandler($event) {
      if ($event.code == "Escape") {
        this.close();
      }
    },
  },
  mounted() {
    if (this.value) {
      this.active = true;
    }
  },
  props: {
    value: {
      type: Boolean,
      default: false,
    },
    title: {
      type: String,
      default: null,
    },
    description: {
      type: String,
      default: null,
    },
  },
  watch: {
    value: {
      handler() {
        this.active = this.value;
      },
    },
    active: {
      handler() {
        this.$emit("input", this.active);
        if (!this.active) {
          this.$emit("close");
        }
      },
    },
  },
};
</script>
<template>
  <div class="ws-crud">
    <WsCrudTitleBar
      :importable="importable"
      :modelName="modelName"
      :label="label"
      :pageMode="pageMode"
      :dialogCreate="dialogCreate"
      :createUrl="createUrl"
      :creatable="creatable"
      @create="$_onCreate()"
    ></WsCrudTitleBar>
    <div class="ws-crud__container">
      <div class="ws-crud__container__filter-container">
        <WsCrudFilter
          ref="filter"
          :searching="searching"
          :orderBy="filter.orderBy"
          :orderWay="filter.orderWay"
          :order.sync="C_order"
          :orderItems="_orderItems"
          @search="$_onQueryUpdate($event,'searching')"
          @update:searching="$_onQueryUpdate($event,'searching')"
        >
          <template v-slot:filters>
            <WsState
              v-for="(filterSelect, filterSelectKey) in filterSelects"
              :key="filterSelectKey"
              type="select"
              :items="filterSelect.items"
              v-model="filterSelectsData[filterSelectKey]"
            ></WsState>
          </template>
        </WsCrudFilter>
      </div>
      <WsCrudTable
        ref="WsCrudTable"
        :loading="modeldataLoading"
        :inRowBtnComplete="inRowBtnComplete"
        :inRowBtnRead="inRowBtnRead"
        :inRowBtnUpdate="inRowBtnUpdate"
        :inRowBtnDelete="inRowBtnDelete"
        :dialogRead="dialogRead"
        :dialogUpdate="dialogUpdate"
        :dialogDelete="dialogDelete"
        :items="modelDatas"
        :itemsPerPage="itemsPerPage"
        :fields="fields"
        :displayFields="_displayFields"
        :headers="_tableHeaders"
        :pageMode="pageMode"
        :modelName="modelName"
        :dataTotalCount="dataTotalCount"
        :currentPage="page"
        :lastPage="lastPage"
        :showExpand="showExpand"
        :expandable="expandable"
        :orderBy.sync="filter.orderBy"
        :orderWay.sync="filter.orderWay"
        :orderByDefault="_orderByDefault"
        :orderWayDefault="_orderWayDefault"
        @read="$_onRead($event)"
        @pageto="$_onPageto($event)"
        @update="$_onUpdate($event)"
        @delete="$_onDelete($event)"
        @complete="$_onComplete($event)"
        @sort-update="$_onSortUpdate($event)"
        @custom-table-action="$_onCustomTableAction($event)"
        :paginate="_paginate"
        :getUpdateUrl="getUpdateUrl"
        :getReadUrl="getReadUrl"
        :customTableActions="customTableActions"
      ></WsCrudTable>
      <WsLoading v-if="modeldataLoading"></WsLoading>
    </div>
    <WsCreateDialog
      ref="createDialog"
      :label="label"
      :fields="_createFields"
      :modelName="modelName"
      :errorMessages="createErrorMessages"
      @submit="$_onCreateSubmit($event)"
    />
    <WsReadDialog
      ref="readDialog"
      :fields="_displayFields"
      :titleKey="titleKey"
      @delete="$_onDelete($event)"
      @update="$_onUpdate($event)"
      @copy="$_onCopy($event)"
      :pageMode="pageMode"
      :dialogUpdate="dialogUpdate"
      :modelName="modelName"
      :copyable="copyable"
      :deletable="deletable"
      :updatable="updatable"
      :getUpdateUrl="getUpdateUrl"
    >
      <template
        v-if="$scopedSlots.readDialogContent"
        v-slot:content="readContentSlotProps"
      >
        <slot
          name="readDialogContent"
          :item="readContentSlotProps.item"
        ></slot>
      </template>
      <template v-slot:leftActions="readLeftActionsSlotProps">
        <slot
          name="readDialogLeftActions"
          :item="readLeftActionsSlotProps.item"
        ></slot>
      </template>
      <template v-slot:rightActions="readRightActionsSlotProps">
        <slot
          name="readDialogRightActions"
          :item="readRightActionsSlotProps.item"
        ></slot>
      </template>
    </WsReadDialog>
    <WsUpdateDialog
      ref="updateDialog"
      :label="label"
      :fields="_updateFields"
      :modelName="modelName"
      :errorMessages="updateErrorMessages"
      @submit="$_onUpdateSubmit($event)"
    />
    <WsAlert
      ref="deleteAlert"
      title="確定要刪除嗎？"
      description="刪除後資料將無法回覆"
      @submit="$_onDeleteSubmit($event)"
    ></WsAlert>
  </div>
</template>

<script>
export default {
  data: () => ({
    page: 1,
    filter: {
      orderBy: "created_at",
      orderWay: "desc",
    },
    isMounted: false,
    modeldataLoading: false,
    modelDatas: [],
    lastPage: null,
    dataTotalCount: null,
    searching: "",
    filterSelectsData: {},
    C_order: null,
    createErrorMessages: null,
    updateErrorMessages: null,
    // focusReadIndex: null,
    baseOrderItems: [
      {
        value: "last_update",
        text: "最近更新",
        order_by: "updated_at",
        order_way: "desc",
      },
      {
        value: "last_created",
        text: "最新建立",
        order_by: "created_at",
        order_way: "desc",
      },
    ],
  }),
  methods: {
    $_onCustomTableAction($event) {
      this.$emit($event.emit, $event.data);
    },
    reset() {
      this.page = 1;
      this.modelDatas = [];
    },
    $_handleScroll() {
      if (!document) {
        return;
      }
      if (!this.infiniteScroll) {
        return;
      }
      if (
        window.innerHeight + window.scrollY >=
        document.body.offsetHeight - this.startLoadingDis
      ) {
        if (this.modeldataLoading) {
          return;
        } else {
          if (this.lastPage > this.page) {
            this.$_onPageto(this.page + 1);
          }
        }
      }
    },
    $_defaultSet() {
      if (this.order) {
        this.C_order = this.order;
        const orderQuery = this.$_getOrderUrl(this.order);
        this.filter = {
          orderBy: orderQuery.order_by,
          orderWay: orderQuery.order_way,
        };
      }
      if (this.filterSelects) {
        for (let key in this.filterSelects) {
          const filterSelect = this.filterSelects[key];
          this.$set(this.filterSelectsData, key, filterSelect.items[0].value);
        }
      }
      setTimeout(() => {
        this.isMounted = true;
      }, 0);
    },
    $_formatErrorMessages(errorMessages) {
      const formeted_error_messages = {};
      for (let key in errorMessages) {
        const errorMessage = errorMessages[key][0];
        if (this.$locale[errorMessage]) {
          formeted_error_messages[key] = this.$locale[errorMessage];
        } else {
          formeted_error_messages[key] = errorMessage;
        }
      }
      return formeted_error_messages;
    },
    $_getOrderUrl(order) {
      const orderItem = this._orderItems.find((e) => {
        return e.value == order;
      });
      return orderItem;
    },
    $_onOrderChange(order) {
      const orderQuery = this.$_getOrderUrl(order);
      this.filter = {
        orderBy: orderQuery.order_by,
        orderWay: orderQuery.order_way,
      };
    },
    $_onPageto($event) {
      this.page = $event;
      this.fetchData();
    },
    async fetchData() {
      this.$refs.WsCrudTable.expandReset();
      this.modeldataLoading = true;
      try {
        const getUrl = `/${this.modelName}`;
        let params = {
          page: this.page,
          search: this.searching,
          order_way: this.filter.orderWay,
          order_by: this.filter.orderBy,
        };
        if (this.$config.locale.api && this.$config.locale.lang) {
          params.lang = this.$store.state.locale.selectingLocale;
        }
        if (this.fetchQuery) {
          params = { ...params, ...this.fetchQuery };
        }

        // filterSelectsData
        for (let key in this.filterSelectsData) {
          const filterSelectsDataItem = this.filterSelectsData[key];
          if (
            filterSelectsDataItem == null ||
            filterSelectsDataItem == undefined
          ) {
            continue;
          } else {
            const filterItem = this.filterSelects[key].items.find((e) => {
              return e.value == filterSelectsDataItem;
            });
            const filterParams = filterItem.params;
            params = { ...params, ...filterParams };
          }
        }
        const res = await this.$axios.get(getUrl, {
          params: params,
        });
        if (res.data.meta && res.data.meta.last_page) {
          this.lastPage = res.data.meta.last_page;
        }
        if (res.data.meta && res.data.meta.total) {
          this.dataTotalCount = res.data.meta.total;
        }
        if (this.infiniteScroll) {
          this.modelDatas = [...this.modelDatas, ...res.data.data];
        } else {
          this.modelDatas = res.data.data;
        }
      } catch (error) {
        alert("存取資料錯誤，請重新嘗試");
      } finally {
        this.modeldataLoading = false;
      }
    },
    $_onQueryUpdate($event, type) {
      this[type] = $event;
    },
    $_onCreate() {
      this.createErrorMessages = null;
      this.$refs.createDialog.open();
    },
    $_onRead($event) {
      const _item = this.modelDatas[$event.itemIndex];
      this.$refs.readDialog.open(_item, $event.itemIndex);
    },
    $_onUpdate($event) {
      this.updateErrorMessages = null;
      const _item = this.modelDatas[$event.itemIndex];
      this.$refs.readDialog.close();
      this.$refs.updateDialog.open(_item);
    },
    $_onDelete($event) {
      this.$refs.deleteAlert.open($event.item);
    },
    $_onCopy($event) {
      this.updateErrorMessages = null;
      const _item = this.modelDatas[$event.itemIndex];
      this.$refs.readDialog.close();
      this.$refs.createDialog.open(_item);
    },
    $_onComplete($event) {
      if (!this.completeAction) {
        return;
      }
      this.completeAction($event);
    },
    copyOpen(id) {
      const _item = this.modelDatas.find((e) => {
        return e.id == id;
      });
      this.$refs.readDialog.close();
      this.$refs.createDialog.open(_item);
    },
    async $_onCreateSubmit($event) {
      this.$refs.createDialog.startLoading();
      try {
        let postData = $event;
        if (this.createDefaultValues) {
          postData = { ...postData, ...this.createDefaultValues };
        }
        await this.$axios.post(`/${this.modelName}`, postData);
        this.reset();
        this.fetchData();
        this.$refs.createDialog.close();
      } catch (error) {
        if (error && error.response) {
          if (error.response.data.message == "data store fail.") {
            alert("新增失敗");
          } else {
            this.createErrorMessages = this.$_formatErrorMessages(
              error.response.data.message
            );
          }
        } else {
          alert("新增失敗");
        }
      } finally {
        this.$refs.createDialog.stopLoading();
      }
    },
    async $_onUpdateSubmit($event) {
      this.$refs.updateDialog.startLoading();
      try {
        await this.$axios.patch(`/${this.modelName}/${$event.id}`, $event);
        this.$refs.updateDialog.close();
        this.reset();
        this.fetchData();
      } catch (error) {
        if (error && error.response) {
          if (error.response.data.message == "data store fail.") {
            alert("新增失敗");
          } else {
            this.updateErrorMessages = this.$_formatErrorMessages(
              error.response.data.message
            );
          }
        } else {
          alert("新增失敗");
        }
      } finally {
        this.$refs.updateDialog.stopLoading();
      }
    },
    async $_onDeleteSubmit($event) {
      this.$refs.deleteAlert.startLoading();
      try {
        await this.$axios.delete(`/${this.modelName}/${$event.id}`);
        this.reset();
        this.fetchData();
      } catch (error) {
        alert("刪除發生錯誤");
      } finally {
        this.$refs.deleteAlert.stopLoading();
        this.$refs.deleteAlert.close();
        this.$refs.readDialog.close();
      }
    },
  },
  mounted() {
    this.$_defaultSet();
    setTimeout(() => {
      this.reset();
      this.fetchData();
    }, 0);
  },

  watch: {
    filterSelectsData: {
      handler() {
        if (!this.isMounted) {
          return;
        }
        this.reset();
        this.fetchData();
      },
      deep: true,
    },
    filter: {
      handler() {
        if (!this.isMounted) {
          return;
        }
        this.reset();
        this.fetchData();
      },
      deep: true,
    },
    C_order: {
      handler() {
        if (!this.isMounted) {
          return;
        }
        this.$_onOrderChange(this.C_order);
      },
    },
    searching: {
      handler() {
        this.reset();
        this.fetchData();
      },
    },
  },

  computed: {
    _orderByDefault() {
      const orderQuery = this.$_getOrderUrl(this.order);
      return orderQuery.order_by;
    },
    _orderWayDefault() {
      const orderQuery = this.$_getOrderUrl(this.order);
      return orderQuery.order_way;
    },
    _paginate() {
      if (this.infiniteScroll) {
        return false;
      } else if (this.dataTotalCount != null) {
        return true;
      } else {
        return false;
      }
    },
    _orderItems() {
      if (!this.plusOrderItems) {
        return this.baseOrderItems;
      } else {
        return [...this.baseOrderItems, ...this.plusOrderItems];
      }
    },
    _createFields() {
      return this.$_CMS_geUpdateFields(this.fields, this.createHideFields);
    },
    _updateFields() {
      return this.$_CMS_geUpdateFields(this.fields, this.updateHideFields);
    },
    _displayFields() {
      return this.$_CMS_getDisplayFields(this.fields);
    },
    _tableHeaders() {
      let _tableHeaders = [];
      this.showFields.forEach((showFieldKey) => {
        if (showFieldKey in this._displayFields) {
          const field = this._displayFields[showFieldKey];
          if (field.type == "list" || field.type == "evaluationStage") {
            return;
          }

          if (
            field.type == "image" ||
            field.type == "tags" ||
            field.type == "password" ||
            field.type == "link" ||
            field.type == "editor"
          ) {
            _tableHeaders.push({
              value: showFieldKey,
              text: field.label,
              width: field.width,
              sortable: false,
            });
            return;
          }

          _tableHeaders.push({
            value: showFieldKey,
            text: field.label,
            width: field.width,
            sortable: field.sortable,
          });
        }
      });
      return _tableHeaders;
    },
  },

  props: {
    customTableActions: {
      type: Array,
      default: null,
    },
    completeAction: {
      type: Function,
      default: null,
    },
    startLoadingDis: {
      type: Number,
      default: 300,
    },
    infiniteScroll: {
      type: Boolean,
      default: false,
    },
    dialogCreate: {
      type: Boolean,
      default: false,
    },
    dialogRead: {
      type: Boolean,
      default: false,
    },
    dialogUpdate: {
      type: Boolean,
      default: false,
    },
    dialogDelete: {
      type: Boolean,
      default: false,
    },
    createDefaultValues: {
      type: Object,
      default: null,
    },
    fetchQuery: {
      type: Object,
      default: null,
    },
    creatable: {
      type: Boolean,
      default: true,
    },
    updatable: {
      type: Boolean,
      default: true,
    },
    deletable: {
      type: Boolean,
      default: true,
    },
    copyable: {
      type: Boolean,
      default: false,
    },
    order: {
      type: String,
      default: "last_created",
    },
    plusOrderItems: {
      type: Array,
      default: null,
    },
    inRowBtnComplete: {
      type: Boolean,
      default: false,
    },
    createUrl: {
      type: String,
      default: null,
    },
    getUpdateUrl: {
      type: Function,
      default: null,
    },
    getReadUrl: {
      type: Function,
      default: null,
    },
    createHideFields: {
      type: Array,
      default: null,
    },
    updateHideFields: {
      type: Array,
      default: null,
    },
    rowClickRead: {
      type: Boolean,
      default: false,
    },
    filterSelects: {
      type: Object,
      default: null,
    },
    itemsPerPage: {
      type: Number,
      default: 15,
    },
    showExpand: {
      type: Boolean,
      default: true,
    },
    expandable: {
      type: Boolean,
      default: true,
    },
    importRoute: {
      type: String,
      default: null,
    },
    pageMode: {
      type: Boolean,
      default: false,
    },
    titleKey: {
      type: String,
      default: "name",
    },
    showFields: {
      type: Array,
      required: true,
    },
    inRowBtnRead: {
      type: Boolean,
      default: true,
    },
    inRowBtnUpdate: {
      type: Boolean,
      default: true,
    },
    inRowBtnDelete: {
      type: Boolean,
      default: true,
    },
    liveSearching: {
      type: Boolean,
      default: false,
    },
    fields: {
      type: Object,
      required: true,
    },
    modelName: {
      type: String,
      default: null,
    },
    label: {
      type: String,
      default: null,
    },
    importable: {
      type: Boolean,
      default: false,
    },
  },

  created() {
    if (this.infiniteScroll) {
      window.addEventListener("scroll", this.$_handleScroll);
    }
  },

  destroyed() {
    if (this.infiniteScroll) {
      window.removeEventListener("scroll", this.$_handleScroll);
    }
  },
};
</script>
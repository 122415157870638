<template>
  <WsMain>
    <WsReadSection
      :id="_id"
      :modelName="modelName"
      :label="label"
      :fields="fields"
      :leftFields="leftFields"
      :rightFields="rightFields"
      v-model="modelData"
    ></WsReadSection>
    <WsInfoTulpaSections :value="state.content"></WsInfoTulpaSections>
  </WsMain>
</template>

<script>
import model from "@/__vue2stone_cms/models/tulpa_page";
export default {
  data: () => ({
    modelName: model.modelName,
    label: model.label,
    fields: model.fields,
    leftFields: ["name", "route", "title", "description", "og_image"],
    rightFields: ["is_active", "tags", "remark", "status"],
    modelData: null,
    state: {
      content: [],
    },
  }),

  methods: {
    $_stateContentInit(modelData) {
      this.state.content = [];
      const content = modelData.content;
      const tulpa_sections = modelData.tulpa_sections;
      content.forEach((contentItem) => {
        const _tulpaSection = tulpa_sections.find((e) => {
          return e.id == contentItem.id;
        });
        this.state.content.push({
          id: contentItem.id,
          content: contentItem.content,
          name: _tulpaSection.name,
          fields: _tulpaSection.tulpa_section_template.fields,
          defaultContent: _tulpaSection.content,
        });
      });
    },
  },

  watch: {
    modelData: {
      handler() {
        this.$_stateContentInit(this.modelData);
      },
    },
  },

  computed: {
    _id() {
      return this.$route.params.id;
    },
  },
};
</script>

<style>
</style>
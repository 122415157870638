<template>
  <div class="ws-full-calendar">
    <div
      v-if="$slots.searchSection"
      class="ws-full-calendar__search-section mr-20 sm-mr-0"
    >
      <slot name="searchSection"></slot>
    </div>
    <FullCalendar
      :options="_calendarOptions"
      :header="header"
    />
  </div>
</template>

<script>
import FullCalendar from "@fullcalendar/vue";
import dayGridPlugin from "@fullcalendar/daygrid";
import timeGridPlugin from "@fullcalendar/timegrid";
import interactionPlugin from "@fullcalendar/interaction";
export default {
  data: () => ({
    fullCalendar: {
      calendarPlugins: [dayGridPlugin, timeGridPlugin, interactionPlugin],
    },
  }),
  methods: {
    $_keyupHandler($event) {
      if ($event.key == "/") {
        let calendarApi = this.$refs.FullCalendar.getApi();
        calendarApi.next();
      }
      if ($event.key == ".") {
        let calendarApi = this.$refs.FullCalendar.getApi();
        calendarApi.prev();
      }
    },
  },

  computed: {
    _calendarOptions() {
      return {
        plugins: [dayGridPlugin, timeGridPlugin, interactionPlugin],
        initialView: "timeGridWeek",
        headerToolbar: this.header,
        buttonText: this.buttonText,
        allDayText: this.allDayText,
      };
    },
  },

  props: {
    events: {
      type: Array,
      default: null,
    },
    minTime: {
      type: String,
      default: "09:00:00",
    },
    maxTime: {
      type: String,
      default: "18:00:00",
    },
    header: {
      type: Object,
      default() {
        return {
          left: "prev,next today",
          center: "title",
          right: "dayGridMonth,timeGridWeek,timeGridDay",
        };
      },
    },
    calendarWeekends: {
      type: Boolean,
      default: true,
    },
    allDayText: {
      type: String,
      default: "Miles",
    },
    defaultView: {
      type: String,
      default: "timeGridWeek",
    },
    locale: {
      type: String,
      default: "zh-tw",
    },
    height: {
      type: [String, Number],
      default: "auto",
    },
    updatable: {
      type: Boolean,
      default: false,
    },
    eventDurationEditable: {
      type: Boolean,
      default: false,
    },
    buttonText: {
      type: Object,
      default() {
        return {
          today: "今天",
          month: "月",
          week: "週",
          day: "日",
          list: "列表",
        };
      },
    },
  },
  components: {
    FullCalendar,
  },
  created() {
    window.addEventListener("keyup", this.$_keyupHandler);
  },
  beforeDestroy() {
    window.removeEventListener("keyup", this.$_keyupHandler);
  },
};
</script>
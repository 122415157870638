<template>
  <WsMain>
    <div v-if="_step==1">
      <WsTitle>1. 基本資料</WsTitle>
      <div class="ws-row mt-12">
        <div class="ws-col col-6">
          <WsState
            :type="fields.project.type"
            :label="fields.project.label"
            :textKey="fields.project.textKey"
            :modelName="fields.project.modelName"
            v-model="updateData.project"
            autofocus
          ></WsState>
        </div>
        <div class="ws-col col-6">
          <WsState
            :label="fields.name.label"
            v-model="updateData.name"
            autofocus
          ></WsState>
        </div>
        <div class="ws-col col-6">
          <WsState
            :type="fields.start_date.type"
            :label="fields.start_date.label"
            v-model="updateData.start_date"
          >
          </WsState>
        </div>
        <div class="ws-col col-3">
          <WsState
            :label="fields.adjust_time_rate.label"
            v-model="updateData.adjust_time_rate"
          ></WsState>
        </div>
        <div class="ws-col col-3">
          <WsState
            :label="fields.adjust_price_rate.label"
            v-model="updateData.adjust_price_rate"
          ></WsState>
        </div>
        <div class="ws-col col-3">
          <WsState
            :type="fields.fundamental_hours.type"
            :label="fields.fundamental_hours.label"
            v-model="updateData.fundamental_hours"
          ></WsState>
        </div>
        <div class="ws-col col-3">
          <WsState
            :label="fields.issue_time_rate.label"
            v-model="updateData.issue_time_rate"
          ></WsState>
        </div>
        <div class="ws-col col-6">
          <WsState
            :type="fields.remark.type"
            :label="fields.remark.label"
            v-model="updateData.remark"
          ></WsState>
        </div>
        <div class="ws-col col-6">
          <WsState
            :type="fields.arrange_way.type"
            :label="fields.arrange_way.label"
            v-model="updateData.arrange_way"
            :items="fields.arrange_way.items"
          ></WsState>
        </div>
      </div>
    </div>
    <div v-else-if="_step==2">
      <WsTitle>2. 選擇山葵仔</WsTitle>
      <WsState
        class="mt-20"
        type="multi-select"
        :items="_wasaItems"
        v-model="updateData.wasas"
      ></WsState>
    </div>
    <div v-else-if="_step==3">
      <WsTitle>3. 執行階段</WsTitle>
      <WsDes class="mt-20">拖曳調整階段順序</WsDes>
      <AppEvaluationStageSlick
        class="mt-20"
        v-model="updateData.evaluation_stages"
        placeholder="Enter 新增執行階段"
      ></AppEvaluationStageSlick>
    </div>
    <div v-else-if="_step==4">
      <WsTitle>4. 執行階段菜單</WsTitle>
      <AppEvaluationStages
        class="mt-20"
        v-model="updateData.evaluation_stages"
      ></AppEvaluationStages>
    </div>
    <div v-else-if="_step==5">
      <WsTitle>5. 執行階段之執行項目勾選</WsTitle>
      <WsDes class="mt-20">勾選欲執行的執行項目</WsDes>
      <AppEvaluationStageExcuteTypes
        class="mt-20"
        v-model="updateData.evaluation_stages"
      ></AppEvaluationStageExcuteTypes>
    </div>
    <div v-else-if="_step==6">
      <WsTitle>6. 測試修改時間點</WsTitle>
      <WsDes class="mt-20">勾選須測試修改該階段以前執行階段之階段</WsDes>
      <AppQcStages
        class="mt-30"
        v-model="updateData.evaluation_stages"
      ></AppQcStages>
    </div>
    <div v-else-if="_step==7">
      <WsTitle>7. 執行人員安排</WsTitle>
      <AppEvaluationWasas
        class="mt-20"
        v-model="updateData.evaluation_wasas"
        :excuteTypes="_excuteTypes"
        :wasaItems="_evaluationWasaItems"
      ></AppEvaluationWasas>
    </div>
    <div v-else-if="_step==8">
      <WsTitle>8. 選取需會議確認之執行類型</WsTitle>
      <AppEvaluationExcuteTypesSetting
        class="mt-20"
        v-model="updateData.evaluation_excute_types_setting"
        :excuteTypes="_noCheckExcuteTypes"
      ></AppEvaluationExcuteTypesSetting>
    </div>
    <div v-else-if="_step==9">
      <WsTitle>9. 執行類型調整次數</WsTitle>
      <AppEvaluationExcuteTypesAdjustTimes
        class="mt-20"
        v-model="updateData.evaluation_excute_types_setting"
        :excuteTypes="_noCheckExcuteTypes"
      ></AppEvaluationExcuteTypesAdjustTimes>
    </div>
    <div v-else-if="_step==10">
      <WsTitle>10. 預排瀏覽</WsTitle>
      <div v-if="evaluation&&(evaluation.tasks.length||evaluation.milestones.length)">已排入</div>
      <AppEvaluationCalendar
        v-else
        class="mt-20"
        :evaluation="updateData"
        :wasas="wasas"
        :excuteTypes="_excuteTypes"
        :additionalOffDays="additionalOffDays"
        :additionalWorkingDays="additionalWorkingDays"
        :leaveDays="leaveDays"
        :afterTasks="_afterTasks"
      ></AppEvaluationCalendar>
    </div>
    <WsBottomNav>
      <template v-slot:leftActions>
        <WsDes v-if="_step==1">Shift + 左右方向鍵可切換快速步驟</WsDes>
        <WsBtn
          v-if="_step>1"
          @click="$_onPrevStep()"
          outlined
        >上一步</WsBtn>
      </template>
      <template v-slot:rightActions>
        <WsBtn
          :loading="saveLoading"
          @click="$_onSave()"
        >儲存</WsBtn>
        <WsBtn
          class="ml-8"
          v-if="_step<steps"
          outlined
          @click="$_onNextStep()"
        >下一步</WsBtn>
      </template>
    </WsBottomNav>
  </WsMain>
</template>

<script>
import evaluation from "@/models/evaluation";
export default {
  data: () => ({
    steps: 10,
    hourStart: 9,
    hourEnd: 18,
    preventWeekdays: [0, 6],
    qcDays: 2,
    fields: evaluation.fields,
    afterTasks: null,
    wasas: null,
    saveLoading: false,
    additionalWorkingDays: null,
    additionalOffDays: null,
    leaveDays: null,
    updateData: {
      project: null,
      name: null,
      adjust_time_rate: 1,
      adjust_price_rate: 1,
      fundamental_hours: 3,
      issue_time_rate: 0.3,
      remark: null,
      arrange_way: "standard",
      start_date: null,
      wasas: [],
      evaluation_stages: [],
      evaluation_wasas: [],
      evaluation_excute_types_setting: [],
    },
    // updateData: evaluationUpdateData,
  }),
  methods: {
    startSaveLoading() {
      this.saveLoading = true;
    },
    stopSaveLoading() {
      this.saveLoading = false;
    },
    async $_onSave() {
      const _updateData = JSON.parse(JSON.stringify(this.updateData));
      _updateData.evaluation_stages.forEach(
        (evaluation_stage, evaluation_stage_index) => {
          evaluation_stage.index = evaluation_stage_index;
          const evaluation_materials = [];
          if (evaluation_stage.evaluation_materials) {
            evaluation_stage.evaluation_materials.forEach(
              (evaluation_material) => {
                evaluation_materials.push(evaluation_material.id);
              }
            );
            evaluation_stage.evaluation_materials = evaluation_materials;
          }

          const evaluation_material_options = [];
          if (evaluation_stage.evaluation_material_options) {
            evaluation_stage.evaluation_material_options.forEach(
              (evaluation_material_option) => {
                evaluation_material_options.push(evaluation_material_option.id);
              }
            );
            evaluation_stage.evaluation_material_options = evaluation_material_options;
          }

          const excute_types = [];
          if (evaluation_stage.excute_types) {
            evaluation_stage.excute_types.forEach((excute_type) => {
              excute_types.push(excute_type.id);
            });
            evaluation_stage.excute_types = excute_types;
          }
        }
      );
      this.$emit("save", _updateData);
    },
    async $_leaveDaysFetch() {
      const params = {
        time_field: "start_date",
        start_time: this.updateData.start_date
          ? this.$moment(this.updateData.start_date).format("YYYY-MM-DD")
          : null,
      };
      const res = await this.$axios.get("leave_day/index/all", {
        params: params,
      });
      this.leaveDays = res.data.data;
    },
    async $_additionalWorkingDaysFetch() {
      const params = {
        time_field: "date",
        start_time: this.updateData.start_date
          ? this.$moment(this.updateData.start_date).format("YYYY-MM-DD")
          : null,
      };
      const res = await this.$axios.get("additional_working_day/index/all", {
        params: params,
      });
      this.additionalWorkingDays = res.data.data;
    },
    async $_additionalOffDaysFetch() {
      const params = {
        time_field: "date",
        start_time: this.updateData.start_date
          ? this.$moment(this.updateData.start_date).format("YYYY-MM-DD")
          : null,
      };
      const res = await this.$axios.get("additional_off_day/index/all", {
        params: params,
      });
      this.additionalOffDays = res.data.data;
    },
    async $_afterTasksFetch() {
      const params = {
        time_field: "start_time",
        start_time: this.updateData.start_date
          ? this.$moment(this.updateData.start_date)
              .add(-10, "days")
              .format("YYYY-MM-DD")
          : null,
        taker: this.updateData.wasas.join(),
      };
      const res = await this.$axios.get("task/index/all", {
        params: params,
      });
      this.afterTasks = res.data.data;
    },
    async $_wasasFetch() {
      const res = await this.$axios.get("wasa/index/all/deep");
      this.wasas = res.data.data;
    },
    $_evaluationWasasReset() {
      const _evaluation_wasas = [];
      this._excuteTypes.forEach((excuteType) => {
        const setting = this.updateData.evaluation_wasas.find((e) => {
          return e.excute_type == excuteType.id;
        });
        if (setting) {
          _evaluation_wasas.push(setting);
        } else {
          _evaluation_wasas.push({
            excute_type: excuteType.id,
            wasa: null,
          });
        }
      });
      this.updateData.evaluation_wasas = _evaluation_wasas;
    },
    $_evaluationExcuteTypesSettingReset() {
      if (!this._excuteTypes) {
        return;
      }
      const _evaluation_excute_types_setting = [];
      this._excuteTypes.forEach((excuteType) => {
        const setting = this.updateData.evaluation_excute_types_setting.find(
          (e) => {
            return e.excute_type == excuteType.id;
          }
        );
        if (setting) {
          _evaluation_excute_types_setting.push(setting);
        } else {
          _evaluation_excute_types_setting.push({
            excute_type: excuteType.id,
            next_keep_week: false,
            adjust_times: 0,
          });
        }
      });
      this.updateData.evaluation_excute_types_setting = _evaluation_excute_types_setting;
    },
    $_onPrevStep() {
      if (this._step > 1) {
        const query = { ...this.$route.query, step: this._step - 1 };
        this.$router.push({
          query: query,
        });
      }
    },
    $_onNextStep() {
      if (this._step + 1 > this.steps) {
        return;
      }
      const query = { ...this.$route.query, step: this._step + 1 };
      this.$router.push({
        query: query,
      });
    },
    $_keydownHandler($event) {
      if ($event.shiftKey && $event.code == "ArrowRight") {
        this.$_onNextStep();
      }
      if ($event.shiftKey && $event.code == "ArrowLeft") {
        this.$_onPrevStep();
      }
    },
    $_valueSet() {
      if (this.evaluation) {
        for (let key in this.updateData) {
          if (this.fields[key]) {
            this.updateData[key] = this.$_CMS_getUpdateData(
              this.evaluation[key],
              this.fields[key]
            );
          } else {
            this.updateData[key] = this.evaluation[key];
          }
        }
      }
      if (!this.updateData.start_date) {
        this.updateData.start_date = this.$moment().format("YYYY-MM-DD");
      }
    },
  },
  computed: {
    _afterTasks() {
      if (!this.afterTasks) {
        return [];
      }
      const _afterTasks = JSON.parse(JSON.stringify(this.afterTasks));

      _afterTasks.sort((a, b) => {
        if (!a.start_time) {
          return false;
        } else if (!b.start_time) {
          return false;
        } else if (a.start_time == b.start_time) {
          return a.hour - b.hour;
        } else {
          return this.$moment(a.start_time).diff(this.$moment(b.start_time));
        }
      });
      return _afterTasks;
    },
    _updateFields() {
      return this.$_CMS_geUpdateFields(this.fields, this.updateHideFields);
    },
    _additionalWorkingDays() {
      if (!this.additionalWorkingDays) {
        return [];
      }
      const _additionalWorkingDays = [];
      this.additionalWorkingDays.forEach((additionalWorkingDay) => {
        _additionalWorkingDays.push(additionalWorkingDay.date);
      });
      return _additionalWorkingDays;
    },
    _additionalOffDays() {
      if (!this.additionalOffDays) {
        return [];
      }
      const _additionalOffDays = [];
      this.additionalOffDays.forEach((additionalOffDay) => {
        _additionalOffDays.push(additionalOffDay.date);
      });
      return _additionalOffDays;
    },
    _evaluationWasaItems() {
      if (!this.updateData.wasas || !this.wasas) {
        return [];
      } else {
        const _evaluationWasaItems = [];
        this.updateData.wasas.forEach((updateWasaId) => {
          const tarWasa = this.wasas.find((e) => {
            return e.id == updateWasaId;
          });
          _evaluationWasaItems.push({
            value: tarWasa.id,
            text: tarWasa.name,
          });
        });
        return _evaluationWasaItems;
      }
    },
    _wasaItems() {
      if (!this.wasas) {
        return [];
      } else {
        const _wasaItems = [];
        this.wasas.forEach((wasa) => {
          _wasaItems.push({
            value: wasa.id,
            text: wasa.name,
          });
        });
        return _wasaItems;
      }
    },
    _noCheckExcuteTypes() {
      if (!this.updateData.evaluation_stages) {
        return [];
      } else {
        const _noCheckExcuteTypes = [];
        this.updateData.evaluation_stages.forEach((stage) => {
          stage.evaluation_materials.forEach((evaluation_material) => {
            evaluation_material.evaluation_material_excutes.forEach(
              (evaluation_material_excute) => {
                if (!evaluation_material_excute.excute_type) {
                  return;
                }
                const has = _noCheckExcuteTypes.some((e) => {
                  return e.id == evaluation_material_excute.excute_type.id;
                });
                if (!has) {
                  _noCheckExcuteTypes.push(
                    evaluation_material_excute.excute_type
                  );
                }
              }
            );
            evaluation_material.evaluation_material_options.forEach(
              (evaluation_material_option) => {
                evaluation_material_option.evaluation_material_option_excutes.forEach(
                  (evaluation_material_option_excute) => {
                    if (!evaluation_material_option_excute.excute_type) {
                      return;
                    }
                    const has = _noCheckExcuteTypes.some((e) => {
                      return (
                        e.id == evaluation_material_option_excute.excute_type.id
                      );
                    });
                    if (!has) {
                      _noCheckExcuteTypes.push(
                        evaluation_material_option_excute.excute_type
                      );
                    }
                  }
                );
              }
            );
          });
        });
        _noCheckExcuteTypes.sort((a, b) => {
          if (a.excute_index == b.excute_index) {
            return a.excute_index_second - b.excute_index_second;
          } else {
            return a.excute_index - b.excute_index;
          }
        });
        return _noCheckExcuteTypes;
      }
    },
    _excuteTypes() {
      const _excuteTypes = this._noCheckExcuteTypes;
      _excuteTypes.push(...this.$store.state.setting.checkExcuteTypes);
      return _excuteTypes;
    },
    _step() {
      return this.$route.query.step ? parseInt(this.$route.query.step) : 1;
    },
  },
  props: {
    evaluation: {
      type: Object,
      default: null,
    },
  },
  created() {
    window.addEventListener("keydown", this.$_keydownHandler);
  },
  beforeDestroy() {
    window.removeEventListener("keydown", this.$_keydownHandler);
  },
  watch: {
    "updateData.wasas": {
      handler() {
        this.$_afterTasksFetch();
      },
    },
    "updateData.start_date": {
      handler() {
        this.$_afterTasksFetch();
        this.$_additionalWorkingDaysFetch();
        this.$_additionalOffDaysFetch();
      },
    },
    _excuteTypes: {
      handler() {
        this.$_evaluationWasasReset();
        this.$_evaluationExcuteTypesSettingReset();
      },
    },
  },
  async mounted() {
    this.$_valueSet();
    this.$_wasasFetch();
    this.$_afterTasksFetch();
    this.$_additionalWorkingDaysFetch();
    this.$_additionalOffDaysFetch();
    this.$_leaveDaysFetch();
  },
};
</script>

<style>
</style>
export default {
  modelName: "business_extension",
  label: "業務開發",
  fields: {
    id: {
      type: "text",
      label: "ID",
      readonly: true
    },
    name: {
      type: "text",
      label: "名稱",
      autofocus: true,
      rules: "required"
    },
    email: {
      type: 'email',
      label: '信箱'
    },
    status: {
      type: 'text',
      label: '狀態',
      items: [
        "第一次寄信",
        "沒回",
        "打掉",
        "已報價",
        "報價後沒回",
        "進入專案"
      ],
      defaultValue: '第一次寄信'
    },
    link: {
      type: 'text',
      label: '連結'
    },
    remark: {
      type: 'textarea',
      label: '備註'
    },
    extend_at: {
      type: 'date',
      label: '開發日期'
    },
    updated_at: {
      type: "datetime",
      label: "最後更新時間",
      readonly: true
    },
    sequence: {
      type: "text",
      label: "排序"
    },
  },
}
<template>
  <div
    class="ws-title"
    :class="[{center:center},`size-${size}`]"
  >
    <slot></slot>
  </div>
</template>

<script>
export default {
  props: {
    center: {
      type: Boolean,
      default: false,
    },
    size: {
      type: [Number,String],
      default: 3,
    },
  },
};
</script>
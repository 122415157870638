<template>
  <div class="app-evaluation-stage-slick-item">
    <WsIcon
      class="icon-handle"
      v-handle
    >dehaze</WsIcon>
    <input
      type="text"
      v-model="stage.name"
    >
    <WsIcon
      @click="$emit('delete',$event)"
      class="icon-delete"
    >delete</WsIcon>
  </div>
</template>

<script>
export default {
  props: {
    stage: {
      type: Object,
      default: null,
    },
  },
};
</script>

<style>
</style>
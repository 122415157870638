<template>
  <div class="ws-state-content-block">
    <WsCard v-if="type=='stateCard'">
      <div
        v-for="(floor,floorKey) in floors"
        :key="floorKey"
        class="ws-content"
      >
        <WsContentTitle v-if="floor.title">{{floor.title}}</WsContentTitle>
        <WsContentRemark v-if="floor.remark">{{floor.remark}}</WsContentRemark>
        <WsState
          v-for="(fieldKey,fieldIndex) in floor.fields"
          :key="fieldIndex"
          :label="$_CMS_getLabelByHideLabelFields(fieldKey,modedFields,floor.hideLabelFields)"
          :type="$_getFieldAttr(fieldKey,'type')"
          :fields="$_getFieldAttr(fieldKey,'fields')"
          :showFields="$_getFieldAttr(fieldKey,'showFields')"
          :textKey="$_getFieldAttr(fieldKey,'textKey')"
          :getText="$_getFieldAttr(fieldKey,'getText')"
          :modelName="$_getFieldAttr(fieldKey,'modelName')"
          :placeholder="$_getFieldAttr(fieldKey,'placeholder')"
          :parent="$_getFieldAttr(fieldKey,'parentKey')"
          :requiredField="$_getFieldAttr(fieldKey,'requiredField')"
          :uploadUrl="$_getFieldAttr(fieldKey,'uploadUrl')"
          :items="$_getFieldAttr(fieldKey,'items')"
          :displayLabel="$_getFieldAttr(fieldKey,'displayLabel')"
          :rules="$_getFieldAttr(fieldKey,'rules')"
          :activeText="$_getFieldAttr(fieldKey,'activeText')"
          :inactiveText="$_getFieldAttr(fieldKey,'inactiveText')"
          :conditions="$_getConditions(fieldKey)"
          :parentState="$_getParentState(fieldKey,modedFields)"
          :requiredFieldState="$_getParentState(fieldKey,modedFields)"
          :originValue="$_getOriginValue(modelData,fieldKey)"
          v-model="updateData[fieldKey]"
        ></WsState>
      </div>
    </WsCard>
    <WsCard v-else-if="type=='infoCard'">
      <div
        v-for="(floor,floorKey) in floors"
        :key="floorKey"
        class="ws-content"
      >
        <WsContentTitle v-if="floor.title">{{floor.title}}</WsContentTitle>
        <WsContentRemark v-if="floor.remark">{{floor.remark}}</WsContentRemark>
        <WsInfo
          v-for="(fieldKey,fieldIndex) in floor.fields"
          :key="fieldIndex"
          :label="$_CMS_getLabelByHideLabelFields(fieldKey,modedFields,floor.hideLabelFields)"
          :type="$_getFieldAttr(fieldKey,'type')"
          :fields="$_getFieldAttr(fieldKey,'fields')"
          :showFields="$_getFieldAttr(fieldKey,'showFields')"
          :textKey="$_getFieldAttr(fieldKey,'textKey')"
          :getText="$_getFieldAttr(fieldKey,'getText')"
          :modelName="$_getFieldAttr(fieldKey,'modelName')"
          :placeholder="$_getFieldAttr(fieldKey,'placeholder')"
          :parent="$_getFieldAttr(fieldKey,'parentKey')"
          :requiredField="$_getFieldAttr(fieldKey,'requiredField')"
          :uploadUrl="$_getFieldAttr(fieldKey,'uploadUrl')"
          :items="$_getFieldAttr(fieldKey,'items')"
          :displayLabel="$_getFieldAttr(fieldKey,'displayLabel')"
          :rules="$_getFieldAttr(fieldKey,'rules')"
          :relationPopup="$_getFieldAttr(fieldKey,'relationPopup')"
          :activeText="$_getFieldAttr(fieldKey,'activeText')"
          :inactiveText="$_getFieldAttr(fieldKey,'inactiveText')"
          :conditions="$_getConditions(fieldKey)"
          :parentState="$_getParentState(fieldKey,modedFields)"
          :requiredFieldState="$_getParentState(fieldKey,modedFields)"
          :originValue="$_getOriginValue(modelData,fieldKey)"
          v-model="modelData[fieldKey]"
        ></WsInfo>
      </div>
    </WsCard>
  </div>
</template>

<script>
export default {
  props: {
    modelData: {
      type: Object,
      default: null,
    },
    updateData: {
      type: Object,
      default: null,
    },
    label: {
      type: String,
      default: null,
    },
    title: {
      type: String,
      default: null,
    },
    remark: {
      type: String,
      default: null,
    },
    locale: {
      type: String,
      default: null,
    },
    fieldsRead: {
      type: Array,
      default: null,
    },
    modedFields: {
      type: Object,
      required: true,
    },
    field: {
      type: String,
      default: null,
    },
    fields: {
      type: Object,
      default: null,
    },
    type: {
      type: String,
      default: "stateCard",
    },
    floors: {
      type: Array,
      default() {
        return [];
      },
    },
    parentModelName: {
      type: String,
      default: null,
    },
    modelName: String,
    modeParent: {
      type: Boolean,
      default: false,
    },
    customButtons: {
      type: Array,
      default: null,
    },
  },
  methods: {
    $_getOriginValue(modelData, fieldKey) {
      if (!modelData) {
        return null;
      } else {
        return modelData[fieldKey];
      }
    },
    $_getConditions(fieldKey) {
      // const $_getFieldAttr(fieldKey,'conditionKeys')
      const _conditions = [];
      const conditionKeys = this.$_getFieldAttr(fieldKey, "conditionKeys");
      if (!conditionKeys) {
        return [];
      }
      conditionKeys.forEach((conditionKey) => {
        _conditions.push({
          key: conditionKey.conditionName,
          value: this.updateData[conditionKey.fieldName],
          type: conditionKey.type,
        });
      });
      return _conditions;
    },
    $_getFieldAttr(fieldKey, keyName) {
      const field = this.$_CMS_getFieldByFieldKey(fieldKey, this.modedFields);
      return field[keyName];
    },
    $_getParentState(fieldKey, modedFields) {
      const field = this.$_CMS_getFieldByFieldKey(fieldKey, modedFields);
      if (field.parentKey) {
        return this.updateData[field.parentKey];
      } else if (field.requiredField) {
        return this.updateData[field.requiredField];
      } else {
        return null;
      }
    },
  },
};
</script>

<style>
</style>
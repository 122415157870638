<template>
  <div class="ws-state-wasa-intro">
    <WsFlexWrap>
      <WsBtn @click="$_onCreateOpen()">新增內容區塊</WsBtn>
      <WsBtn
        class="ml-10"
        @click="$_onSortOpen()"
      >調整順序</WsBtn>
      <WsSortDialog
        ref="WsSortDialog"
        @input="$emit('input',$event)"
        :value="value"
      ></WsSortDialog>
    </WsFlexWrap>
    <WsCard
      color="#333"
      class="ws-state-wasa-intro__card mt-20"
      v-for="(introItem, introIndex) in value"
      :key="introIndex"
    >
      <WsState
        label="區塊名稱"
        v-model="introItem.name"
      ></WsState>
      <WsIconBtn
        @click="$_onRemove(introItem)"
        class="ws-state-wasa-intro__card__remove-btn"
      >close</WsIconBtn>
      <WsTitle
        class="mt-20"
        size=6
      >區塊內容</WsTitle>
      <WsFlex class="mt-10">
        <WsFull
          v-for="(col, colIndex) in introItem.cols"
          :key="colIndex"
        >
          <WsCard v-if="col.type=='image'">
            <WsState
              type="image"
              label="圖片"
              :value="col.state.image"
              @input="$_onColStateInput(introIndex,colIndex,'image',$event,)"
            ></WsState>
          </WsCard>
          <WsCard v-else-if="col.type=='intro'">
            <WsState
              type="text"
              label="標題"
              :value="col.state.h2"
              @input="$_onColStateInput(introIndex,colIndex,'h2',$event,)"
            ></WsState>
            <WsState
              type="text"
              label="副標題"
              :value="col.state.h3"
              @input="$_onColStateInput(introIndex,colIndex,'h3',$event,)"
            ></WsState>
            <WsState
              type="textarea"
              label="說明"
              :value="col.state.text"
              @input="$_onColStateInput(introIndex,colIndex,'text',$event,)"
            ></WsState>
            <WsState
              type="link"
              label="外部連結"
              :value="col.state.link"
              @input="$_onColStateInput(introIndex,colIndex,'link',$event,)"
            ></WsState>
          </WsCard>
        </WsFull>
      </WsFlex>
    </WsCard>
    <WsDialogPicker
      ref="WsDialogPicker"
      title="選擇類型"
      @submit="$_onPickerSubmit($event)"
      :items="sectionTypeItems"
    ></WsDialogPicker>
    <WsSortDialog
      ref="WsSortDialog"
      @input="$emit('input',$event)"
      :value="value"
    ></WsSortDialog>
  </div>
</template>

<script>
export default {
  data: () => ({
    sectionTypeItems: [
      {
        text: "滿版圖",
        value: 0,
      },
      {
        text: "版滿內容",
        value: 1,
      },
      {
        text: "左內容右圖",
        value: 2,
      },
      {
        text: "左圖右內容",
        value: 3,
      },
      {
        text: "右內容左內容",
        value: 4,
      },
      {
        text: "左圖右圖",
        value: 5,
      },
    ],
  }),

  methods: {
    $_onRemove(introItem) {
      const _value = JSON.parse(JSON.stringify(this.value));
      _value.splice(introItem, 1);
      this.$emit("input", _value);
    },
    $_onColStateInput(introIndex, colIndex, target, $event) {
      const _value = JSON.parse(JSON.stringify(this.value));
      _value[introIndex].cols[colIndex].state[target] = $event;
      this.$emit("input", _value);
    },
    $_onPickerSubmit($event) {
      const _sectionContent = [];
      const imageContet = {
        type: "image",
        state: {
          image: "",
        },
      };
      const introContet = {
        type: "intro",
        state: {
          h2: "",
          h3: "",
          text: "",
          link: "",
        },
      };
      if ($event.value == 0) {
        _sectionContent.push(imageContet);
      } else if ($event.value == 1) {
        _sectionContent.push(introContet);
      } else if ($event.value == 2) {
        _sectionContent.push(introContet);
        _sectionContent.push(imageContet);
      } else if ($event.value == 3) {
        _sectionContent.push(imageContet);
        _sectionContent.push(introContet);
      } else if ($event.value == 4) {
        _sectionContent.push(introContet);
        _sectionContent.push(introContet);
      } else if ($event.value == 5) {
        _sectionContent.push(imageContet);
        _sectionContent.push(imageContet);
      }
      const _value = this.value ? JSON.parse(JSON.stringify(this.value)) : [];
      _value.push({
        name: "",
        cols: _sectionContent,
      });
      this.$emit("input", _value);
    },
    $_onCreateOpen() {
      this.$refs.WsDialogPicker.open();
    },
    $_onSortOpen() {
      this.$refs.WsSortDialog.open();
    },
  },

  props: {
    value: {
      type: [Object, Array],
      default: null,
    },
  },
};
</script>

<style>
</style>
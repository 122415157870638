<template>
  <div class="app-evaluation-stage-slick">
    <SlickList
      @input="$emit('input',$event)"
      :value="_value"
      :useDragHandle="true"
    >
      <SlickItem
        v-for="(stage,stageIndex) in value"
        :key="stageIndex"
        :index="stageIndex"
      >
        <AppEvaluationStageSlickItem
          class="mt-10"
          :stage="stage"
          @delete="$_onDelete(stageIndex)"
        ></AppEvaluationStageSlickItem>
      </SlickItem>
    </SlickList>
    <form
      class="mt-40"
      @submit.prevent="$_onStageAddSubmit()"
    >
      <WsState
        autofocus
        :placeholder="placeholder"
        v-model="stageAdding"
      ></WsState>
    </form>
  </div>
</template>

<script>
export default {
  data: () => ({
    stageAdding: null,
  }),
  methods: {
    $_onDelete(stageIndex) {
      const _value = this.value ? JSON.parse(JSON.stringify(this.value)) : [];
      _value.splice(stageIndex, 1);
      this.$emit("input", _value);
    },
    $_onStageAddSubmit() {
      const newStage = {
        name: this.stageAdding,
        evaluation_materials: [],
        evaluation_material_options: [],
        excute_types: [],
      };
      const _value = this.value ? JSON.parse(JSON.stringify(this.value)) : [];
      _value.push(newStage);
      this.$emit("input", _value);
      this.stageAdding = "";
    },
  },
  computed: {
    _value() {
      return this.value ? this.value : [];
    },
  },
  props: {
    placeholder: {
      type: String,
      default: "Enter 新增項目",
    },
    value: {
      type: Array,
      default: null,
    },
  },
};
</script>

<style>
</style>
<template>
  <div class="ws-info-files">
    <div class="ws-info-files__upload-btns">
      <WsStateFileItem
        v-for="(file,fileIndex) in value"
        :key="fileIndex"
        :url="file"
        :updatable="false"
        class="mb-2"
      ></WsStateFileItem>
    </div>
  </div>
</template>

<script>
export default {
  props: {
    value: {
      type: Array,
      default() {
        return [];
      }
    }
  }
};
</script>

<style>
</style>
<template>
  <AppStateEvaluation
    ref="appStateEvaluation"
    @save="$_onSave($event)"
  ></AppStateEvaluation>

</template>

<script>
export default {
  metaInfo() {
    return {
      title: "新增執行評估",
    };
  },
  methods: {
    async $_onSave($event) {
      try {
        this.$refs.appStateEvaluation.startSaveLoading();
        await this.$axios.post("evaluation", $event);
        this.$router.push("/evaluation");
      } catch (error) {
        alert("儲存錯誤");
      } finally {
        this.$refs.appStateEvaluation.stopSaveLoading();
      }
    },
  },
};
</script>
<template>
  <WsMain>
    <WsState
      type="belongsTo"
      label="專案"
      textKey="name"
      modelName="project"
      :conditions="projectConditions"
      v-model="state.project"
    ></WsState>
    <WsState
      type="date"
      class="mt-10"
      label="起始時間"
      v-model="state.startDateOri"
    ></WsState>

    <template v-if="state.project">
      <WsRow class="mt-20">
        <WsCol class="col-6">
          <WsCard>
            <WsTitle size="5">範圍內Task</WsTitle>
            <WsCrudTable
              class="mt-20"
              ref="WsCrudTable"
              :items="tasks"
              :fields="taskFields"
              :showFields="taskShowFields"
              :inRowBtnRead="false"
              :inRowBtnUpdate="false"
              :inRowBtnDelete="false"
              :expandable="false"
              modelName="task"
              :paginate="false"
            ></WsCrudTable>
          </WsCard>
        </WsCol>
        <WsCol class="col-6">
          <WsCard>
            <WsTitle size="5">範圍內Milestone</WsTitle>
            <WsCrudTable
              class="mt-20"
              ref="WsCrudTable"
              :items="milestones"
              :fields="milestoneFields"
              :showFields="milestoneShowFields"
              :inRowBtnRead="false"
              :inRowBtnUpdate="false"
              :inRowBtnDelete="false"
              :expandable="false"
              modelName="milestone"
              :paginate="false"
            ></WsCrudTable>
          </WsCard>
        </WsCol>
      </WsRow>

      <WsBtn
        class="mt-20"
        :loading="loading.delete"
        @click="$_onBatchDelete()"
        verybig
        full
      >清除</WsBtn>
    </template>
    <WsAlert
      ref="deleteAlert"
      title="確定要刪除嗎？"
      description="刪除後資料將無法回覆"
      @submit="$_onDeleteSubmit($event)"
    ></WsAlert>
  </WsMain>
</template>

<script>
import task from "@/models/task";
import milestone from "@/models/milestone";
export default {
  metaInfo() {
    return {
      title: "批次刪除",
    };
  },

  data: () => ({
    tasks: null,
    milestones: null,
    taskFields: task.fields,
    milestoneFields: milestone.fields,
    taskShowFields: ["id", "name", "start_time", "taker", "creator"],
    milestoneShowFields: ["id", "name", "date", "taker", "creator"],
    state: {
      project: null,
      startDateOri: null,
    },
    info: {
      project: null,
    },
    loading: {
      delete: false,
    },
    projectConditions: [
      {
        key: "status",
        value: [0, 1],
      },
      {
        key: "order_by",
        value: "created_at",
      },
      {
        key: "order_way",
        value: "desc",
      },
    ],
  }),

  methods: {
    async $_onDeleteSubmit() {
      const tasks = [];
      this.tasks.forEach((task) => {
        tasks.push(this.$axios.delete(`task/${task.id}`));
      });
      this.milestones.forEach((milestone) => {
        tasks.push(this.$axios.delete(`milestone/${milestone.id}`));
      });

      try {
        this.$refs.deleteAlert.startLoading();
        await Promise.all(tasks);
        this.$_fetchTasks();
        this.$_fetchMilestones();
      } catch (error) {
        console.log("error");
      } finally {
        this.$refs.deleteAlert.stopLoading();
      }
    },
    async $_fetchTasks() {
      const params = {
        project: this.state.project,
        time_field: "start_time",
        start_time: this.state.startDateOri
          ? this.$moment(this.state.startDateOri).format("YYYY-MM-DD")
          : null,
      };
      const res = await this.$axios.get("task/index/all", {
        params: params,
      });
      this.tasks = res.data.data;
    },
    async $_fetchMilestones() {
      const params = {
        project: this.state.project,
        time_field: "date",
        start_time: this.state.startDateOri
          ? this.$moment(this.state.startDateOri).format("YYYY-MM-DD")
          : null,
      };
      const res = await this.$axios.get("milestone/index/all", {
        params: params,
      });
      this.milestones = res.data.data;
    },
    $_onBatchDelete() {
      if (!this.state.project) {
        return;
      }
      this.$refs.deleteAlert.open();
    },
    $_stateInit() {
      this.state.startDateOri = this.$moment().format("YYYY-MM-DD");
    },
  },

  mounted() {
    this.$_stateInit();
  },

  watch: {
    "state.project": {
      handler() {
        if (this.state.project) {
          this.$_fetchTasks();
          this.$_fetchMilestones();
        }
      },
    },
    "state.startDateOri": {
      handler() {
        if (this.state.project) {
          this.$_fetchTasks();
          this.$_fetchMilestones();
        }
      },
    },
  },
};
</script>

<style>
</style>
<template>
  <div
    class="ws-infinite-scroll"
    @scroll="$_onScroll($event)"
  >
    <slot :items="items"></slot>
    <WsLoading v-if="fetching"></WsLoading>
  </div>
</template>

<script>
export default {
  data: () => ({
    items: [],
    page: 1,
    lastPage: 100,
    fetching: false,
  }),

  methods: {
    $_onScroll($event) {
      if (
        $event.srcElement.offsetHeight +
          $event.srcElement.scrollTop +
          this.scrollCheckDis >=
        $event.srcElement.scrollHeight
      ) {
        this.$_fetchItems();
      }
    },
    async $_fetchItems() {
      if (this.fetching) {
        return;
      }
      if (this.page > this.lastPage) {
        return;
      }
      try {
        this.fetching = true;
        const res = await this.$axios.get(this.modelName, {
          params: {
            page: this.page,
          },
        });
        this.lastPage = res.data.meta.last_page;
        this.page++;
        this.items.push(...res.data.data);
      } catch (error) {
        alert(`發生錯誤 ${error}`);
      } finally {
        this.fetching = false;
      }
    },
    $_clear() {
      this.items = [];
      this.page = 1;
      this.lastPage = 100;
    },
  },

  mounted() {
    this.$_fetchItems();
  },

  beforeDestroy() {
    this.$_clear();
  },

  props: {
    scrollCheckDis: {
      type: Number,
      default: 200,
    },
    modelName: {
      type: String,
      required: true,
    },
    params: {
      type: Object,
      default: null,
    },
  },
};
</script>

<style>
</style>
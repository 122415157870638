<template>
  <WsMain>
    <WsRow>
      <WsCol class="col-4">
        <WsState
          label="開始時間"
          type="date"
          v-model="state.startDate"
        ></WsState>
      </WsCol>
      <WsCol class="col-4">
        <WsState
          label="結束時間"
          type="date"
          v-model="state.endDate"
        ></WsState>
      </WsCol>
    </WsRow>
    <WsRow class="mt-20">
      <WsCol class="col-4">
        <WsAnalyticsCard title="訂單未稅總額">
          <template v-slot:content>
            <WsTitle
              size="1"
              class="py-66"
              center
            >{{$numeral(_orderSum).format('$0,0')}}</WsTitle>
          </template>
        </WsAnalyticsCard>
      </WsCol>
      <WsCol class="col-4">
        <WsAnalyticsCard title="訂單含稅總額">
          <template v-slot:content>
            <WsTitle
              size="1"
              class="py-66"
              center
            >{{$numeral(_orderSumWithTax).format('$0,0')}}</WsTitle>
          </template>
        </WsAnalyticsCard>
      </WsCol>
      <WsCol class="col-4">
        <WsAnalyticsCard title="時間內應收總額">
          <template v-slot:content>
            <WsTitle
              size="1"
              class="py-66"
              center
            >{{$numeral(_orderStageOverDateSum).format('$0,0')}}</WsTitle>
          </template>
        </WsAnalyticsCard>
      </WsCol>
    </WsRow>
    <WsRow class="mt-20">
      <WsCol class="col-12">
        <WsCard>
          <WsTitle size="5">期間訂單</WsTitle>
          <WsCrudTable
            class="mt-20"
            ref="WsCrudTable"
            :items="orders"
            :fields="orderFields"
            :showFields="orderShowFields"
            :inRowBtnRead="false"
            :inRowBtnUpdate="false"
            :inRowBtnDelete="false"
            :expandable="false"
            modelName="order"
            :paginate="false"
          ></WsCrudTable>
        </WsCard>
      </WsCol>
    </WsRow>
  </WsMain>
</template>

<script>
import order from "@/models/order";
export default {
  metaInfo() {
    return {
      title: "時間分析",
    };
  },

  data: () => ({
    state: {
      startDate: null,
      endDate: null,
    },
    orders: [],
    order_stages: [],
    orderFields: order.fields,
    orderShowFields: ["id", "price", "tax_type", "total_price"],
  }),

  methods: {
    $_dateSet() {
      this.state.startDate = this.$moment()
        .add(-1, "month")
        .add(-7, "day")
        .format("YYYY-MM-DD");
      this.state.endDate = this.$moment().add(-7, "day").format("YYYY-MM-DD");
    },
    async $_fetchOrders() {
      const params = {
        start_time: this.state.startDate,
        end_time: this.state.endDate,
        time_field: "deal_date",
      };
      const res = await this.$axios.get("order/index/all", {
        params: params,
      });
      this.orders = res.data.data;
    },
    async $_fetchOrderStages() {
      const params = {
        start_time: this.state.startDate,
        end_time: this.state.endDate,
        time_field: "invoice_date",
      };
      const res = await this.$axios.get("order_stage/index/all", {
        params: params,
      });
      this.order_stages = res.data.data;
    },
  },

  mounted() {
    this.$_dateSet();
  },

  computed: {
    _orderStageOverDateSum() {
      let _orderStageOverDateSum = 0;
      this.order_stages.forEach((order_stage) => {
        if (
          order_stage.invoice_date &&
          (!order_stage.transfer_date ||
            this.$moment(order_stage.transfer_date).isAfter(this.state.endDate))
        ) {
          const tax_type = order_stage.order
            ? order_stage.order.tax_type
            : null;
          if (tax_type == 5) {
            _orderStageOverDateSum += order_stage.price * 1.05;
          } else {
            _orderStageOverDateSum += order_stage.price;
          }
        }
      });
      return _orderStageOverDateSum;
    },
    _orderSum() {
      let _orderSum = 0;
      this.orders.forEach((order) => {
        _orderSum += order.price;
      });
      return _orderSum;
    },
    _orderSumWithTax() {
      let _orderSumWithTax = 0;
      this.orders.forEach((order) => {
        if (order.tax_type == 5) {
          _orderSumWithTax += Math.round(order.price * 1.05);
        } else {
          _orderSumWithTax += order.price;
        }
      });
      return _orderSumWithTax;
    },
  },

  watch: {
    state: {
      handler() {
        this.$_fetchOrders();
        this.$_fetchOrderStages();
      },
      deep: true,
    },
  },
};
</script>

<style>
</style>
<template>
  <div class="app-state-quotation">
    <div class="app-state-quotation__input-section">
      <WsMain>
        <ValidationObserver
          ref="form"
          class="ws-content-section-observer"
        >
          <form
            ref="createForm"
            @submit.prevent
          >
            <div class="ws-row">
              <div class="ws-col">
                <WsState
                  :type="fields.name.type"
                  :label="fields.name.label"
                  :rules="fields.name.rules"
                  v-model="updateData.name"
                  autofocus
                ></WsState>
              </div>
              <div class="ws-col">
                <WsState
                  ref="clientState"
                  :type="fields.client.type"
                  :label="fields.client.label"
                  :textKey="fields.client.textKey"
                  :modelName="fields.client.modelName"
                  :originValue="_clientValue"
                  :rules="fields.client.rules"
                  v-model="updateData.client"
                ></WsState>
              </div>
              <div class="ws-col">
                <WsState
                  :type="fields.creator_name.type"
                  :label="fields.creator_name.label"
                  :rules="fields.creator_name.rules"
                  v-model="updateData.creator_name"
                ></WsState>
              </div>
              <div class="ws-col">
                <WsState
                  :type="fields.contact_window.type"
                  :label="fields.contact_window.label"
                  v-model="updateData.contact_window"
                ></WsState>
              </div>
              <div class="ws-col">
                <WsState
                  :type="fields.contact_window_email.type"
                  :label="fields.contact_window_email.label"
                  v-model="updateData.contact_window_email"
                ></WsState>
              </div>
              <div class="ws-col">
                <WsState
                  :type="fields.has_return_sign.type"
                  :label="fields.has_return_sign.label"
                  :activeText="fields.has_return_sign.activeText"
                  :inactiveText="fields.has_return_sign.inactiveText"
                  v-model="updateData.has_return_sign"
                ></WsState>
              </div>
              <div class="ws-col">
                <WsState
                  :type="fields.date.type"
                  :label="fields.date.label"
                  :rules="fields.date.rules"
                  v-model="updateData.date"
                ></WsState>
              </div>
              <div class="ws-col">
                <WsState
                  :type="fields.begin_date.type"
                  :label="fields.begin_date.label"
                  v-model="updateData.begin_date"
                ></WsState>
              </div>
              <div class="ws-col">
                <WsState
                  :type="fields.need_total_amount.type"
                  :label="fields.need_total_amount.label"
                  :activeText="fields.need_total_amount.activeText"
                  :inactiveText="fields.need_total_amount.inactiveText"
                  v-model="updateData.need_total_amount"
                ></WsState>
              </div>
              <div class="ws-col">
                <WsModelPicker
                  ref="quotationExcutePicker"
                  @submit="$_onQuotationExcutePickerSubmit($event)"
                  modelName="quotation_excute"
                  :fields="quotationExcute.fields"
                  :showFields="quotationExcute.showFields"
                ></WsModelPicker>
                <WsState
                  :type="fields.excutes.type"
                  :label="fields.excutes.label"
                  :fields="fields.excutes.fields"
                  :showFields="fields.excutes.showFields"
                  v-model="updateData.excutes"
                  :inRowBtnRead="false"
                  :inRowBtnUpdate="false"
                  :inRowBtnDelete="false"
                >
                  <template v-slot:extendCols>
                    <div class="ws-col col-6">
                      <WsBtn @click="$_onQuotationExcuteAddClick()">加入預設項目</WsBtn>
                    </div>
                    <div class="ws-col col-6">
                      <WsBtn @click="$_onQuotaionExcuteSortUpdate()">調整順序</WsBtn>
                    </div>
                  </template>
                </WsState>
              </div>
              <div class="ws-col">
                <WsModelPicker
                  ref="quotationDescriptionPicker"
                  @submit="$_onQuotationDescriptionPickerSubmit($event)"
                  modelName="quotation_description"
                  :fields="quotationDescription.fields"
                  :showFields="quotationDescription.showFields"
                ></WsModelPicker>
                <WsState
                  :type="fields.descriptions.type"
                  :label="fields.descriptions.label"
                  :fields="fields.descriptions.fields"
                  :showFields="fields.descriptions.showFields"
                  v-model="updateData.descriptions"
                  :inRowBtnRead="false"
                  :inRowBtnUpdate="false"
                  :inRowBtnDelete="false"
                >
                  <template v-slot:extendCols>
                    <div class="ws-col col-6">
                      <WsBtn @click="$_onQuotationDescriptionAddClick()">加入預設說明</WsBtn>
                    </div>
                    <div class="ws-col col-6">
                      <WsBtn @click="$_onQuotaionDescriptionSortUpdate()">調整順序</WsBtn>
                    </div>
                  </template>
                </WsState>
              </div>
              <WsCol>
                <WsBtn
                  :loading="submitLoading"
                  @click="$_onSave()"
                  full
                >儲存</WsBtn>
              </WsCol>
            </div>
          </form>
        </ValidationObserver>
      </WsMain>
    </div>
    <div class="app-state-quotation__browse-section">
      <AppQuotation
        :name="updateData.name"
        :company_name="_company_name"
        :creator_name="updateData.creator_name"
        :contact_window="updateData.contact_window"
        :contact_window_email="updateData.contact_window_email"
        :has_return_sign="updateData.has_return_sign"
        :date="updateData.date"
        :begin_date="updateData.begin_date"
        :need_total_amount="updateData.need_total_amount"
        :excutes="updateData.excutes"
        :descriptions="updateData.descriptions"
      ></AppQuotation>
    </div>
    <!-- Excutes Sort Dialog -->
    <WsSortDialog
      ref="WsExcutesSortDialog"
      v-model="updateData.excutes"
    ></WsSortDialog>
    <!-- Descriptions Sort Dialog -->
    <WsSortDialog
      ref="WsDescriptionsSortDialog"
      nameKey="content"
      v-model="updateData.descriptions"
    ></WsSortDialog>
  </div>
</template>

<script>
import quotation from "@/models/quotation";
import quotation_excute from "@/models/quotation_excute";
import quotation_description from "@/models/quotation_description";
import moment from "moment";
export default {
  data: () => ({
    submitLoading: false,
    fields: quotation.fields,
    quotationExcute: quotation_excute,
    quotationDescription: quotation_description,
    quotationExcuteShowFields: ["name", "content"],
    updateData: {
      client: null,
      name: null,
      creator_name: null,
      contact_window: null,
      contact_window_email: null,
      has_return_sign: false,
      date: moment().format("YYYY-MM-DD"),
      begin_date: moment().format("YYYY-MM-DD"),
      need_total_amount: true,
      excutes: [],
      descriptions: [],
    },
  }),

  methods: {
    $_onQuotaionExcuteSortUpdate() {
      this.$refs.WsExcutesSortDialog.open();
    },
    $_onQuotaionDescriptionSortUpdate() {
      this.$refs.WsDescriptionsSortDialog.open();
    },
    submitLoadingStart() {
      this.submitLoading = true;
    },
    submitLoadingStop() {
      this.submitLoading = false;
    },
    async $_onSave() {
      const isValidate = await this.$refs.form.validate();
      if (!isValidate) {
        return;
      }
      this.$emit("save", this.updateData);
    },
    $_onQuotationExcuteAddClick() {
      this.$refs.quotationExcutePicker.open();
    },
    $_onQuotationDescriptionAddClick() {
      this.$refs.quotationDescriptionPicker.open();
    },
    $_onQuotationExcutePickerSubmit($event) {
      if (!$event) {
        return;
      }
      $event.forEach((excute) => {
        this.updateData.excutes.push({
          name: excute.name,
          content: excute.content,
          excute_days: excute.excute_days,
          price: excute.price,
        });
      });
    },
    $_onQuotationDescriptionPickerSubmit($event) {
      if (!$event) {
        return;
      }
      $event.forEach((description) => {
        this.updateData.descriptions.push({
          content: description.content,
        });
      });
    },
    $_valueSet() {
      if (this.quotation) {
        for (let key in this.updateData) {
          if (this.fields[key]) {
            this.updateData[key] = this.$_CMS_getUpdateData(
              this.quotation[key],
              this.fields[key]
            );
          } else {
            this.updateData[key] = this.evaluation[key];
          }
        }
      }
    },
  },

  props: {
    quotation: {
      type: Object,
      default: null,
    },
  },

  computed: {
    _clientValue() {
      if (this.quotation) {
        return this.quotation.client;
      } else {
        return null;
      }
    },
    _company_name() {
      if (!this.updateData.client) {
        return null;
      } else {
        return this.$refs.clientState.getValueText();
      }
    },
  },

  async mounted() {
    this.$_valueSet();
  },
};
</script>
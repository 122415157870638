export default {
  'validate-min': '不得少於{length}位',
  'validate-max': '不得多於{length}位',
  'validate-required': '此項目為必填',
  'validate-email': 'email格式不符',
  'find no email.': '找不到此Email',
  'password not correct.': '密碼錯誤',
  'internet error.': '網路連線錯誤',
  'The value has been taken.': '內容已被使用，不可重複。',
  'index error.': '取得資料列表錯誤',
  'index locale error.': '取得語言列表錯誤',
}
<template>
  <WsMain>
    <AppGardenCalendar
      :creatable="true"
      :updatable="true"
      :deletable="true"
      :params="_params"
      :taskFields="taskFields"
    >
      <template v-slot:searchSection>
        <WsState
          type="belongsTo"
          label="執行者"
          textKey="name"
          modelName="wasa"
          v-model="state.taker"
        ></WsState>
        <WsState
          type="belongsTo"
          class="mt-10"
          label="專案"
          textKey="name"
          modelName="project"
          :conditions="projectConditions"
          v-model="state.project"
        ></WsState>
      </template>
    </AppGardenCalendar>
  </WsMain>
</template>

<script>
export default {
  metaInfo() {
    return {
      title: "山葵行事曆",
    };
  },
  data: () => ({
    taskFields: [
      "id",
      "name",
      "start_time",
      "is_close",
      "is_finish",
      "hour",
      "finish_hour",
      "remark",
      "project",
      "taker",
      "excute_type",
      "evaluation_material",
      "evaluation_material_option",
      "creator",
      "adjust",
      "qc",
      "is_rd_task",
      "is_not_complete",
    ],
    projectConditions: [
      {
        key: "status",
        value: "0,1",
      },
      {
        key: "order_by",
        value: "created_at",
      },
      {
        key: "order_way",
        value: "desc",
      },
    ],
    state: {
      taker: null,
      project: null,
    },
  }),

  computed: {
    _params() {
      return {
        taker: this.state.taker,
        project: this.state.project,
      };
    },
  },
};
</script>

<style>
</style>
<template>
  <div
    class="ws-state-input"
    :class="[{'is-focus':isFocus},{'full':full},{'is-ok':isOk},{'is-error':_isError||(errors&&errors.length)},{'is-dislabled':disabled},{'has-before-icon':_hasBeforeIcon},{'has-after-icon':_hasAfterIcon},{small:small},{center:center}]"
    :style="{'max-width':maxWidth}"
  >
    <div
      @click="$_inputBlockOnClick()"
      v-click-outside="$_inputBlockOnBlur"
      class="ws-state__block"
    >
      <WsIcon
        v-if="type=='search'"
        size="20"
        class="before-icon"
      >search</WsIcon>
      <input
        @keyup="$_onKeyup"
        ref="input"
        :autofocus="autofocus"
        :name="name"
        :type="_type"
        :disabled="disabled"
        :placeholder="placeholder"
        :value="value"
        @input="$emit('input',$event.target.value)"
      >
      <WsIcon
        @click="$_passwordSeeOff()"
        v-if="type=='password'&&passwordSee"
        class="after-icon clickable"
      >visibility_off</WsIcon>
      <WsIcon
        @click="$_passwordSeeOn()"
        v-else-if="type=='password'&&!passwordSee"
        class="after-icon clickable"
      >visibility</WsIcon>
      <WsIcon
        v-else-if="isOk"
        class="after-icon"
        size="20"
      >check_circle</WsIcon>
      <WsIcon
        v-else-if="type=='link'"
        class="after-icon"
        size="20"
      >link</WsIcon>
      <WsIcon
        v-else-if="_isError"
        class="after-icon"
        size="20"
      >info</WsIcon>
    </div>
  </div>
</template>

<script>
export default {
  data: () => ({
    isFocus: false,
    passwordSee: false,
  }),
  props: {
    full: {
      type: Boolean,
      default: false,
    },
    center: {
      type: Boolean,
      default: false,
    },
    small: {
      type: Boolean,
      default: false,
    },
    errors: {
      type: Array,
      default: null,
    },
    maxWidth: {
      type: String,
      default: null,
    },
    autofocus: {
      type: Boolean,
      default: false,
    },
    name: {
      type: String,
      default: null,
    },
    type: {
      type: String,
      default: "text",
    },
    placeholder: {
      type: String,
      default: null,
    },
    disabled: {
      type: Boolean,
      default: false,
    },
    note: {
      type: String,
      default: null,
    },
    errorMessage: {
      type: String,
      default: null,
    },
    isOk: {
      type: Boolean,
      default: false,
    },
    value: {
      type: [Number, String],
      default: null,
    },
    rules: {
      type: String,
      default: null,
    },
  },
  methods: {
    focus() {
      setTimeout(() => {
        this.isFocus = true;
      }, 0);
    },
    $_onKeyup($event) {
      this.$emit("keyup", $event);
    },
    $_passwordSeeOff() {
      this.passwordSee = false;
    },
    $_passwordSeeOn() {
      this.passwordSee = true;
    },
    $_inputBlockOnClick() {
      if (this.disabled) {
        return;
      }
      this.isFocus = true;
    },
    $_inputBlockOnBlur() {
      this.isFocus = false;
    },
  },
  computed: {
    _hasAfterIcon() {
      if (this.type == "password") {
        return true;
      } else if (this.isOk) {
        return true;
      } else if (this._isError) {
        return true;
      } else {
        return false;
      }
    },
    _hasBeforeIcon() {
      if (this.type == "search") {
        return true;
      } else {
        return false;
      }
    },
    _isError() {
      if (this.errorMessage || (this.errors && this.errors.length)) {
        return true;
      } else {
        return false;
      }
    },
    _type() {
      if (this.type == "password") {
        if (this.passwordSee) {
          return "text";
        } else {
          return "password";
        }
      } else {
        return this.type;
      }
    },
  },
  watch: {
    isFocus: {
      handler() {
        if (this.isFocus) {
          this.$refs.input.focus();
        } else {
          this.$refs.input.blur();
        }
      },
    },
  },
};
</script>
<template>
  <div
    class="ws-content"
    :style="[{'min-height':minHeight},{'max-width':maxWidth}]"
  >
    <slot></slot>
  </div>
</template>
<script>
export default {
  props: {
    minHeight: {
      type: String,
      default: null,
    },
    maxWidth: {
      type: String,
      default: null,
    },
  },
};
</script>
<template>
  <div
    class="ws-navigation-drawer"
    :class="[{active:$store.state.app.sideMenuOpen}]"
  >
    <div
      @click="$_close()"
      class="bg"
    ></div>
    <div class="ws-navigation-drawer__container">
      <div class="ws-navigation-drawer__logo">
        <img
          src="@/__vue2stone_cms/img/wasajon.svg"
          alt=""
        >
      </div>
      <div class="ws-navigation-drawer__menu">
        <div
          v-for="(menuItem,menuIndex) in menu"
          :key="menuIndex"
        >
          <WsMenu
            v-if="menuItem.menu"
            :menu="menuItem.menu"
            :title="menuItem.title"
            :icon="menuItem.icon"
          ></WsMenu>
          <WsMenuItem
            v-else
            :title="menuItem.title"
            :link="menuItem.link"
            :icon="menuItem.icon"
          ></WsMenuItem>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
export default {
  methods: {
    $_close() {
      this.$store.dispatch("app/menuClose");
    },
  },

  props: {
    menu: {
      type: [Array, Object],
      default: null,
    },
    icon: {
      type: String,
      default: null,
    },
    contentPadding: {
      type: Boolean,
      default: true,
    },
  },
};
</script>
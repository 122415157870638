<template>
  <div>
    <WsUpdate
      ref="WsUpdate"
      :modelName="modelName"
      :id="_id"
      :label="label"
      :fields="fields"
      :primary="pageSetting.primary"
      :secondary="pageSetting.secondary"
      titleKey="name"
      v-model="updateData"
      :emitSubmit="true"
      @submit="$_onSubmit()"
    >
    </WsUpdate>
    <WsMain v-if="tulpaSectionTemplate">
      <WsCard>
        <WsTitle size="5">區塊內容</WsTitle>
        <WsState
          class="mt-20"
          type="form"
          :fields="tulpaSectionTemplate.fields"
          v-model="updateData.content"
        ></WsState>
      </WsCard>
    </WsMain>
  </div>
</template>

<script>
import model from "@/__vue2stone_cms/models/tulpa_section";
export default {
  metaInfo() {
    return {
      title: "編輯區塊",
    };
  },
  data: () => ({
    updateData: null,
    modelName: model.modelName,
    label: model.label,
    fields: model.fields,
    pageSetting: {
      primary: [
        {
          type: "stateCard",
          floors: [
            {
              fields: ["tulpa_section_template", "name"],
            },
          ],
        },
      ],
      secondary: [
        {
          type: "stateCard",
          floors: [
            {
              title: "設定",
              fields: ["tags", "remark"],
            },
          ],
        },
      ],
    },
    tulpaSectionTemplate: null,
    // state: {
    //   content: {},
    // },
  }),

  methods: {
    async $_onSubmit() {
      const postData = JSON.parse(JSON.stringify(this.updateData));
      try {
        this.$refs.WsUpdate.loadingStart();
        await this.$axios.patch(`tulpa_section/${this._id}`, postData);
        this.$router.push("/tulpa_section");
      } catch (error) {
        console.log(error);
        alert("儲存錯誤");
      } finally {
        this.$refs.WsUpdate.loadingStop();
      }
    },
    async $_fetchTulpaSectionTemplate() {
      const res = await this.$axios.get(
        `tulpa_section_template/${this._tulpaSectionTemplateId}`
      );
      this.tulpaSectionTemplate = res.data.data;
    },
  },

  watch: {
    _tulpaSectionTemplateId: {
      handler() {
        if (this._tulpaSectionTemplateId) {
          this.$_fetchTulpaSectionTemplate();
        } else {
          this.tulpaSectionTemplate = null;
        }
      },
    },
  },

  computed: {
    _id() {
      return this.$route.params.id;
    },
    _tulpaSectionTemplateId() {
      if (!this.updateData) {
        return null;
      }
      return this.updateData.tulpa_section_template;
    },
  },
};
</script>

<style>
</style>
export default {
  modelName: "evaluation_stage",
  label: "執行階段",
  fields: {
    name: {
      type: 'text',
      label: '名稱'
    },
    evaluation_materials: {
      type: 'belongsToMany',
      label: '評估元件'
    },
    updated_at: {
      type: "datetime",
      label: "最後更新時間",
      readonly: true
    },
  },
}
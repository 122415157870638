<template>
  <div>
    <WsUpdate
      :modelName="modelName"
      :id="_id"
      :label="label"
      :fields="fields"
      :primary="pageSetting.primary"
      :secondary="pageSetting.secondary"
      titleKey="name"
      v-model="updateData"
    >
    </WsUpdate>
  </div>
</template>

<script>
import model from "@/models/order";
export default {
  metaInfo() {
    return {
      title: "編輯訂單",
    };
  },
  data: () => ({
    updateData: null,
    modelName: model.modelName,
    label: model.label,
    fields: model.fields,
    pageSetting: {
      primary: [
        {
          type: "stateCard",
          floors: [
            {
              fields: [
                "quotation",
                "name",
                "price",
                "relate_files",
                "relate_links",
              ],
            },
          ],
        },
      ],
      secondary: [
        {
          type: "stateCard",
          floors: [
            {
              title: "設定",
              fields: ["deal_date", "client", "tax_type"],
            },
          ],
        },
        {
          type: "stateCard",
          floors: [
            {
              title: "狀態",
              fields: ["status"],
              hideLabelFields: ["status"],
            },
          ],
        },
      ],
    },
  }),
  methods: {},
  computed: {
    _id() {
      return this.$route.params.id;
    },
  },
  mounted() {},
};
</script>

<style>
</style>
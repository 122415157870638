<template>
  <WsMain>
    <WsReadSection
      :id="_id"
      :modelName="$store.state.model[modelName].modelName"
      :label="$store.state.model[modelName].label"
      :fields="$store.state.model[modelName].fields"
      :leftFields="leftFields"
      :rightFields="rightFields"
    ></WsReadSection>
    <WsCrud
      class="mt-40"
      :modelName="$store.state.model.order_stage.modelName"
      :label="$store.state.model.order_stage.label"
      :fields="$store.state.model.order_stage.fields"
      :liveSearching="true"
      :showFields="orderStageShowFields"
      :expandable="false"
      :showExpand="false"
      :fetchQuery="_orderStageFetchQuery"
      :createHideFields="orderStageCreateHideFields"
      :createDefaultValues="_createDefaultValues"
    ></WsCrud>
  </WsMain>
</template>

<script>
export default {
  data: () => ({
    modelName: "order",
    leftFields: [
      "order_no",
      "name",
      "client",
      "tax_type",
      "price",
      "tax_price",
      "total_price",
    ],
    rightFields: ["relate_files", "relate_links", "quotation"],
    orderStageCreateHideFields: ["order"],
    orderStageShowFields: [
      "id",
      "status",
      "stage_name",
      "order_no",
      "order",
      "price",
      "total_price",
      "invoice_date",
      "invoice_number",
      "expect_transfer_date",
      "transfer_date",
    ],
  }),

  computed: {
    _createDefaultValues() {
      return {
        order: this._id,
      };
    },
    _id() {
      return this.$route.params.id;
    },
    _orderStageFetchQuery() {
      return {
        order: this._id,
      };
    },
  },
};
</script>

<style>
</style>
<template>
  <WsMain>
    <WsCrud
      :modelName="$store.state.model[modelName].modelName"
      :label="$store.state.model[modelName].label"
      :fields="$store.state.model[modelName].fields"
      :liveSearching="true"
      :pageMode="true"
      :showFields="showFields"
      :expandable="false"
      :showExpand="false"
      :dialogRead="true"
      :copyable="true"
      :order="order"
      :plusOrderItems="plusOrderItems"
    >
      <template v-slot:readDialogContent="readContentSlotProps">
        <AppQuotation
          :name="readContentSlotProps.item.name"
          :company_name="$_getCompanyName(readContentSlotProps.item.client)"
          :creator_name="readContentSlotProps.item.creator_name"
          :contact_window="readContentSlotProps.item.contact_window"
          :contact_window_email="readContentSlotProps.item.contact_window_email"
          :has_return_sign="readContentSlotProps.item.has_return_sign"
          :date="readContentSlotProps.item.date"
          :begin_date="readContentSlotProps.item.begin_date"
          :need_total_amount="readContentSlotProps.item.need_total_amount"
          :excutes="readContentSlotProps.item.excutes"
          :descriptions="readContentSlotProps.item.descriptions"
        ></AppQuotation>
      </template>
      <template v-slot:readDialogRightActions="readRightActionsSlotProps">
        <WsBtn
          :loading="downloadLoading"
          @click="$_onQuotationDownload(readRightActionsSlotProps.item)"
        >下載</WsBtn>
      </template>
    </WsCrud>
    <AppQuotation
      class="ws-quotation-download-browse"
      ref="downloadBrowse"
      v-if="downloadItem"
      :name="downloadItem.name"
      :company_name="$_getCompanyName(downloadItem.client)"
      :creator_name="downloadItem.creator_name"
      :contact_window="downloadItem.contact_window"
      :contact_window_email="downloadItem.contact_window_email"
      :has_return_sign="downloadItem.has_return_sign"
      :date="downloadItem.date"
      :begin_date="downloadItem.begin_date"
      :need_total_amount="downloadItem.need_total_amount"
      :excutes="downloadItem.excutes"
      :descriptions="downloadItem.descriptions"
    ></AppQuotation>
  </WsMain>
</template>

<script>
export default {
  metaInfo() {
    return {
      title: "報價單",
    };
  },

  data: () => ({
    downloadLoading: false,
    downloadItem: null,
    order: "last_date",
    plusOrderItems: [
      {
        value: "last_date",
        text: "最近報價",
        order_by: "date",
        order_way: "desc",
      },
    ],
    modelName: "quotation",
    showFields: ["id", "name", "client", "creator_name", "date", "begin_date"],
  }),

  methods: {
    $_onQuotationDownload(item) {
      this.downloadLoading = true;
      this.downloadItem = item;
      const vm = this;
      setTimeout(() => {
        vm.$html2canvas(vm.$refs.downloadBrowse.$el).then(function (canvas) {
          let date = new Date();
          let uri = canvas.toDataURL();
          let filename =
            "報價單-" +
            vm.downloadItem.name +
            "-" +
            vm.$moment(date).format("YYYY-MM-DD") +
            ".png";
          let link = document.createElement("a");
          if (typeof link.download === "string") {
            link.href = uri;
            link.download = filename;

            document.body.appendChild(link);

            link.click();

            document.body.removeChild(link);
          } else {
            window.open(uri);
          }
          vm.downloadItem = null;
          vm.downloadLoading = false;
        });
      }, 0);
    },
    $_getCompanyName(client) {
      if (!client) {
        return null;
      } else {
        return client.name;
      }
    },
  },
};
</script>
<template>
  <WsMain>
    <WsCrud
      :modelName="$store.state.model[modelName].modelName"
      :label="$store.state.model[modelName].label"
      :fields="$store.state.model[modelName].fields"
      :liveSearching="true"
      :pageMode="true"
      :showFields="showFields"
      :expandable="false"
      :showExpand="false"
      :infiniteScroll="true"
      :order="order"
      :plusOrderItems="plusOrderItems"
    ></WsCrud>
  </WsMain>
</template>

<script>
export default {
  metaInfo() {
    return {
      title: "訂單",
    };
  },
  data: () => ({
    order: "last_order_no",
    plusOrderItems: [
      {
        value: "last_order_no",
        text: "訂單編號",
        order_by: "order_no",
        order_way: "desc",
      },
    ],
    modelName: "order",
    showFields: [
      "order_no",
      "name",
      "client",
      "price",
      "total_price",
      "tax_price",
      "deal_date",
    ],
  }),
};
</script>
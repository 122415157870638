import app from '@/__vue2stone_cms/store/app'
import auth from '@/__vue2stone_cms/store/auth'
import locale from '@/__vue2stone_cms/store/locale'
import Vue from 'vue'
import Vuex from 'vuex'
import model from "./model";
import setting from "./setting";

Vue.use(Vuex)

export default new Vuex.Store({
  state: {
  },
  mutations: {
  },
  actions: {
  },
  modules: {
    auth: auth,
    app: app,
    locale: locale,
    model: model,
    setting: setting,
  }
})

<template>
  <div
    v-if="_diffArr"
    class="ws-info-string-compare"
  >
    <span
      v-for="(diffItem,diffKey) in _diffArr"
      :key="diffKey"
      :class="$_getDiffClass(diffItem)"
    >{{diffItem.value}}</span>
  </div>
</template>

<script>
const diff = require("diff");
export default {
  data: () => ({}),
  props: {
    value: {
      type: Array,
      default: null
    }
  },
  methods: {
    $_getDiffClass(diffItem) {
      if (diffItem.removed) {
        return "removed";
      } else if (diffItem.added) {
        return "added";
      } else {
        return null;
      }
    }
  },
  computed: {
    _diffArr() {
      if (!this.value) {
        return null;
      } else if (!this.value[0] || !this.value[1]) {
        return null;
      } else {
        return diff.diffChars(this.value[0], this.value[1]);
      }
    }
  }
};
</script>
import moment from 'moment'
export default {
  modelName: "billing_statement",
  label: "請款單",
  fields: {
    id: {
      type: "text",
      label: "ID",
      readonly: true
    },
    wasa: {
      type: 'belongsTo',
      label: '申請人',
      textKey: "name",
      modelName: "wasa",
      width: '100px',
    },
    invoice_type: {
      type: 'select',
      label: '憑證類型',
      items: [
        { value: 0, text: '紙本發票' },
        { value: 1, text: '電子發票' },
        { value: 2, text: 'Invoice' },
      ],
      defaultValue: 0
    },
    images: {
      type: "files",
      label: "附件圖片",
      uploadUrl: 'billing_statement/image/'
    },
    type: {
      type: 'select',
      label: '付款類型',
      items: [
        { value: 0, text: '現金' },
        { value: 1, text: '信用卡-需附明細截圖' },
      ],
      defaultValue: 0
    },
    amount: {
      type: 'currency',
      label: '金額',
    },
    pay_date: {
      type: 'date',
      label: '付款日期',
    },
    apply_date: {
      type: 'date',
      label: '請款日期',
      defaultValue: moment().format('YYYY-MM-DD')
    },
    remark: {
      text: 'textarea',
      label: '備註'
    },
    status: {
      type: 'select',
      label: '覆核狀態',
      defaultValue: 0,
      items: [
        {
          value: 0,
          text: '申請中'
        },
        {
          value: 1,
          text: 'OK'
        },
        {
          value: 2,
          text: '打妹'
        },
        {
          value: 3,
          text: '已付款'
        },
      ]
    },
    updated_at: {
      type: "datetime",
      label: "最後更新時間",
      readonly: true
    },
  },
}
import moment from 'moment'
export default {
  modelName: "evaluation",
  label: "執行評估",
  fields: {
    id: {
      type: "text",
      label: "ID",
      readonly: true,
      width: '70px',
      sortable: true
    },
    project: {
      type: 'belongsTo',
      label: '專案',
      textKey: "name",
      modelName: "project",
    },
    name: {
      type: 'text',
      label: '名稱'
    },
    adjust_time_rate: {
      type: 'number',
      label: '時間調整比例',
      defaultValue: 1
    },
    adjust_price_rate: {
      type: 'number',
      label: '價格調整比例',
      defaultValue: 1
    },
    fundamental_hours: {
      type: 'number',
      label: '基礎建置時數',
      defaultValue: 3
    },
    issue_time_rate: {
      type: 'number',
      label: 'QC調整時間比例',
      defaultValue: .3
    },
    remark: {
      type: "textarea",
      label: "備註"
    },
    arrange_way: {
      type: "select",
      label: "安排模式",
      defaultValue: 'standard',
      items: [
        {
          value: 'standard',
          text: '標準 (同樣階段的不同工作項目，有前後關係的會間隔一週)'
        },
        {
          value: 'press',
          text: '緊密 (同樣階段的不同工作項目，有前後關係的緊接著進行)'
        },
      ]
    },
    start_date: {
      type: 'date',
      label: '開始日期',
      defaultValue: moment().format('YYYY-MM-DD')
    },
    wasas: {
      type: 'belongsToMany',
      label: '山葵仔',
      textKey: "name",
      modelName: "wasa",
    },
    evaluation_wasas: {
      type: 'hasMany',
      label: '執行人員安排',
      fields: {
        id: {
          type: 'text',
          label: 'ID',
          readonly: true
        },
        excute_type: {
          type: 'belongsTo',
          label: '執行類型',
          textKey: "name",
          modelName: "excute_type",
        },
        wasa: {
          type: 'belongsTo',
          label: '執行類型',
          textKey: "name",
          modelName: "wasa",
        },
      }
    },
    // evaluation_stages
    // evaluation_excute_types_setting
    updated_at: {
      type: "datetime",
      label: "最後更新時間",
      readonly: true
    },
  },
}
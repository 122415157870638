<template>
  <ValidationProvider
    :rules="rules"
    v-slot="{ errors }"
    class="ws-state"
  >
    <div
      class="ws-state__container"
      :class="[{'is-error':errors&&errors.length}]"
    >
      <WsTitle
        v-if="_label"
        size="6"
      >{{_label}}</WsTitle>
      <WsStateInput
        v-if="type=='text'||type=='password'||type=='email'||type=='link'||type=='number'||type=='search'||type=='currency'"
        ref="WsStateInput"
        @keyup="$_onKeyup"
        :autofocus="autofocus"
        :maxWidth="maxWidth"
        :rules="rules"
        :type="type"
        :name="name"
        :errors="errors"
        :placeholder="placeholder"
        :note="note"
        :isOk="isOk"
        :errorMessage="errorMessage"
        :disabled="disabled"
        :value="value"
        @input="$emit('input',$event)"
      ></WsStateInput>
      <WsStateTextarea
        v-else-if="type=='textarea'"
        :value="value"
        :autoGrow="autoGrow"
        @input="$emit('input',$event)"
        @keyup="$_onKeyup"
      ></WsStateTextarea>
      <WsStateEditor
        v-else-if="type=='editor'"
        :value="value"
        @input="$emit('input',$event)"
      ></WsStateEditor>
      <WsStateRelationshipSelect
        ref="belongsToState"
        v-else-if="type=='belongsTo'"
        :placeholder="placeholder"
        :modelName="modelName"
        :parentState="parentState"
        :requiredField="requiredField"
        :requiredFieldState="requiredFieldState"
        :conditions="conditions"
        :parent="parent"
        :textKey="textKey"
        :getText="getText"
        :value="value"
        :errors="errors"
        :originValue="originValue"
        @input="$emit('input',$event)"
        :note="note"
        :rules="rules"
        :errorMessage="errorMessage"
      />
      <WsStateList
        v-else-if="type=='hasMany'"
        :value="value"
        @input="$emit('input',$event)"
        :fields="fields"
        :showFields="showFields"
        :label="label"
      ></WsStateList>
      <WsStateList
        v-else-if="type=='hasManyList'"
        :value="value"
        @input="$emit('input',$event)"
        :fields="fields"
        :showFields="showFields"
        :label="label"
      ></WsStateList>
      <WsStateRelationshipSelect
        v-else-if="type=='belongsToMany'"
        :placeholder="placeholder"
        :modelName="modelName"
        :conditions="conditions"
        :rules="rules"
        multiple
        :textKey="textKey"
        :getText="getText"
        :errors="errors"
        :parentState="parentState"
        :requiredField="requiredField"
        :requiredFieldState="requiredFieldState"
        :parent="parent"
        :value="value"
        :originValue="originValue"
        @input="$emit('input',$event)"
      />
      <WsStateSwitch
        v-else-if="type=='switch'"
        :activeText="activeText"
        :inactiveText="inactiveText"
        :value="value"
        @input="$emit('input',$event)"
      ></WsStateSwitch>
      <WsStateVersion
        v-else-if="type=='last_version'"
        :fields="fields"
        :value="value"
        :originValue="originValue"
        @input="$emit('input',$event)"
      ></WsStateVersion>
      <WsStateDate
        v-else-if="type=='date'"
        :value="value"
        @input="$emit('input',$event)"
      ></WsStateDate>
      <WsStateDatetime
        v-else-if="type=='datetime'"
        :value="value"
        @input="$emit('input',$event)"
      ></WsStateDatetime>
      <WsStateLocales
        v-else-if="type=='locales'"
        :fields="fields"
        :value="value"
        @input="$emit('input',$event)"
      ></WsStateLocales>
      <WsStateFile
        v-else-if="type=='file'"
        :value="value"
        :uploadUrl="uploadUrl"
        :parent="parent"
        :parentState="parentState"
        @input="$emit('input',$event)"
      ></WsStateFile>
      <WsStateFiles
        v-else-if="type=='files'"
        :value="value"
        :uploadUrl="uploadUrl"
        :parent="parent"
        :parentState="parentState"
        @input="$emit('input',$event)"
      ></WsStateFiles>
      <WsStateTags
        v-else-if="type=='tags'"
        :value="value"
        @input="$emit('input',$event)"
        :placeholder="placeholder"
      ></WsStateTags>
      <WsStateSelect
        v-else-if="type=='select'"
        :items="items"
        :value="value"
        @input="$emit('input',$event)"
      ></WsStateSelect>
      <WsStateSelect
        v-else-if="type=='multi-select'"
        :items="items"
        :value="value"
        multiple
        @input="$emit('input',$event)"
      ></WsStateSelect>
      <WsStateRadio
        v-else-if="type=='radio'"
        :items="items"
        :value="value"
        @input="$emit('input',$event)"
      ></WsStateRadio>
      <WsStateImage
        v-else-if="type=='image'"
        :value="value"
        :uploadUrl="uploadUrl"
        :mode="mode"
        :staticUrl="staticUrl"
        :postData="postData"
        :requestMethod="requestMethod"
        :maxWidth="maxWidth"
        :parent="parent"
        :parentState="parentState"
        @input="$emit('input',$event)"
      ></WsStateImage>
      <WsStateImages
        v-else-if="type=='images'"
        :value="value"
        :uploadUrl="uploadUrl"
        :maxWidth="maxWidth"
        :parent="parent"
        :parentState="parentState"
        @input="$emit('input',$event)"
      ></WsStateImages>
      <WsStateColor
        v-else-if="type=='color'"
        :value="value"
        @input="$emit('input',$event)"
      ></WsStateColor>
      <WsStateList
        v-else-if="type=='list'"
        :value="value"
        @input="$emit('input',$event)"
        :fields="fields"
        :showFields="showFields"
        :label="label"
        :inRowBtnRead="inRowBtnRead"
        :inRowBtnUpdate="inRowBtnUpdate"
        :inRowBtnDelete="inRowBtnDelete"
      >
        <template v-slot:extendCols>
          <slot name="extendCols"></slot>
        </template>
      </WsStateList>
      <WsStateFields
        v-else-if="type=='fields'"
        :value="value"
        @input="$emit('input',$event)"
      >
      </WsStateFields>
      <WsStateRules
        v-else-if="type=='rules'"
        :value="value"
        @input="$emit('input',$event)"
      ></WsStateRules>
      <WsStateForm
        v-else-if="type=='form'"
        :fields="fields"
        :value="value"
        :resourceData="resourceData"
        @input="$emit('input',$event)"
        :errorMessages="errorMessages"
      ></WsStateForm>
      <WsStateWasaIntro
        v-else-if="type=='wasaintro'"
        :value="value"
        @input="$emit('input',$event)"
      ></WsStateWasaIntro>
      <input
        type="hidden"
        :value="value"
      >
      <div
        v-if="errors&&errors.length"
        class="ws-state__detail"
      >
        <WsIcon size="20">info</WsIcon>
        <p>{{errors[0]}}</p>
      </div>
      <div
        v-if="errorMessage"
        class="ws-state__detail"
      >
        <WsIcon size="20">info</WsIcon>
        <p>{{errorMessage}}</p>
      </div>
      <div
        v-if="note"
        class="ws-state__detail"
      >
      </div>
    </div>
  </ValidationProvider>
</template>

<script>
import { extend } from "vee-validate";
import { required, email, min, max } from "vee-validate/dist/rules";
// import locales from "./../locales/zh_tw";
import { message } from "@/__vue2stone_cms";
extend("max", {
  ...max,
  // message: "不得多於{length}位"
  message: message["validate-max"],
});
extend("min", {
  ...min,
  // message: "不得少於{length}位"
  message: message["validate-min"],
});
extend("required", {
  ...required,
  // message: "此項目為必填"
  message: message["validate-required"],
});
extend("email", {
  ...email,
  // message: "email格式不符"
  message: message["validate-email"],
});
export default {
  name: "WsState",
  computed: {
    _label() {
      if (this.displayLabel) {
        return this.label;
      } else {
        return null;
      }
    },
  },
  methods: {
    getValueText() {
      if (this.type == "belongsTo") {
        return this.$refs.belongsToState.getValueText();
      }
    },
    $_onKeyup($event) {
      this.$emit("keyup", $event);
    },
    focus() {
      if (
        this.type == "text" ||
        this.type == "email" ||
        this.type == "search" ||
        this.type == "password"
      ) {
        if (this.$refs.WsStateInput) {
          this.$refs.WsStateInput.focus();
        }
      }
    },
  },
  mounted() {
    if (this.autofocus) {
      if (this.$refs.WsStateInput) {
        this.$refs.WsStateInput.focus();
      }
    }
  },
  props: {
    showFields: {
      type: Array,
      default: null,
    },
    originValue: {},
    displayLabel: {
      type: Boolean,
      default: true,
    },
    getText: {
      type: Function,
      default: null,
    },
    textKey: {
      type: String,
      default: "name",
    },
    requiredFieldState: {},
    requiredField: {
      type: String,
      default: null,
    },
    autoGrow: {
      type: Boolean,
      default: false,
    },
    items: {
      type: Array,
      default: null,
    },
    uploadUrl: {
      type: String,
      default: null,
    },
    activeText: {
      type: String,
      default: null,
    },
    inactiveText: {
      type: String,
      default: null,
    },
    conditions: {
      type: Array,
      default: null,
    },
    maxWidth: {
      type: String,
      default: null,
    },
    fields: {
      type: Object,
      default: null,
    },
    autofocus: {
      type: Boolean,
      default: false,
    },
    type: {
      type: String,
      default: "text",
    },
    label: {
      type: String,
      default: null,
    },
    placeholder: {
      type: String,
    },
    note: {
      type: String,
      default: null,
    },
    errorMessage: {
      type: String,
      default: null,
    },
    errorMessages: {
      type: Object,
      default: null,
    },
    isOk: {
      type: Boolean,
      default: false,
    },
    disabled: {
      type: Boolean,
      default: false,
    },
    value: {},
    name: {
      type: String,
      default: null,
    },
    rules: {
      type: [String, Array],
      default: null,
    },
    modelName: {
      type: String,
      default: null,
    },
    resourceData: {
      type: Object,
      default: null,
    },
    parentState: {},
    parent: {
      type: String,
      default: null,
    },
    inRowBtnRead: {
      type: Boolean,
      default: true,
    },
    inRowBtnUpdate: {
      type: Boolean,
      default: true,
    },
    inRowBtnDelete: {
      type: Boolean,
      default: true,
    },
    mode: {
      type: String,
      default: "default",
    },
    staticUrl: {
      type: Boolean,
      default: false,
    },
    postData: {
      type: Object,
      default: null,
    },
    requestMethod: {
      type: String,
      default: "put",
    },
  },
};
</script>
<template>
  <WsMain class="app-info-evluation">
    <WsTitle>{{evaluation.name}}</WsTitle>
    <WsTable
      class="mt-20"
      :items="_calcStages"
      :headers="calcStageHeaders"
    ></WsTable>
    <div class="ws-row mt-12">
      <WsCol class="col-12">
        <WsRow>
          <WsCol
            v-if="!evaluation.project"
            class="col-4"
          >
            <WsBtn :to="`/project/create?evaluation=${evaluation.id}`">建立專案</WsBtn>
          </WsCol>
          <WsCol class="col-4">
            <WsBtn
              :loading="loading.copy"
              @click="$_onCopy()"
            >複製評估</WsBtn>
          </WsCol>
        </WsRow>
      </WsCol>
      <div
        v-if="evaluation.project"
        class="ws-col col-3"
      >
        <WsInfo
          :type="fields.project.type"
          :label="fields.project.label"
          :modelName="fields.project.modelName"
          :textKey="fields.project.textKey"
          :value="evaluation.project"
        ></WsInfo>
      </div>
      <div class="ws-col col-3">
        <WsInfo
          :type="fields.start_date.type"
          :label="fields.start_date.label"
          :value="evaluation.start_date"
        ></WsInfo>
      </div>
      <div class="ws-col col-3">
        <WsInfo
          :type="fields.adjust_time_rate.type"
          :label="fields.adjust_time_rate.label"
          :value="evaluation.adjust_time_rate"
        ></WsInfo>
      </div>
      <div class="ws-col col-3">
        <WsInfo
          :type="fields.adjust_price_rate.type"
          :label="fields.adjust_price_rate.label"
          :value="evaluation.adjust_price_rate"
        ></WsInfo>
      </div>
      <div class="ws-col col-3">
        <WsInfo
          :type="fields.fundamental_hours.type"
          :label="fields.fundamental_hours.label"
          :value="evaluation.fundamental_hours"
        ></WsInfo>
      </div>
      <div class="ws-col col-3">
        <WsInfo
          :type="fields.issue_time_rate.type"
          :label="fields.issue_time_rate.label"
          :value="evaluation.issue_time_rate"
        ></WsInfo>
      </div>
      <div class="ws-col col-3">
        <WsInfo
          :type="fields.arrange_way.type"
          :label="fields.arrange_way.label"
          :value="_evaluation.arrange_way"
          :items="fields.arrange_way.items"
        ></WsInfo>
      </div>
      <div class="ws-col col-12">
        <WsInfo
          :type="fields.remark.type"
          :label="fields.remark.label"
          :value="evaluation.remark"
        ></WsInfo>
      </div>
      <div class="ws-col col-12">
        <WsInfo
          :type="fields.wasas.type"
          :label="fields.wasas.label"
          :value="evaluation.wasas"
        ></WsInfo>
      </div>
      <div class="ws-col col-12 mt-40">
        <WsBtn
          v-if="!evaluation.tasks.length&&!evaluation.milestones.length"
          :loading="createTaskLoading"
          @click="$_onCreateEvaluationTasks()"
          :disabled="!_evaluation.project"
        >排入評估Task</WsBtn>
        <WsDes
          v-if="!_evaluation.project"
          class="mt-4"
        >建立專案此啟用此功能</WsDes>
        <WsBtn
          v-else-if="(evaluation.tasks.length||evaluation.milestones.length)&&_evaluation.project"
          :loading="removeTaskLoading"
          @click="$_onRemoveEvaluationTasks()"
        >移除已排入Task與Milestone</WsBtn>
      </div>
      <div class="ws-col col-12">
        <AppGardenCalendar
          v-if="evaluation.tasks.length||evaluation.milestones.length"
          :evaluation="evaluation.id"
          :tasks="evaluation.tasks"
          :milestones="evaluation.milestones"
          :additionalOffDays="additionalOffDays"
          :additionalWorkingDays="additionalWorkingDays"
          :params="_gardenCalenderParams"
        ></AppGardenCalendar>
        <AppEvaluationCalendar
          v-else
          ref="AppEvaluationCalendar"
          :evaluation="_evaluation"
          :wasas="wasas"
          :excuteTypes="_excuteTypes"
          :additionalOffDays="additionalOffDays"
          :additionalWorkingDays="additionalWorkingDays"
          :afterTasks="_afterTasks"
          :leaveDays="leaveDays"
        ></AppEvaluationCalendar>
      </div>
    </div>
  </WsMain>
</template>

<script>
import evaluation_stage from "@/models/evaluation_stage";
import evaluation from "@/models/evaluation";
export default {
  data: () => ({
    fields: evaluation.fields,
    additionalWorkingDays: null,
    additionalOffDays: null,
    leaveDays: null,
    wasas: null,
    afterTasks: null,
    createTaskLoading: false,
    removeTaskLoading: false,
    loading: {
      copy: false,
    },
    evaluationStageFields: evaluation_stage.fields,
    calcStageShowFields: ["name", "excute_days", "price"],
    calcStageHeaders: [
      {
        text: "階段名稱",
        value: "name",
      },
      {
        text: "執行天數",
        value: "excute_days",
      },
      {
        text: "價格",
        value: "price",
      },
    ],
  }),
  methods: {
    async $_onCopy() {
      let _wasas = [];
      const _evaluation = JSON.parse(JSON.stringify(this.evaluation));
      if (_evaluation.wasas) {
        _evaluation.wasas.forEach((wasa) => {
          _wasas.push(wasa.id);
        });
      }
      const postData = {
        adjust_price_rate: _evaluation.adjust_price_rate,
        adjust_time_rate: _evaluation.adjust_time_rate,
        arrange_way: _evaluation.arrange_way,
        evaluation_excute_types_setting:
          _evaluation.evaluation_excute_types_setting,
        evaluation_stages: _evaluation.evaluation_stages,
        evaluation_wasas: _evaluation.evaluation_wasas,
        fundamental_hours: _evaluation.fundamental_hours,
        issue_time_rate: _evaluation.issue_time_rate,
        name: _evaluation.name,
        project: _evaluation.project ? _evaluation.project.id : null,
        remark: _evaluation.remark,
        start_date: _evaluation.start_date,
        wasas: _wasas,
      };
      postData.evaluation_stages.forEach(
        (evaluation_stage, evaluation_stage_index) => {
          evaluation_stage.index = evaluation_stage_index;
          const evaluation_materials = [];
          if (evaluation_stage.evaluation_materials) {
            evaluation_stage.evaluation_materials.forEach(
              (evaluation_material) => {
                evaluation_materials.push(evaluation_material.id);
              }
            );
            evaluation_stage.evaluation_materials = evaluation_materials;
          }

          const evaluation_material_options = [];
          if (evaluation_stage.evaluation_material_options) {
            evaluation_stage.evaluation_material_options.forEach(
              (evaluation_material_option) => {
                evaluation_material_options.push(evaluation_material_option.id);
              }
            );
            evaluation_stage.evaluation_material_options = evaluation_material_options;
          }

          const excute_types = [];
          if (evaluation_stage.excute_types) {
            evaluation_stage.excute_types.forEach((excute_type) => {
              excute_types.push(excute_type.id);
            });
            evaluation_stage.excute_types = excute_types;
          }
        }
      );

      postData.evaluation_wasas.forEach((evaluation_wasa) => {
        evaluation_wasa.excute_type = evaluation_wasa.excute_type
          ? evaluation_wasa.excute_type.id
          : null;
        evaluation_wasa.wasa = evaluation_wasa.wasa
          ? evaluation_wasa.wasa.id
          : null;
      });
      try {
        this.loading.copy = true;
        await this.$axios.post("evaluation", postData);
        this.$router.go(-1);
      } catch (error) {
        alert("儲存錯誤");
      } finally {
        this.loading.copy = false;
      }
    },
    async $_fetchLeaveDays() {
      const params = {
        time_field: "start_date",
        start_time: this.evaluation.start_date
          ? this.$moment(this.evaluation.start_date).format("YYYY-MM-DD")
          : null,
      };
      const res = await this.$axios.get("leave_day/index/all", {
        params: params,
      });
      this.leaveDays = res.data.data;
    },
    async $_onRemoveEvaluationTasks() {
      const deleteTasks = [];

      const tasks = this._evaluation.tasks;
      tasks.forEach((task) => {
        deleteTasks.push(this.$axios.delete(`/task/${task.id}`));
      });

      const milestones = this._evaluation.milestones;
      milestones.forEach((milestone) => {
        deleteTasks.push(this.$axios.delete(`/milestone/${milestone.id}`));
      });

      try {
        this.removeTaskLoading = true;
        await Promise.all(deleteTasks);
        this.$emit("change");
      } catch (error) {
        alert("移除發生錯誤");
      } finally {
        this.removeTaskLoading = false;
      }
    },
    async $_onCreateEvaluationTasks() {
      const postTasks = [];

      const tasks = this.$refs.AppEvaluationCalendar.getTasks();

      tasks.forEach((task) => {
        const postData = {
          qc: task.qc ? task.qc : false,
          adjust: task.adjust ? task.adjust : false,
          name: task.name,
          start_time: task.start_time,
          hour: task.hour,
          evaluation_stage: task.evaluation_stage
            ? task.evaluation_stage.id
            : null,
          evaluation_start_time: task.start_time,
          evaluation_start_hour: task.hour,
          project: this.evaluation.project.id,
          evaluation: this.evaluation.id,
          evaluation_taker: task.taker ? task.taker.id : null,
          taker: task.taker ? task.taker.id : null,
          excute_type: task.excute_type ? task.excute_type.id : null,
          evaluation_material: task.evaluation_material
            ? task.evaluation_material.id
            : null,
          evaluation_material_option: task.evaluation_material_option
            ? task.evaluation_material_option.id
            : null,
          evaluation_material_excute: task.evaluation_material_excute
            ? task.evaluation_material_excute.id
            : null,
          evaluation_material_option_excute: task.evaluation_material_option_excute
            ? task.evaluation_material_option_excute.id
            : null,
        };
        postTasks.push(this.$axios.post("task", postData));
      });

      const milestones = this.$refs.AppEvaluationCalendar.getMilestones();
      milestones.forEach((milestone) => {
        const postData = {
          name: milestone.name,
          date: milestone.date,
          project: this.evaluation.project.id,
          evaluation: this.evaluation.id,
          evaluation_taker: milestone.taker ? milestone.taker.id : null,
          taker: milestone.taker ? milestone.taker.id : null,
          excute_type: milestone.excute_type ? milestone.excute_type.id : null,
        };
        postTasks.push(this.$axios.post("milestone", postData));
      });

      try {
        this.createTaskLoading = true;
        await Promise.all(postTasks);
        this.$emit("change");
      } catch (error) {
        alert("排入發生錯誤");
      } finally {
        this.createTaskLoading = false;
      }
    },
    async $_afterTasksFetch() {
      const wasas = [];
      this.evaluation.wasas.forEach((wasa) => {
        wasas.push(wasa.id);
      });
      const params = {
        time_field: "start_time",
        start_time: this.evaluation.start_date
          ? this.$moment(this.evaluation.start_date).format("YYYY-MM-DD")
          : null,
        taker: wasas.join(),
      };
      const res = await this.$axios.get("task/index/all", {
        params: params,
      });
      this.afterTasks = res.data.data;
    },
    async $_wasasFetch() {
      const res = await this.$axios.get("wasa/index/all/deep");
      this.wasas = res.data.data;
    },
    async $_additionalWorkingDaysFetch() {
      const params = {
        time_field: "date",
        start_time: this.evaluation.start_date
          ? this.$moment(this.evaluation.start_date).format("YYYY-MM-DD")
          : null,
      };
      const res = await this.$axios.get("additional_working_day/index/all", {
        params: params,
      });
      this.additionalWorkingDays = res.data.data;
    },
    async $_additionalOffDaysFetch() {
      const params = {
        time_field: "date",
        start_time: this.evaluation.start_date
          ? this.$moment(this.evaluation.start_date).format("YYYY-MM-DD")
          : null,
      };
      const res = await this.$axios.get("additional_off_day/index/all", {
        params: params,
      });
      this.additionalOffDays = res.data.data;
    },
  },
  computed: {
    _calcStages() {
      if (!this.evaluation || !this.evaluation.evaluation_stages) {
        return null;
      }
      const _calcStages = [];
      const adjustPriceRate = this.evaluation.adjust_price_rate;
      const adjudtTimeRate = this.evaluation.adjust_time_rate;
      this.evaluation.evaluation_stages.forEach((evaluation_stage) => {
        const _name = evaluation_stage.name;
        let excute_days = 0;
        let price = 0;
        evaluation_stage.evaluation_materials.forEach((evaluation_material) => {
          excute_days += evaluation_material.excute_days;
          price += evaluation_material.price;
        });
        evaluation_stage.evaluation_material_options.forEach(
          (evaluation_material_option) => {
            excute_days += evaluation_material_option.excute_days;
            price += evaluation_material_option.price;
          }
        );
        _calcStages.push({
          name: _name,
          excute_days: Math.round(excute_days * adjudtTimeRate),
          price: Math.round(price * adjustPriceRate),
        });
      });
      return _calcStages;
    },
    _afterTasks() {
      if (!this.afterTasks) {
        return [];
      }
      const _afterTasks = JSON.parse(JSON.stringify(this.afterTasks));

      _afterTasks.sort((a, b) => {
        if (!a.start_time) {
          return false;
        } else if (!b.start_time) {
          return false;
        } else if (a.start_time == b.start_time) {
          return a.hour - b.hour;
        } else {
          return this.$moment(a.start_time).diff(this.$moment(b.start_time));
        }
      });
      return _afterTasks;
    },
    _wasaItems() {
      if (!this.wasas) {
        return [];
      } else {
        const _wasaItems = [];
        this.wasas.forEach((wasa) => {
          _wasaItems.push({
            value: wasa.id,
            text: wasa.name,
          });
        });
        return _wasaItems;
      }
    },
    _evaluationWasaItems() {
      if (!this._evaluation.wasas || !this.wasas) {
        return [];
      } else {
        const _evaluationWasaItems = [];
        this._evaluation.wasas.forEach((updateWasaId) => {
          const tarWasa = this.wasas.find((e) => {
            return e.id == updateWasaId;
          });
          _evaluationWasaItems.push({
            value: tarWasa.id,
            text: tarWasa.name,
          });
        });
        return _evaluationWasaItems;
      }
    },
    _evaluation() {
      const _evaluation = JSON.parse(JSON.stringify(this.evaluation));
      for (let key in _evaluation) {
        if (this.fields[key]) {
          _evaluation[key] = this.$_CMS_getUpdateData(
            this.evaluation[key],
            this.fields[key]
          );
        } else {
          _evaluation[key] = this.evaluation[key];
        }
      }
      return _evaluation;
    },
    _additionalWorkingDays() {
      if (!this.additionalWorkingDays) {
        return [];
      }
      const _additionalWorkingDays = [];
      this.additionalWorkingDays.forEach((additionalWorkingDay) => {
        _additionalWorkingDays.push(additionalWorkingDay.date);
      });
      return _additionalWorkingDays;
    },
    _additionalOffDays() {
      if (!this.additionalOffDays) {
        return [];
      }
      const _additionalOffDays = [];
      this.additionalOffDays.forEach((additionalOffDay) => {
        _additionalOffDays.push(additionalOffDay.date);
      });
      return _additionalOffDays;
    },
    _excuteTypes() {
      if (!this.evaluation.evaluation_stages) {
        return [];
      } else {
        const _excuteTypes = [];
        this.evaluation.evaluation_stages.forEach((stage) => {
          stage.evaluation_materials.forEach((evaluation_material) => {
            evaluation_material.evaluation_material_excutes.forEach(
              (evaluation_material_excute) => {
                if (!evaluation_material_excute.excute_type) {
                  return;
                }
                const has = _excuteTypes.some((e) => {
                  return e.id == evaluation_material_excute.excute_type.id;
                });
                if (!has) {
                  _excuteTypes.push(evaluation_material_excute.excute_type);
                }
              }
            );
            evaluation_material.evaluation_material_options.forEach(
              (evaluation_material_option) => {
                evaluation_material_option.evaluation_material_option_excutes.forEach(
                  (evaluation_material_option_excute) => {
                    if (!evaluation_material_option_excute.excute_type) {
                      return;
                    }
                    const has = _excuteTypes.some((e) => {
                      return (
                        e.id == evaluation_material_option_excute.excute_type.id
                      );
                    });
                    if (!has) {
                      _excuteTypes.push(
                        evaluation_material_option_excute.excute_type
                      );
                    }
                  }
                );
              }
            );
          });
        });
        _excuteTypes.sort((a, b) => {
          if (a.excute_index == b.excute_index) {
            return a.excute_index_second - b.excute_index_second;
          } else {
            return a.excute_index - b.excute_index;
          }
        });
        return _excuteTypes;
      }
    },
    _gardenCalenderParams() {
      return {
        evaluation: this.evaluation.id,
      };
    },
  },
  props: {
    evaluation: {
      type: Object,
      default: null,
    },
  },
  mounted() {
    this.$_wasasFetch();
    this.$_afterTasksFetch();
    this.$_additionalWorkingDaysFetch();
    this.$_additionalOffDaysFetch();
    this.$_fetchLeaveDays();
  },
};
</script>

<style>
</style>
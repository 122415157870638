<template>
  <WsMain>
    <div>
      <a
        target="_blank"
        href="http://vue2cms.showroom.wasateam.com/"
      >管理後台 Showroom (Vue2)</a>
    </div>
    <div class="mt-20">
      <a
        target="_blank"
        href="http://nuxt.showroom.wasateam.com/"
      >前台 Showroom (Nuxt)</a>
    </div>
  </WsMain>
</template>

<script>
export default {};
</script>

<style>
</style>
<template>
  <WsMain>
    <WsCrud
      :modelName="$store.state.model[modelName].modelName"
      :label="$store.state.model[modelName].label"
      :fields="$store.state.model[modelName].fields"
      :liveSearching="true"
      :showFields="showFields"
      :order="order"
      :plusOrderItems="plusOrderItems"
      :infiniteScroll="true"
    ></WsCrud>
  </WsMain>
</template>

<script>
export default {
  metaInfo() {
    return {
      title: "打卡管理",
    };
  },
  data: () => ({
    order: "last_punch",
    modelName: "punch",
    showFields: ["wasa", "type", "punch_at"],
    plusOrderItems: [
      {
        value: "last_punch",
        text: "最後打卡",
        order_by: "punch_at",
        order_way: "desc",
      },
    ],
  }),
};
</script>
export default [
  {
    path: '/calendar',
    name: 'calendar',
    component: () => import('../views/Calendar.vue'),
    meta: {
      middleware: 'auth',
      requiredScopes: ['boss', 'admin', 'pm']
    }
  },
  {
    path: '/calendar/batch/delete',
    name: 'calendar_batch_delete',
    component: () => import('../views/Calendar/Batch/Delete.vue'),
    meta: {
      middleware: 'auth',
      requiredScopes: ['boss', 'pm']
    }
  },
  {
    path: '/calendar/adjust',
    name: 'calendar_adjust',
    component: () => import('../views/Calendar/Adjust.vue'),
    meta: {
      middleware: 'auth',
      requiredScopes: ['boss', 'pm']
    }
  },
]
export default [
  {
    path: '/quotation',
    name: 'quotation',
    component: () => import('../views/Quotation/Index.vue'),
    meta: {
      middleware: 'auth',
      requiredScopes: ['boss', 'admin', 'pm']
    }
  },
  {
    path: '/quotation/create',
    name: 'quotation_create',
    component: () => import('../views/Quotation/Create.vue'),
    meta: {
      middleware: 'auth',
      requiredScopes: ['boss', 'admin', 'pm']
    }
  },
  {
    path: '/quotation/:id/update',
    name: 'quotation_update',
    component: () => import('../views/Quotation/Update.vue'),
    meta: {
      middleware: 'auth',
      requiredScopes: ['boss', 'admin', 'pm']
    }
  },
  {
    path: '/quotation/:id',
    name: 'quotation_read',
    component: () => import('../views/Quotation/Read.vue'),
    requiredScopes: ['boss', 'admin', 'pm']
  },
  {
    path: '/quotation_description',
    name: 'quotation_description',
    component: () => import('../views/QuotationDescription/Index.vue'),
    meta: {
      middleware: 'auth',
      requiredScopes: ['boss', 'admin', 'pm']
    }
  },
  {
    path: '/quotation_excute',
    name: 'quotation_excute',
    component: () => import('../views/QuotationExcute/Index.vue'),
    meta: {
      middleware: 'auth',
      requiredScopes: ['boss', 'admin', 'pm']
    }
  },
]
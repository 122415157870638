<template>
  <div
    class="ws-info-text"
    v-html="value"
    v-linkified
  ></div>
</template>

<script>
export default {
  props: {
    value: {
      type: [String, Number],
      default: null,
    },
  },
};
</script>
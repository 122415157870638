<template>
  <WsMain>
    <WsReadSection
      :id="_id"
      :modelName="modelName"
      :label="label"
      :fields="fields"
      :leftFields="leftFields"
      :rightFields="rightFields"
      v-model="modelData"
    ></WsReadSection>
    <WsCard class="mt-20">
      <WsEasyTable
        v-if="modelData"
        :fields="_contentFields"
        :modelData="modelData.content"
      />
    </WsCard>
  </WsMain>
</template>

<script>
import model from "@/__vue2stone_cms/models/tulpa_section";
export default {
  data: () => ({
    modelName: model.modelName,
    label: model.label,
    fields: model.fields,
    leftFields: ["name", "tags", "remark"],
    rightFields: [],
    modelData: null,
  }),

  computed: {
    _contentFields() {
      if (this.modelData && this.modelData.tulpa_section_template) {
        return this.modelData.tulpa_section_template.fields;
      } else {
        return null;
      }
    },
    _id() {
      return this.$route.params.id;
    },
  },
};
</script>

<style>
</style>
import moment from 'moment'
import numeral from 'numeral'
export default {
  modelName: "order",
  label: "山葵訂單",
  fields: {
    id: {
      type: "text",
      label: "ID",
      readonly: true
    },
    quotation: {
      type: 'belongsTo',
      label: '報價單',
      textKey: "name",
      modelName: "quotation",
      relationPopup: true,
    },
    client: {
      type: 'belongsTo',
      label: '客戶',
      textKey: "name",
      modelName: "client",
      relationPopup: true,
    },
    order_no: {
      type: 'text',
      label: '訂單編號',
      width: '100px',
      readonly: true
    },
    name: {
      type: 'text',
      label: '訂單名稱',
    },
    tax_type: {
      type: "select",
      label: "課稅別",
      defaultValue: 5,
      items: [
        {
          text: "應稅(5%)",
          value: 5
        },
        {
          text: "零稅率",
          value: 0
        },
        {
          text: "免稅",
          value: 'no'
        }
      ]
    },
    price: {
      type: "currency",
      label: "未稅金額",
      width: '100px'
    },
    tax_price: {
      type: 'custom',
      label: "稅金",
      getValue: item => {
        if (!item.price) {
          return 0;
        }
        if (item.tax_type == 5) {
          return numeral(Math.round(item.price * 0.05)).format("$0,0");
        } else {
          return numeral(item.price).format("$0,0");
        }
      },
    },
    total_price: {
      type: 'custom',
      label: "含稅金額",
      getValue: item => {
        if (!item.price) {
          return 0;
        }
        if (item.tax_type == 5) {
          return numeral(Math.round(item.price * 1.05)).format("$0,0");
        } else {
          return numeral(item.price).format("$0,0");
        }
      },
    },
    status: {
      type: "select",
      label: "狀態",
      defaultValue: 0,
      items: [
        {
          text: '進行中',
          value: 0
        },
        {
          text: '已結案',
          value: 1
        }
      ]
    },
    deal_date: {
      type: "date",
      label: "成交日期",
      defaultValue: moment().format('YYYY-MM-DD'),
      width: '120px'
    },
    relate_files: {
      type: "files",
      label: "相關檔案",
      uploadUrl: 'order_stage/invoice_photo/'
      // fields: {
      //   name: {
      //     type: "text",
      //     label: "名稱",
      //     rules: "required"
      //   },
      //   file: {
      //     type: "file",
      //     label: "檔案",
      //     rules: "required",
      //     uploadUrl: 'order_stage/invoice_photo/'
      //   },
      //   remark: {
      //     type: "textarea",
      //     label: "備註"
      //   }
      // },
      // showFields: ["name", "file", "remark"]
    },
    relate_links: {
      type: "list",
      label: "相關連結",
      fields: {
        name: {
          type: "text",
          label: "名稱",
          rules: "required"
        },
        link: {
          type: "link",
          label: "連結",
          rules: "required"
        },
        remark: {
          type: "textarea",
          label: "備註"
        }
      },
      showFields: ["name", 'link', 'remark']
    },
    creator: {
      type: 'belongsTo',
      label: '建立人',
      textKey: "name",
      modelName: "wasa",
      relationPopup: true,
      readonly: true,
    },
  },
}
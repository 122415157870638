<template>
  <div class="app-evaluation-stages">
    <AppEvaluationStageItem
      v-for="(stage,stageIndex) in _value"
      :key="stageIndex"
      :value="stage"
      @input="$_onInput($event,stageIndex)"
    ></AppEvaluationStageItem>
  </div>
</template>

<script>
export default {
  methods: {
    $_onInput($event, stageIndex) {
      const _value = this.value ? JSON.parse(JSON.stringify(this.value)) : [];
      _value[stageIndex] = $event;
      this.$emit("input", _value);
    },
  },
  computed: {
    _value() {
      return this.value ? this.value : [];
    },
  },
  props: {
    value: {
      type: Array,
      default: null,
    },
  },
};
</script>

<style>
</style>
import moment from 'moment'
export default {
  modelName: "additional_working_day",
  label: "額外工作日",
  fields: {
    name: {
      type: "text",
      label: "名稱",
      rules: 'required',
      autofocus: true
    },
    date: {
      type: "date",
      label: "日期",
      rules: 'required',
      defaultValue: moment().format('YYYY-MM-DD')
    },
  },
}
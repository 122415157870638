<template>
  <div class="ws-state-date">
    <DatePicker
      class="date-picker"
      :value="_value"
      @input="$_onInput($event)"
      placeholder="選擇日期"
      :format="format"
    >
    </DatePicker>
  </div>
</template>

<script>
export default {
  props: {
    clearable: {
      type: Boolean,
      default: true,
    },
    value: {},
    placeholder: {
      type: String,
      default: "選擇日期",
    },
    format: {
      type: String,
      default: "YYYY-MM-DD",
    },
  },
  computed: {
    _value() {
      // return this.$moment(this.value).format();
      if (this.value) {
        return new Date(this.value);
      } else {
        return null;
      }
    },
  },
  methods: {
    $_onInput($event) {
      if ($event) {
        this.$emit("input", this.$moment($event).format(this.format));
      } else {
        this.$emit("input", null);
      }
    },
    customFormatter(date) {
      return this.$moment(date).format(this.format);
    },
  },
};
</script>
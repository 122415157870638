import moment from 'moment'
export default {
  modelName: "leave_day",
  label: "請假管理",
  fields: {
    id: {
      type: "text",
      label: "ID",
      readonly: true
    },
    type: {
      type: 'select',
      label: '請假類型',
      items: [
        {
          value: 0,
          text: '休假'
        },
        {
          value: 1,
          text: '病假'
        },
        {
          value: 2,
          text: '事假'
        },
      ],
      defaultValue: 0
    },
    start_date: {
      type: "date",
      label: "日期",
      defaultValue: moment().format('YYYY-MM-DD')
    },
    days: {
      type: "number",
      label: "天數",
      defaultValue: 1,
    },
    start_time: {
      type: 'datetime',
      label: '開始時間 (不足一日填寫)',
      // defaultValue: moment().format('YYYY-MM-DD')
    },
    hour: {
      type: 'number',
      label: '時數 (不足一日填寫)'
    },
    des: {
      type: "textarea",
      label: "描述",
      rules: "required",
      maxlength: 300,
    },
    status: {
      type: 'select',
      label: '覆核狀態',
      defaultValue: 0,
      items: [
        {
          value: 0,
          text: '申請中'
        },
        {
          value: 1,
          text: 'OK'
        },
        {
          value: 2,
          text: '打妹'
        },
      ]
    },
    wasa: {
      type: "belongsTo",
      label: "山葵仔",
      textKey: "name",
      modelName: "wasa"
    },
    updated_admin: {
      type: "belongsTo",
      label: "最後更新人",
      readonly: true,
      textKey: "name",
      modelName: "wasa"
    },
  },
}
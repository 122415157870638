export default {
  namespaced: true,
  state: {
    needSave: false,
    sideMenuOpen: false,
    appBarSaveLoading: false,
    pageLoading: true,
    hasBottomNav: false,
    hasDrawer: true,
    hasHeader: true,
  },
  mutations: {
    STOP_NEED_SAVE(state) {
      state.needSave = false;
    },
    START_NEED_SAVE(state) {
      state.needSave = true;
    },
    START_APP_BAR_SAVE_LOADING(state) {
      state.appBarSaveLoading = true;
    },
    STOP_APP_BAR_SAVE_LOADING(state) {
      state.appBarSaveLoading = false;
    },
    START_PAGE_LOADING(state) {
      state.pageLoading = true;
    },
    STOP_PAGE_LOADING(state) {
      state.pageLoading = false;
    },
    MENU_TOGGLE(state) {
      state.sideMenuOpen = !state.sideMenuOpen;
    },
    MENU_CLOSE(state) {
      state.sideMenuOpen = false;
    },
    MENU_OPEN(state) {
      state.sideMenuOpen = true;
    },
    HAS_BOTTOM_NAV_ACTIVE(state) {
      state.hasBottomNav = true
    },
    HAS_BOTTOM_NAV_DEACTIVE(state) {
      state.hasBottomNav = false
    }
  },
  actions: {
    startAppBarSaveLoading({ commit }) {
      commit('START_APP_BAR_SAVE_LOADING', true);
    },
    stopAppBarSaveLoading({ commit }) {
      commit('STOP_APP_BAR_SAVE_LOADING', false);
    },
    startPageLoading({ commit }) {
      commit('START_PAGE_LOADING', true);
    },
    stopPageLoading({ commit }) {
      commit('STOP_PAGE_LOADING', false);
    },
    startNeedSave({ commit }) {
      commit('START_NEED_SAVE');
    },
    stopNeedSave({ commit }) {
      commit('STOP_NEED_SAVE');
    },
    menuToggle({ commit }) {
      commit('MENU_TOGGLE');
    },
    menuOpen({ commit }) {
      commit('MENU_OPEN');
    },
    menuClose({ commit }) {
      commit('MENU_CLOSE');
    },
    hasBottomNavActive({ commit }) {
      commit('HAS_BOTTOM_NAV_ACTIVE');
    },
    hasBottomNavDeactive({ commit }) {
      commit('HAS_BOTTOM_NAV_DEACTIVE');
    },
  },
}
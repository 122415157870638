export default [
  {
    path: '/project',
    name: 'project',
    component: () => import('../views/Project/Index.vue'),
    meta: {
      middleware: 'auth',
    }
  },
  {
    path: '/project/create',
    name: 'project_create',
    component: () => import('../views/Project/Create.vue'),
    meta: {
      middleware: 'auth',
      requiredScopes: ['boss', 'admin', 'pm']
    }
  },
  {
    path: '/project/:id/update',
    name: 'project_update',
    component: () => import('../views/Project/Update.vue'),
    meta: {
      middleware: 'auth',
      requiredScopes: ['boss', 'admin', 'pm']
    }
  },
  {
    path: '/project/:id',
    name: 'project_read',
    redirect: '/project/:id/overview'
  },
  {
    path: '/project/:id/overview',
    name: 'project_read',
    component: () => import('../views/Project/Overview.vue'),
    meta: {
      middleware: 'auth',
    }
  },
  {
    path: '/project/:id/task',
    name: 'project_task',
    component: () => import('../views/Project/Task.vue'),
    meta: {
      middleware: 'auth',
      requiredScopes: ['boss', 'admin', 'pm']
    }
  },
  {
    path: '/project/:id/analytics',
    name: 'project_analytics',
    component: () => import('../views/Project/Analytics.vue'),
    meta: {
      middleware: 'auth',
    }
  },
  {
    path: '/excute_type',
    name: 'excute_type',
    component: () => import('../views/ExcuteType/Index.vue'),
    meta: {
      middleware: 'auth',
      requiredScopes: ['boss', 'admin', 'pm']
    }
  },
  {
    path: '/evaluation_type',
    name: 'evaluation_type',
    component: () => import('../views/EvaluationType/Index.vue'),
    meta: {
      middleware: 'auth',
      requiredScopes: ['boss', 'admin', 'pm']
    }
  },
  {
    path: '/evaluation/create',
    name: 'evaluation_create',
    component: () => import('../views/Evaluation/Create.vue'),
    meta: {
      middleware: 'auth',
      templateBottomNav: true,
      requiredScopes: ['boss', 'admin', 'pm']
    }
  },
  {
    path: '/evaluation/:id/update',
    name: 'evaluation_update',
    component: () => import('../views/Evaluation/Update.vue'),
    meta: {
      middleware: 'auth',
      templateBottomNav: true,
      requiredScopes: ['boss', 'admin', 'pm']
    }
  },
  {
    path: '/evaluation/:id',
    name: 'evaluation_read',
    component: () => import('../views/Evaluation/Read.vue'),
    meta: {
      middleware: 'auth',
      templateBottomNav: true,
      requiredScopes: ['boss', 'admin', 'pm']
    }
  },
  {
    path: '/evaluation',
    name: 'evaluation',
    component: () => import('../views/Evaluation/Index.vue'),
    meta: {
      middleware: 'auth',
      requiredScopes: ['boss', 'admin', 'pm']
    }
  },
  {
    path: '/task',
    name: 'task',
    component: () => import('../views/Task/Index.vue'),
    meta: {
      middleware: 'auth',
      requiredScopes: ['boss', 'pm']
    }
  },
  {
    path: '/milestone',
    name: 'milestone',
    component: () => import('../views/Milestone/Index.vue'),
    meta: {
      middleware: 'auth',
      requiredScopes: ['boss', 'pm']
    }
  },
]
export default {
  login: {
    url: '/auth/signin'
  },
  logout: {
    url: '/auth/signout'
  },
  // user: {
  //   url: 'auth/user'
  // }
}
<template>
  <WsMain class="sm-pa-0">
    <WsCrud
      :modelName="$store.state.model[modelName].modelName"
      label="我的專案"
      :fields="$store.state.model[modelName].fields"
      :liveSearching="true"
      :pageMode="true"
      :showFields="showFields"
      :expandable="false"
      :showExpand="false"
      :getReadUrl="getReadUrl"
      :creatable="false"
      :inRowBtnUpdate="false"
      :inRowBtnDelete="false"
      :fetchQuery="_fetchQuery"
      :infiniteScroll="true"
    ></WsCrud>
  </WsMain>
</template>

<script>
export default {
  metaInfo() {
    return {
      title: "我的專案",
    };
  },
  data: () => ({
    modelName: "project",
    showFields: ["id", "name"],
  }),
  methods: {
    getReadUrl(model) {
      return `/project/${model.url_id}`;
    },
  },

  computed: {
    _fetchQuery() {
      return {
        wasas: this._currentUser.id,
      };
    },
    _currentUser() {
      return this.$store.state.auth.currentUser;
    },
  },
};
</script>
<template>
  <div class="ws-btn-flex-group">
    <slot></slot>
  </div>
</template>

<script>
export default {};
</script>

<style>
</style>
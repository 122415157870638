<template>
  <div>
    <WsUpdate
      :modelName="modelName"
      :id="_id"
      :label="label"
      :fields="fields"
      :primary="pageSetting.primary"
      :secondary="pageSetting.secondary"
      titleKey="name"
      v-model="updateData"
    >
    </WsUpdate>
  </div>
</template>

<script>
import model from "@/models/project";
export default {
  metaInfo() {
    return {
      title: "編輯專案",
    };
  },
  data: () => ({
    updateData: null,
    modelName: model.modelName,
    label: model.label,
    fields: model.fields,
    pageSetting: {
      primary: [
        {
          type: "stateCard",
          floors: [
            {
              fields: [
                "name",
                "remark",
                "noun",
                "relate_links",
                "relate_infos",
              ],
            },
          ],
        },
      ],
      secondary: [
        {
          type: "stateCard",
          floors: [
            {
              title: "設定",
              fields: ["url_id", "slack_webhook_url", "wasas"],
            },
          ],
        },
        {
          type: "stateCard",
          floors: [
            {
              title: "連結",
              fields: [
                "slack_channel",
                "zeplin",
                "gitlab_wasa",
                "gitlab_client",
                "google_drive_wasa",
                "google_drive_client",
              ],
            },
          ],
        },
        {
          type: "stateCard",
          floors: [
            {
              title: "狀態",
              fields: ["status"],
              hideLabelFields: ["status"],
            },
          ],
        },
      ],
    },
    evaluation: null,
  }),
  methods: {},
  computed: {
    _id() {
      return this.$route.params.id;
    },
  },
  mounted() {},
};
</script>

<style>
</style>
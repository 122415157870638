export default {
	namespaced: true,
	state: {
		currentUser: null,
		scopes: null
	},
	mutations: {
		SET_CURRENT_USER(state, data) {
			state.currentUser = data.user
			state.scopes = data.scopes
		},
		REMOVE_CURRENT_USER(state) {
			state.currentUser = null
			state.scopes = null
		},
	},
	actions: {
		setCurrentUser({ commit }, data) {
			commit('SET_CURRENT_USER', data)
		},
		removeCurrentUser({ commit }) {
			commit('REMOVE_CURRENT_USER')
		},
	}
}
<template>
  <div class="ws-read-section">
    <WsAlert
      ref="deleteAlert"
      title="確定要刪除嗎？"
      description="刪除後資料將無法回覆"
      @submit="$_onDeleteSubmit($event)"
    ></WsAlert>
    <WsLoading v-if="loadingFetch"></WsLoading>
    <template v-else-if="modelData">
      <WsBreadcrumbs :menu="_breadcrumbsItems"></WsBreadcrumbs>
      <div class="ws-read-section__titlebar mt-20">
        <h3 class="ws-read-section__titlebar__text">{{_title}}</h3>
        <div class="ws-read-section__titlebar__btns">
          <WsBtn
            v-if="updatable"
            :to="`/${modelName}/${id}/update`"
          >編輯</WsBtn>
          <WsBtn
            v-if="deletable"
            class="ml-20"
            alert
            :loading="loadingDelete"
            @click="$_onDelete()"
          >刪除</WsBtn>
        </div>
      </div>
      <WsCard
        class="mt-20"
      >
        <div class="ws-row">
          <div class="ws-col sm-col-12 col-6">
            <div class="ws-row">
              <div
                v-for="(field, fieldKey) in _leftFields"
                :key="fieldKey"
                class="ws-col sm-col-12 pa-16"
              >
                <WsInfo
                  :type="field.type"
                  :label="field.label"
                  :fields="field.fields"
                  :showFields="field.showFields"
                  :items="field.items"
                  :textKey="field.textKey"
                  :relationPopup="field.relationPopup"
                  :value="modelData[fieldKey]"
                  :modelName="field.modelName"
                  :activeText="field.activeText"
                  :inactiveText="field.inactiveText"
                  :getText="field.getText"
                  :getValue="field.getValue"
                  :modelData="modelData"
                ></WsInfo>
              </div>
            </div>
          </div>
          <div class="ws-col sm-col-12 col-6">
            <div class="ws-row">
              <div
                v-for="(field, fieldKey) in _rightFields"
                :key="fieldKey"
                class="ws-col sm-col-12"
              >
                <WsInfo
                  :type="field.type"
                  :label="field.label"
                  :fields="field.fields"
                  :showFields="field.showFields"
                  :items="field.items"
                  :textKey="field.textKey"
                  :relationPopup="field.relationPopup"
                  :value="modelData[fieldKey]"
                  :modelName="field.modelName"
                  :activeText="field.activeText"
                  :inactiveText="field.inactiveText"
                  :getText="field.getText"
                  :getValue="field.getValue"
                  :modelData="modelData"
                ></WsInfo>
              </div>
            </div>
          </div>
        </div>
      </WsCard>
    </template>
  </div>
</template>

<script>
export default {
  data: () => ({
    modelData: null,
    loadingFetch: false,
    loadingDelete: false,
  }),

  methods: {
    async $_onDeleteSubmit() {
      try {
        this.loadingDelete = true;
        await this.$axios.delete(`/${this.modelName}/${this.id}`);
      } catch (error) {
        console.log(error);
        alert("刪除錯誤");
        this.$router.push(`/${this.modelName}`);
      } finally {
        this.loadingDelete = false;
      }
    },
    $_onDelete() {
      this.$refs.deleteAlert.open();
    },
    async $_fetchModelData() {
      try {
        this.loadingFetch = true;
        const res = await this.$axios.get(`${this.modelName}/${this.id}`);
        this.modelData = res.data.data;
      } catch (error) {
        alert("取得資料錯誤");
      } finally {
        this.loadingFetch = false;
      }
    },
  },

  computed: {
    _leftFields() {
      const _leftFields = {};
      this.leftFields.forEach((fieldKey) => {
        if (this.fields[fieldKey]) {
          _leftFields[fieldKey] = this.fields[fieldKey];
        }
      });
      return _leftFields;
    },
    _rightFields() {
      const _rightFields = {};
      this.rightFields.forEach((fieldKey) => {
        if (this.fields[fieldKey]) {
          _rightFields[fieldKey] = this.fields[fieldKey];
        }
      });
      return _rightFields;
    },
    _breadcrumbsItems() {
      const _breadcrumbsItems = [];
      _breadcrumbsItems.push({
        text: this.label,
        to: `/${this.modelName}`,
        disabled: false,
      });
      _breadcrumbsItems.push({
        text: this._title,
        disabled: false,
      });
      return _breadcrumbsItems;
    },
    _title() {
      if (!this.modelData) {
        return null;
      } else {
        return this.modelData[this.titleKey];
      }
    },
  },

  props: {
    label: {
      type: String,
      default: null,
    },
    modelName: {
      type: String,
      required: true,
    },
    id: {
      type: [String, Number],
      required: true,
    },
    titleKey: {
      type: String,
      default: "name",
    },
    fields: {
      type: Object,
      required: true,
    },
    leftFields: {
      type: Array,
      default: null,
    },
    rightFields: {
      type: Array,
      default: null,
    },
    updatable: {
      type: Boolean,
      default: true,
    },
    deletable: {
      type: Boolean,
      default: true,
    },
  },

  watch: {
    modelData: {
      handler() {
        console.log('modelData');
        this.$emit("input", this.modelData);
      },
    },
  },

  mounted() {
    this.$_fetchModelData();
  },
};
</script>

<style>
</style>
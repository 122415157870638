<template>
  <div
    class="ws-info-html"
    v-html="value"
  ></div>
</template>

<script>
export default {
  props: {
    value: {
      type: String,
      default: null,
    },
  },
};
</script>
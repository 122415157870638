<template>
  <div class="ws-crud-table">
    <WsNoDataMessage v-if="(!_items||!_items.length)&&!loading">沒資料 :(</WsNoDataMessage>
    <template v-else>
      <WsProgressBar :loading="loading"></WsProgressBar>
      <WsDataTablePagenateTypeB
        v-if="paginate"
        class="ws-data-table-pagenate-top"
        @pageto="$emit('pageto',$event)"
        :currentPage="currentPage"
        :dataTotalCount="dataTotalCount"
        :itemsPerPage="itemsPerPage"
        :lastPage="lastPage"
      ></WsDataTablePagenateTypeB>
      <div class="ws-crud-table__table-container">
        <table
          cellspacing="0"
          cellpadding="0"
        >
          <tr>
            <th
              v-if="showExpand&&expandable"
              class="expand"
            ></th>
            <th
              @click="$_orderSet(headersItem)"
              v-for="(headersItem,headersIndex) in _headers"
              :key="headersIndex"
              :class="[{orderable:$_orderableCheck(headersItem)},C_orderWay]"
              :style="[{width:$_getThWidth(headersItem.width)},{'max-width':$_getThWidth(headersItem.width)},{'min-width':$_getThWidth(headersItem.width)}]"
            >
              <span>{{headersItem.text}}</span>
              <WsIcon v-if="$_orderableCheck(headersItem)&&C_orderBy==headersItem.value">keyboard_arrow_up</WsIcon>
            </th>
            <th
              v-if="inRowBtnRead||inRowBtnUpdate||inRowBtnDelete||inRowBtnComplete"
              class="actions"
              :style="[{width:`${_actionWidth}px`},{'min-width':`${_actionWidth}px`},{'max-width':`${_actionWidth}px`},]"
            >
            </th>
          </tr>

          <template v-for="(item,itemIndex) in _items">
            <tr
              :key="itemIndex"
              :class="[{expandable:expandable}]"
              @click="$_onRowClick(item,itemIndex)"
            >
              <td
                v-if="showExpand&&expandable"
                class="expand"
              >
                <WsIcon size="20">expand_more</WsIcon>
              </td>
              <td
                v-for="(headersItem,headersIndex) in _headers"
                :key="headersIndex"
              >
                <WsInfo
                  :type="fields[headersItem.value].type"
                  :fields="fields[headersItem.value].fields"
                  :showFields="fields[headersItem.value].showFields"
                  :items="fields[headersItem.value].items"
                  :textKey="fields[headersItem.value].textKey"
                  :relationPopup="fields[headersItem.value].relationPopup"
                  :value="item[headersItem.value]"
                  :modelName="fields[headersItem.value].modelName"
                  :activeText="fields[headersItem.value].activeText"
                  :inactiveText="fields[headersItem.value].inactiveText"
                  :getText="fields[headersItem.value].getText"
                  :getValue="fields[headersItem.value].getValue"
                  :modelData="item"
                ></WsInfo>
              </td>
              <td
                v-if="inRowBtnRead||inRowBtnUpdate||inRowBtnDelete||inRowBtnComplete"
                class="actions"
              >
                <template v-if="inRowBtnRead">
                  <WsIconBtn
                    :to="$_getReadUrl(item)"
                    v-if="pageMode&&!dialogRead"
                  >description</WsIconBtn>
                  <WsIconBtn
                    v-else
                    @click.stop="$emit('read',{item,itemIndex})"
                  >description</WsIconBtn>
                </template>
                <template v-if="inRowBtnUpdate">
                  <WsIconBtn
                    :to="$_getUpdateUrl(item)"
                    v-if="pageMode&&!dialogUpdate"
                  >edit</WsIconBtn>
                  <WsIconBtn
                    v-else
                    @click.stop="$emit('update',{item,itemIndex})"
                  >edit</WsIconBtn>
                </template>
                <WsIconBtn
                  @click.stop="$emit('delete',{item,itemIndex})"
                  v-if="inRowBtnDelete"
                >delete</WsIconBtn>
                <WsIconBtn
                  @click.stop="$emit('complete',{item,itemIndex})"
                  v-if="inRowBtnComplete"
                >done</WsIconBtn>
                <template v-if="customTableActions">
                  <WsIconBtn
                    v-for="(customTableAction, customTableActionIndex) in customTableActions"
                    :key="customTableActionIndex"
                    @click.stop="$emit('custom-table-action',{emit:customTableAction.emit,data:{item,itemIndex}})"
                  >{{customTableAction.icon}}</WsIconBtn>
                </template>
              </td>
            </tr>
            <tr
              v-if="$_expandedCheck(itemIndex)"
              :key="`${itemIndex}-expand`"
              class="expand-content"
            >
              <td
                v-if="showExpand"
                class="expand"
              >
              </td>
              <td :colspan="_expandColspan">
                <WsEasyTable
                  :fields="displayFields"
                  :modelData="items[itemIndex]"
                />
              </td>
            </tr>
          </template>
        </table>
      </div>
      <WsDataTablePagenateTypeB
        v-if="paginate"
        @pageto="$emit('pageto',$event)"
        :currentPage="currentPage"
        :dataTotalCount="dataTotalCount"
        :itemsPerPage="itemsPerPage"
        :lastPage="lastPage"
      ></WsDataTablePagenateTypeB>
    </template>
  </div>
</template>

<script>
export default {
  data: () => ({
    C_orderBy: null,
    C_orderWay: "desc",
    expandedRows: [],
  }),
  methods: {
    $_getThWidth(width) {
      if (width) {
        return width;
      } else {
        return "100px";
      }
    },
    $_getReadUrl(item) {
      if (this.getReadUrl) {
        return this.getReadUrl(item);
      } else {
        return `/${this.modelName}/${item.id}`;
      }
    },
    $_getUpdateUrl(item) {
      if (this.getUpdateUrl) {
        return this.getUpdateUrl(item);
      } else {
        return `/${this.modelName}/${item.id}/update`;
      }
    },
    $_expandedCheck(index) {
      return this.expandedRows.includes(index);
    },
    $_onRowClick(item, itemIndex) {
      if (!this.inRowBtnRead && this.rowClickRead) {
        this.$emit("read", { item, itemIndex });
      }
      if (!this.expandable) {
        return;
      }
      const tarIndex = this.expandedRows.findIndex((e) => {
        return e == itemIndex;
      });
      if (tarIndex >= 0) {
        this.expandedRows.splice(tarIndex, 1);
      } else {
        this.expandedRows.push(itemIndex);
      }
    },
    $_orderSet(item) {
      if (!this.$_orderableCheck(item)) {
        return;
      }
      const value = item.value;
      if (this.C_orderBy != value) {
        this.C_orderBy = value;
        this.C_orderWay = this.orderWayDefault;
      } else {
        if (this.C_orderWay == this.orderWayDefault) {
          this.C_orderWay = this.orderWayDefault == "desc" ? "asc" : "desc";
        } else {
          this.C_orderWay = this.orderWayDefault;
          this.C_orderBy = this.orderByDefault;
        }
      }
    },
    $_orderableCheck(headersItem) {
      // if (!headersItem.sortable && headersItem.sortable != undefined) {
      //   return false;
      if (!headersItem.sortable) {
        return false;
      } else if (headersItem.value == "actions") {
        return false;
      } else {
        return true;
      }
    },
    expandReset() {
      this.expandedRows = [];
    },
  },
  computed: {
    _expandColspan() {
      return this._headers.length + 1;
    },
    _items() {
      const field = this.fields[this.C_orderBy];
      if (!this.C_orderBy || !field || !this.items) {
        return this.items;
      }
      const _items = this.items;
      const type = field.type;

      _items.sort((a, b) => {
        let x;
        let y;
        if (this.C_orderWay == "asc") {
          x = a;
          y = b;
        } else {
          x = b;
          y = a;
        }
        if (type == "datetime") {
          return this.$moment(x[this.C_orderBy]).diff(y[this.C_orderBy]);
        } else {
          let textX;
          let textY;
          if (type == "belongsTo") {
            textX = x[this.C_orderBy][field.textKey].toUpperCase();
            textY = y[this.C_orderBy][field.textKey].toUpperCase();
          } else {
            textX = x[this.C_orderBy];
            textY = y[this.C_orderBy];
          }
          if (textX < textY) {
            return -1;
          }
          if (textX > textY) {
            return 1;
          }
          return 0;
        }
      });
      return _items;
    },
    // _items() {
    //   return this.$_CMS_getFormatedTableItems(this.items, this.fields);
    // },
    _actionWidth() {
      let _actionWidth = 10;
      let count = 0;

      if (this.inRowBtnRead) {
        _actionWidth += 40.1;
        count++;
      }
      if (this.inRowBtnUpdate) {
        _actionWidth += 40.1;
        count++;
      }
      if (this.inRowBtnDelete) {
        _actionWidth += 40.1;
        count++;
      }
      if (this.inRowBtnComplete) {
        _actionWidth += 40.1;
        count++;
      }
      if (this.customTableActions) {
        this.customTableActions.forEach(() => {
          _actionWidth += 40.1;
          count++;
        });
      }
      _actionWidth += count * 8;
      return _actionWidth;
    },
    _headers() {
      if (this.headers) {
        return this.headers;
      } else if (this.showFields) {
        const _headers = [];
        this.showFields.forEach((showFieldKey) => {
          if (showFieldKey in this.fields) {
            const field = this.fields[showFieldKey];
            if (field.type == "list" || field.type == "evaluationStage") {
              return;
            }
            if (
              field.type == "image" ||
              field.type == "tags" ||
              field.type == "password" ||
              field.type == "link" ||
              field.type == "editor"
            ) {
              _headers.push({
                value: showFieldKey,
                text: field.label,
                width: field.width,
                sortable: false,
              });
              return;
            }
            _headers.push({
              value: showFieldKey,
              text: field.label,
              width: field.width,
              sortable: field.sortable,
              // sortable: false,
            });
          }
        });
        return _headers;
      } else {
        return [];
      }
    },
  },

  props: {
    customTableActions: {
      type: Array,
      default: null,
    },
    showFields: {
      type: Array,
      default: null,
    },
    dialogRead: {
      type: Boolean,
      default: false,
    },
    dialogUpdate: {
      type: Boolean,
      default: false,
    },
    dialogDelete: {
      type: Boolean,
      default: false,
    },
    getUpdateUrl: {
      type: Function,
      default: null,
    },
    getReadUrl: {
      type: Function,
      default: null,
    },
    paginate: {
      type: Boolean,
      default: true,
    },
    headers: {
      type: Array,
      default: null,
    },
    lastPage: Number,
    itemsPerPage: Number,
    currentPage: [Number, String],
    dataTotalCount: {
      type: Number,
      default: 0,
    },
    orderBy: {
      type: [String, Array],
      default: "updated_at",
    },
    orderWay: {
      type: [String, Array],
      default: "desc",
    },
    orderByDefault: {
      type: [String, Array],
      default: "updated_at",
    },
    orderWayDefault: {
      type: [String, Array],
      default: "desc",
    },
    loading: {
      type: Boolean,
      default: false,
    },
    rowClickRead: {
      type: Boolean,
      default: true,
    },
    inRowBtnRead: {
      type: Boolean,
      default: true,
    },
    inRowBtnUpdate: {
      type: Boolean,
      default: true,
    },
    inRowBtnDelete: {
      type: Boolean,
      default: true,
    },
    inRowBtnComplete: {
      type: Boolean,
      default: false,
    },
    showExpand: {
      type: Boolean,
      default: true,
    },
    expandable: {
      type: Boolean,
      default: true,
    },
    modelName: String,
    pageMode: Boolean,
    displayFields: Object,
    fields: Object,
    items: {
      type: Array,
      default: null,
    },
  },

  watch: {
    C_orderBy: {
      handler() {
        this.$emit("update:orderBy", this.C_orderBy);
      },
    },
    C_orderWay: {
      handler() {
        this.$emit("update:orderWay", this.C_orderWay);
      },
    },
    orderBy: {
      handler() {
        this.C_orderBy = this.orderBy;
      },
    },
    orderWay: {
      handler() {
        this.C_orderWay = this.orderWay;
      },
    },
  },
};
</script>
<template>
  <div class="app-calendar">
    <WsFullCalendar
      ref="WsFullCalendar"
      :events="C_calendarEvents"
      @dragstart="$_onDragStart($event)"
      @resize="$_onResize($event)"
      @drop="$_onDrop($event)"
      @click="$_onClick($event)"
      @date-click="$_onDateClick($event)"
      @date-rander="$_onDateRender($event)"
      :calendarWeekends="_calendarWeekends"
      :updatable="updatable"
    >
      <template v-slot:searchSection>
        <slot name="searchSection"></slot>
      </template>
    </WsFullCalendar>
    <!-- Task Read -->
    <WsReadDialog
      ref="taskReadDialog"
      :fields="$_getReadFields($store.state.model.task.fields,taskFields)"
      :updatable="updatable"
      :deletable="deletable"
      @delete="$_onTaskDelete($event)"
      @update="$_onTaskUpdate($event)"
    >
      <template v-slot:rightActions>
        <WsBtn
          v-if="doneMode&&!taskFocus.is_finish"
          @click="$_onTaskDone()"
        >Done</WsBtn>
      </template>
    </WsReadDialog>
    <!-- Milestone Read -->
    <WsReadDialog
      ref="milestoneReadDialog"
      :fields="$_getReadFields($store.state.model.milestone.fields,milestoneFields)"
      :updatable="updatable"
      :deletable="deletable"
      @delete="$_onMilestoneDelete($event)"
      @update="$_onMilestoneUpdate($event)"
    >
      <template v-slot:rightActions>
        <WsBtn
          v-if="doneMode&&!milestoneFocus.is_finish"
          @click="$_onMilestoneDone()"
          :loading="milestoneDoneLoading"
        >Done</WsBtn>
      </template>
    </WsReadDialog>
    <!-- Additional Off Day -->
    <WsReadDialog
      ref="additionalOffDayReadDialog"
      :fields="$_getReadFields($store.state.model.additional_off_day.fields,additionalOffDayFields)"
      :deletable="false"
      :updatable="false"
    ></WsReadDialog>
    <!-- Additional Working Day -->
    <WsReadDialog
      ref="additionalWorkingDayReadDialog"
      :fields="$_getReadFields($store.state.model.additional_working_day.fields,additionalWorkingDayFields)"
      :deletable="false"
      :updatable="false"
    ></WsReadDialog>
    <!-- Leave Day -->
    <WsReadDialog
      ref="leaveDayReadDialog"
      :fields="$_getReadFields($store.state.model.leave_day.fields,leaveDayFields)"
      :deletable="false"
      :updatable="false"
    ></WsReadDialog>
    <AppTaskDoneDialog
      ref="taskDoneDialog"
      @submit="$_onTaskDoneSubmit($event)"
    ></AppTaskDoneDialog>
    <WsCreateDialog
      ref="taskCreateDialog"
      label="建立Task"
      :fields="$_getUpdateFields($store.state.model.task.fields,taskFields)"
      :errorMessages="createErrorMessages"
      modelName="task"
      @submit="$_onTaskCreateSubmit($event)"
    />
    <WsCreateDialog
      ref="milestoneCreateDialog"
      label="建立Milestone"
      :fields="$_getUpdateFields($store.state.model.milestone.fields,milestoneFields)"
      :errorMessages="createErrorMessages"
      modelName="task"
      @submit="$_onMilestoneCreateSubmit($event)"
    />
    <WsUpdateDialog
      ref="taskUpdateDialog"
      label="Task編輯"
      :fields="$_getUpdateFields($store.state.model.task.fields,taskFields)"
      modelName="task"
      :errorMessages="updateErrorMessages"
      @submit="$_onTaskUpdateSubmit($event)"
    />
    <WsUpdateDialog
      ref="milestoneUpdateDialog"
      label="Milestone編輯"
      :fields="$_getUpdateFields($store.state.model.milestone.fields,milestoneFields)"
      modelName="milestone"
      :errorMessages="updateErrorMessages"
      @submit="$_onMilestoneUpdateSubmit($event)"
    />
    <WsAlert
      ref="taskDeleteAlert"
      title="確定要刪除嗎？"
      description="刪除後資料將無法回覆"
      @submit="$_onTaskDeleteSubmit($event)"
    ></WsAlert>
    <WsAlert
      ref="milestoneDeleteAlert"
      title="確定要刪除嗎？"
      description="刪除後資料將無法回覆"
      @submit="$_onMilestoneDeleteSubmit($event)"
    ></WsAlert>
  </div>
</template>

<script>
export default {
  data: () => ({
    dateFocus: null,
    milestoneDoneLoading: false,
    C_calendarEvents: null,
    taskFocus: null,
    additionalOffDayFocus: null,
    additionalWorkingDayFocus: null,
    leaveDayFocus: null,
    milestoneFocus: null,
    updateErrorMessages: null,
    createErrorMessages: null,
    allDayDragging: null,
  }),

  computed: {
    _calendarWeekends() {
      if (!this.dateFocus) {
        return false;
      }
      const dateFocusMoment = this.$moment(this.dateFocus);
      const startDate = dateFocusMoment.startOf("week").format("YYYY-MM-DD");
      const endDate = dateFocusMoment.endOf("week").format("YYYY-MM-DD");
      if (
        this.additionalWorkingDays.includes(startDate) ||
        this.additionalWorkingDays.includes(endDate)
      ) {
        return true;
      } else {
        return false;
      }
    },
    // _taskFields() {
    //   return this.$_getFilteredFields(
    //     this.$store.state.model.task.fields,
    //     this.taskFields
    //   );
    // },
    // _milestoneFields() {
    //   return this.$_getFilteredFields(
    //     this.$store.state.model.milestone.fields,
    //     this.milestoneFields
    //   );
    // },
    // _additionalOffDayFields() {
    //   return this.$_getFilteredFields(
    //     this.$store.state.model.additional_off_day.fields,
    //     this.additionalOffDayFields
    //   );
    // },
    // _additionalWorkingDayFields() {
    //   return this.$_getFilteredFields(
    //     this.$store.state.model.additional_working_day.fields,
    //     this.additionalWorkingDayFields
    //   );
    // },
    // _leaveDayFields() {
    //   return this.$_getFilteredFields(
    //     this.$store.state.model.leave_day.fields,
    //     this.leaveDayFields
    //   );
    // },
  },

  methods: {
    $_getUpdateFields(fields, showFields) {
      const _filteredFields = {};
      showFields.forEach((fieldKey) => {
        if (fields[fieldKey] != undefined) {
          if (fields[fieldKey].readonly) {
            return;
          }
          _filteredFields[fieldKey] = fields[fieldKey];
        }
      });
      return _filteredFields;
    },
    $_getReadFields(fields, showFields) {
      const _filteredFields = {};
      showFields.forEach((fieldKey) => {
        if (fields[fieldKey] != undefined) {
          _filteredFields[fieldKey] = fields[fieldKey];
        }
      });
      return _filteredFields;
    },
    async $_onMilestoneDone() {
      try {
        this.milestoneDoneLoading = true;
        const res = await this.$axios.post(
          `milestone/${this.milestoneFocus.id}/done`
        );
        this.$emit("milestone-done", res.data.data);
      } catch (error) {
        alert("錯誤");
      } finally {
        this.milestoneDoneLoading = false;
        this.$refs.milestoneReadDialog.close();
      }
    },
    async $_onTaskDoneSubmit($event) {
      try {
        this.$refs.taskDoneDialog.startLoading();
        const res = await this.$axios.post(`task/${this.taskFocus.id}/done`, {
          finish_hour: $event.finishHour,
        });
        this.$emit("task-done", res.data.data);
      } catch (error) {
        alert("錯誤");
      } finally {
        this.$refs.taskDoneDialog.stopLoading();
        this.$refs.taskDoneDialog.close();
        this.$refs.taskReadDialog.close();
      }
    },
    $_onTaskDone() {
      this.$refs.taskDoneDialog.open();
    },
    $_onDateRender($event) {
      this.$emit("date-rander", $event);
      const calendarApi = this.$refs.WsFullCalendar.$refs.FullCalendar.getApi();
      this.dateFocus = calendarApi.getDate();
    },
    $_calendarEventsReset() {
      this.C_calendarEvents = [];
      // setTimeout(() => {
      this.calendarEvents.forEach((event) => {
        this.C_calendarEvents.push(event);
      });
      // }, 50);
    },
    async $_onTaskUpdateSubmit($event) {
      this.$refs.taskUpdateDialog.startLoading();
      try {
        const res = await this.$axios.patch(`/task/${$event.id}`, $event);
        this.$refs.taskUpdateDialog.close();
        this.$emit("task-updated", res.data.data);
      } catch (error) {
        if (error && error.response) {
          if (error.response.data.message == "data store fail.") {
            alert("新增失敗");
          } else {
            this.updateErrorMessages = this.$_formatErrorMessages(
              error.response.data.message
            );
          }
        } else {
          alert("新增失敗");
        }
      } finally {
        this.$refs.taskUpdateDialog.stopLoading();
      }
    },
    async $_onMilestoneUpdateSubmit($event) {
      this.$refs.milestoneUpdateDialog.startLoading();
      try {
        const res = await this.$axios.patch(`/milestone/${$event.id}`, $event);
        this.$refs.milestoneUpdateDialog.close();
        this.$emit("milestone-updated", res.data.data);
      } catch (error) {
        if (error && error.response) {
          if (error.response.data.message == "data store fail.") {
            alert("新增失敗");
          } else {
            this.updateErrorMessages = this.$_formatErrorMessages(
              error.response.data.message
            );
          }
        } else {
          alert("新增失敗");
        }
      } finally {
        this.$refs.milestoneUpdateDialog.stopLoading();
      }
    },
    async $_onTaskDeleteSubmit($event) {
      this.$refs.taskDeleteAlert.startLoading();
      try {
        await this.$axios.delete(`/task/${$event.id}`);
        this.$emit("task-deleted", $event);
        this.$refs.taskReadDialog.close();
      } catch (error) {
        alert("刪除發生錯誤");
      } finally {
        this.$refs.taskDeleteAlert.stopLoading();
        this.$refs.taskDeleteAlert.close();
      }
    },
    async $_onMilestoneDeleteSubmit($event) {
      this.$refs.milestoneDeleteAlert.startLoading();
      try {
        await this.$axios.delete(`/milestone/${$event.id}`);
        this.$emit("milestone-deleted", $event);
        this.$refs.milestoneReadDialog.close();
      } catch (error) {
        alert("刪除發生錯誤");
      } finally {
        this.$refs.milestoneDeleteAlert.stopLoading();
        this.$refs.milestoneDeleteAlert.close();
      }
    },
    $_onTaskDelete($event) {
      this.$refs.taskDeleteAlert.open($event.item);
    },
    $_onTaskUpdate($event) {
      this.updateErrorMessages = null;
      this.$refs.taskReadDialog.close();
      this.$refs.taskUpdateDialog.open($event.item);
    },
    $_onMilestoneDelete($event) {
      this.$refs.milestoneDeleteAlert.open($event.item);
    },
    $_onMilestoneUpdate($event) {
      this.updateErrorMessages = null;
      this.$refs.milestoneReadDialog.close();
      this.$refs.milestoneUpdateDialog.open($event.item);
    },
    async $_onMilestoneCreateSubmit($event) {
      this.$refs.milestoneCreateDialog.startLoading();
      try {
        const res = await this.$axios.post(`/milestone`, $event);
        this.$emit("milestone-created", res.data.data);
        this.$refs.milestoneCreateDialog.close();
      } catch (error) {
        if (error && error.response) {
          if (error.response.data.message == "data store fail.") {
            alert("新增失敗");
          } else {
            this.createErrorMessages = this.$_formatErrorMessages(
              error.response.data.message
            );
          }
        } else {
          alert("新增失敗");
        }
      } finally {
        this.$refs.milestoneCreateDialog.stopLoading();
      }
    },
    async $_onTaskCreateSubmit($event) {
      this.$refs.taskCreateDialog.startLoading();
      try {
        const res = await this.$axios.post(`/task`, $event);
        this.$emit("task-created", res.data.data);
        this.$refs.taskCreateDialog.close();
      } catch (error) {
        if (error && error.response) {
          if (error.response.data.message == "data store fail.") {
            alert("新增失敗");
          } else {
            this.createErrorMessages = this.$_formatErrorMessages(
              error.response.data.message
            );
          }
        } else {
          alert("新增失敗");
        }
      } finally {
        this.$refs.taskCreateDialog.stopLoading();
      }
    },
    $_onDragStart($event) {
      this.allDayDragging = $event.event.allDay;
    },
    $_onResize($event) {
      console.log($event);
    },
    async $_onDrop($event) {
      if (this.allDayDragging != $event.event.allDay) {
        this.$_calendarEventsReset();
        return;
      }
      if (this.allDayDragging) {
        const date = this.$moment($event.event.start).format("YYYY-MM-DD");
        const id = $event.event.extendedProps.data.id;
        const res = await this.$axios.patch(`milestone/${id}`, {
          date: date,
          id: id,
        });
        this.$emit("milestone-updated", res.data.data);
      } else {
        const start_time = this.$moment($event.event.start).format(
          "YYYY-MM-DD HH:mm:ss"
        );
        const id = $event.event.extendedProps.data.id;
        const res = await this.$axios.patch(`task/${id}`, {
          start_time: start_time,
          id: id,
        });
        this.$emit("task-updated", res.data.data);
      }
    },
    $_onClick($event) {
      console.log($event);
      const type = $event.event.extendedProps.type;
      if (type == "task") {
        this.taskFocus = $event.event.extendedProps.data;
        this.$refs.taskReadDialog.open(this.taskFocus);
      } else if (type == "milestone") {
        this.milestoneFocus = $event.event.extendedProps.data;
        this.$refs.milestoneReadDialog.open(this.milestoneFocus);
      } else if (type == "additional_off_day") {
        this.additionalOffDayFocus = $event.event.extendedProps.data;
        this.$refs.additionalOffDayReadDialog.open(this.additionalOffDayFocus);
      } else if (type == "additional_working_day") {
        this.additionalWorkingDayFocus = $event.event.extendedProps.data;
        this.$refs.additionalWorkingDayReadDialog.open(
          this.additionalWorkingDayFocus
        );
      } else if (type == "leave_day") {
        this.leaveDayFocus = $event.event.extendedProps.data;
        this.$refs.leaveDayReadDialog.open(this.leaveDayFocus);
      }
    },
    $_onDateClick($event) {
      console.log($event);
      if (!this.creatable) {
        return;
      }
      this.createErrorMessages = null;
      let createUpdateItem = {};
      if ($event.allDay) {
        createUpdateItem.date = this.$moment($event.date).format("YYYY-MM-DD");
        this.$refs.milestoneCreateDialog.open(createUpdateItem);
      } else {
        createUpdateItem.start_time = this.$moment($event.date).format(
          "YYYY-MM-DD HH:mm"
        );
        createUpdateItem.hours = 1;
        this.$refs.taskCreateDialog.open(createUpdateItem);
      }
    },
  },

  props: {
    leaveDayFields: {
      type: Array,
      default() {
        return ["start_date", "days", "start_time", "hour", "wasa"];
      },
    },
    additionalWorkingDayFields: {
      type: Array,
      default() {
        return ["name", "date"];
      },
    },
    additionalOffDayFields: {
      type: Array,
      default() {
        return ["name", "date"];
      },
    },
    taskFields: {
      type: Array,
      default() {
        return [
          "id",
          "name",
          "start_time",
          "is_close",
          "is_finish",
          "hour",
          "finish_hour",
          "remark",
          "project",
          "taker",
          "excute_type",
          "evaluation_material",
          "evaluation_material_option",
          "creator",
          "adjust",
          "qc",
        ];
      },
    },
    milestoneFields: {
      type: Array,
      default() {
        return [
          "id",
          "name",
          "project",
          "date",
          "taker",
          "excute_type",
          "is_close",
          "is_finish",
          "remark",
        ];
      },
    },
    deletable: {
      type: Boolean,
      default: false,
    },
    updatable: {
      type: Boolean,
      default: false,
    },
    creatable: {
      type: Boolean,
      default: false,
    },
    calendarEvents: {
      type: Array,
      default: null,
    },
    hourStart: {
      type: Number,
      default: 9,
    },
    hourEnd: {
      type: Number,
      default: 18,
    },
    preventWeekdays: {
      type: Array,
      default() {
        return [0, 6];
      },
    },
    preQcMilestones: {
      type: Array,
      default: null,
    },
    preQcTasks: {
      type: Array,
      default: null,
    },
    preMilestones: {
      type: Array,
      default: null,
    },
    preTasks: {
      type: Array,
      default: null,
    },
    additionalWorkingDays: {
      type: Array,
      default: null,
    },
    additionalOffDays: {
      type: Array,
      default: null,
    },
    doneMode: {
      type: Boolean,
      default: false,
    },
  },

  watch: {
    calendarEvents: {
      handler() {
        this.$_calendarEventsReset();
      },
    },
  },

  mounted() {
    this.$_calendarEventsReset();
  },
};
</script>

<style>
</style>
export default {
  modelName: "friend",
  label: "山葵好朋友",
  fields: {
    id: {
      type: "text",
      label: "ID",
      readonly: true
    },
    name: {
      type: "text",
      label: "名稱",
      autofocus: true,
      rules: "required"
    },
    company_name: {
      type: 'text',
      label: '公司名稱'
    },
    title: {
      type: 'text',
      label: '頭銜'
    },
    tel: {
      type: 'text',
      label: '電話'
    },
    meet_at: {
      type: 'date',
      label: '認識日期'
    },
    email: {
      type: 'email',
      label: 'Email'
    },
    fb: {
      type: 'text',
      label: 'FB連結'
    },
    line: {
      type: 'text',
      label: 'line ID'
    },
    remark: {
      type: 'textarea',
      label: '備註'
    },
    client: {
      type: 'belongsTo',
      label: '山葵客戶',
      textKey: "name",
      modelName: "client",
      relationPopup: true,
      width: '100px',
    },
    updated_at: {
      type: "datetime",
      label: "最後更新時間",
      readonly: true
    },
    sequence: {
      type: "text",
      label: "排序"
    },
  },
}
import Vue from 'vue'
import VueRouter from 'vue-router'
import { router as cmsRouter } from '@/__vue2stone_cms'
import RouteAnalytics from './analytics'
import RouteAccounting from './accounting'
import RouteQuotation from './quotation'
import RouteCalendar from './calendar'
import RouteMy from './my'
import RouteOrder from './order'
import RouteExecution from './execution'
import RouteHr from './hr'

Vue.use(VueRouter)

const routes = [
  {
    path: '/',
    name: 'Home',
    component: () => import('../views/Home.vue'),
    meta: {
      middleware: 'auth'
    }
  },
  {
    path: '/public_property',
    name: 'public_property',
    component: () => import('../views/PublicProperty.vue'),
    meta: {
      middleware: 'auth',
    }
  },
  // ...cmsRouter.getResourceRoutes({
  //   routes: ['index', 'create', 'update', 'read'],
  //   modelName: 'tulpa_section_template',
  //   filePrefix: "/Tulpa",
  //   viewsPath: '__vue2stone_cms/views'
  // }),
  {
    path: '/business_extension',
    name: 'business_extension',
    component: () => import('../views/BusinessExtension/Index.vue'),
    meta: {
      middleware: 'auth',
      requiredScopes: ['boss']
    }
  },
  {
    path: '/evaluation_material',
    name: 'evaluation_material',
    component: () => import('../views/EvaluationMaterial/Index.vue'),
    meta: {
      middleware: 'auth',
      requiredScopes: ['boss', 'admin', 'pm']
    }
  },
  {
    path: '/friend',
    name: 'friend',
    component: () => import('../views/Friend/Index.vue'),
    meta: {
      middleware: 'auth',
      requiredScopes: ['boss', 'admin', 'pm']
    }
  },
  {
    path: '/client',
    name: 'client',
    component: () => import('../views/Client/Index.vue'),
    meta: {
      middleware: 'auth',
      requiredScopes: ['boss', 'admin', 'pm']
    }
  },
  {
    path: '/out_source',
    name: 'out_source',
    component: () => import('../views/OutSource/Index.vue'),
    meta: {
      middleware: 'auth',
      requiredScopes: ['boss', 'admin', 'pm']
    }
  },
  {
    path: '/additional_off_day',
    name: 'additional_off_day',
    component: () => import('../views/AdditionalOffDay/Index.vue'),
    meta: {
      middleware: 'auth',
      requiredScopes: ['boss', 'pm']
    }
  },
  {
    path: '/additional_working_day',
    name: 'additional_working_day',
    component: () => import('../views/AdditionalWorkingDay/Index.vue'),
    meta: {
      middleware: 'auth',
      requiredScopes: ['boss', 'pm']
    }
  },
  {
    path: '/wasa_relate_links',
    name: 'WasaRelateLinks',
    component: () => import('../views/WasaRelateLinks.vue'),
    meta: {
      middleware: 'auth',
    }
  },
  // {
  //   path: '/test',
  //   name: 'test',
  //   component: () => import('../views/Test.vue'),
  //   meta: {
  //     middleware: 'auth',
  //     requiredScopes: ['boss', 'pm']
  //   }
  // },
  ...RouteAnalytics,
  ...RouteAccounting,
  ...RouteQuotation,
  ...RouteCalendar,
  ...RouteMy,
  ...RouteOrder,
  ...RouteExecution,
  ...RouteHr,
]

const router = new VueRouter({
  mode: 'history',
  base: process.env.BASE_URL,
  routes: cmsRouter.getCmsRoutes(routes, [])
})

router.beforeEach(cmsRouter.middlewareAuth)

export default router

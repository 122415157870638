<template>
  <div class="ws-spacer"></div>
</template>

<script>
export default {};
</script>

<style lang="scss" >
.ws-spacer {
  width: 100%;
  height: 100%;
}
</style>
<template>
  <div class="ws-info">
    <div
      v-if="_label"
      class="ws-info-label"
    >{{_label}}</div>
    <div v-if="(value==null||value.length==0||(typeof(value)=='object'&&Object.keys(value).length===0))&&type!='custom'"> - </div>
    <WsInfoText
      v-else-if="type=='text'||type=='password'||type=='email'||type=='textarea'||type=='number'"
      :value="value"
    ></WsInfoText>
    <WsInfoCurrency
      v-else-if="type=='currency'"
      :value="value"
    ></WsInfoCurrency>
    <WsInfoLink
      v-else-if="type=='link'"
      :value="value"
    ></WsInfoLink>
    <WsInfoLocales
      v-else-if="type=='locales'"
      :value="value"
      :fields="fields"
      :locale="locale"
      :hideOnNull="hideOnNull"
      :showLocalePicker="showLocalePicker"
    ></WsInfoLocales>
    <WsInfoTime
      v-else-if="type=='time'"
      :value="value"
    ></WsInfoTime>
    <WsInfoDatetime
      v-else-if="type=='datetime'"
      :value="value"
    ></WsInfoDatetime>
    <WsInfoDate
      v-else-if="type=='date'"
      :value="value"
    ></WsInfoDate>
    <WsInfoFile
      v-else-if="type=='file'"
      :value="value"
    ></WsInfoFile>
    <WsInfoFiles
      v-else-if="type=='files'"
      :value="value"
    ></WsInfoFiles>
    <WsInfoBelongsTo
      v-else-if="type=='belongsTo'"
      :value="value"
      :textKey="textKey"
      :fields="fields"
      :modelName="modelName"
      :getText="getText"
      :relationPopup="relationPopup"
    ></WsInfoBelongsTo>
    <WsInfoHasMany
      v-else-if="type=='hasMany'"
      :value="value"
      :textKey="textKey"
      :fields="fields"
      :modelName="modelName"
      :getText="getText"
      :relationPopup="relationPopup"
    ></WsInfoHasMany>
    <WsInfoBelongsToMany
      v-else-if="type=='belongsToMany'"
      :value="value"
      :textKey="textKey"
      :fields="fields"
      :modelName="modelName"
      :getText="getText"
      :relationPopup="relationPopup"
    ></WsInfoBelongsToMany>
    <WsInfoHtml
      v-else-if="type=='editor'"
      :value="value"
    ></WsInfoHtml>
    <WsInfoSwitch
      v-else-if="type=='switch'"
      :activeText="activeText"
      :inactiveText="inactiveText"
      :value="value"
    ></WsInfoSwitch>
    <WsInfoSelect
      v-else-if="type=='select'"
      :value="value"
      :items="items"
    ></WsInfoSelect>
    <WsInfoSelect
      v-else-if="type=='multi-select'"
      :value="value"
      multiple
      :items="items"
    ></WsInfoSelect>
    <WsInfoRadio
      v-else-if="type=='radio'"
      :value="value"
      :items="items"
    ></WsInfoRadio>
    <WsInfoList
      v-else-if="type=='list'"
      :value="value"
      :fields="fields"
      :showFields="showFields"
    ></WsInfoList>
    <WsInfoList
      v-else-if="type=='hasManyList'"
      :value="value"
      :fields="fields"
      :showFields="showFields"
    ></WsInfoList>
    <img
      v-else-if="type=='image'&&value"
      :src="value"
    />
    <WsInfoImages
      v-else-if="type=='images'"
      :value="value"
    />
    <WsInfoColor
      v-else-if="type=='color'"
      :value="value"
    ></WsInfoColor>
    <WsInfoStringCompare
      v-else-if="type=='stringCompare'"
      :value="value"
    ></WsInfoStringCompare>
    <WsInfoTags
      v-else-if="type=='tags'"
      :value="value"
    ></WsInfoTags>
    <WsInfoFields
      v-else-if="type=='fields'"
      :value="value"
    >
    </WsInfoFields>
    <WsInfoWasaIntro
      v-else-if="type=='wasaintro'"
      :value="value"
    ></WsInfoWasaIntro>
    <div v-else-if="type=='custom'">{{getValue(modelData)}}</div>
  </div>
</template>

<script>
export default {
  computed: {
    _label() {
      if (this.displayLabel) {
        return this.label;
      } else {
        return null;
      }
    },
  },
  props: {
    modelData: {
      type: Object,
      default: null,
    },
    showFields: {
      type: Array,
      default: null,
    },
    hideOnNull: {
      type: Boolean,
      default: true,
    },
    displayLabel: {
      type: Boolean,
      default: true,
    },
    getValue: {
      type: Function,
      default: null,
    },
    getText: {
      type: Function,
      default: null,
    },
    relationPopup: {
      type: Boolean,
      default: false,
    },
    showLocalePicker: {
      type: Boolean,
      default: false,
    },
    activeText: {
      type: String,
      default: "active",
    },
    inactiveText: {
      type: String,
      default: "inactive",
    },
    textKey: {
      type: String,
      default: "name",
    },
    locale: {
      type: String,
      default: null,
    },
    active: {
      type: String,
      default: null,
    },
    items: {
      type: Array,
      default: null,
    },
    conditions: {
      type: Array,
      default: null,
    },
    maxWidth: {
      type: String,
      default: null,
    },
    fields: {
      type: Object,
      default: null,
    },
    autofocus: {
      type: Boolean,
      default: false,
    },
    type: {
      type: String,
      default: "text",
    },
    label: {
      type: String,
      default: null,
    },
    placeholder: {
      type: String,
      default: null,
    },
    note: {
      type: String,
      default: null,
    },
    errorMessage: {
      type: String,
      default: null,
    },
    isOk: {
      type: Boolean,
      default: false,
    },
    disabled: {
      type: Boolean,
      default: false,
    },
    value: {},
    name: {
      type: [String, Array],
      default: null,
    },
    rules: {
      type: String,
      default: null,
    },
    modelName: {
      type: String,
      default: null,
    },
    parentState: {},
    parent: {
      type: String,
      default: null,
    },
  },
};
</script>
<template>
  <WsMain>
    <WsState
      label="開始時間"
      type="date"
      v-model="state.startDate"
    ></WsState>
    <WsState
      class="mt-20"
      label="結束時間"
      type="date"
      v-model="state.endDate"
    ></WsState>
    <WsTitle class="mt-40">超時執行元件</WsTitle>
    <table class="calc-table mt-10">
      <tr>
        <th>元件名稱</th>
      </tr>
      <tr
        v-for="(evaluationMaterial, evaluationMaterialIndex) in _overtimeEvaluationMaterials"
        :key="evaluationMaterialIndex"
      >
        <td>{{evaluationMaterial.name}}</td>
      </tr>
    </table>
    <WsTitle class="mt-40">超時執行元件選項</WsTitle>
    <table class="calc-table mt-10">
      <tr>
        <th>選項名稱</th>
      </tr>
      <tr
        v-for="(evaluationMaterialOption, evaluationMaterialOptionIndex) in _overtimeEvaluationMaterialOptions"
        :key="evaluationMaterialOptionIndex"
      >
        <td>{{evaluationMaterialOptionIndex.name}}</td>
      </tr>
    </table>
  </WsMain>
</template>

<script>
export default {
  metaInfo() {
    return {
      title: "超時執行元件",
    };
  },

  data: () => ({
    tasks: [],
    state: {
      startDate: null,
      endDate: null,
    },
  }),

  computed: {
    _overtimeEvaluationMaterialOptions(){
      if (!this._overtimeTasks || !this._overtimeTasks.length) {
        return [];
      }
      const _overtimeEvaluationMaterialOptions = []
      this._overtimeTasks.forEach((_overtimeTask) => {
        if (!_overtimeTask.evaluation_material||!_overtimeTask.evaluation_material_option) {
          return;
        }
        const exist = _overtimeEvaluationMaterialOptions.find((e) => {
          return e.id == _overtimeTask.evaluation_material_option.id;
        });
        if (exist) {
          exist.tasks.push(_overtimeTask);
        } else {
          const newMaterialOption = _overtimeTask.evaluation_material_option;
          newMaterialOption.tasks = [];
          newMaterialOption.tasks.push(_overtimeTask);
          _overtimeEvaluationMaterialOptions.push(newMaterialOption);
        }
      });
      return _overtimeEvaluationMaterialOptions;
    },
    _overtimeEvaluationMaterials() {
      if (!this._overtimeTasks || !this._overtimeTasks.length) {
        return [];
      }
      const _overtimeEvaluationMaterials = [];
      this._overtimeTasks.forEach((_overtimeTask) => {
        if (!_overtimeTask.evaluation_material) {
          return;
        }
        if (
          _overtimeTask.evaluation_material &&
          _overtimeTask.evaluation_material_option
        ) {
          return;
        }
        const exist = _overtimeEvaluationMaterials.find((e) => {
          return e.id == _overtimeTask.evaluation_material.id;
        });
        if (exist) {
          exist.tasks.push(_overtimeTask);
        } else {
          const newMaterial = _overtimeTask.evaluation_material;
          newMaterial.tasks = [];
          newMaterial.tasks.push(_overtimeTask);
          _overtimeEvaluationMaterials.push(newMaterial);
        }
      });
      console.log(_overtimeEvaluationMaterials);
      return _overtimeEvaluationMaterials;
    },
    _overtimeTasks() {
      if (!this.tasks || !this.tasks.length) {
        return [];
      }
      const _overtimeTasks = [];
      this.tasks.forEach((task) => {
        if (task.hour && task.finish_hour && task.finish_hour > task.hour) {
          _overtimeTasks.push(task);
        }
      });
      console.log(_overtimeTasks);
      return _overtimeTasks;
    },
    // _excuteMaterials(){
    //   if(!this.tasks||!this.tasks.length){
    //     return []
    //   }else{
    //     this.tasks.forEach(task => {
    //       // console.log(task);
    //       if(task.evaluation_material){
    //         console.log(task.evaluation_material);
    //       }
    //     });
    //     return []
    //   }
    // }
  },

  methods: {
    async $_fetchTask() {
      if (!this.state.startDate || !this.state.endDate) {
        return;
      }
      const params = {
        time_field: "start_time",
        start_time: this.state.startDate,
        end_time: this.state.endDate,
        order_by: "start_time",
        order_way: "asc",
      };
      const res = await this.$axios.get("task/index/all", {
        params: params,
      });
      this.tasks = res.data.data;
    },
    $_dateSet() {
      this.state.startDate = this.$moment()
        .add(-1, "month")
        .format("YYYY-MM-DD");
      this.state.endDate = this.$moment().format("YYYY-MM-DD");
    },
  },

  watch: {
    state: {
      handler() {
        this.$_fetchTask();
      },
      deep: true,
    },
  },

  mounted() {
    this.$_dateSet();
    // this.$_fetchTask();
  },
};
</script>
<style lang="scss" >
.calc-table {
  tr {
    th {
      padding: 10px;
      text-align: left;
    }
    td {
      padding: 10px;
      &.center {
        text-align: center;
        vertical-align: middle;
      }
    }
  }
}
</style>
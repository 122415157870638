import moment from 'moment'
export default {
  modelName: "overtime_apply",
  label: "加班申請單",
  fields: {
    id: {
      type: "text",
      label: "ID",
      readonly: true
    },
    updated_at: {
      type: "datetime",
      label: "最後更新時間",
      readonly: true
    },
    apply_date: {
      type: "date",
      label: "加班日期",
      defaultValue: moment().format('YYYY-MM-DD')
    },
    apply_hours: {
      type: "text",
      label: "預計加班時數",
      defaultValue: 2
    },
    wasa: {
      type: 'belongsTo',
      label: '申請人',
      textKey: "name",
      modelName: "wasa",
      width: '100px',
    },
    task: {
      type: 'belongsTo',
      label: '需加班Task',
      textKey: "name",
      modelName: "task",
      width: '100px',
    },
    status: {
      type: 'select',
      label: '覆核狀態',
      defaultValue: 0,
      items: [
        {
          value: 0,
          text: '申請中'
        },
        {
          value: 1,
          text: 'OK'
        },
        {
          value: 2,
          text: '打妹'
        },
      ]
    },
    remark: {
      type: "textarea",
      label: "備註"
    },
  },
}
<template>
  <WsBtn
    class="ws-test-btn"
    @click="$emit('click',$event)"
  >測試用按鈕</WsBtn>
</template>

<script>
export default {};
</script>

<style>
</style>
<template>
  <WsDialog
    ref="WsDialog"
    title="Done Task"
    :escMetaClose="true"
  >
    <template v-slot:content>

      <ValidationObserver ref="form">
        <form
          ref="createForm"
          @submit.prevent
        >

          <div class="ws-row">
            <div
              class="ws-col"
              cols="12"
              :class="[`sm-col-12`]"
            >
              <WsState
                type="number"
                label="完成時數"
                rules="required"
                v-model="updateData.finishHour"
                :autofocus="true"
              ></WsState>
            </div>
          </div>
        </form>
      </ValidationObserver>
    </template>
    <template v-slot:rightActions>
      <WsBtn
        @click="$_onCancel()"
        minWidth="100px"
        color="textSecondary"
        outlined
      >取消</WsBtn>
      <WsBtn
        @click="$_onSubmit()"
        minWidth="100px"
        :loading="submitLoading"
        color="primary"
      >送出</WsBtn>
    </template>
  </WsDialog>
</template>

<script>
export default {
  data: () => ({
    dialog: false,
    submitLoading: false,
    updateData: {
      finishHour: null,
    },
  }),

  methods: {
    stopLoading() {
      this.submitLoading = false;
    },
    startLoading() {
      this.submitLoading = true;
    },
    $_keydownHandler($event) {
      if ($event.metaKey && $event.key == "Enter") {
        this.$_onSubmit();
      }
    },
    async $_onSubmit() {
      this.$emit("submit", this.updateData);
    },
    $_onCancel() {
      this.close();
    },
    close() {
      this.dialog = false;
      this.$refs.WsDialog.close();
      window.removeEventListener("keydown", this.$_keydownHandler);
    },
    open() {
      this.dialog = true;
      this.$refs.WsDialog.open();
      window.addEventListener("keydown", this.$_keydownHandler);
    },
  },
};
</script>

<style>
</style>
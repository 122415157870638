<template>
  <div>
    <WsErrorMessage v-if="_hasEmptyStage">尚有階段未安排執行項目，安排可能導致錯誤</WsErrorMessage>
    <WsErrorMessage
      class="mt-20"
      v-if="_hasEmptyExcuteTypeWasa"
    >尚有階段未安排執行人員之項目，安排可能導致錯誤</WsErrorMessage>
    <AppCalendar
      v-if="evaluation"
      :calendarEvents="_calendarEvents"
      :additionalWorkingDays="_additionalWorkingDays"
      :additionalOffDays="_additionalOffDays"
    ></AppCalendar>
  </div>
</template>

<script>
export default {
  data: () => ({}),
  methods: {
    getTasks() {
      return this._tasks;
    },
    getMilestones() {
      return this._milestones;
    },
    $_getPrepareTasks(evaluationWasas, wasas) {
      if (!this._evaluationFundamentalHours) {
        return [];
      }
      const _wasas = [];
      const prepareTasks = [];
      evaluationWasas.forEach((evaluationWasa) => {
        const checkType = this._checkExcuteTypes.find((e) => {
          return e.id == evaluationWasa.excute_type;
        });
        if (checkType) {
          return;
        }
        const exist = _wasas.find((e) => {
          return e.id == evaluationWasa.wasa;
        });
        if (!exist) {
          const wasa = wasas.find((e) => {
            return e.id == evaluationWasa.wasa;
          });
          _wasas.push(wasa);
        }
      });
      _wasas.forEach((wasa) => {
        prepareTasks.push({
          name: "專案準備",
          taker: wasa,
          hour: this._evaluationFundamentalHours,
        });
      });
      return prepareTasks;
    },
    $_getExcuteTypeWasa(excuteTypeId, evaluationWasas, wasas) {
      const evaluationWasa = evaluationWasas.find((e) => {
        return e.excute_type == excuteTypeId;
      });
      if (!evaluationWasa) {
        return null;
      }
      const wasa = wasas.find((e) => {
        return e.id == evaluationWasa.wasa;
      });
      return wasa;
    },
    $_adjustTaskSet(tasks) {
      const _tasks = tasks;
      this._excuteTypeSetting.forEach((_excuteTypeSettingItem) => {
        if (!_excuteTypeSettingItem.adjust_times) {
          return;
        }
        _tasks
          .slice()
          .reverse()
          .forEach((_task, _taskIndex) => {
            if (!_task.excute_type) {
              return;
            }
            if (_task.excute_type.id != _excuteTypeSettingItem.excute_type) {
              return;
            }
            const adjustTasks = [];
            for (let i = 0; i < _excuteTypeSettingItem.adjust_times; i++) {
              const startTime = this.$_getNextValidDateTime(
                this.$moment(_task.start_time).add(1, "days").set({
                  hour: 9,
                }),
                1
              );
              adjustTasks.push({
                name: `${_task.name}__第${i + 1}次調整`,
                start_time: startTime,
                hour: Math.ceil(_task.hour * 0.3),
                taker: _task.taker,
                stage_index: _task.stage_index,
                excute_type: _task.excute_type,
                adjust: true,
              });
            }
            _tasks.splice(
              _tasks.length - 1 - _taskIndex + 1,
              0,
              ...adjustTasks
            );
          });
      });
      return _tasks;
    },
    $_qcTaskSet(tasks) {
      const _tasks = tasks;
      const _evaluationStages = this._evaluationStages;
      _evaluationStages.forEach((evaluationStage, evaluationStageIndex) => {
        if (evaluationStage.qc) {
          const countStages = [evaluationStageIndex];
          const prevStages = _evaluationStages.slice(0, evaluationStageIndex);
          for (
            let prevStageIndex = 0;
            prevStageIndex < prevStages.length;
            prevStageIndex++
          ) {
            const prevStage = prevStages.slice().reverse()[prevStageIndex];
            if (!prevStage.qc) {
              countStages.push(prevStages.length - prevStageIndex - 1);
            } else {
              break;
            }
          }
          let stagesName = "";
          countStages.forEach((countStageIndex, arrayIndex) => {
            if (arrayIndex > 0) {
              stagesName += ",";
            }
            stagesName += _evaluationStages[countStageIndex].name;
          });

          const lastTaskReverseIndex = tasks
            .slice()
            .reverse()
            .findIndex((e) => {
              return e.stage_index == evaluationStageIndex;
            });
          const lastTaskIndex = tasks.length - lastTaskReverseIndex - 1;
          const qcTasks = [];
          this.excuteTypes.forEach((excuteType) => {
            if (
              evaluationStage.excute_types &&
              !evaluationStage.excute_types.find((e) => {
                return e.id == excuteType.id;
              })
            ) {
              return;
            }
            if (!excuteType.qc) {
              return;
            }
            const taker = this.$_getExcuteTypeWasa(
              excuteType.id,
              this._evaluationWasas,
              this.wasas
            );
            if (!taker) {
              return;
            }
            const exist = qcTasks.find((e) => {
              if (!e.taker) {
                return false;
              }
              return e.taker.id == taker.id;
            });
            if (!exist) {
              let hour = 0;
              tasks.forEach((task) => {
                if (!task.taker) {
                  return;
                }
                if (
                  task.taker.id == taker.id &&
                  countStages.includes(task.stage_index)
                ) {
                  hour += task.hour;
                }
              });
              hour = Math.ceil(hour * this._evaluationIssueTimeRate);
              qcTasks.push({
                name: `階段QC調整__${stagesName}`,
                // name: `階段QC調整__${evaluationStage.name}`,
                hour: hour,
                qc: true,
                taker: taker,
                stage_index: evaluationStageIndex,
              });
            }
          });
          _tasks.splice(lastTaskIndex + 1, 0, ...qcTasks);
        }
      });
      return _tasks;
    },
    $_getNextValidDateTime(time, dayDiff = 1) {
      const _time = this.$moment(time);
      let maxCount = 0;
      for (let i = 0; i < dayDiff; i++) {
        _time.add(1, "day");
        while (!this.$_startTimeValidCheck(_time) && maxCount < 100) {
          _time.add(1, "day");
          maxCount++;
        }
      }
      return _time;
    },
    $_getPrevValidDateTime(time, dayDiff = 1) {
      const _time = this.$moment(time);
      let maxCount = 0;
      for (let i = 0; i < dayDiff; i++) {
        _time.add(-1, "day");
        while (!this.$_startTimeValidCheck(_time) && maxCount < 100) {
          _time.add(-1, "day");
          maxCount++;
        }
      }
      return _time;
    },
    $_getExcuteTypeNextKeepWeekById(excuteType) {
      if (!excuteType) {
        return false;
      } else {
        const setting = this._excuteTypeSetting.find((e) => {
          return e.excute_type == excuteType.id;
        });
        return setting ? setting.next_keep_week : false;
      }
    },
    $_getPrevTask({ task, prevTasks }) {
      if (task.qc) {
        let tarIndex = 0;
        prevTasks
          .slice()
          .reverse()
          .forEach((prevTask, prevTaskIndex) => {
            if (prevTask.qc) {
              return;
            } else {
              const tarTaskEndTime = this.$_GRD_getEndtimeByStartTimeAndHour({
                start_time: this.$moment(prevTasks[tarIndex].start_time),
                hour: prevTasks[tarIndex].hour,
                additionalOffDays: this._additionalOffDays,
                additionalWorkingDays: this._additionalWorkingDays,
              });
              const prevTaskEndTime = this.$_GRD_getEndtimeByStartTimeAndHour({
                start_time: this.$moment(prevTask.start_time),
                hour: prevTask.hour,
                additionalOffDays: this._additionalOffDays,
                additionalWorkingDays: this._additionalWorkingDays,
              });
              if (this.$moment(tarTaskEndTime).isBefore(prevTaskEndTime)) {
                tarIndex = prevTaskIndex;
              }
            }
          });
        return prevTasks.slice().reverse()[tarIndex];
      }

      const prevTaskTakerMatch = this.$_getPrevTaskByType(
        task,
        prevTasks,
        "takermatch"
      );
      const prevTaskPrevIndex = this.$_getPrevTaskByType(
        task,
        prevTasks,
        "prevexcuteindex"
      );
      if (prevTaskTakerMatch && !prevTaskPrevIndex) {
        return prevTaskTakerMatch;
      } else if (!prevTaskTakerMatch && prevTaskPrevIndex) {
        return prevTaskPrevIndex;
      } else if (!prevTaskTakerMatch && !prevTaskPrevIndex) {
        return null;
      } else {
        const startTimePrevIndex = this.$_getStartTimeByPrevTask(
          prevTaskPrevIndex
        );
        const startTimeTakerMatch = this.$_getStartTimeByPrevTask(
          prevTaskTakerMatch
        );
        if (this.$moment(startTimeTakerMatch).isAfter(startTimePrevIndex)) {
          return prevTaskTakerMatch;
        } else {
          return prevTaskPrevIndex;
        }
      }
    },
    $_getPrevTaskByType(task, prevTasks, type) {
      if (type == "takermatch") {
        if (!task.taker) {
          return null;
        }
        const prevTask = prevTasks
          .slice()
          .reverse()
          .find((e) => {
            if (!e.taker) {
              return false;
            } else {
              return e.taker.id == task.taker.id;
            }
          });
        return prevTask;
      } else if (type == "prevexcuteindex") {
        if (!task.excute_type) {
          return null;
        }
        const prevTask = prevTasks
          .slice()
          .reverse()
          .find((e) => {
            if (!e.excute_type) {
              return false;
            } else if (
              e.excute_type.excute_index == task.excute_type.excute_index
            ) {
              return (
                e.excute_type.excute_index_second <
                task.excute_type.excute_index_second
              );
            } else {
              return e.excute_type.excute_index < task.excute_type.excute_index;
            }
          });
        return prevTask;
      } else {
        return null;
      }
    },
    $_tasksTimeSet(tasks) {
      tasks.forEach((task, taskIndex) => {
        const prevTasks = tasks.slice(0, taskIndex);
        const prevTask = this.$_getPrevTask(task, prevTasks);
        let start_time;
        if (!prevTask) {
          start_time = this.$moment(this.evaluation.start_date)
            .set({
              hour: this.hourStart,
              minute: 0,
              second: 0,
            })
            .format();
        } else {
          if (task.qc) {
            const _start_time = this.$moment(
              this.$_getStartTimeByPrevTask(prevTask)
            ).format();
            start_time = this.$_getNextValidDateTime(_start_time, 2)
              .set({
                hour: this.hourStart,
              })
              .format();
          } else {
            start_time = this.$moment(
              this.$_getStartTimeByPrevTask(prevTask)
            ).format();
          }
        }
        start_time = this.$_getValidStartTime({
          start_time: this.$moment(start_time),
          task: task,
          afterTasks: this.afterTasks,
          leaveDays: this.leaveDays,
        });
        task.start_time = start_time;
      });
      return tasks;
    },
    $_getValidStartTime({ start_time, task, afterTasks, leaveDays }) {
      if (!task.taker || !this.afterTasks) {
        return start_time;
      }
      let _start_time = start_time;
      const takerAfterTasks = afterTasks.filter((e) => {
        if (!e.taker) {
          return false;
        } else {
          return e.taker.id == task.taker.id;
        }
      });
      const takerLeaveDays = leaveDays.filter((e) => {
        if (!e.wasa) {
          return false;
        } else {
          return e.wasa.id == task.taker.id;
        }
      });
      let count = 0;
      let checkIndexAfterTask = 0;
      let checkIndexLeaveDay = 0;
      let complete = false;
      while (
        count < 500 &&
        (checkIndexAfterTask < takerAfterTasks.length ||
          checkIndexLeaveDay < takerLeaveDays.length) &&
        !complete
      ) {
        const _takerAfterTasks = takerAfterTasks.slice(
          checkIndexAfterTask,
          takerAfterTasks.length
        );
        const _takerLeaveDays = takerLeaveDays.slice(
          checkIndexLeaveDay,
          takerLeaveDays.length
        );
        const _end_time = this.$_GRD_getEndtimeByStartTimeAndHour({
          start_time: _start_time,
          hour: task.hour,
          additionalOffDays: this._additionalOffDays,
          additionalWorkingDays: this._additionalWorkingDays,
        });
        let valid = true;

        // after task
        for (
          let _takerAfterTaskIndex = 0;
          _takerAfterTaskIndex < _takerAfterTasks.length;
          _takerAfterTaskIndex++
        ) {
          const _takerAfterTask = _takerAfterTasks[_takerAfterTaskIndex];

          const _takerAfterTaskEndTime = this.$_GRD_getEndtimeByStartTimeAndHour(
            {
              start_time: _takerAfterTask.start_time,
              hour: _takerAfterTask.hour,
              additionalOffDays: this._additionalOffDays,
              additionalWorkingDays: this._additionalWorkingDays,
              preventWeekdays: this.preventWeekdays,
            }
          );
          if (
            (this.$moment(_start_time).isSameOrBefore(
              _takerAfterTask.start_time
            ) &&
              this.$moment(_end_time).isAfter(_takerAfterTask.start_time)) ||
            (this.$moment(_takerAfterTask.start_time).isSameOrBefore(
              _start_time
            ) &&
              this.$moment(_takerAfterTaskEndTime).isAfter(_start_time))
          ) {
            valid = false;
            _start_time = this.$_getStartTimeByEndTime(_takerAfterTaskEndTime);
            checkIndexAfterTask =
              _takerAfterTaskIndex +
              takerAfterTasks.length -
              _takerAfterTasks.length;
            break;
          }
        }

        // leave day
        for (
          let _takerLeaveDayIndex = 0;
          _takerLeaveDayIndex < _takerLeaveDays.length;
          _takerLeaveDayIndex++
        ) {
          // const _takerAfterTask = _takerAfterTasks[_takerAfterTaskIndex];
          const takerLeaveDay = takerLeaveDays[_takerLeaveDayIndex];

          let start =
            takerLeaveDay.start_time && takerLeaveDay.hour
              ? this.$moment(takerLeaveDay.start_time).format(
                  "YYYY-MM-DD HH:mm:ss"
                )
              : this.$moment(takerLeaveDay.start_date)
                  .set({
                    hour: 9,
                    minute: 0,
                    second: 0,
                  })
                  .format("YYYY-MM-DD HH:mm:ss");
          let hour = 0;
          if (takerLeaveDay.hour) {
            hour = takerLeaveDay.hour;
          } else if (takerLeaveDay.days) {
            hour = takerLeaveDay.days * 8;
          }

          const _takerLeaveDayEndTime = this.$_GRD_getEndtimeByStartTimeAndHour(
            {
              start_time: start,
              hour: hour,
              additionalOffDays: this._additionalOffDays,
              additionalWorkingDays: this._additionalWorkingDays,
            }
          );
          if (
            (this.$moment(_start_time).isSameOrBefore(start) &&
              this.$moment(_end_time).isAfter(start)) ||
            (this.$moment(start).isSameOrBefore(_start_time) &&
              this.$moment(_takerLeaveDayEndTime).isAfter(_start_time))
          ) {
            valid = false;
            _start_time = this.$_getStartTimeByEndTime(_takerLeaveDayEndTime);
            checkIndexLeaveDay =
              _takerLeaveDayIndex +
              takerLeaveDays.length -
              _takerLeaveDays.length;
            break;
          }
        }

        if (valid) {
          complete = true;
        }
        count++;
      }

      return this.$moment(_start_time).format();
    },
    $_getStartTimeByPrevTask(prevTask) {
      const endTimePrevTask = this.$_GRD_getEndtimeByStartTimeAndHour({
        start_time: prevTask.start_time,
        hour: prevTask.hour,
        additionalOffDays: this._additionalOffDays,
        additionalWorkingDays: this._additionalWorkingDays,
        preventWeekdays: this.preventWeekdays,
      });
      const settingNextKeepWeek = this.$_getExcuteTypeNextKeepWeekById(
        prevTask.excute_type
      );
      const startTime = this.$_getStartTimeByEndTime(
        endTimePrevTask,
        settingNextKeepWeek
      );
      return startTime;
    },
    $_getStartTimeByEndTime(end_time, next_keep_week = false) {
      const _startTime = this.$moment(end_time);
      if (next_keep_week) {
        _startTime.add(1, "weeks").set({
          weekday: 1,
          hour: this.hourStart,
          minute: 0,
          second: 0,
        });
        let maxCount = 0;
        while (!this.$_startTimeValidCheck(_startTime) && maxCount < 100) {
          _startTime.add(1, "day");
          maxCount++;
        }
        return _startTime;
      } else if (_startTime.hour() >= this.hourEnd) {
        _startTime.add(1, "day").set({
          hour: this.hourStart,
          minute: 0,
          second: 0,
        });
        let maxCount = 0;
        while (!this.$_startTimeValidCheck(_startTime) && maxCount < 100) {
          _startTime.add(1, "day");
          maxCount++;
        }
        return _startTime;
      } else {
        return end_time;
      }
    },
    $_startTimeValidCheck(startTime) {
      const _startTime = this.$moment(startTime);
      if (this.preventWeekdays.includes(_startTime.weekday())) {
        return false;
      } else if (
        this._additionalOffDays.includes(_startTime.format("YYYY-MM-DD"))
      ) {
        return false;
      } else {
        return true;
      }
    },
    $_getPreTask({
      materialName,
      excute,
      evaluation_wasas,
      evaluation_material = null,
      evaluation_material_excute = null,
      evaluation_material_option = null,
      evaluation_material_option_excute = null,
    }) {
      // Taker
      const tarEvaluationWasa = evaluation_wasas.find((e) => {
        if (!excute.excute_type) {
          return false;
        }
        return e.excute_type == excute.excute_type.id;
      });
      const taker = tarEvaluationWasa
        ? this.wasas.find((e) => {
            return e.id == tarEvaluationWasa.wasa;
          })
        : null;

      // Hour
      const hour = taker
        ? Math.ceil((excute.hour * 100) / (taker.speed ? taker.speed : 100))
        : excute.hour;

      // Name
      let name = materialName;
      if (excute.excute_type) {
        name += `__${excute.excute_type.name}`;
      }

      return {
        taker: taker,
        hour: hour,
        name: name,
        excute_type: excute.excute_type ? excute.excute_type : null,
        evaluation_material: evaluation_material,
        evaluation_material_excute: evaluation_material_excute,
        evaluation_material_option: evaluation_material_option,
        evaluation_material_option_excute: evaluation_material_option_excute,
      };
    },
  },
  computed: {
    _additionalWorkingDays() {
      if (!this.additionalWorkingDays) {
        return [];
      }
      const _additionalWorkingDays = [];
      this.additionalWorkingDays.forEach((additionalWorkingDay) => {
        _additionalWorkingDays.push(additionalWorkingDay.date);
      });
      return _additionalWorkingDays;
    },
    _additionalOffDays() {
      if (!this.additionalOffDays) {
        return [];
      }
      const _additionalOffDays = [];
      this.additionalOffDays.forEach((additionalOffDay) => {
        _additionalOffDays.push(additionalOffDay.date);
      });
      return _additionalOffDays;
    },
    _checkExcuteTypes() {
      return this.$store.state.setting.checkExcuteTypes;
    },
    _hasEmptyExcuteTypeWasa() {
      let _hasEmptyExcuteTypeWasa = false;
      this._evaluationWasas.forEach((_evaluationWasa) => {
        if (!_evaluationWasa.wasa) {
          _hasEmptyExcuteTypeWasa = true;
        }
      });
      return _hasEmptyExcuteTypeWasa;
    },
    _hasEmptyStage() {
      let _hasEmptyStage = false;
      this._evaluationStages.forEach((_evaluationStage) => {
        if (!_evaluationStage.evaluation_materials.length) {
          _hasEmptyStage = true;
        }
      });
      return _hasEmptyStage;
    },
    _evaluationFundamentalHours() {
      if (!this.evaluation) {
        return null;
      } else {
        return this.evaluation.fundamental_hours;
      }
    },
    _evaluationWasas() {
      if (!this.evaluation) {
        return null;
      } else {
        return this.evaluation.evaluation_wasas;
      }
    },
    _evaluationIssueTimeRate() {
      if (!this.evaluation) {
        return null;
      } else {
        return this.evaluation.issue_time_rate;
      }
    },
    _evaluationStages() {
      if (!this.evaluation) {
        return null;
      } else {
        return this.evaluation.evaluation_stages;
      }
    },
    _excuteTypeSetting() {
      if (!this.evaluation) {
        return null;
      } else {
        return this.evaluation.evaluation_excute_types_setting;
      }
    },
    _calendarEvents() {
      return this.$_CMS_getEvaluationCalendarEvents({
        tasks: this._tasks,
        milestones: this._milestones,
        afterTasks: this.afterTasks,
        leaveDays: this.leaveDays,
        additionalOffDays: this.additionalOffDays,
        additionalWorkingDays: this.additionalWorkingDays,
      });
    },
    _milestones() {
      if (!this.wasas) {
        return [];
      }
      const excuteTypeQC = this._checkExcuteTypes.find((e) => {
        return e.name == "QC";
      });
      const evaluationWasaQC = this.evaluation.evaluation_wasas.find((e) => {
        return e.excute_type == excuteTypeQC.id;
      });
      const wasaQC = this.wasas.find((e) => {
        return e.id == evaluationWasaQC.wasa;
      });
      const excuteTypeSR = this._checkExcuteTypes.find((e) => {
        return e.name == "SR";
      });
      const evaluationWasaSR = this.evaluation.evaluation_wasas.find((e) => {
        return e.excute_type == excuteTypeSR.id;
      });
      const wasaSR = this.wasas.find((e) => {
        return e.id == evaluationWasaSR.wasa;
      });
      const excuteTypeDR = this._checkExcuteTypes.find((e) => {
        return e.name == "DR";
      });
      const evaluationWasaDR = this.evaluation.evaluation_wasas.find((e) => {
        return e.excute_type == excuteTypeDR.id;
      });
      const wasaDR = this.wasas.find((e) => {
        return e.id == evaluationWasaDR.wasa;
      });
      const excuteTypeCR = this._checkExcuteTypes.find((e) => {
        return e.name == "CR";
      });
      const evaluationWasaCR = this.evaluation.evaluation_wasas.find((e) => {
        return e.excute_type == excuteTypeCR.id;
      });
      const wasaCR = this.wasas.find((e) => {
        return e.id == evaluationWasaCR.wasa;
      });
      const excuteTypePM = this._checkExcuteTypes.find((e) => {
        return e.name == "PM";
      });
      const evaluationWasaPM = this.evaluation.evaluation_wasas.find((e) => {
        return e.excute_type == excuteTypePM.id;
      });
      const wasaPM = this.wasas.find((e) => {
        return e.id == evaluationWasaPM.wasa;
      });

      const _milestones = [];
      // Review
      this._tasks.forEach((task, taskIndex) => {
        if (task.qc) {
          const prevTasks = this._tasks.slice(0, taskIndex);
          const calcTask = prevTasks.find((e) => {
            return (
              e.qc && e.stage_index == task.stage_index && e.hour > task.hour
            );
          });
          if (calcTask) {
            return;
          }
          const endTime = this.$_GRD_getEndtimeByStartTimeAndHour({
            start_time: task.start_time,
            hour: task.hour,
            additionalOffDays: this._additionalOffDays,
            additionalWorkingDays: this._additionalWorkingDays,
          });
          const prevTime = this.$_getPrevValidDateTime(task.start_time);
          const _qcMilestoneExist = _milestones.find((e) => {
            return (
              e.name ==
                `👀 [總Qc] 階段__${
                  this._evaluationStages[task.stage_index].name
                }` &&
              e.date == this.$moment(prevTime).format("YYYY-MM-DD") &&
              e.taker == wasaQC &&
              e.excuteType == excuteTypeQC
            );
          });
          if (_qcMilestoneExist) {
            return;
          }
          _milestones.push({
            name: `👀 [總Qc] 階段__${
              this._evaluationStages[task.stage_index].name
            }`,
            date: this.$moment(prevTime).format("YYYY-MM-DD"),
            taker: wasaQC,
            excuteType: excuteTypeQC,
          });
          _milestones.push({
            name: `💩 [總Qc] Check 階段__${
              this._evaluationStages[task.stage_index].name
            }`,
            date: this.$moment(endTime).format("YYYY-MM-DD"),
            taker: wasaQC,
            excuteType: excuteTypeQC,
          });
          _milestones.push({
            name: `💩 [總Qc階段外部會議] 階段__${
              this._evaluationStages[task.stage_index].name
            }`,
            date: this.$moment(this.$_getNextValidDateTime(endTime)).format(
              "YYYY-MM-DD"
            ),
            taker: wasaPM,
            excuteType: excuteTypePM,
          });
        }

        if (!task.excute_type) {
          return;
        }

        const endTime = this.$_GRD_getEndtimeByStartTimeAndHour({
          start_time: task.start_time,
          hour: task.hour,
          additionalOffDays: this._additionalOffDays,
          additionalWorkingDays: this._additionalWorkingDays,
        });

        const excuteTypeSettingItme = this._excuteTypeSetting.find((e) => {
          return e.excute_type == task.excute_type.id;
        });

        if (excuteTypeSettingItme) {
          if (excuteTypeSettingItme.next_keep_week) {
            const date = this.$_getPrevValidDateTime(
              this.$moment(endTime).set({
                weekday: 6,
              })
            );
            _milestones.push({
              name: `💩 [會議] ${task.name} ${task.excute_type.name}`,
              date: this.$moment(date).format("YYYY-MM-DD"),
              taker: wasaPM,
              excuteType: excuteTypePM,
            });
          }
        }

        if (task.excute_type.sr) {
          const _exist = _milestones.find((e) => {
            return (
              e.name == `👀 [Sr] ${task.name}` &&
              e.date == this.$moment(endTime).format("YYYY-MM-DD") &&
              e.taker == wasaSR &&
              e.excuteType == excuteTypeSR
            );
          });
          if (!_exist) {
            _milestones.push({
              name: `👀 [Sr] ${task.name}`,
              date: this.$moment(endTime).format("YYYY-MM-DD"),
              taker: wasaSR,
              excuteType: excuteTypeSR,
            });
          }
        }
        if (task.excute_type.dr) {
          const _exist = _milestones.find((e) => {
            return (
              e.name == `👀 [DR] ${task.name}` &&
              e.date == this.$moment(endTime).format("YYYY-MM-DD") &&
              e.taker == wasaDR &&
              e.excuteType == excuteTypeDR
            );
          });
          if (!_exist) {
            _milestones.push({
              name: `👀 [DR] ${task.name}`,
              date: this.$moment(endTime).format("YYYY-MM-DD"),
              taker: wasaDR,
              excuteType: excuteTypeDR,
            });
          }
        }
        if (task.excute_type.cr) {
          const _exist = _milestones.find((e) => {
            return (
              e.name == `👀 [CR] ${task.name}` &&
              e.date == this.$moment(endTime).format("YYYY-MM-DD") &&
              e.taker == wasaCR &&
              e.excuteType == excuteTypeCR
            );
          });
          if (!_exist) {
            _milestones.push({
              name: `👀 [CR] ${task.name}`,
              date: this.$moment(endTime).format("YYYY-MM-DD"),
              taker: wasaCR,
              excuteType: excuteTypeCR,
            });
          }
        }
      });

      // Meeting

      return _milestones;
    },
    _tasks() {
      if (
        !this.evaluation.evaluation_wasas ||
        !this.evaluation.evaluation_stages ||
        !this.leaveDays ||
        !this.wasas
      ) {
        return [];
      }
      let _tasks = [];
      const prePareTask = this.$_getPrepareTasks(
        this._evaluationWasas,
        this.wasas
      );
      _tasks = _tasks.concat(...prePareTask);
      this.evaluation.evaluation_stages.forEach(
        (evaluation_stage, evaluation_stage_index) => {
          evaluation_stage.evaluation_materials.forEach(
            (evaluation_material) => {
              evaluation_material.evaluation_material_excutes.forEach(
                (evaluation_material_excute) => {
                  if (
                    evaluation_stage.excute_types &&
                    evaluation_material_excute.excute_type &&
                    !evaluation_stage.excute_types.find((e) => {
                      return e.id == evaluation_material_excute.excute_type.id;
                    })
                  ) {
                    return;
                  }
                  const preTask = this.$_getPreTask({
                    materialName: evaluation_material.name,
                    excute: evaluation_material_excute,
                    evaluation_wasas: this.evaluation.evaluation_wasas,
                    evaluation_material: evaluation_material,
                    evaluation_material_excute: evaluation_material_excute,
                  });
                  if (preTask) {
                    preTask.stage_index = evaluation_stage_index;
                    preTask.evaluation_stage = evaluation_stage;
                    preTask.name = `${evaluation_stage.name}`;
                    // preTask.name = `${evaluation_stage.name}__${preTask.name}`;
                    _tasks.push(preTask);
                  }
                }
              );
            }
          );
          evaluation_stage.evaluation_material_options.forEach(
            (evaluation_material_option) => {
              evaluation_material_option.evaluation_material_option_excutes.forEach(
                (evaluation_material_option_excute) => {
                  if (
                    evaluation_stage.excute_types &&
                    evaluation_material_option_excute.excute_type &&
                    !evaluation_stage.excute_types.find((e) => {
                      return (
                        e.id == evaluation_material_option_excute.excute_type.id
                      );
                    })
                  ) {
                    return;
                  }
                  const preTask = this.$_getPreTask({
                    materialName: evaluation_material_option.name,
                    excute: evaluation_material_option_excute,
                    evaluation_wasas: this.evaluation.evaluation_wasas,
                    evaluation_material_option: evaluation_material_option,
                    evaluation_material_option_excute: evaluation_material_option_excute,
                  });
                  if (preTask) {
                    preTask.stage_index = evaluation_stage_index;
                    preTask.evaluation_stage = evaluation_stage;
                    // preTask.name = `${evaluation_stage.name}__${preTask.name}`;
                    preTask.name = `${evaluation_stage.name}`;
                    _tasks.push(preTask);
                  }
                }
              );
            }
          );
        }
      );
      _tasks.sort((a, b) => {
        if (a.stage_index == b.stage_index) {
          if (!a.excute_type) {
            return false;
          } else if (!b.excute_type) {
            return true;
          } else if (a.excute_type.excute_index == b.excute_type.excute_index) {
            return (
              a.excute_type.excute_index_second -
              b.excute_type.excute_index_second
            );
          } else {
            return a.excute_type.excute_index - b.excute_type.excute_index;
          }
        } else {
          return a.stage_index - b.stage_index;
        }
      });
      _tasks = this.$_qcTaskSet(_tasks);
      _tasks = this.$_CMS_tasksTimeSet({
        tasks: _tasks,
        startDate: this.evaluation.start_date,
        additionalOffDays: this._additionalOffDays,
        additionalWorkingDays: this._additionalWorkingDays,
        excuteTypeSetting: this._excuteTypeSetting,
        leaveDays: this.leaveDays,
        afterTasks: this.afterTasks,
      });
      _tasks = this.$_adjustTaskSet(_tasks);
      _tasks.sort((a, b) => {
        return this.$moment(a.start_time).diff(b.start_time);
      });
      _tasks = this.$_CMS_tasksTimeSet({
        tasks: _tasks,
        startDate: this.evaluation.start_date,
        additionalOffDays: this._additionalOffDays,
        additionalWorkingDays: this._additionalWorkingDays,
        excuteTypeSetting: this._excuteTypeSetting,
        leaveDays: this.leaveDays,
        afterTasks: this.afterTasks,
      });
      return _tasks;
    },
  },
  props: {
    leaveDays: {
      type: Array,
      default: null,
    },
    afterTasks: {
      type: Array,
      default: null,
    },
    hourStart: {
      type: Number,
      default: 9,
    },
    hourEnd: {
      type: Number,
      default: 18,
    },
    wasas: {
      type: Array,
      default: null,
    },
    excuteTypes: {
      type: Array,
      default: null,
    },
    evaluation: {
      type: Object,
      default() {
        return null;
      },
    },
    preventWeekdays: {
      type: Array,
      default() {
        return [0, 6];
      },
    },
    additionalWorkingDays: {
      type: Array,
      default: null,
    },
    additionalOffDays: {
      type: Array,
      default: null,
    },
  },
};
</script>

<style>
</style>
<template>
  <div class="ws-info-files">
    <div class="ws-info-files__upload-btns">
      <WsStateFileItem
        v-if="value"
        :url="value"
        :updatable="false"
        class="mb-2"
      ></WsStateFileItem>
    </div>
  </div>
</template>

<script>
export default {
  props: {
    value: {
      type: String,
      default: null,
    },
  },
};
</script>

<style>
</style>
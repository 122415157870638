<template>
  <WsDialog
    ref="WsDialog"
    v-model="dialog"
    title="篩選條件"
    :type="type"
    :escMetaClose="true"
  >
    <template v-slot:content>
      <slot></slot>
    </template>
    <!-- <template v-slot:rightActions>
    </template> -->
  </WsDialog>
</template>

<script>
export default {
  data: () => ({
    dialog: false,
  }),

  methods: {
    open() {
      this.dialog = true;
    },
  },

  props: {
    type: {
      type: String,
      default: "leftPanel",
    },
  },
};
</script>

<style>
</style>
<template>
  <WsMain>
    <AppGardenCalendar
      :doneMode="true"
      :params="_params"
    ></AppGardenCalendar>
  </WsMain>
</template>

<script>
export default {
  metaInfo() {
    return {
      title: "我的行事曆",
    };
  },

  computed: {
    _currentUser() {
      return this.$store.state.auth.currentUser;
    },
    _params() {
      return {
        taker: this._currentUser.id,
      };
    },
  },
};
</script>

<style>
</style>
<template>
  <div
    class="ws-btn"
    :class="[{outlined:outlined},{small:small},{alert:alert},{full:full},{disabled:disabled},{verybig:verybig}]"
    :style="[,{'min-width':minWidth},{'min-height':minHeight}]"
  >
    <WsLoading v-if="loading"></WsLoading>
    <template v-else>
      <RouterLink
        v-if="to"
        class="ws-btn__button"
        @mouseover="onMouseover()"
        :class="[{small:small}]"
        :color="color"
        :disabled="disabled"
        :to="to"
      >
        <slot></slot>
      </RouterLink>
      <button
        v-else
        :type="type"
        @click="onClick()"
        @mouseover="onMouseover()"
        class="ws-btn__button"
        :class="[{small:small}]"
        :color="color"
        :disabled="disabled"
      >
        <slot></slot>
      </button>
    </template>
  </div>
</template>

<script>
export default {
  methods: {
    onClick() {
      this.$emit("click");
    },
    onMouseover() {
      this.$emit("mouseover");
    },
  },
  props: {
    verybig: {
      type: Boolean,
      default: false,
    },
    small: {
      type: Boolean,
      default: false,
    },
    disabled: {
      type: Boolean,
      default: false,
    },
    href: {
      type: String,
      default: null,
    },
    target: {
      type: String,
      default: null,
    },
    to: {
      type: String,
      default: null,
    },
    minWidth: {
      type: String,
      default: null,
    },
    minHeight: {
      type: String,
      default: null,
    },
    color: {
      type: String,
      default: "primary",
    },
    outlined: {
      type: Boolean,
      default: false,
    },
    alert: {
      type: Boolean,
      default: false,
    },
    type: {
      type: String,
      default: null,
    },
    loading: {
      type: Boolean,
      default: false,
    },
    block: {
      type: Boolean,
      default: false,
    },
    full: {
      type: Boolean,
      default: false,
    },
  },
};
</script>
<template>
  <WsMain>
    <WsCrud
      :modelName="$store.state.model[modelName].modelName"
      :label="$store.state.model[modelName].label"
      :fields="$store.state.model[modelName].fields"
      :liveSearching="true"
      :showFields="showFields"
      :order="order"
      :plusOrderItems="plusOrderItems"
      :filterSelects="filterSelects"
    ></WsCrud>
  </WsMain>
</template>

<script>
import moment from "moment";
export default {
  metaInfo() {
    return {
      title: "Milestone",
    };
  },
  data: () => ({
    order: "last_start",
    plusOrderItems: [
      {
        value: "last_start",
        text: "最後開始",
        order_by: "date",
        order_way: "desc",
      },
    ],
    modelName: "milestone",
    showFields: ["id", "taker", "project", "name", "date", "is_finish"],
    filterSelects: {
      status: {
        label: "狀態",
        items: [
          {
            text: "全部",
            value: "all",
            params: {},
          },
          {
            text: "未完成",
            value: "undo",
            params: {
              is_finish: 0,
              time_field: "date",
              end_time: moment().add(-3, "days").format("YYYY-MM-DD"),
            },
          },
          {
            text: "完成",
            value: "done",
            params: {
              is_finish: 1,
            },
          },
        ],
      },
    },
  }),
};
</script>
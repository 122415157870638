<template>
  <a
    class="ws-info-link"
    :href="value"
    target="_blank"
  >
    <WsIcon v-if="value">link</WsIcon>
  </a>
</template>

<script>
export default {
  props: {
    value: {
      type: String,
      default: null,
    },
  },
};
</script>
export default {
  modelName: "evaluation_material_excute",
  label: "評估執行",
  fields: {
    // id: {
    //   type: "text",
    //   label: "ID",
    //   readonly: true
    // },
    excute_type: {
      type: 'belongsTo',
      label: '執行類型',
      textKey: "name",
      modelName: "excute_type",
      width: '100px',
    },
    hour: {
      type: 'number',
      label: '時數',
    },
    updated_at: {
      type: "datetime",
      label: "最後更新時間",
      readonly: true
    },
  },
}
export default {
  modelName: "wasa_hr_skill",
  label: "山葵仔職業技能",
  fields: {
    id: {
      type: "text",
      label: "ID",
      readonly: true
    },
    wasa: {
      type: "belongsTo",
      label: "山葵仔",
      textKey: "name",
      modelName: "wasa",
      relationPopup: true
    },
    hr_skill: {
      type: "belongsTo",
      label: "職位技能",
      textKey: "name",
      modelName: "hr_skill",
      relationPopup: true
    },
    reach_at: {
      type: 'datetime',
      label: '達成時間',
    },
    updated_admin: {
      type: "belongsTo",
      label: "最後更新人",
      readonly: true,
      textKey: "name",
      modelName: "wasa"
    },
  },
}
<template>
  <WsMain>
    <AppCalendar
      :creatable="false"
      :updatable="false"
      :deletable="false"
      :params="_params"
      :calendarEvents="_calendarEvents"
      :additionalWorkingDays="_additionalWorkingDays"
      :additionalOffDays="_additionalOffDays"
    >
      <template v-slot:searchSection>
        <WsState
          type="belongsTo"
          label="專案"
          textKey="name"
          modelName="project"
          :conditions="projectConditions"
          v-model="state.project"
        ></WsState>
        <WsState
          type="date"
          class="mt-10"
          label="修正前起始時間"
          v-model="state.startDateOri"
        ></WsState>
        <WsState
          type="date"
          class="mt-10"
          label="修正後起始時間"
          v-model="state.startDateAfter"
        ></WsState>
        <template v-if="info.project">
          <WsTitle
            class="mt-20"
            v-if="_excuteTypes"
            size="5"
          >需會議確認之執行類型</WsTitle>
          <AppEvaluationExcuteTypesSetting
            class="mt-20"
            v-model="state.excuteTypeSetting"
            :excuteTypes="_excuteTypes"
          ></AppEvaluationExcuteTypesSetting>
          <WsTitle
            class="mt-20"
            v-if="_excuteTypes"
            size="5"
          >執行人員安排</WsTitle>
          <AppEvaluationWasas
            class="mt-20"
            v-model="state.evaluationWasas"
            :excuteTypes="_excuteTypes"
            :wasaItems="_projectWasaItems"
          ></AppEvaluationWasas>
        </template>
      </template>
    </AppCalendar>
  </WsMain>
</template>

<script>
export default {
  metaInfo() {
    return {
      title: "大風吹",
    };
  },

  data: () => ({
    additionalWorkingDays: null,
    additionalOffDays: null,
    leaveDays: null,
    wasas: null,
    afterTasks: null,
    tasks: null,
    milestones: null,
    projectConditions: [
      {
        key: "status",
        value: [0, 1],
      },
      {
        key: "order_by",
        value: "created_at",
      },
      {
        key: "order_way",
        value: "desc",
      },
    ],
    info: {
      project: null,
    },
    state: {
      excuteTypeSetting: [],
      evaluationWasas: [],
      project: null,
      startDateOri: null,
      startDateAfter: null,
    },
  }),

  methods: {
    async $_fetchProject() {
      const res = await this.$axios.get(
        `project/getbyid/${this.state.project}`
      );
      this.info.project = res.data.data;
    },
    async $_fetchTasks() {
      const params = {
        project: this.project,
        time_field: "start_time",
        start_time: this.state.startDateOri
          ? this.$moment(this.state.startDateOri).format("YYYY-MM-DD")
          : null,
      };
      const res = await this.$axios.get("task/index/all", {
        params: params,
      });
      this.tasks = res.data.data;
    },
    async $_fetchMilestones() {
      const params = {
        project: this.state.project,
        time_field: "date",
        start_time: this.state.startDateOri
          ? this.$moment(this.state.startDateOri).format("YYYY-MM-DD")
          : null,
      };
      const res = await this.$axios.get("milestone/index/all", {
        params: params,
      });
      this.milestones = res.data.data;
    },
    $_stateInit() {
      this.state.startDateOri = this.$moment().format("YYYY-MM-DD");
      this.state.startDateAfter = this.$moment().format("YYYY-MM-DD");
    },
    async $_afterTasksFetch() {
      const params = {
        time_field: "start_time",
        start_time: this.state.startDateOri
          ? this.$moment(this.state.startDateOri).format("YYYY-MM-DD")
          : null,
      };
      const res = await this.$axios.get("task/index/all", {
        params: params,
      });
      this.afterTasks = res.data.data;
    },
    async $_wasasFetch() {
      const res = await this.$axios.get("wasa/index/all/deep");
      this.wasas = res.data.data;
    },
    async $_additionalWorkingDaysFetch() {
      const params = {
        time_field: "date",
        start_time: this.state.startDateOri
          ? this.$moment(this.state.startDateOri).format("YYYY-MM-DD")
          : null,
      };
      const res = await this.$axios.get("additional_working_day/index/all", {
        params: params,
      });
      this.additionalWorkingDays = res.data.data;
    },
    async $_additionalOffDaysFetch() {
      const params = {
        time_field: "date",
        start_time: this.state.startDateOri
          ? this.$moment(this.state.startDateOri).format("YYYY-MM-DD")
          : null,
      };
      const res = await this.$axios.get("additional_off_day/index/all", {
        params: params,
      });
      this.additionalOffDays = res.data.data;
    },
    async $_fetchLeaveDays() {
      const params = {
        time_field: "start_date",
        start_time: this.state.startDateOri
          ? this.$moment(this.state.startDateOri).format("YYYY-MM-DD")
          : null,
      };
      const res = await this.$axios.get("leave_day/index/all", {
        params: params,
      });
      this.leaveDays = res.data.data;
    },
    $_evaluationWasasReset() {
      const _evaluationWasas = [];
      this._excuteTypes.forEach((excuteType) => {
        const setting = this.state.evaluationWasas.find((e) => {
          return e.excute_type == excuteType.id;
        });
        if (setting) {
          _evaluationWasas.push(setting);
        } else {
          _evaluationWasas.push({
            excute_type: excuteType.id,
            wasa: null,
          });
        }
      });
      this.state.evaluationWasas = _evaluationWasas;
    },
    $_evaluationExcuteTypesSettingReset() {
      if (!this._excuteTypes) {
        return;
      }
      const _excuteTypeSetting = [];
      this._excuteTypes.forEach((excuteType) => {
        const setting = this.state.excuteTypeSetting.find((e) => {
          return e.excute_type == excuteType.id;
        });
        if (setting) {
          _excuteTypeSetting.push(setting);
        } else {
          _excuteTypeSetting.push({
            excute_type: excuteType.id,
            next_keep_week: false,
          });
        }
      });
      this.state.excuteTypeSetting = _excuteTypeSetting;
    },
  },

  computed: {
    _projectWasaItems() {
      if (!this.info.project) {
        return [];
      }
      const wasas = this.info.project.wasas;
      const _projectWasaItems = [];
      wasas.forEach((wasa) => {
        _projectWasaItems.push({
          value: wasa.id,
          text: wasa.name,
        });
      });
      return _projectWasaItems;
    },
    _afterTasks() {
      if (!this.afterTasks) {
        return null;
      }
      const _afterTasks = [];
      this.afterTasks.forEach((afterTask) => {
        if (afterTask.project && afterTask.project.id == this.state.project) {
          return;
        }
        _afterTasks.push(afterTask);
      });
      return _afterTasks;
    },
    _excuteTypes() {
      if (!this.afterTasks) {
        return null;
      }
      const _excuteTypes = [];
      const _tasks = [];
      this.afterTasks.forEach((afterTask) => {
        if (afterTask.project && afterTask.project.id == this.state.project) {
          _tasks.push(afterTask);
        }
      });
      _tasks.forEach((task) => {
        if (!task.excute_type) {
          return;
        }
        const exist = _excuteTypes.find((e) => {
          return e.id == task.excute_type.id;
        });
        if (!exist) {
          _excuteTypes.push(task.excute_type);
        }
      });
      _excuteTypes.push(...this.$store.state.setting.checkExcuteTypes);
      return _excuteTypes;
    },
    _tasks() {
      if (!this.leaveDays || !this.tasks || !this.wasas) {
        return [];
      }
      let _tasks = [];
      this.afterTasks.forEach((afterTask) => {
        if (afterTask.project && afterTask.project.id == this.state.project) {
          _tasks.push(afterTask);
        }
      });
      const _setTasks = this.$_CMS_tasksTimeSet({
        tasks: _tasks,
        startDate: this.state.startDateAfter,
        additionalOffDays: this.additionalOffDays,
        additionalWorkingDays: this.additionalWorkingDays,
        excuteTypeSetting: this.state.excuteTypeSetting,
        leaveDays: this.leaveDays,
        afterTasks: this._afterTasks,
      });
      return _setTasks;
    },
    _checkExcuteTypes() {
      return this.$store.state.setting.checkExcuteTypes;
    },
    _milestones() {
      if (!this.leaveDays || !this.tasks || !this.milestones || !this.wasas) {
        return [];
      }
      let _milestones = [];
      this.milestones.forEach((milestone) => {
        if (milestone.project && milestone.project.id == this.state.project) {
          _milestones.push(milestone);
        }
      });
      return _milestones;
    },
    _calendarEvents() {
      return this.$_CMS_getEvaluationCalendarEvents({
        tasks: this._tasks,
        milestones: this._milestones,
        afterTasks: this._afterTasks,
        leaveDays: this.leaveDays,
        additionalOffDays: this._additionalOffDays,
        additionalWorkingDays: this._additionalWorkingDays,
      });
    },
    _additionalWorkingDays() {
      if (!this.additionalWorkingDays) {
        return [];
      }
      const _additionalWorkingDays = [];
      this.additionalWorkingDays.forEach((additionalWorkingDay) => {
        _additionalWorkingDays.push(additionalWorkingDay.date);
      });
      return _additionalWorkingDays;
    },
    _additionalOffDays() {
      if (!this.additionalOffDays) {
        return [];
      }
      const _additionalOffDays = [];
      this.additionalOffDays.forEach((additionalOffDay) => {
        _additionalOffDays.push(additionalOffDay.date);
      });
      return _additionalOffDays;
    },
    _params() {
      return {
        project: this.state.project,
      };
    },
  },

  watch: {
    "state.project": {
      handler() {
        if (this.state.project) {
          this.$_wasasFetch();
          this.$_afterTasksFetch();
          this.$_additionalWorkingDaysFetch();
          this.$_additionalOffDaysFetch();
          this.$_fetchLeaveDays();
          this.$_fetchTasks();
          this.$_fetchMilestones();
          this.$_fetchProject();
        }
      },
    },
    "state.startDateOri": {
      handler() {
        this.$_afterTasksFetch();
        this.$_additionalWorkingDaysFetch();
        this.$_additionalOffDaysFetch();
        this.$_fetchLeaveDays();
        this.$_fetchTasks();
        this.$_fetchMilestones();
      },
    },
    _excuteTypes: {
      handler() {
        this.$_evaluationExcuteTypesSettingReset();
        this.$_evaluationWasasReset();
      },
    },
  },

  mounted() {
    this.$_stateInit();
  },
};
</script>

<style>
</style>
<template>
  <WsInfiniteScroll
    v-slot="{items}"
    modelName="pocket_image"
    class="ws-pocjet-image-picker-pocket"
  >
    <WsFlexGallery>
      <WsFlexGalleryItem
        v-for="(pocketImage, itemIndex) in items"
        :key="itemIndex"
        @click.native="$_onImagePickerClick(pocketImage,itemIndex)"
        :class="{selected:focusIndex==itemIndex}"
        class="ws-pocjet-image-picker-pocket__item"
      >
        <div
          class="ws-pocjet-image-picker-pocket__item__image"
          v-lazy:background-image="$_getUrl(pocketImage)"
        ></div>
        <div class="ws-pocjet-image-picker-pocket__item__bg"></div>
      </WsFlexGalleryItem>
    </WsFlexGallery>
  </WsInfiniteScroll>
</template>

<script>
export default {
  data: () => ({
    focusIndex: -1,
    focusItem: null,
  }),

  methods: {
    $_onImagePickerClick(pocketImage, pocketImageIndex) {
      this.focusItem = pocketImage;
      this.focusIndex = pocketImageIndex;
    },
    $_getUrl(pocketImage) {
      if (pocketImage.signed) {
        return pocketImage.signed_url;
      } else {
        return pocketImage.url;
      }
    },
    submit() {
      if (this.focusItem.signed) {
        this.$emit("submit", this.focusItem.signed_url);
      } else {
        this.$emit("submit", this.focusItem.url);
      }
    },
  },
};
</script>
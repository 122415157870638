export default {
  getBase64(file) {
    return new Promise((resolve) => {
      const reader = new FileReader();
      reader.addEventListener(
        "load",
        () => {
          resolve(reader.result);
        },
        false
      );
      if (file) {
        reader.readAsDataURL(file);
      }
    });
  },
}
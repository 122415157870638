export default {
  modelName: "quotation_description",
  label: '報價單說明項目',
  fields: {
    id: {
      type: "text",
      label: "ID",
      readonly: true,
      width: '70px'
    },
    content: {
      type: 'textarea',
      label: '內容'
    },
    updated_admin: {
      type: "belongsTo",
      label: "最後更新人",
      readonly: true,
      textKey: "name",
      modelName: "wasa"
    },
  },
}
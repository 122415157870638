<template>
  <div>
    {{_switchText}}
  </div>
</template>

<script>
export default {
  computed: {
    _switchText() {
      if (this.value) {
        return this.activeText;
      } else {
        return this.inactiveText;
      }
    },
  },
  props: {
    activeText: {
      type: String,
      default: "active",
    },
    inactiveText: {
      type: String,
      default: "inactive",
    },
    value: {
      type: [Boolean, Number],
      default: false,
    },
  },
};
</script>

<style>
</style>
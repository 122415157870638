<template>
  <div class="ws-state-file">
    <div class="ws-state-file__upload-btns">
      <WsStateFileItem
        v-for="(file,fileIndex) in C_files"
        :key="fileIndex"
        :loading="file.loading"
        :percent="file.percent"
        :url="file.url"
        @remove="$_onFileRemove(fileIndex)"
        class="mb-2"
      ></WsStateFileItem>
    </div>
    <WsBtn
      outlined
      color="textSecondary"
      @click="$_onClick()"
    >
      <WsIcon>backup</WsIcon> 上傳附件
    </WsBtn>
    <input
      multiple
      v-show="false"
      @change="$_onChange($event)"
      type="file"
      ref="fileInput"
    >
  </div>
</template>

<script>
export default {
  data: () => ({
    C_files: [],
    C_value: [],
  }),
  props: {
    value: {
      type: Array,
      default() {
        return [];
      },
    },
    uploadUrl: {
      type: String,
      required: true,
    },
  },
  mounted() {
    this.$_valueSet();
  },
  watch: {
    C_files: {
      handler() {
        this.$_emitValue();
      },
      deep: true,
    },
  },
  methods: {
    $_emitValue() {
      const _value = [];
      this.C_files.forEach((C_file) => {
        if (C_file.url) {
          _value.push(C_file.url);
        }
      });
      this.$emit("input", _value);
    },
    $_onFileRemove(fileIndex) {
      this.C_files.splice(fileIndex, 1);
    },
    $_valueSet() {
      if (this.value) {
        this.value.forEach((valueItem) => {
          this.C_files.push({
            url: valueItem,
          });
        });
      }
    },
    $_handleUploadProgress(progressEvent, target) {
      target.percent = Math.round(
        (progressEvent.loaded / progressEvent.total) * 100
      );
    },
    $_onRemoveClick(index) {
      const _value = this.value;
      _value.splice(index, 1);
      this.$emit("input", _value);
    },
    async $_onChange($event) {
      const files = $event.target.files;
      files.forEach((file, fileIndex) => {
        this.C_files.push({
          percent: 0,
          loading: true,
          file: file,
          fileIndex: fileIndex,
          uploading: true,
        });
      });
      this.C_files.forEach((C_file) => {
        if (!C_file.uploading) {
          return;
        }
        const file = C_file.file;
        this.$axios
          .put(`${this.uploadUrl}${file.name}`, file, {
            onUploadProgress: (progressEvent) =>
              this.$_handleUploadProgress(progressEvent, C_file),
          })
          .then((res) => {
            C_file.url = res.data.signed_url;
            C_file.percent = 0;
            C_file.loading = false;
          });
      }, 0);
    },
    $_onClick() {
      this.$refs.fileInput.click();
    },
  },
};
</script>
<template>
  <div
    class="ws-flex-gallery-item"
    :class="[{'auto-height':autoHeight}]"
  >
    <slot></slot>
  </div>
</template>

<script>
export default {
  props: {
    autoHeight: {
      type: Boolean,
      default: true,
    },
  },
};
</script>

<style>
</style>
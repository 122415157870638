<template>
  <AppCalendar
    ref="AppCalendar"
    @date-rander="$_onDateRender($event)"
    :calendarEvents="_calendarEvents"
    :creatable="creatable"
    :updatable="updatable"
    :deletable="deletable"
    @task-done="$_onTaskDone($event)"
    @milestone-done="$_onMilestoneDone($event)"
    :additionalWorkingDays="_additionalWorkingDays"
    :additionalOffDays="_additionalOffDays"
    :doneMode="doneMode"
    :taskFields="taskFields"
    @task-created="$_onTaskCreated($event)"
    @task-updated="$_onTaskUpdated($event)"
    @task-deleted="$_onTaskDeleted($event)"
    @milestone-created="$_onMilestoneCreated($event)"
    @milestone-updated="$_onMilestoneUpdated($event)"
    @milestone-deleted="$_onMilestoneDeleted($event)"
  >
    <template v-slot:searchSection>
      <slot name="searchSection"></slot>
    </template>
  </AppCalendar>
</template>

<script>
export default {
  data: () => ({
    dateFocus: null,
    tasks: [],
    milestones: [],
    additionalWorkingDays: null,
    additionalOffDays: null,
    leaveDays: null,
  }),

  computed: {
    _additionalWorkingDays() {
      if (!this.additionalWorkingDays) {
        return [];
      }
      const _additionalWorkingDays = [];
      this.additionalWorkingDays.forEach((additionalWorkingDay) => {
        _additionalWorkingDays.push(additionalWorkingDay.date);
      });
      return _additionalWorkingDays;
    },
    _additionalOffDays() {
      if (!this.additionalOffDays) {
        return [];
      }
      const _additionalOffDays = [];
      this.additionalOffDays.forEach((additionalOffDay) => {
        _additionalOffDays.push(additionalOffDay.date);
      });
      return _additionalOffDays;
    },
    _calendarEvents() {
      return this.$_CMS_getCalendarEvents({
        tasks: this.tasks,
        milestones: this.milestones,
        additionalOffDays: this.additionalOffDays,
        additionalWorkingDays: this.additionalWorkingDays,
        leaveDays: this.leaveDays,
      });
    },
    _fetchStartDate() {
      const focusMoment = this.$moment(this.dateFocus);
      const startMoment = this.$moment(focusMoment)
        .startOf("month")
        .add(-15, "day");
      const startDate = startMoment.format("YYYY-MM-DD");
      return startDate;
    },
    _fetchEndDate() {
      const focusMoment = this.$moment(this.dateFocus);
      const endMoment = this.$moment(focusMoment).endOf("month").add(15, "day");
      const endDate = endMoment.format("YYYY-MM-DD");
      return endDate;
    },
  },

  methods: {
    $_onTaskDeleted($event) {
      const taskIndex = this.tasks.findIndex((e) => {
        return e.id == $event.id;
      });
      this.tasks.splice(taskIndex, 1);
    },
    $_onMilestoneDeleted($event) {
      const milestoneIndex = this.milestones.findIndex((e) => {
        return e.id == $event.id;
      });
      this.milestones.splice(milestoneIndex, 1);
    },
    $_onTaskUpdated($event) {
      const taskIndex = this.tasks.findIndex((e) => {
        return e.id == $event.id;
      });
      this.tasks.splice(taskIndex, 1);
      this.tasks.splice(taskIndex, 0, $event);
    },
    $_onMilestoneUpdated($event) {
      const milestoneIndex = this.milestones.findIndex((e) => {
        return e.id == $event.id;
      });
      this.milestones.splice(milestoneIndex, 1);
      this.milestones.splice(milestoneIndex, 0, $event);
    },
    $_onTaskCreated($event) {
      this.tasks.push($event);
    },
    $_onMilestoneCreated($event) {
      this.milestones.push($event);
    },
    $_onMilestoneDone($event) {
      const milestoneIndex = this.milestones.findIndex((e) => {
        return e.id == $event.id;
      });
      this.milestones.splice(milestoneIndex, 1);
      this.milestones.splice(milestoneIndex, 0, $event);
    },
    $_onTaskDone($event) {
      const taskIndex = this.tasks.findIndex((e) => {
        return e.id == $event.id;
      });
      this.tasks.splice(taskIndex, 1);
      this.tasks.splice(taskIndex, 0, $event);
    },
    $_onDateRender() {
      this.$_dateFocusSet();
    },
    $_dateFocusSet() {
      const calendarApi =
        this.$refs.AppCalendar.$refs.WsFullCalendar.$refs.FullCalendar.getApi();
      this.dateFocus = calendarApi.getDate();
    },
    async $_fetchLeaveDays() {
      const params = {
        start_time: this._fetchStartDate,
        end_time: this._fetchEndDate,
        time_field: "start_date",
      };
      const res = await this.$axios.get("leave_day/index/all", {
        params: params,
      });
      this.leaveDays = res.data.data;
    },
    async $_fetchAdditionalWorkingDays() {
      const params = {
        start_time: this._fetchStartDate,
        end_time: this._fetchEndDate,
        time_field: "date",
      };
      const res = await this.$axios.get("additional_working_day/index/all", {
        params: params,
      });
      this.additionalWorkingDays = res.data.data;
    },
    async $_fetchAdditionalOffDays() {
      const params = {
        start_time: this._fetchStartDate,
        end_time: this._fetchEndDate,
        time_field: "date",
      };
      const res = await this.$axios.get("additional_off_day/index/all", {
        params: params,
      });
      this.additionalOffDays = res.data.data;
    },
    async $_fetchTasks() {
      this.tasks = [];
      let params = {
        start_time: this._fetchStartDate,
        end_time: this._fetchEndDate,
        time_field: "start_time",
      };
      if (this.params) {
        params = { ...params, ...this.params };
      }
      try {
        const res = await this.$axios.get("task/index/all", {
          params: params,
        });
        this.tasks = res.data.data;
      } catch (error) {
        console.log(error);
        alert("讀取錯誤");
      }
    },
    async $_fetchMilestones() {
      this.milestones = [];
      let params = {
        start_time: this._fetchStartDate,
        end_time: this._fetchEndDate,
        time_field: "date",
      };
      if (this.params) {
        params = { ...params, ...this.params };
      }
      try {
        const res = await this.$axios.get("milestone/index/all", {
          params: params,
        });
        this.milestones = res.data.data;
      } catch (error) {
        console.log(error);
        alert("讀取錯誤");
      }
    },
  },

  watch: {
    params: {
      handler() {
        this.$_fetchLeaveDays();
        this.$_fetchAdditionalWorkingDays();
        this.$_fetchAdditionalOffDays();
        this.$_fetchTasks();
        this.$_fetchMilestones();
      },
      deep: true,
    },
    dateFocus: {
      handler(newValue, oldValue) {
        const newMoment = this.$moment(newValue);
        const oldMoment = this.$moment(oldValue);
        if (newMoment.format("YYYY-MM") == oldMoment.format("YYYY-MM")) {
          return;
        } else {
          this.$_fetchLeaveDays();
          this.$_fetchAdditionalWorkingDays();
          this.$_fetchAdditionalOffDays();
          this.$_fetchTasks();
          this.$_fetchMilestones();
        }
      },
    },
  },

  props: {
    taskFields: {
      type: Array,
      default() {
        return [
          "id",
          "name",
          "start_time",
          "is_close",
          "is_finish",
          "hour",
          "finish_hour",
          "remark",
          "project",
          "taker",
          "excute_type",
          "evaluation_material",
          "evaluation_material_option",
          "adjust",
          "creator",
          "qc",
          "is_rd_task",
          "is_not_complete",
        ];
      },
    },
    creatable: {
      type: Boolean,
      default: false,
    },
    updatable: {
      type: Boolean,
      default: false,
    },
    deletable: {
      type: Boolean,
      default: false,
    },
    projectInTitle: {
      type: Boolean,
      default: true,
    },
    doneMode: {
      type: Boolean,
      default: false,
    },
    params: {
      type: Object,
      default: null,
    },
  },

  mounted() {
    this.$_dateFocusSet();
    this.$_fetchLeaveDays();
    this.$_fetchAdditionalWorkingDays();
    this.$_fetchAdditionalOffDays();
    this.$_fetchTasks();
    this.$_fetchMilestones();
  },
};
</script>

<style>
</style>
<template>
  <RouterLink
    class="ws-info-router-link"
    :to="`/${value}`"
    target="_blank"
  >
    <WsIcon v-if="value">link</WsIcon>
  </RouterLink>
</template>

<script>
export default {
  props: {
    value: {
      type: String,
      default: null,
    },
  },
};
</script>
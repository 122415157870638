<template>
  <WsRow>
    <WsCol
      v-for="(setting,settingIndex) in value"
      :key="settingIndex"
    >
      <WsState
        type="switch"
        :label="$_getExcuteTypeLabel(setting)"
        v-model="value[settingIndex].next_keep_week"
      ></WsState>
    </WsCol>
  </WsRow>
</template>

<script>
export default {
  methods: {
    $_getExcuteTypeLabel(setting) {
      if (!setting.excute_type) {
        return null;
      } else {
        const excuteType = this.excuteTypes.find((e) => {
          return e.id == setting.excute_type;
        });
        return excuteType.name;
      }
    },
  },
  props: {
    value: {
      type: Array,
      default: null,
    },
    excuteTypes: {
      type: Array,
      default: null,
    },
  },
};
</script>

<style>
</style>
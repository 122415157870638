<template>
  <div>{{_value}}</div>
</template>

<script>
export default {
  computed: {
    _value() {
      if (!this.value) {
        return null;
      } else {
        const datetime = this.$moment(this.value);
        return datetime.format("YYYY-MM-DD HH:mm:ss");
      }
    },
  },
  props: {
    value: {
      type: [String, Object],
      default: null,
    },
  },
};
</script>

<style>
</style>
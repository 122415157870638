export default {
  modelName: "tulpa_section_template",
  label: "山葵製作區塊公版",
  fields: {
    id: {
      type: "text",
      label: "ID",
      readonly: true
    },
    name: {
      type: "text",
      label: "名稱",
      rules: 'required',
      autofocus: true
    },
    component_name: {
      type: "text",
      label: "Component Name",
      rules: 'required',
    },
    remark: {
      type: "textarea",
      label: "備註",
    },
    fields: {
      type: 'fields',
      label: '欄位',
    }
  },
}
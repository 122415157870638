<template>
  <AppStateEvaluation
    v-if="!_loading"
    :evaluation="evaluation"
    ref="appStateEvaluation"
    @save="$_onSave($event)"
  ></AppStateEvaluation>
</template>

<script>
export default {
  metaInfo() {
    const title = this.evaluation ? `${this.evaluation.name} | 編輯` : "編輯";
    return {
      title: title,
    };
  },
  data: () => ({
    evaluation: null,
  }),
  methods: {
    async $_onSave($event) {
      try {
        this.$refs.appStateEvaluation.startSaveLoading();
        await this.$axios.patch(`evaluation/${this._id}`, $event);
        this.$router.push("/evaluation");
      } catch (error) {
        alert("儲存錯誤");
      } finally {
        this.$refs.appStateEvaluation.stopSaveLoading();
      }
    },
    async $_fetchEvaluation() {
      try {
        this.$store.dispatch("app/startPageLoading");
        const res = await this.$axios.get(`evaluation/${this._id}`);
        this.evaluation = res.data.data;
      } catch (error) {
        alert("取得評估錯誤");
      } finally {
        this.$store.dispatch("app/stopPageLoading");
      }
    },
  },
  mounted() {
    this.$_fetchEvaluation();
  },
  computed: {
    _loading() {
      return this.$store.state.app.pageLoading;
    },
    _id() {
      return this.$route.params.id;
    },
  },
};
</script>

<style>
</style>
<template>
  <div
    class="ws-card"
    :class="[{center:center}]"
    :style="[{'max-width':`${maxWidth}px`},{'width':`${width}px`},{'background-color':color}]"
  >
    <slot></slot>
  </div>
</template>

<script>
export default {
  props: {
    center: {
      type: Boolean,
      default: false,
    },
    width: {
      type: [Number, String],
      default: null,
    },
    maxWidth: {
      type: [Number, String],
      default: null,
    },
    color: {
      type: String,
      default: null,
    },
  },
};
</script>
<template>
  <div class="ws-pagenate-type-b">
    <div class="typing-section">
      <p>前往第</p>
      <WsStateInput
        type="number"
        @keyup.enter="$_onGoingToPageSubmit()"
        v-model="goingToPage"
        small
        center
      ></WsStateInput>
      <p>頁 共{{lastPage}}頁</p>
      <WsBtn
        small
        @click="$_onGoingToPageSubmit()"
      >Go</WsBtn>
    </div>
    <div class="click-section">
      <p>第{{_dataIngStartIndex}}-{{_dataIngEndIndex}}筆 共{{dataTotalCount}}筆</p>
      <div class="click-section-btns">
        <WsIconBtn
          @click="$_pageTo(1)"
          :size="32"
        >first_page</WsIconBtn>
        <WsIconBtn
          @click="$_pagePrev()"
          :size="32"
        >chevron_left</WsIconBtn>
        <WsIconBtn
          @click="$_pageNext()"
          :size="32"
        >chevron_right</WsIconBtn>
        <WsIconBtn
          @click="$_pageTo(lastPage)"
          :size="32"
        >last_page</WsIconBtn>
      </div>
    </div>
  </div>
</template>

<script>
export default {
  data: () => ({
    goingToPage: 1,
  }),
  computed: {
    _dataIngStartIndex() {
      return (this.currentPage - 1) * this.itemsPerPage + 1;
    },
    _dataIngEndIndex() {
      return this.currentPage * this.itemsPerPage > this.dataTotalCount
        ? this.dataTotalCount
        : this.currentPage * this.itemsPerPage;
    },
  },
  props: {
    currentPage: {
      type: [Number, String],
      default: 1,
    },
    lastPage: {
      type: Number,
      default: 1,
    },
    dataTotalCount: Number,
    itemsPerPage: Number,
  },
  methods: {
    $_pagePrev() {
      if (this.currentPage <= 1) {
        return;
      } else {
        this.$emit("pageto", this.currentPage - 1);
      }
    },
    $_pageNext() {
      if (!this.currentPage) {
        this.$emit("pageto", 2);
      } else if (this.currentPage >= this.lastPage) {
        return;
      } else {
        this.$emit("pageto", this.currentPage + 1);
      }
    },
    $_onGoingToPageSubmit() {
      this.$emit("pageto", parseInt(this.goingToPage));
    },
    $_pageTo(page) {
      this.$emit("pageto", page);
    },
  },
  watch: {
    currentPage: {
      handler() {
        this.goingToPage = this.currentPage;
      },
    },
  },
  mounted() {
    this.goingToPage = this.currentPage;
  },
};
</script>
import wasa from '@/models/wasa'
import wasa_hr_skill from '@/models/wasa_hr_skill'
import business_extension from '@/models/business_extension'
import client from '@/models/client'
import evaluation_material_option from '@/models/evaluation_material_option'
import evaluation_material from '@/models/evaluation_material'
import evaluation_type from '@/models/evaluation_type'
import excute_material from '@/models/excute_material'
import excute_type from '@/models/excute_type'
import friend from '@/models/friend'
import hr_position from '@/models/hr_position'
import hr_recruit from '@/models/hr_recruit'
import hr_skill from '@/models/hr_skill'
import leave_day from '@/models/leave_day'
import work_from_home from '@/models/work_from_home'
import order from '@/models/order'
import order_stage from '@/models/order_stage'
import out_source from '@/models/out_source'
import project from '@/models/project'
import punch from '@/models/punch'
import quotation from '@/models/quotation'
import quotation_excute from '@/models/quotation_excute'
import quotation_description from '@/models/quotation_description'
import report from '@/models/report'
import tag from '@/models/tag'
import task from '@/models/task'
import milestone from '@/models/milestone'
import work_type from '@/models/work_type'
import evaluation from '@/models/evaluation'
import additional_working_day from '@/models/additional_working_day'
import additional_off_day from '@/models/additional_off_day'
import public_property from '@/models/public_property'
import overtime_apply from '@/models/overtime_apply'
import billing_statement from '@/models/billing_statement'
import accounting_subject_first from '@/models/accounting_subject_first'
import accounting_subject_second from '@/models/accounting_subject_second'
import voucher from '@/models/voucher'
import voucher_template from '@/models/voucher_template'
import voucher_event from '@/models/voucher_event'
export default {
  namespaced: true,
  state: {
    wasa: wasa,
    wasa_hr_skill: wasa_hr_skill,
    business_extension: business_extension,
    client: client,
    evaluation_material_option: evaluation_material_option,
    evaluation_material: evaluation_material,
    evaluation_type: evaluation_type,
    excute_material: excute_material,
    excute_type: excute_type,
    friend: friend,
    hr_position: hr_position,
    hr_recruit: hr_recruit,
    hr_skill: hr_skill,
    leave_day: leave_day,
    work_from_home: work_from_home,
    order: order,
    order_stage: order_stage,
    out_source: out_source,
    project: project,
    punch: punch,
    quotation: quotation,
    quotation_excute: quotation_excute,
    quotation_description: quotation_description,
    report: report,
    tag: tag,
    task: task,
    milestone: milestone,
    work_type: work_type,
    evaluation: evaluation,
    additional_working_day: additional_working_day,
    additional_off_day: additional_off_day,
    public_property: public_property,
    overtime_apply: overtime_apply,
    billing_statement: billing_statement,
    accounting_subject_first: accounting_subject_first,
    accounting_subject_second: accounting_subject_second,
    voucher: voucher,
    voucher_template: voucher_template,
    voucher_event: voucher_event,
  },
  mutations: {
  },
  actions: {
  }
}

<template>
  <div class="ws-des">
    <WsIcon size="20">error</WsIcon>
    <div class="ws-des__text">
      <slot></slot>
    </div>
  </div>
</template>

<script>
export default {};
</script>

<style>
</style>
<template>
  <div
    v-if="menu"
    class="ws-breadcrumbs"
    :class="[{'bottom-line':bottomLine}]"
  >
    <div
      v-for="(menuItem,menuIndex) in menu"
      :key="menuIndex"
      class="ws-breadcrumbs__item"
    >
      <WsIcon
        v-if="menuIndex>0"
        class="ws-breadcrumbs__item__arrow"
      >keyboard_arrow_right</WsIcon>
      <router-link
        v-if="menuItem.to"
        :to="menuItem.to"
        class="ws-breadcrumbs__item__text link"
      >{{menuItem.text}}</router-link>
      <div
        v-else
        class="ws-breadcrumbs__item__text"
      >{{menuItem.text}}</div>
    </div>
  </div>
</template>

<script>
export default {
  props: {
    bottomLine: {
      type: Boolean,
      default: true,
    },
    menu: {
      type: Array,
      default: null,
    },
  },
};
</script>